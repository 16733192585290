import { useAuth } from "../../hooks/useAuth"
import { Navigate } from "react-router-dom"

export const PageOAuth = () => {
  const { signed } = useAuth()

  if (!signed) return <Navigate to="/sign-in" />

  return <Navigate to="/channels/posts" />
}
