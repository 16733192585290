import { createStyles } from "@raisesistemas/ds"

export const useStyles = createStyles(() => ({
  container: {
    position: "relative"
  },
  icon: {
    fontSize: 16,
    position: "absolute",
    top: 18,
    left: 10,
    transform: "translateY(-50%)"
  },
  input: {
    paddingLeft: 36
  }
}))
