import { useMutation, useQueryClient } from "@tanstack/react-query"
import { topicCreate } from "../../api/topic/topicCreate"
import { queries } from "../../queries"
import { TopicEdit } from "../../api/topic/topicEdit"
import { topicDelete } from "../../api/topic/topicDelete"

export const useTopicMutations = () => {
  const queryClient = useQueryClient()

  const create = useMutation({
    mutationFn: topicCreate,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queries.topic.listWithChannels._def
      })
    }
  })

  const edit = useMutation({
    mutationFn: TopicEdit,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queries.topic.listWithChannels._def
      })
    }
  })

  const _delete = useMutation({
    mutationFn: topicDelete,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queries.topic.listWithChannels._def
      })
    }
  })
  return {
    create,
    edit,
    delete: _delete
  }
}
