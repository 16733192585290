import { supabase } from "../../lib/supabase"

export async function channelDelete(channelId: string) {
  const { error } = await supabase
    .from("db")
    .update({ kind: "trash_channel", parent_id: null })
    .eq("id", channelId)

  if (error) throw new Error(error.message)
}
