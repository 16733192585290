import { useNavigate } from "react-router-dom"
import { drawers } from "../../../state"
import * as ChatSignal from "../../../pages/messages/PageMessages/components/Chat/Chat.signal"

import { isOnline } from "../../../utils/isOnline"
import { useMemberQueries } from "../../../hooks/queries/useMemberQueries"

export type MemberDetailParams = { memberId: string }

export const useMemberDetail = (params: MemberDetailParams) => {
  const { memberId } = params

  const navigate = useNavigate()
  const { member, isLoading } = useMemberQueries().detail(memberId)

  const handleSendMessage = () => {
    if (!member) return
    drawers.member.detail.close()
    ChatSignal.init({
      id: member?.user_uuid,
      avatar: member.data.avatar,
      name: member.data.name
    })
    navigate(`/messages?memberId=${memberId}`)
  }

  return {
    member,
    isLoading,
    isOnline: isOnline(member?.user_uuid),
    handleSendMessage
  }
}
