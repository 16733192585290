import { useInfiniteQuery } from "@tanstack/react-query"
import { queries } from "../../queries"

export const usePostQueries = () => {
  const byUserId = (memberId: string) => {
    return useInfiniteQuery({
      ...queries.posts.listByUser({ userId: memberId }),
      enabled: !!memberId,
      initialPageParam: 1,
      getNextPageParam: (lastPage: any) => {
        return lastPage.nextId ?? undefined
      },
      getPreviousPageParam: (firstPage: any) => {
        return firstPage.previousId ?? undefined
      }
    })
  }
  return {
    byUserId
  }
}
