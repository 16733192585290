import { UploadImageOptions, uploadImage } from "../api/upload/uploadImage"
import { DEFAULT_AVATAR_OPTIONS } from "../lib/constants"
import { base64ToBlob } from "../utils/base64ToBlob"

export const useImageUpload = () => {
  const handleUploadImage = async (
    base64?: string,
    defaultOptions?: UploadImageOptions
  ) => {
    const options = Object.assign({}, DEFAULT_AVATAR_OPTIONS, defaultOptions)
    if (!base64) return null
    const blob = await base64ToBlob(base64)
    return await uploadImage(blob, options)
  }

  return {
    handleUploadImage
  }
}
