import { KINDS } from "../../lib/constants"
import { supabase } from "../../lib/supabase"
import { PostModel } from "../../types/post"
import { getTenant } from "../../utils/getTenant"
import { createInfiniteService } from "../../utils/createInfiniteService"

export type PostType = Pick<PostModel, "id" | "data">
export type PostListByUserPayload = { userId: string }

export const postListByUser = createInfiniteService<
  PostListByUserPayload,
  PostType
>(({ userId, rangeEnd, rangeStart, limit = 10 }) => {
  return supabase
    .from("db")
    .select(`id::text, data`, { count: "exact", head: false })
    .eq("tenant", getTenant())
    .eq("kind", KINDS.POST)
    .eq("user_uuid", userId)
    .order("id")
    .limit(limit)
    .range(rangeStart, rangeEnd)
})
