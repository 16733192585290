import { CommentReply } from "./CommentReply"
import { InfiniteScroll } from "../common/InfiniteScroll"
import { ReplyType } from "../../types/comment"
import { useReplyQueries } from "../../hooks/queries/useReplyQueries"
import { DsBox } from "@raisesistemas/ds"

export type CommentRepliesProps = {
  commentId: string
  channelId?: string
}

export const CommentReplies = (props: CommentRepliesProps) => {
  const { commentId, channelId } = props

  const { list } = useReplyQueries()
  const query = list(commentId)

  const renderItem = (reply: ReplyType) => (
    <CommentReply reply={reply} channelId={channelId} />
  )

  return (
    <DsBox data-testid={COMMENT_REPLIES_ID}>
      <InfiniteScroll
        query={query}
        renderItem={renderItem}
        keyExtractor={item => item.id}
        data-testid={COMMENT_REPLIES_ID}
      />
    </DsBox>
  )
}

export const COMMENT_REPLIES_ID = "comment-replies-id"
