import {
  DsButtonPrimary,
  DsFlex,
  DsSpace,
  DsSwitch,
  DsText,
  DsTextInputBase,
  createStyles
} from "@raisesistemas/ds"
import { capitalize } from "../../../utils/capitalize"

import { useTranslation } from "react-i18next"
import { UseFormReturnType } from "@mantine/form"

const useStyles = createStyles(() => ({
  linkEditorInput: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: 0
  },
  linkEditorSave: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
  }
}))

export type EditorLinkPreviewContentProps = {
  form: UseFormReturnType<{
    url: string
    withPreview: boolean
  }>
  onSubmit: () => void
}

export const EditorLinkPreviewContent = (
  props: EditorLinkPreviewContentProps
) => {
  const { form, onSubmit } = props
  const { t } = useTranslation()
  const { classes } = useStyles()
  return (
    <div>
      <DsFlex>
        <DsTextInputBase
          classNames={{ input: classes.linkEditorInput }}
          placeholder="https://example.com"
          type="url"
          {...form.getInputProps("url")}
        />

        <DsButtonPrimary className={classes.linkEditorSave} onClick={onSubmit}>
          {t("button.save")}
        </DsButtonPrimary>
      </DsFlex>

      <DsSpace height={16} />

      <DsFlex gap={16}>
        <DsSwitch {...form.getInputProps("withPreview")} />
        <DsText variant="body-2">
          {capitalize(t("common.create", { type: t("root.bookmark") }))}
        </DsText>
      </DsFlex>
    </div>
  )
}
