import { supabase } from "../../lib/supabase"
import { MemberDataType } from "../../types/member"
import { getTenant } from "../../utils/getTenant"

export type JoinPayload = {
  user_uuid: string
  role: string
  tenant?: string
  data: MemberDataType
}

export function memberCreate(payload: JoinPayload) {
  const record = {
    user_uuid: payload.user_uuid,
    role: payload.role,
    tenant: payload.tenant || getTenant(),
    data: {
      ...payload.data,
      created_at: new Date().toISOString()
    }
  }

  return supabase.from("member").insert(record)
}
