import {
  DsCenter,
  DsSpace,
  DsStackVertical,
  DsText,
  createStyles,
  useDsTheme
} from "@raisesistemas/ds"
import { PropsWithChildren } from "react"

import { useTranslation } from "react-i18next"
import { IconType } from "react-icons/lib"
import { useThemeColoring } from "../../hooks/useThemeColoring"

const useStyles = createStyles(
  ({ shadows, radius, colorScheme, colors, fn, spacing }) => ({
    textCenter: {
      textAlign: "center"
    },
    box: {
      boxShadow: shadows.sm,
      borderRadius: radius.md,
      border: `1px solid ${
        colorScheme === "dark" ? colors.dark[4] : colors.gray[2]
      }`,
      padding: spacing.lg
    },
    boxBorderDashed: {
      borderStyle: "dashed",
      borderColor: fn.primaryColor()
    },
    icon: {
      path: {
        strokeWidth: 12
      }
    }
  })
)

const EmptyStateRoot = (
  props: PropsWithChildren<{ dashed?: boolean; className?: string }>
) => {
  const { children, dashed = false, className, ...rest } = props
  const { classes, cx } = useStyles()
  return (
    <DsStackVertical
      align="center"
      spacing="xs"
      className={cx(
        classes.box,
        { [classes.boxBorderDashed]: dashed },
        className
      )}
      {...rest}
    >
      {children}
    </DsStackVertical>
  )
}

const EmptyStateIcon = (props: { icon: IconType; size?: number }) => {
  const { icon: Icon, size = 56 } = props
  const { fn } = useDsTheme()
  const { classes } = useStyles()
  return <Icon size={size} color={fn.primaryColor()} className={classes.icon} />
}

const EmptyStateTitle = (props: PropsWithChildren<unknown>) => {
  const { children } = props
  const { t } = useTranslation()
  const { classes } = useStyles()

  return (
    <DsCenter>
      <DsText
        className={classes.textCenter}
        variant="body-1"
        weight="semi-bold"
      >
        {children || t("root.empty_state_title")}
      </DsText>
    </DsCenter>
  )
}

const EmptyStateDescription = (props: PropsWithChildren<unknown>) => {
  const { children } = props
  const { t } = useTranslation()
  const { lightDark } = useThemeColoring()
  const { classes } = useStyles()
  return (
    <DsCenter>
      <DsText
        className={classes.textCenter}
        variant="body-2"
        color={lightDark("#A0AEC0", "gray.4")}
        weight="regular"
      >
        {children || t("root.empty_state_description")}
      </DsText>
    </DsCenter>
  )
}

const EmptyStateFooter = (props: PropsWithChildren<unknown>) => {
  const { children } = props
  return (
    <>
      <DsSpace height="sm" />
      {children}
    </>
  )
}

export const EmptyState = {
  Root: EmptyStateRoot,
  Icon: EmptyStateIcon,
  Title: EmptyStateTitle,
  Description: EmptyStateDescription,
  Footer: EmptyStateFooter
}
