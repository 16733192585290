import { useTranslation } from "react-i18next"
import { Dropzone, DropzoneProps } from "@mantine/dropzone"
import { DsBox, DsStackVertical, createStyles } from "@raisesistemas/ds"

import { TextCenter } from "./common/TextCenter"
import { IconCloudUpload, IconX } from "../icons"

const useStyles = createStyles(({ fn }) => ({
  iconWrapper: {
    width: 60,
    height: 60,
    backgroundColor: fn.variant({ variant: "light", color: "gray" }).hover,
    borderRadius: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}))

type FileDropzoneProps = Omit<DropzoneProps, "children"> & {
  description?: string
}

export const FileDropzone = (props: FileDropzoneProps) => {
  const { t } = useTranslation()
  const { classes, theme } = useStyles()
  const { description = t("input.dropzone.description"), ...rest } = props
  return (
    <Dropzone {...rest}>
      <DsStackVertical align="center" spacing="xl">
        <Dropzone.Accept>
          <DsBox className={classes.iconWrapper}>
            <IconCloudUpload color={theme.fn.primaryColor()} size={35} />
          </DsBox>
        </Dropzone.Accept>
        <Dropzone.Reject>
          <DsBox className={classes.iconWrapper}>
            <IconX color={theme.colors.red[5]} size={35} />
          </DsBox>
        </Dropzone.Reject>
        <Dropzone.Idle>
          <DsBox className={classes.iconWrapper}>
            <IconCloudUpload color={theme.fn.primaryColor()} size={35} />
          </DsBox>
        </Dropzone.Idle>

        <TextCenter variant="body-2" color={theme.fn.primaryColor()}>
          {description}
        </TextCenter>
      </DsStackVertical>
    </Dropzone>
  )
}
