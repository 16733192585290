import { commentReply } from "../../api/reply/commentReplyCreate"
import { commentReplyDelete } from "../../api/reply/commentReplyDelete"
import { commentReplyEdit } from "../../api/reply/commentReplyEdit"
import { replyLike } from "../../api/reply/replyLike"
import { queries } from "../../queries"
import { toast } from "@raisesistemas/ds"

import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

export const useReplyMutations = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const onSuccess = () => {
    queryClient.invalidateQueries({ queryKey: queries.posts.list._def })
    queryClient.invalidateQueries({ queryKey: queries.comments.list._def })
    queryClient.invalidateQueries({ queryKey: queries.replies.list._def })
  }

  const create = useMutation({
    mutationFn: commentReply,
    onSuccess
  })

  const destroy = useMutation({
    mutationFn: commentReplyDelete,
    onSuccess,
    onError() {
      toast.error({
        message: t("error.message.reply_delete_fail")
      })
    }
  })

  const edit = useMutation({
    mutationFn: commentReplyEdit,
    onError() {
      toast.error({
        message: t("error.message.reply_edit_fail")
      })
    },
    onSuccess
  })

  const like = useMutation({
    mutationFn: replyLike
  })

  return { create, destroy, edit, like }
}
