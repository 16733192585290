import { MS_ACTIVITIES_URL } from "../../env"
import { getTenant } from "../../utils/getTenant"
import { getUserId } from "../../utils/getUserId"

type ActivityPreferencePayload = {
  paused: boolean
  data: string[]
}

export const activityPreferenceUpdate = async (
  payload: ActivityPreferencePayload
) => {
  const { paused, data } = payload
  const userId = await getUserId()

  const endpoint = `${MS_ACTIVITIES_URL}/preferences`

  const body = {
    user_id: userId,
    tenant: getTenant(),
    paused,
    data: data
  }
  const response = await fetch(endpoint, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  })

  if (!response.ok) {
    throw new Error(response.statusText)
  }
}
