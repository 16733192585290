import { useMutation, useQueryClient } from "@tanstack/react-query"
import { postFixedRemove } from "../../api/post/postFixedRemove"
import { queries } from "../../queries"
import { useStandardToast } from "../useStandardToast"

export const usePostFixedRemoveMutation = () => {
  const queryClient = useQueryClient()
  const { cantFinalize } = useStandardToast()

  return useMutation({
    mutationFn: postFixedRemove,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queries.posts.list._def })
    },
    onError: () => {
      cantFinalize()
    }
  })
}
