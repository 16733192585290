import { KINDS } from "../../lib/constants"
import { supabase } from "../../lib/supabase"
import { getTenant } from "../../utils/getTenant"

type SpaceConfigEditPayload = {
  primaryColor: string
}

export const spaceConfigEdit = async (payload: SpaceConfigEditPayload) => {
  const tenant = getTenant()

  if (!tenant) throw new Error("tenant not found")

  const { error } = await supabase
    .from("db")
    .update({
      data: {
        theme: {
          colors: {
            primary: payload.primaryColor
          }
        },
        colorScheme: "light"
      }
    })
    .eq("tenant", tenant)
    .eq("kind", KINDS.SPACE_CONFIG)

  if (error) throw error
}
