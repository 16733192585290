import { KINDS } from "../../lib/constants"
import { supabase } from "../../lib/supabase"

type TopicDeletePayload = {
  topicId: string
}

export const topicDelete = async (payload: TopicDeletePayload) => {
  const channelsResult = await supabase
    .from("db")
    .update({
      parent_id: null
    })
    .eq("parent_id", payload.topicId)

  const topicResult = await supabase
    .from("db")
    .update({
      kind: KINDS.TOPIC_TRASH
    })
    .eq("id", payload.topicId)

  if (channelsResult.error || topicResult.error)
    throw new Error(topicResult.error?.message)
}
