import { Providers } from "./Providers"
import { RouterProvider } from "react-router-dom"

import { HIDE_DEBUG_RESOURCES } from "./lib/constants"
import { lazy } from "react"
import { Loader } from "./components/Loader"
import { router } from "./routes"

const DebugMenu = lazy(() => import("./components/DebugMenu"))
const ReactQueryDevtools = lazy(() =>
  import("@tanstack/react-query-devtools").then(module => ({
    default: module.ReactQueryDevtools
  }))
)

export const App = () => {
  return (
    <Providers>
      <RouterProvider
        router={router}
        fallbackElement={<Loader mih="100vh" />}
      />
      {!HIDE_DEBUG_RESOURCES && <DebugMenu />}
      {!HIDE_DEBUG_RESOURCES && <ReactQueryDevtools initialIsOpen={false} />}
    </Providers>
  )
}
