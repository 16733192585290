import { createQueryKeys } from "@lukemorales/query-key-factory"
import { commentList } from "../api/comment/commentList"
import {
  CommentListByUserIdPayload,
  commentListByUserId
} from "../api/comment/commentListByUserId"

export const commentQueries = createQueryKeys("comments", {
  list: ({ postId }: { postId: string }) => ({
    queryKey: [{ postId }],
    queryFn: ({ pageParam = 1 }: { pageParam: number }) =>
      commentList({ postId, page: pageParam })
  }),
  listById: ({ userId }: CommentListByUserIdPayload) => ({
    queryKey: [{ userId }],
    queryFn: ({ pageParam = 1 }: { pageParam: number }) =>
      commentListByUserId({ userId, page: pageParam })
  })
})
