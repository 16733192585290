import { DsText } from "@raisesistemas/ds"

export type ErrorMessageProps = {
  children: React.ReactNode
}

export const ErrorMessage = ({ children }: ErrorMessageProps) => {
  return (
    <DsText color="red" variant="body-3" weight="regular">
      {children}
    </DsText>
  )
}
