import { BankBRType } from "../../types/bank"

export const bankBRListAll = async () => {
  const response = await fetch("https://brasilapi.com.br/api/banks/v1")

  if (!response.ok) throw new Error(response.statusText)

  const data = (await response.json()) as BankBRType[]

  const parsedData = data.filter(bank => !!bank.name)

  return parsedData || []
}
