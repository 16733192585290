import { useLocalStorage } from "@mantine/hooks"
import { DsColorScheme } from "@raisesistemas/ds"
import { RN } from "../../env"

export const useColorScheme = () => {
  const defaultValue = RN ? (RN.isDarkMode ? "dark" : "light") : "light"
  return useLocalStorage<DsColorScheme>({
    key: "luminy-color-scheme",
    defaultValue,
    getInitialValueInEffect: true
  })
}
