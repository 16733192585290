import { useTranslation } from "react-i18next"

import {
  DsBox,
  DsSpace,
  DsStackVertical,
  DsTextInputBase,
  toast
} from "@raisesistemas/ds"
import { EmojiPicker } from "../EmojiPicker"
import { ChannelFooter } from "./ChannelFooter"
import { Sheet } from "../Sheet"
import { ChannelCanMemberWrite } from "./ChannelCanMemberWrite"
import { ChannelAccessSelect } from "./ChannelAccessSelect"
import { drawers } from "../../state"
import { useForm } from "@mantine/form"
import { useChannelMutations } from "../../hooks/mutations/useChannelMutations"
import { useCharactersRemaining } from "../../hooks/useCharactersRemaining"
import { channelCreateSchema } from "../../validations/channelCreateSchema"
import { ChannelCreatePayload } from "../../api/channel/channelCreate"

export type ChannelCreateDrawerProps = {
  topicId?: string
}

const MAX_TITLE_CHARACTERS = 24

export const ChannelCreateDrawer = () => {
  const { isOpen, close, props } = drawers.channel.create
  const { topicId } = props
  const { t } = useTranslation()

  const ChannelMutations = useChannelMutations()

  const form = useForm<ChannelCreatePayload>({
    initialValues: {
      title: "",
      emoji: "",
      locked: false,
      topicId: topicId,
      can_member_write: false
    },
    validate: channelCreateSchema(t)
  })

  const { characters } = useCharactersRemaining(
    MAX_TITLE_CHARACTERS,
    form.values.title.length
  )

  const onSuccess = () => {
    toast.success({ message: t("toast.channel.created") })
    close()
  }

  const handleSubmit = () => {
    form.onSubmit(values => {
      ChannelMutations.create.mutate(values, { onSuccess })
    })()
  }

  return (
    <Sheet title={t("root.create_channel")} opened={isOpen} onClose={close}>
      <DsBox>
        <DsStackVertical>
          <DsTextInputBase
            withAsterisk
            label={t("input.channel.create.label")}
            placeholder={t("input.channel.create.placeholder")}
            maxLength={MAX_TITLE_CHARACTERS}
            description={characters}
            inputWrapperOrder={["label", "input", "description", "error"]}
            {...form.getInputProps("title")}
          />

          <EmojiPicker
            value={form.values.emoji}
            onSelect={value => form.setFieldValue("emoji", value)}
            error={form.errors?.emoji ? String(form.errors?.emoji) : ""}
            required
          />

          <ChannelAccessSelect
            locked={Boolean(form.values.locked)}
            onSelectPrivate={() => form.setFieldValue("locked", true)}
            onSelectPublic={() => form.setFieldValue("locked", false)}
          />

          <DsSpace height={8} />

          <ChannelCanMemberWrite {...form.getInputProps("can_member_write")} />

          <DsSpace height={8} />

          <ChannelFooter
            isEdit={false}
            onClose={close}
            onSubmit={handleSubmit}
            isLoading={ChannelMutations.create.isPending}
          />
        </DsStackVertical>
      </DsBox>
    </Sheet>
  )
}
