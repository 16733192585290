import { KINDS } from "../../lib/constants"
import { supabase } from "../../lib/supabase"
import { DbIdOnlyType } from "../../types/shared"
import { getTenant } from "../../utils/getTenant"
import { getUserId } from "../../utils/getUserId"
import { authorizeMemberToCreatePost } from "./authorizeMemberToCreatePost"

export type ChannelCreatePayload = {
  topicId?: string
  title: string
  emoji: string
  can_member_write: boolean
  locked: boolean
}

export async function channelCreate(payload: ChannelCreatePayload) {
  const userId = await getUserId()

  const record = {
    tenant: getTenant(),
    kind: KINDS.CHANNEL,
    data: {
      emoji: payload.emoji,
      title: payload.title,
      locked: payload.locked
    },
    user_uuid: userId,
    parent_id: payload.topicId
  }

  const { data, error } = await supabase
    .from("db")
    .insert(record)
    .select("id::text")
    .limit(1)
    .returns<DbIdOnlyType[]>()
    .single()

  if (error) throw new Error(error.message)

  await authorizeMemberToCreatePost({
    parent_id: data.id,
    can_write: payload.can_member_write
  })
}
