import { Sheet } from "../../Sheet"
import { LikeItem, LikeMember } from "../LikeItem"

import { drawers } from "../../../state"
import { useTranslation } from "react-i18next"
import { useLikeQueries } from "../../../hooks/queries/useLikeQueries"
import { InfiniteScroll } from "../../common/InfiniteScroll"

export type LikeListDrawerProps = {
  parentId: string
  kind: string
}

export const LikeListDrawer = () => {
  const { t } = useTranslation()
  const { list } = useLikeQueries()

  const { close, isOpen, props } = drawers.like.list
  const query = list(props)

  const renderItem = ({ member }: { member: LikeMember }) => (
    <LikeItem member={member} />
  )

  return (
    <Sheet opened={isOpen} onClose={close} title={t("common.likes")}>
      <InfiniteScroll query={query} renderItem={renderItem} />
    </Sheet>
  )
}
