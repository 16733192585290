import { useEffect } from "react"

type WebsocketProps = {
  url: string
  onRecieveData: (data: any) => void
}

export const useWebsocket = (props: WebsocketProps) => {
  const { url, onRecieveData } = props

  useEffect(() => {
    const socket = new WebSocket(url)
    socket.addEventListener("message", event => {
      const data = JSON.parse(event.data)
      if (data) {
        onRecieveData(data)
      }
    })

    return () => {
      socket.close()
    }
  }, [url])
}
