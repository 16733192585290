import { useInfiniteQuery } from "@tanstack/react-query"
import { queries } from "../../queries"

export const useCommentQueries = () => {
  const byUserId = (userId: string) => {
    return useInfiniteQuery({
      ...queries.comments.listById({ userId }),
      enabled: !!userId,
      initialPageParam: 1,
      getNextPageParam: (lastPage: any) => {
        return lastPage.nextId ?? undefined
      },
      getPreviousPageParam: (firstPage: any) => {
        return firstPage.previousId ?? undefined
      }
    })
  }

  const byPost = (postId: string) => {
    return useInfiniteQuery({
      ...queries.comments.list({ postId }),
      initialPageParam: 1,
      getNextPageParam: (lastPage: any) => {
        return lastPage.nextId ?? undefined
      },
      getPreviousPageParam: (firstPage: any) => {
        return firstPage.previousId ?? undefined
      }
    })
  }

  return {
    byUserId,
    byPost
  }
}
