import {
  DsFlex,
  DsImage,
  DsSpace,
  DsStackVertical,
  DsText,
  createStyles,
  rem
} from "@raisesistemas/ds"
import { NodeViewRendererProps, NodeViewWrapper } from "@tiptap/react"

const useStyles = createStyles(({ radius, fn }) => {
  const colors = fn.variant({ variant: "light", color: "gray" })
  return {
    container: {
      display: "flex",
      backgroundColor: colors.background,
      borderRadius: radius.sm,
      padding: `${rem(8)} ${rem(16)}`,
      gap: rem(24),
      justifyContent: "space-between",
      cursor: "pointer",
      margin: `${rem(8)} 0`,

      "&:hover": {
        textDecoration: "none !important",
        backgroundColor: colors.hover
      }
    }
  }
})

export const LinkPreviewNode = (props: NodeViewRendererProps) => {
  const {
    node: { attrs }
  } = props

  const { classes, theme } = useStyles()

  const { color } = theme.fn.variant({ variant: "light", color: "dark" })

  return (
    <NodeViewWrapper
      as="a"
      href={attrs.href}
      className={classes.container}
      target="_blank"
    >
      <DsStackVertical spacing={0}>
        <DsText weight="medium" variant="body-2" color={color}>
          {attrs.title}
        </DsText>

        {attrs.description && (
          <DsText variant="body-3" color="dimmed">
            {attrs.description}
          </DsText>
        )}

        <DsSpace height={20} />

        <DsFlex align="center" gap={8}>
          <DsImage src={attrs.cover} width={16} height={16} />
          <DsText variant="body-3" color="dimmed">
            {attrs.href}
          </DsText>
        </DsFlex>
      </DsStackVertical>
    </NodeViewWrapper>
  )
}
