import { zodResolver } from "@mantine/form"
import { TFunction } from "i18next"
import { z } from "zod"

export type FinishRegisterForm = {
  phone: string
  dialCode: string
  acceptedTerms: false
}

export const memberInviteSchema = (t: TFunction<"translation", undefined>) => {
  const schema = z.object({
    members: z
      .array(
        z.object({
          name: z.string().min(2, {
            message: t("drawer.invite_members.input.name.validation")
          }),
          email: z
            .string()
            .email({
              message: t("drawer.invite_members.input.email.validation")
            })
        })
      )
      .min(1)
  })

  return zodResolver(schema)
}
