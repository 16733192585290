import { ReactNode } from "react"
import { DsText, DsTextProps, createStyles } from "@raisesistemas/ds"

type TextCenterProps = Partial<DsTextProps> & { children: ReactNode }

const useStyles = createStyles(() => ({
  text: {
    textAlign: "center"
  }
}))

export const TextCenter = (props: TextCenterProps) => {
  const { variant = "body-1", weight = "regular", className, ...rest } = props
  const { classes, cx } = useStyles()
  return (
    <DsText
      className={cx(classes.text, className)}
      variant={variant}
      weight={weight}
      {...rest}
    />
  )
}
