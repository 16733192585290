import {
  DsButtonUnstyled,
  DsGroup,
  DsStackVertical,
  DsText,
  createStyles
} from "@raisesistemas/ds"
import { useTranslation } from "react-i18next"

export const useStyles = createStyles(({ fn, primaryColor, radius }) => {
  const primaryVariant = fn.variant({ variant: "light", color: primaryColor })
  const grayVariant = fn.variant({ variant: "light", color: "gray" })
  return {
    accessButton: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      background: grayVariant.background,
      padding: "0.5rem",
      borderRadius: radius.sm,
      color: grayVariant.color,

      "&:hover": {
        background: grayVariant.hover
      }
    },
    accessButtonActive: {
      background: primaryVariant.background,
      color: primaryVariant.color,

      "&:hover": {
        background: primaryVariant.hover
      }
    }
  }
})

type ChannelAccessSelectProps = {
  locked: boolean
  onSelectPrivate: () => void
  onSelectPublic: () => void
}

export const ChannelAccessSelect = (props: ChannelAccessSelectProps) => {
  const { locked, onSelectPrivate, onSelectPublic } = props
  const { t } = useTranslation()
  const { cx, classes } = useStyles()
  return (
    <DsStackVertical spacing="xs">
      <DsText variant="body-2" weight="medium">
        {t("root.access")}
      </DsText>
      <DsGroup>
        <DsButtonUnstyled
          className={cx(classes.accessButton, {
            [classes.accessButtonActive]: !locked
          })}
          onClick={onSelectPublic}
        >
          {t("root.open")}
        </DsButtonUnstyled>
        <DsButtonUnstyled
          className={cx(classes.accessButton, {
            [classes.accessButtonActive]: locked
          })}
          onClick={onSelectPrivate}
        >
          {t("root.private")}
        </DsButtonUnstyled>
      </DsGroup>
      {locked && (
        <DsText variant="body-3" weight="regular" color="dimmed">
          <strong>{t("root.only_invited_members")}</strong>.
          {t("root.other_members_will_see")}
        </DsText>
      )}
    </DsStackVertical>
  )
}
