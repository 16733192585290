export const getSeparator = (line: string) => {
  const validSeparators = [",", ";"]

  for (const separator of validSeparators) {
    if (line.includes(separator)) {
      return separator
    }
  }

  return null
}

export const csvJSON = (csv: string) => {
  const lines = csv.split("\n").filter(line => line.trim() !== "")
  if (lines.length === 0) return []

  const result = []

  const separator = getSeparator(lines[0])

  if (!separator) return []

  const headers = lines[0].split(separator).map(header => header.trim())

  for (let lineIndex = 1; lineIndex < lines.length; lineIndex++) {
    const object: Record<string, string> = {}

    const values = lines[lineIndex]
    const separator = getSeparator(values)

    if (!separator) continue

    const currentLine = lines[lineIndex]
      .split(separator)
      .map(line => line.trim())

    for (let objectIndex = 0; objectIndex < headers.length; objectIndex++) {
      object[headers[objectIndex]] = currentLine[objectIndex]
    }

    result.push(object)
  }

  return result
}
