import { getHost } from "./getHost"
import { STORAGE_KEYS } from "../lib/constants"

export const getTenant = () => {
  const tenant = localStorage.getItem(STORAGE_KEYS.TENANT_ID) || ""
  const currentHost = getHost()

  if (currentHost !== "app" && !currentHost.includes(".")) {
    return currentHost
  }

  return tenant
}
