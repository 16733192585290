import { supabase } from "../../lib/supabase"
import { MemberAudience } from "../../types/member"
import { getTenant } from "../../utils/getTenant"

export const memberByAudience = async (audienceId: string) => {
  if (!audienceId) return []
  const { data, error } = await supabase
    .from("member_audience")
    .select("audience_id::text, member(*)")
    .eq("tenant", getTenant())
    .eq("audience_id", audienceId)
    .returns<MemberAudience[]>()

  if (error) throw error

  return data
}
