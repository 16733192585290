import { PlanFormValues } from "./usePlanForm"
import { getPlanPriceCents } from "./getPlanPriceCents"

export const planPresenter = (plan: PlanFormValues) => {
  const price_cents = getPlanPriceCents({
    fee_paid_by_member: plan.fee_paid_by_member,
    price_cents: plan.price_cents
  })

  return { ...plan, price_cents }
}
