import {
  DsButtonSecondary,
  DsGroup,
  DsMediaQuery,
  DsStackVertical,
  DsText,
  useDsTheme
} from "@raisesistemas/ds"
import { useTranslation } from "react-i18next"
import { Sheet } from "../Sheet"
import { createRandomId } from "../../utils/createRandomId"
import { drawers } from "../../state"

export type ExtractSimpleData = {
  label: string
  value: string
}

export type ExtractSimpleDrawerProps = {
  title: string
  subtitle?: string
  data: ExtractSimpleData[]
}

export const ExtractSimpleDrawer = () => {
  const { isOpen, close, props } = drawers.extract.simple
  const { data, subtitle, title } = props

  const { t } = useTranslation()
  const { colors } = useDsTheme()

  return (
    <Sheet onClose={close} opened={isOpen} title={title}>
      <DsStackVertical spacing={16}>
        {subtitle && (
          <DsText variant="body-2" weight="regular" color={colors.gray[6]}>
            {subtitle}
          </DsText>
        )}

        {data.map(extract => (
          <DsStackVertical key={createRandomId()} spacing={8}>
            <DsText variant="body-2" weight="regular">
              {extract.label}
            </DsText>
            <DsText variant="body-2" weight="regular" color={colors.gray[6]}>
              {extract.value}
            </DsText>
          </DsStackVertical>
        ))}

        <DsGroup position="right">
          <DsMediaQuery smallerThan="md" styles={{ width: "100%" }}>
            <DsButtonSecondary onClick={close}>
              {t("root.back")}
            </DsButtonSecondary>
          </DsMediaQuery>
        </DsGroup>
      </DsStackVertical>
    </Sheet>
  )
}
