import { DsButtonUnstyled, DsFlex, DsText, useDsTheme } from "@raisesistemas/ds"

import { IconHeart } from "../../../icons"
import { drawers } from "../../../state"
import { AvatarOnline } from "../../AvatarOnline"
import { MemberRole } from "../../../types/member"
import { ROLES } from "../../../lib/constants"
import { isOnline } from "../../../utils/isOnline"

export type LikeMember = {
  user_uuid: string
  name: string
  avatar: string
  role: MemberRole
}

type LikeItemProps = {
  member: LikeMember
}

export const LikeItem = (props: LikeItemProps) => {
  const { avatar, name, user_uuid, role } = props.member
  const isBlockedMember = role === ROLES.BLOCKED_MEMBER
  const { colors } = useDsTheme()

  const handleOpenProfile = () => {
    if (isBlockedMember) return

    drawers.member.detail.open({ memberId: user_uuid })
  }
  return (
    <DsButtonUnstyled onClick={handleOpenProfile} style={{ width: "100%" }}>
      <DsFlex justify="space-between" align="center">
        <DsFlex gap={8} align="center">
          <AvatarOnline
            name={name}
            size="md"
            src={avatar}
            online={isOnline(user_uuid)}
          />

          <DsText variant="body-1" weight="medium">
            {name}
          </DsText>
        </DsFlex>

        <IconHeart size={20} color={colors.red[7]} />
      </DsFlex>
    </DsButtonUnstyled>
  )
}
