import { KINDS } from "../../lib/constants"
import { supabase } from "../../lib/supabase"
import { getTenant } from "../../utils/getTenant"
import { CommentListByUserIdType } from "../../types/comment"
import { createInfiniteService } from "../../utils/createInfiniteService"

export type CommentListByUserIdPayload = {
  userId: string
}

export const commentListByUserId = createInfiniteService<
  CommentListByUserIdPayload,
  CommentListByUserIdType
>(({ userId, rangeEnd, rangeStart, limit = 20 }) => {
  const tenant = getTenant()

  return supabase
    .from("db")
    .select(
      `
    id::text,
    data,
    member(data),
    post:parent_id!inner(
      id::text,
      data,
      channel:parent_id!inner(
        id::text,
        data
      )
    )
  `,
      { count: "exact", head: false }
    )
    .eq("tenant", tenant)
    .eq("kind", KINDS.POST_COMMENT)
    .eq("user_uuid", userId)
    .eq("post.kind", KINDS.POST)
    .limit(limit)
    .order("id")
    .range(rangeStart, rangeEnd)
})
