import { RichTextEditor } from "@mantine/tiptap"
import { DsBox, DsFlex } from "@raisesistemas/ds"
import {
  EditorGifPicker,
  EditorGifPickerProps
} from "../Editor/EditorGifPicker"
import { EditorEmojiPicker } from "../Editor/EditorEmojiPicker"
import { createStyles, rem } from "@mantine/core"
import { Editor } from "@tiptap/react"

type RichTextProps = Omit<EditorGifPickerProps, "classNames"> & {
  editor: Editor | null
  classNames?: { root?: string; content?: string; actions?: string }
  showActions?: boolean
}

export const RichText = (props: RichTextProps) => {
  const {
    editor,
    onGifPick,
    classNames,
    showActions = true,
    ...restProps
  } = props
  const { cx, classes } = useStyles()
  return (
    <RichTextEditor
      editor={editor}
      classNames={{
        root: cx(classes.root, classNames?.root),
        content: cx(classes.content, classNames?.content)
      }}
      {...restProps}
    >
      <RichTextEditor.Content />
      {showActions && (
        <DsBox className={cx(classes.actions, classNames?.actions)}>
          <DsFlex gap={16}>
            <EditorGifPicker onGifPick={onGifPick} />
            <EditorEmojiPicker />
          </DsFlex>
        </DsBox>
      )}
    </RichTextEditor>
  )
}

const useStyles = createStyles({
  root: {
    flex: 1,
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    "&>div": { flex: 1, wordBreak: "break-word" }
  },
  content: {
    backgroundColor: "transparent",
    "&>div.tiptap": { padding: `${rem(10)} ${rem(12)}` }
  },
  actions: {
    padding: rem(7),
    display: "flex",
    justifyContent: "flex-end",
    flex: "none !important"
  }
})
