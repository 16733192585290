import { useMutation, useQueryClient } from "@tanstack/react-query"
import { commentCreate } from "../../api/comment/commentCreate"
import { queries } from "../../queries"
import { useStandardToast } from "../useStandardToast"

export const useCommentCreateMutation = () => {
  const { cantFinalize } = useStandardToast()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: commentCreate,
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: queries.posts._def })
      queryClient.invalidateQueries(
        queries.comments.list({ postId: variables.parent_id })
      )
    },
    onError: () => {
      cantFinalize()
    }
  })
}
