import { createQueryKeys } from "@lukemorales/query-key-factory"
import { postById } from "../api/post/postById"
import { postList } from "../api/post/postList"
import {
  PostListByUserPayload,
  postListByUser
} from "../api/post/postListByUser"
import { getTenant } from "../utils/getTenant"

export const postQueries = createQueryKeys("posts", {
  list: ({ channelId }: { channelId?: string }) => ({
    queryKey: [{ channelId, tenant: getTenant() }],
    queryFn: ({ pageParam = 1 }: { pageParam: number }) =>
      postList({ channelId, page: pageParam, limit: 2 })
  }),
  listByUser: ({ userId }: PostListByUserPayload) => ({
    queryKey: [{ userId }],
    queryFn: ({ pageParam = 1 }: { pageParam: number }) =>
      postListByUser({ userId, page: pageParam })
  }),
  detail: (postId: string) => ({
    queryKey: [postId],
    queryFn: () => postById(postId)
  })
})
