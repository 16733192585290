import { getTenant } from "./getTenant"
import { getSpaceUrl } from "./getSpaceUrl"

type NavigateToSpaceProps = {
  space: string
  replace: boolean
  path: string
}

export const navigateToSpace = async (props?: NavigateToSpaceProps) => {
  const { space, replace, path } = props || {
    space: getTenant(),
    replace: false,
    path: "/"
  }
  if (space) {
    const redirectUrl = await getSpaceUrl(space, path)
    if (replace) {
      return window.location.replace(redirectUrl)
    } else {
      return window.open(redirectUrl, "_blank")
    }
  }
}
