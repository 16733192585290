type CalculateImageDimensionsProps = {
  image: HTMLImageElement
  maxWidth?: number
  maxHeight?: number
}

export const calculateImageDimensions = ({
  image,
  maxWidth = 200,
  maxHeight = 200
}: CalculateImageDimensionsProps) => {
  const { width: imageWidth, height: imageHeight } = image
  let width = maxWidth
  let height = maxHeight

  if (imageWidth > maxWidth || imageHeight > maxHeight) {
    const aspectRatio = imageWidth / imageHeight

    if (imageWidth > imageHeight) {
      height = Math.round(maxWidth / aspectRatio)
    } else {
      width = Math.round(maxHeight * aspectRatio)
    }
  }

  return { width, height }
}
