import { useAuth } from "../useAuth"
import { permissions } from "./permissions"
import { PermissionCan } from "./permissions.types"

export const usePermissions = () => {
  const { user } = useAuth()
  const can: PermissionCan = (role, action, resource, data) => {
    if (
      !user ||
      !permissions[role] ||
      !permissions[role][resource] ||
      !permissions[role][resource][action]
    ) {
      return false
    }
    const permission = permissions[role][resource][action]

    if (typeof permission === "boolean") return permission
    return permission(user, data as any)
  }
  return { can }
}
