import { DsBox, DsFlex, DsLoader } from "@raisesistemas/ds"
import { useInfiniteQuery } from "@tanstack/react-query"
import { forwardRef } from "react"

type UseInfiniteQueryProps = ReturnType<typeof useInfiniteQuery>

type LoadMoreProps = Pick<
  UseInfiniteQueryProps,
  "fetchNextPage" | "isFetchingNextPage" | "hasNextPage"
>

export const LoadMore = forwardRef<HTMLDivElement, LoadMoreProps>(
  (props, ref) => {
    const { isFetchingNextPage, hasNextPage } = props

    if (!hasNextPage) return null

    return (
      <DsBox sx={{ padding: 24 }}>
        <DsFlex justify="center">
          <div ref={ref}>{isFetchingNextPage && <DsLoader />}</div>
        </DsFlex>
      </DsBox>
    )
  }
)
