import { init, BrowserTracing, Replay } from "@sentry/react"

export const sentryInit = () => {
  if (import.meta.env.PROD) {
    init({
      dsn:
        "https://e436b8d68d865d3754dce11eae840592@o4506512810573824.ingest.sentry.io/4506512828399616",
      environment: "production",
      integrations: [
        new BrowserTracing({
          tracePropagationTargets: [
            "localhost",
            /^https:\/\/yourserver\.io\/api/
          ]
        }),

        new Replay({
          maskAllText: false,
          blockAllMedia: false
        })
      ],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0
    })
  }
}
