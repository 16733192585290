import { MS_ACTIVITIES_URL } from "../../env"
import { ActivityType } from "../../types/activity"

import { buildURL } from "../../utils/buildUrl"
import { fetchAPI } from "../../utils/fetchAPI"
import { getTenant } from "../../utils/getTenant"
import { getUserId } from "../../utils/getUserId"

type MetadataData = {
  emoji?: string
  title?: string
  content?: string
}

type ResponseData = ActivityType<unknown, MetadataData>[]

export type ActivitiesLatestListPayload = {
  limit?: number
  page?: number
}

export const activitiesLatestList = async (
  props: ActivitiesLatestListPayload
) => {
  const tenant = getTenant()
  const user_id = await getUserId()
  const { limit = 20, page = 1 } = props

  const currentPage = Math.max(0, page - 1)
  const skip = currentPage * limit

  const queryParams = {
    tenant,
    user_id,
    limit: String(limit),
    skip: String(skip),
    type: "post,event",
    time: "24"
  }

  const url = buildURL(`${MS_ACTIVITIES_URL}/activities`, queryParams)

  const data = await fetchAPI<ResponseData>(url)

  return {
    data,
    nextId: data.length < limit ? null : page + 1,
    previousId: page > 0 ? page - 1 : null
  }
}
