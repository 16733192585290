import { RefAttributes } from "react"
import { ReactRenderer } from "@tiptap/react"
import { SuggestionProps } from "@tiptap/suggestion"
import { MentionOptions } from "@tiptap/extension-mention"
import tippy, { GetReferenceClientRect, Instance, Props } from "tippy.js"

import MentionList from "./MentionList"
import { asyncDebounce } from "../../utils/asyncDebounce"
import { Roles, memberSearch } from "../../api/member/memberSearch"

export type SuggestionItem = {
  id: string
  name: string
  avatar: string
}

const search = async (query: string, mentionRoles?: Roles) => {
  const members = await memberSearch({ query, roles: mentionRoles })

  const filtered = members.filter(item =>
    item.data.name.toLowerCase().startsWith(query.toLowerCase())
  )

  const items = filtered.map(member => ({
    id: member.user_uuid,
    name: member.data.name,
    avatar: member.data.avatar
  }))

  return items
}

const debouncedSearch = asyncDebounce(search, 1000)

export const suggestions = (
  mentionRoles?: Roles
): MentionOptions["suggestion"] => ({
  items: async ({ query }) => {
    return await debouncedSearch(query, mentionRoles)
  },

  render: () => {
    let component: ReactRenderer<
      any,
      SuggestionProps<SuggestionItem> & RefAttributes<any>
    >
    let popup: Instance<Props>[]

    return {
      onStart: (props: SuggestionProps<SuggestionItem>) => {
        component = new ReactRenderer(MentionList, {
          props,
          editor: props.editor
        })

        if (!props.clientRect) {
          return
        }

        popup = tippy("body", {
          getReferenceClientRect: props.clientRect as GetReferenceClientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: "manual",
          placement: "bottom-start"
        })
      },

      onUpdate(props) {
        component.updateProps(props)

        if (!props.clientRect) {
          return
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect as GetReferenceClientRect
        })
      },

      onKeyDown(props) {
        if (props.event.key === "Escape") {
          popup[0].hide()

          return true
        }

        return component.ref?.onKeyDown(props)
      },

      onExit() {
        popup[0].destroy()
        component.destroy()
      }
    }
  }
})
