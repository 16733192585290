import { JSONContent } from "@tiptap/react"
import { supabase } from "../../lib/supabase"
import { getTenant } from "../../utils/getTenant"
import { getUserId } from "../../utils/getUserId"
import { makeUrl } from "../../utils/makeUrl"
import { uploadImageBase64 } from "../upload/uploadImageBase64"

export type EventCreatePayload = {
  name: string
  type: "local" | "online"
  local?: string
  banner: string
  end_date: string
  start_date: string
  end_hour: string
  start_hour: string
  available_to: string
  link: string
  speaker?: string | Array<{ id: string; name: string }>
  content: JSONContent
}

export const eventCreate = async (payload: EventCreatePayload) => {
  const userId = await getUserId()

  const banner = await uploadImageBase64(payload.banner, {
    maxWidth: 840,
    maxHeight: 300,
    quality: 0.8
  })

  if (!banner) throw new Error("Banner upload failed")

  const { error } = await supabase.from("db").insert({
    kind: "event",
    tenant: getTenant(),
    user_uuid: userId,
    data: { ...payload, banner, link: makeUrl(payload.link) }
  })

  if (error) throw error
}
