import { EditorOptions } from "@tiptap/react"
import { useTranslation } from "react-i18next"

import { useDefaultEditor } from "./useDefaultEditor"
import { PlaceholderOptions } from "@tiptap/extension-placeholder"

type PostEditorOptions = Partial<EditorOptions> & {
  placeholder?: Partial<PlaceholderOptions>
}

export const usePostEditor = (options: PostEditorOptions) => {
  const { t } = useTranslation()

  return useDefaultEditor({
    ...options,
    placeholder: {
      emptyNodeClass: "is-post-empty",
      emptyEditorClass: "is-post-editor-empty",
      showOnlyCurrent: false,
      placeholder: ({ editor, node }) => {
        const blockCount = editor.view.state.doc.content.childCount

        if (blockCount > 2) return ""

        if (node.type.name === "heading") {
          return t("input.post.create.title.placeholder")
        }

        return t("input.post.edit.content.placeholder")
      }
    }
  })
}
