import { getTenant } from "../../utils/getTenant"
import { getSession } from "../auth/getSession"
import { MS_PAYMENTS_URL } from "../../env"
import { ExtractDataExist } from "../../types/extract"

export const extractOwnerExistsList = async () => {
  const { access_token } = await getSession()

  const response = await fetch(
    `${MS_PAYMENTS_URL}/withdraw-statement/${getTenant()}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json"
      }
    }
  )

  if (!response.ok) throw new Error("Extract list error")

  return (await response.json()) as ExtractDataExist[]
}
