import { supabase } from "../../lib/supabase"
import { getUserId } from "../../utils/getUserId"
import { MemberDataType, MemberType } from "../../types/member"
import { getTenant } from "../../utils/getTenant"

export type MemberEditPayload = Partial<MemberDataType> & {
  user_uuid?: string
}

export async function memberEdit(payload: MemberEditPayload) {
  const { user_uuid = await getUserId(), ...restDataPayload } = payload

  const { data, error } = await supabase
    .from("member")
    .update({
      data: restDataPayload
    })
    .eq("user_uuid", user_uuid)
    .eq("tenant", getTenant())
    .select()
    .single<MemberType>()

  if (error) throw new Error(error.message)

  return { member: data }
}
