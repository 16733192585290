import { createQueryKeys } from "@lukemorales/query-key-factory"
import { audienceList } from "../api/audience/audienceList"
import { getTenant } from "../utils/getTenant"
import { memberByAudience } from "../api/member/memberByAudience"
import { memberAudienceFindUnique } from "../api/member/memberAudienceFindUnique"
import { audienceListWithMembers } from "../api/audience/audienceListWithMembers"
import { audienceById } from "../api/audience/audienceById"

export const audienceQueries = createQueryKeys("audience", {
  list: () => ({
    queryKey: [getTenant()],
    queryFn: audienceList
  }),
  listWithMembers: () => ({
    queryKey: ["withMembers", getTenant()],
    queryFn: audienceListWithMembers
  }),
  byId: (audienceId: string) => ({
    queryKey: [audienceId],
    queryFn: () => audienceById(audienceId)
  }),
  byAudience: (audienceId: string) => ({
    queryKey: [audienceId],
    queryFn: () => memberByAudience(audienceId)
  }),
  findUnique: (payload: { audienceId: string; memberId: string }) => ({
    queryKey: [payload],
    queryFn: () => memberAudienceFindUnique(payload)
  })
})
