import { Fragment, memo } from "react"
import { DsBadge, DsGroup } from "@raisesistemas/ds"
import { createRandomId } from "../../../../utils/createRandomId"

type Tag = {
  name: string
  color: string
  size: string
  isVisible: boolean
}

type TagsProps = {
  tags?: Tag[]
}

export const Tags = memo((props: TagsProps) => {
  const { tags } = props

  const visibleTags = tags?.filter(({ isVisible }) => isVisible)
  if (!tags) return <Fragment />

  return (
    <DsGroup spacing={2}>
      {visibleTags?.map(({ color, name, size }) => (
        <DsBadge key={createRandomId()} color={color} size={size}>
          {name}
        </DsBadge>
      ))}
    </DsGroup>
  )
})
