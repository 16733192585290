import {
  DsMediaQuery,
  DsStackVertical,
  DsButtonPrimary,
  DsButtonSecondary
} from "@raisesistemas/ds"
import { useTranslation } from "react-i18next"

type SheetDefaultFooterProps = {
  disabled?: boolean
  submitText?: string
  isLoading?: boolean
  onSubmit?: () => void
  onClose: () => void
}

export const SheetDefaultFooter = (props: SheetDefaultFooterProps) => {
  const { t } = useTranslation()
  const {
    onSubmit,
    onClose,
    isLoading,
    submitText = t("button.save"),
    disabled
  } = props
  return (
    <DsMediaQuery
      largerThan="md"
      styles={{
        flexDirection: "row-reverse",
        justifyContent: "end"
      }}
    >
      <DsStackVertical spacing="sm">
        {onSubmit && (
          <DsButtonPrimary
            disabled={isLoading || disabled}
            onClick={onSubmit}
            loading={isLoading}
          >
            {submitText}
          </DsButtonPrimary>
        )}

        <DsButtonSecondary onClick={onClose}>
          {t("root.back")}
        </DsButtonSecondary>
      </DsStackVertical>
    </DsMediaQuery>
  )
}
