import { InfinitListResponse } from "../../components/InfinitList/useInfinitList"
import { getUserId } from "../../utils/getUserId"
import { getSession } from "../auth/getSession"
import { Direct } from "./direct"
import { DirectConversationType } from "../../types/direct"

type DirectListGroupPayload = {
  page: number
}

export const directListGroup = async (
  payload: DirectListGroupPayload
): Promise<InfinitListResponse<DirectConversationType>> => {
  const { access_token } = await getSession()
  const { page } = payload
  const userId = await getUserId()

  const url = new URL(Direct.endpoint.listGroup(userId, page))
  const config = Direct.fetchConfig("GET", access_token)

  const res = await fetch(url, config)
  const data = Array.from(await res.json()) as DirectConversationType[]

  const hasNextPage = data && data.length === Direct.pagination

  const nextPage = page + 1
  const isLast = !res.ok || !hasNextPage

  return {
    data,
    nextPage,
    page,
    isLast
  }
}
