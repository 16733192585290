import {
  DsBox,
  DsSpace,
  DsSpoiler,
  DsStackVertical,
  createStyles
} from "@raisesistemas/ds"
import { useTranslation } from "react-i18next"

import { PostContent } from "../post/PostItem/PostContent"
import { PostType } from "../../api/post/postListByUser"
import { InfiniteScroll } from "../common/InfiniteScroll"
import { usePostQueries } from "../../hooks/queries/usePostQueries"

import { EmptyState } from "../EmptyState"
import { IconCubeOutline } from "../../icons"

type MemberPostsProps = {
  memberId: string
}

const useStyles = createStyles(theme => {
  const isDark = theme.colorScheme === "dark"
  const borderColor = isDark ? theme.colors.dark[5] : theme.colors.gray[2]
  return {
    container: {
      boxShadow: theme.shadows.sm,
      padding: theme.spacing.lg,
      borderRadius: theme.radius.md,
      border: `1px solid ${borderColor}`
    }
  }
})

export const MemberPosts = (props: MemberPostsProps) => {
  const { memberId } = props
  const { t } = useTranslation()
  const { classes } = useStyles()

  const { byUserId } = usePostQueries()
  const query = byUserId(memberId)

  const renderItem = (post: PostType) => (
    <DsBox className={classes.container}>
      <DsStackVertical>
        <DsSpoiler
          maxHeight={200}
          hideLabel={t("root.see_less")}
          showLabel={t("root.see_more")}
        >
          <PostContent content={post.data.content} />
        </DsSpoiler>
      </DsStackVertical>
    </DsBox>
  )

  const empty = (
    <EmptyState.Root dashed>
      <EmptyState.Icon icon={IconCubeOutline} />
      <EmptyState.Title>{t("empty_state.member.posts.title")}</EmptyState.Title>
    </EmptyState.Root>
  )
  return (
    <>
      <DsSpace height={24} />
      <InfiniteScroll query={query} renderItem={renderItem} empty={empty} />
    </>
  )
}
