import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import resourcesToBackend from "i18next-resources-to-backend"
import { z } from "zod"
import { makeZodI18nMap } from "zod-i18n-map"

import zodEN from "zod-i18n-map/locales/en/zod.json"
import zodPT from "zod-i18n-map/locales/pt/zod.json"

import ptBR from "./ptBR/translation.json"
import enUS from "./enUS/translation.json"

i18n
  .use(initReactI18next)
  .use(
    resourcesToBackend(async (language, namespace, callback) => {
      try {
        const resources = await import(`./${language}/${namespace}.json`)
        callback(null, resources.default)
      } catch (error) {}
    })
  )
  .init({
    fallbackLng: "ptBR",
    defaultNS: "translation",
    resources: {
      enUS: {
        zod: zodEN,
        translation: enUS
      },
      ptBR: {
        zod: zodPT,
        translation: ptBR
      }
    }
  })

z.setErrorMap(makeZodI18nMap({ ns: ["zod", "translation", "custom"] }))

export default i18n
