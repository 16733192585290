import { useDsTheme } from "@raisesistemas/ds"

export const useThemeColoring = () => {
  const { fn, colors, colorScheme } = useDsTheme()
  const isDark = colorScheme === "dark"
  const isLight = colorScheme === "light"

  const getPathColor = (target: string) => {
    if (target.startsWith("#")) return target
    const [color, variant] = target.split(".")
    return colors[color][Number(variant)]
  }

  const lightDark = (lightColor: string, darkColor: string) => {
    return isDark ? getPathColor(darkColor) : getPathColor(lightColor)
  }

  return {
    fn,
    colors,
    isDark,
    isLight,
    lightDark
  }
}
