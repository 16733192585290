import { supabase } from "../../lib/supabase"
import { MemberType } from "../../types/member"
import { getTenant } from "../../utils/getTenant"

export const memberListAll = async () => {
  const { data, error } = await supabase
    .from("member")
    .select("*")
    .eq("tenant", getTenant())
    .eq("data->>confirmed", true)
    .order("data->>created_at", { ascending: false })
    .returns<MemberType[]>()

  if (error) throw new Error("Member list all")
  return {
    members: data
  }
}
