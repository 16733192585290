import ReactDOM from "react-dom/client"
import { sentryInit } from "./lib/sentry"
import { facebookEventsInit } from "./lib/facebook"
import { App } from "./App"
import "./locales"
import "./styles/global.css"
import "./styles/editor.css"
import "./styles/emoji-picker.css"

sentryInit()
facebookEventsInit()
ReactDOM.createRoot(document.getElementById("root")!).render(<App />)
