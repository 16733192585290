import { random } from "../../utils/random"
import { sleep } from "../../utils/sleep"

export type MessagesWithMemberPayload = {
  memberId?: string
  page: number
}

export const messagesWithMember = async (
  payload: MessagesWithMemberPayload
) => {
  await sleep(4000)

  if (payload.page === 1) return [...page1]
  if (payload.page === 2) return [...page2, ...page1]
  if (payload.page === 3) return [...page3, ...page2, ...page1]

  return page1
}

const fakeMessages = () => {
  const dialogues = [
    {
      message1: "Oi, como vai?",
      message2: "Estou bem, obrigado. E você?"
    },
    {
      message1: "Você viu o novo filme que lançaram?",
      message2: "Sim, eu vi. Achei muito bom!"
    },
    {
      message1: "Qual é a sua comida favorita?",
      message2: "Gosto muito de pizza. E você?"
    },
    {
      message1: "Estou pensando em fazer uma viagem no próximo mês.",
      message2: "Que legal! Para onde você está pensando em ir?"
    },
    {
      message1: "Você já leu algum livro interessante recentemente?",
      message2: "Sim, acabei de ler um romance que gostei muito."
    },
    {
      message1: "Você pratica algum esporte?",
      message2: "Sim, eu jogo futebol nas horas vagas."
    },
    {
      message1: "Qual é o seu animal de estimação favorito?",
      message2: "Adoro cachorros. E você?"
    },
    {
      message1: "Como foi o seu final de semana?",
      message2: "Foi ótimo! Passei tempo com amigos e familiares."
    },
    {
      message1: "Você já experimentou comida tailandesa?",
      message2: "Sim, é uma das minhas favoritas."
    },
    {
      message1: "Qual é o seu filme preferido?",
      message2: "Gosto muito de 'O Senhor dos Anéis'. E você?"
    }
  ]

  const chats = []

  const LENGHT = 5
  for (let i = 0; i < LENGHT; i++) {
    const messages = []
    const numMessages = Math.floor(Math.random() * LENGHT) + 1

    for (let j = 0; j < numMessages; j++) {
      const index = random.number(0, dialogues.length - 1)
      const { message1, message2 } = dialogues[index]
      const isUserMessage = random.boolean()
      const message = {
        id: j + 1,
        message: isUserMessage ? message1 : message2,
        isUserMessage
      }
      messages.push(message)
    }

    const chat = {
      date: random.date(new Date(2012, 0, 1), new Date()).toString(),
      messages: messages
    }

    chats.push(chat)
  }

  return chats
}

const page1 = fakeMessages()
const page2 = fakeMessages()
const page3 = fakeMessages()
