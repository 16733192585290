import { KINDS } from "../../lib/constants"
import { supabase } from "../../lib/supabase"
import { ChannelType } from "../../types/channel"
import { getTenant } from "../../utils/getTenant"

export type ChannelByIdPayload = {
  id?: string
}

export const channelById = async (payload: ChannelByIdPayload) => {
  if (!payload.id) return undefined

  const { data, error } = await supabase
    .from("db")
    .select(
      `
        id::text, 
        data,
        can_member_write:rls_parent_write(count)
      `
    )
    .eq("tenant", getTenant())
    .eq("kind", KINDS.CHANNEL)
    .eq("id", payload.id)
    .eq("can_member_write.role", "member")
    .eq("can_member_write.resource", "post")
    .eq("can_member_write.parent_id", payload.id)
    .single<ChannelType>()

  if (error) throw error

  return {
    ...data,
    //@ts-ignore
    can_member_write: data.can_member_write[0].count > 0
  }
}
