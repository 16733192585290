import { KINDS } from "../../lib/constants"
import { supabase } from "../../lib/supabase"
import { ChannelType } from "../../types/channel"
import { SortType } from "../../types/sort"
import { TopicType } from "../../types/topic"
import { getTenant } from "../../utils/getTenant"

type ChannelResult = ChannelType & {
  sort: SortType
}

type TopicResult = TopicType & {
  channels: ChannelResult[]
}

export const topicListWithChannels = async () => {
  const topicPromise = new Promise<TopicResult[]>(async (resolve, reject) => {
    const { data, error } = await supabase
      .from("db")
      .select(
        `
        *,
        id::text,
        sort( id::text, position, kind, tenant),
        channels:db!parent_id(
          *, 
          id::text,
          sort( id::text, position, kind, tenant)
        )
        `
      )
      .eq("tenant", getTenant())
      .eq("kind", KINDS.TOPIC)
      .returns<TopicResult[]>()

    if (error) return reject(error.message)
    return resolve(data)
  })

  const channelsWithouTopicPromise = new Promise<ChannelResult[]>(
    async (resolve, reject) => {
      const { data, error } = await supabase
        .from("db")
        .select(
          `
          *,
          id::text,
          sort( id::text, position, kind, tenant)
        `
        )
        .eq("tenant", getTenant())
        .eq("kind", KINDS.CHANNEL)
        .is("parent_id", null)
        .returns<ChannelResult[]>()

      if (error) return reject(error.message)
      return resolve(data)
    }
  )

  const [topicResult, channelResult] = await Promise.all([
    topicPromise,
    channelsWithouTopicPromise
  ])

  return {
    topics: topicResult,
    channelsWithoutTopic: channelResult
  }
}
