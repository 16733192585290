import { createQueryKeys } from "@lukemorales/query-key-factory"

import { GifSearchPayload, gifSearch } from "../api/gif/gifSearch"

export const gifQueries = createQueryKeys("gifs", {
  search: (payload: GifSearchPayload) => ({
    queryKey: [payload],
    queryFn: ({ pageParam = "" }: { pageParam: string }) =>
      gifSearch({ ...payload, next: pageParam })
  })
})
