import {
  DsBox,
  DsStackHorizontal,
  DsStackVertical,
  DsTextInputBase,
  DsTextInputEmail,
  DsButtonUnstyled,
  createStyles,
  rem
} from "@raisesistemas/ds"
import { IconMinusCircle } from "../../../icons"

import { useTranslation } from "react-i18next"
import {
  Member,
  useMemberInviteFormContext
} from "./member-invite-form-context"

const useStyles = createStyles(({ fn, radius, colors }) => ({
  box: {
    border: `${rem(1)} solid ${
      fn.variant({ variant: "default", color: "gray" }).border
    }`,
    padding: rem(16),
    borderRadius: radius.md
  },
  invalidBox: {
    border: `${rem(1)} solid ${colors.red[5]}`
  },

  inner: {
    flex: 1
  }
}))

type MemberInviteByEmailUserCardProps = {
  index: number
  showRemoveBtn: boolean
}

export const MemberInviteByEmailUserCard = (
  props: MemberInviteByEmailUserCardProps
) => {
  const { index, showRemoveBtn } = props
  const { t } = useTranslation()
  const { classes, theme, cx } = useStyles()

  const form = useMemberInviteFormContext()
  const remove = () => form.removeListItem("members", index)

  const hasFieldError = (field: keyof Member) =>
    !!form.errors[`members.${index}.${field}`]
  const hasError = hasFieldError("email") || hasFieldError("name")
  return (
    <DsBox
      key={index}
      className={cx(classes.box, {
        [classes.invalidBox]: hasError
      })}
    >
      <DsStackHorizontal spacing="md">
        <DsStackVertical className={classes.inner}>
          <DsTextInputBase
            withAsterisk
            label={t("input.name.label")}
            placeholder={t("input.name.placeholder_first_name")}
            {...form.getInputProps(`members.${index}.name`)}
          />
          <DsTextInputEmail
            isRequired
            label={t("input.email.label")}
            placeholder={t("input.email.placeholder_write_email")}
            {...form.getInputProps(`members.${index}.email`)}
          />
        </DsStackVertical>

        {showRemoveBtn && (
          <DsButtonUnstyled onClick={remove}>
            <IconMinusCircle color={theme.fn.primaryColor()} />
          </DsButtonUnstyled>
        )}
      </DsStackHorizontal>
    </DsBox>
  )
}
