import Image from "@tiptap/extension-image"
import { Extensions } from "@tiptap/react"
import Link from "@tiptap/extension-link"
import StarterKit from "@tiptap/starter-kit"
import Youtube from "@tiptap/extension-youtube"
import Highlight from "@tiptap/extension-highlight"
import Underline from "@tiptap/extension-underline"
import TextAlign from "@tiptap/extension-text-align"
import Typography from "@tiptap/extension-typography"

import { Iframe } from "../Iframe"
import { LinkPreview } from "../LinkPreview"
import { Mention, suggestions } from "../Mention"

export const ExtensionKit = (): Extensions => [
  Link.configure({
    HTMLAttributes: {
      target: "_blank"
    }
  }),
  Image,
  Highlight,
  Underline,
  Typography,
  StarterKit,
  LinkPreview,
  Iframe.configure({ HTMLAttributes: { class: "video-container" } }),
  Youtube.configure({
    controls: true,
    HTMLAttributes: { class: "video-container" }
  }),
  TextAlign.configure({ types: ["heading", "paragraph"] }),
  Mention.configure({ suggestion: suggestions() })
]
