import { JSONContent } from "@tiptap/core"
import { DsStackVertical } from "@raisesistemas/ds"

import { Comment } from "../../comment/Comment"
import { CommentType } from "../../../types/comment"
import { CommentForm } from "../../comment/CommentForm"
import { InfiniteScroll } from "../../common/InfiniteScroll"
import { useCommentQueries } from "../../../hooks/queries/useCommentQueries"
import { useCommentCreateMutation } from "../../../hooks/mutations/useCommentCreateMutation"

type PostCommentsProps = {
  postId: string
  channelId?: string
}

export const PostComments = (props: PostCommentsProps) => {
  const { postId } = props
  const mutation = useCommentCreateMutation()

  const { byPost } = useCommentQueries()
  const query = byPost(postId)

  const handleComment = (content: JSONContent) => {
    if (!postId) return
    mutation.mutate({
      content,
      parent_id: postId
    })
  }

  const renderItem = (comment: CommentType) => (
    <Comment comment={comment} channelId={props.channelId} />
  )

  return (
    <DsStackVertical spacing="sm">
      <CommentForm onSubmit={handleComment} channelId={props.channelId} />
      <InfiniteScroll query={query} renderItem={renderItem} />
    </DsStackVertical>
  )
}
