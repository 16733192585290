import debounce from "lodash.debounce"
import React, { useCallback, useEffect, useState } from "react"

import { LikeContext } from "./LikeContext"
import { incrementDecrement } from "../../utils/incrementDecrement"

type LikeProviderProps = {
  liked: boolean
  likeCount: number
  children: React.ReactNode
  onLike: (liked: boolean) => void
}

export const LikeProvider = (props: LikeProviderProps) => {
  const { children, liked, likeCount, onLike } = props
  const [clicked, setClicked] = useState(false)
  const [isLiked, setIsLiked] = useState(liked)
  const [countLike, setCountLike] = useState(likeCount)

  const like = useCallback(
    debounce(() => {
      onLike(false)
    }, 1000),
    []
  )

  const deslike = useCallback(
    debounce(() => {
      onLike(true)
    }, 1000),
    []
  )

  const handleLikeDeslike = () => {
    setClicked(true)
    setIsLiked(!isLiked)
    setCountLike(incrementDecrement(!isLiked, countLike))

    if (isLiked) {
      like.cancel()
      deslike()
    } else {
      deslike.cancel()
      like()
    }
  }

  useEffect(() => {
    if (!clicked) {
      setIsLiked(liked)
      setCountLike(likeCount)
    }
  }, [liked, likeCount])

  return (
    <LikeContext.Provider
      value={{
        likeCount: countLike,
        liked: isLiked,
        onLike: handleLikeDeslike
      }}
    >
      {children}
    </LikeContext.Provider>
  )
}
