import { createQueryKeys } from "@lukemorales/query-key-factory"
import { getTenant } from "../utils/getTenant"
import {
  messagesWithMember,
  MessagesWithMemberPayload
} from "../api/message/messagesWithMember"

export const messagesQueries = createQueryKeys("messages", {
  withMember: (payload: MessagesWithMemberPayload) => ({
    queryKey: [getTenant(), payload],
    queryFn: () => messagesWithMember(payload)
  })
})
