import { supabase } from "../../lib/supabase"
import { getTenant } from "../../utils/getTenant"

type AuthorizeMemberToCreatePostPayload = {
  parent_id: string
  can_write: boolean
}

export const authorizeMemberToCreatePost = async (
  payload: AuthorizeMemberToCreatePostPayload
) => {
  const { can_write, parent_id } = payload

  const { data } = await supabase
    .from("rls_parent_write")
    .select("*")
    .eq("parent_id", parent_id)

  if (can_write) {
    if (data && data.length > 0) return

    const record = {
      resource: "post",
      parent_id,
      owner: true,
      tenant: getTenant()
    }

    const records = ["member", "member_pro"].map(role => ({ ...record, role }))

    const { error } = await supabase.from("rls_parent_write").insert(records)

    if (error) throw error
  } else {
    if (!data || data.length === 0) return

    const { error } = await supabase.from("rls_parent_write").delete().match({
      parent_id,
      resource: "post",
      tenant: getTenant()
    })

    if (error) throw error
  }
}
