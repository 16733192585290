import { Indicator, IndicatorProps } from "@mantine/core"
import { DsAvatar, DsAvatarProps } from "@raisesistemas/ds"

type AvatarOnlineProps = DsAvatarProps & {
  online?: boolean
  onlineOptions?: Pick<IndicatorProps, "offset" | "size">
}

export const AvatarOnline = ({
  online,
  onlineOptions,
  ...rest
}: AvatarOnlineProps) => {
  return (
    <Indicator
      size={14}
      offset={5}
      position="bottom-end"
      color="green"
      withBorder
      disabled={!online}
      {...onlineOptions}
      data-testid={AVATAR_ONLINE_ID}
    >
      <DsAvatar {...rest} />
    </Indicator>
  )
}

export const AVATAR_ONLINE_ID = "avatar-online-id"
