import { STORAGE_KEYS } from "../lib/constants"
import { supabase } from "../lib/supabase"
import { setUserId } from "./setUserId"

export const getUserId = async () => {
  const userIdStorage = localStorage.getItem(STORAGE_KEYS.USER_ID)

  if (userIdStorage) return userIdStorage

  const { data } = await supabase.auth.getUser()

  if (!data.user) throw new Error("User not found")

  setUserId(data.user.id)
  return data.user.id
}
