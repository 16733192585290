import { createQueryKeys } from "@lukemorales/query-key-factory"

import { LikeListPayload, likeList } from "../api/like/likeList"

export const likesQueries = createQueryKeys("likes", {
  list: (payload: LikeListPayload) => ({
    queryKey: [payload],
    queryFn: ({ pageParam = 1 }: { pageParam: number }) =>
      likeList({ ...payload, page: pageParam })
  })
})
