import {
  createStyles,
  DsBox,
  DsButtonUnstyled,
  DsRadio,
  DsSpace,
  DsText,
  DsThemeIcon
} from "@raisesistemas/ds"
import { ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { formatMoney } from "../../utils/formatMoney"

const useStyles = createStyles(({ colors, primaryColor, fn, colorScheme }) => {
  const lightColors = fn.variant({ variant: "light", color: primaryColor })
  const isDark = colorScheme === "dark"
  return {
    card: {
      padding: "10px 16px",
      borderRadius: "12px",
      border: `1px solid ${colors.gray[isDark ? 7 : 2]}`
    },
    activeCard: {
      background: lightColors.background,
      color: lightColors.color,
      borderRadius: "12px",
      border: `2px solid ${colors[primaryColor][7]}`
    },
    textInline: {
      display: "inline-block",
      color: colors[primaryColor][9]
    }
  }
})

type PlanChooseItemProps = {
  isActive?: boolean
  onClick?: () => void
  multiple?: boolean
  data: {
    id: string
    icon: ReactNode
    title: string
    description: string
    price: number
    frequency: string
  }
}

export const PlanChooseItem = (props: PlanChooseItemProps) => {
  const { t } = useTranslation()
  const { classes, cx } = useStyles()
  const { isActive = false, onClick, data, multiple = false } = props

  const frequencyTexts: Record<string, string> = {
    month: t("root.month"),
    year: t("root.year")
  }

  return (
    <DsButtonUnstyled
      onClick={onClick}
      style={{
        pointerEvents: onClick ? "auto" : "none",
        width: "100%"
      }}
    >
      <DsBox
        className={cx(classes.card, {
          [classes.activeCard]: isActive
        })}
      >
        <DsBox style={{ display: "flex", alignItems: "center" }}>
          <DsThemeIcon size={40} radius={20}>
            {data.icon}
          </DsThemeIcon>
          <DsSpace width="sm" />
          <DsBox>
            <DsText variant="body-1" weight="regular">
              <DsText
                variant="body-1"
                weight="medium"
                className={classes.textInline}
              >
                {data.title}
              </DsText>{" "}
              {formatMoney(data.price)}/{frequencyTexts[data.frequency]}
            </DsText>
            <DsText variant="body-2" weight="regular">
              {data.description}
            </DsText>
          </DsBox>
          {onClick && multiple && (
            <DsBox style={{ marginLeft: "auto" }}>
              <DsRadio checked={isActive} />
            </DsBox>
          )}
        </DsBox>
      </DsBox>
    </DsButtonUnstyled>
  )
}
