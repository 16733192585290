import { KINDS } from "../../lib/constants"
import { supabase } from "../../lib/supabase"
import { PostType } from "../../types/post"
import { getTenant } from "../../utils/getTenant"
import { getUserId } from "../../utils/getUserId"
import { parsePost } from "../../utils/parsePost"

export async function postFixedByChannel(channelId: string) {
  if (!channelId) return null
  const userId = (await getUserId()) as string

  const { data, error } = await supabase
    .from("db")
    .select(
      `
        id::text,
        data,
        kind,
        member(user_uuid, data),
        user_uuid,
        liked:db!parent_id(id::text),
        likesCount:db!parent_id(count),
        commentsCount:db!parent_id(count),
        channel:parent_id!inner(
          id::text,
          kind,
          data
        )
      `
    )
    .eq("tenant", getTenant())
    .eq("kind", KINDS.POST_FIXED)
    .eq("parent_id", channelId)
    .eq("channel.kind", KINDS.CHANNEL)
    .eq("liked.kind", KINDS.POST_LIKE)
    .eq("likesCount.kind", KINDS.POST_LIKE)
    .eq("liked.user_uuid", userId)
    .eq("commentsCount.kind", KINDS.POST_COMMENT)
    .returns<PostType>()
    .limit(1)
    .single()

  if (!data || error) return null

  return parsePost(data, true)
}
