import { getHost } from "../../utils/getHost"
import { STORAGE_KEYS } from "../../lib/constants"
import { Navigate, Outlet } from "react-router-dom"
import { Loader } from "../Loader"

import { useNav } from "../../hooks/useNav"
import { useTenantQuery } from "../../hooks/queries/useTenantQuery"
import { useDatabasePrimaryColor } from "../../hooks/theme/useDatabasePrimaryColor"
import { useEffect } from "react"

export const TenantWrapper = () => {
  useDatabasePrimaryColor()

  const { data, isFetched } = useTenantQuery()
  const navigate = useNav()

  const currentHost = getHost()
  const isAppHost = currentHost === "app"
  const hasTenant = !!data

  useEffect(() => {
    if (hasTenant) localStorage.setItem(STORAGE_KEYS.TENANT_ID, data.tenant)
  }, [data])

  useEffect(() => {
    window.addEventListener("navigate", (props: any) => {
      navigate(props.detail.page, props.details.props)
    })
  }, [])

  if (isFetched && isAppHost) return <Navigate to="/creator" />
  if (!isFetched && !hasTenant && !isAppHost)
    return <Loader mih="100vh" data-testid="loader-tenant" />
  if (isFetched && !hasTenant) return <Navigate to="/404" />
  return <Outlet />
}
