import { KINDS } from "../../lib/constants"
import { supabase } from "../../lib/supabase"
import { TopicType } from "../../types/topic"
import { getTenant } from "../../utils/getTenant"

export type TopicByIdPayload = {
  id: string
}

export const topicById = async (payload: TopicByIdPayload) => {
  const { data, error } = await supabase
    .from("db")
    .select(
      `
        *,
        id::text
      `
    )
    .eq("tenant", getTenant())
    .eq("kind", KINDS.TOPIC)
    .eq("id", payload.id)
    .single<TopicType>()

  if (error) throw error

  return data
}
