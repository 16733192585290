import React, { Fragment } from "react"

type IframeChatBotProps = {
  chatId?: string
  width?: number
  height?: number
}

export const IframeChatBot: React.FC<IframeChatBotProps> = props => {
  const { chatId = "", width = 400, height = 600 } = props

  return (
    <Fragment>
      <iframe
        src={`https://interfaces.zapier.com/embed/chatbot/${chatId}`}
        width={width + "px"}
        height={height + "px"}
        allow="clipboard-write *"
        data-testid={IFRAME_CHAT_BOT_ID}
        style={{ border: 0 }}
      />

      <script
        type="module"
        src="https://interfaces.zapier.com/assets/web-components/zapier-interfaces/zapier-interfaces.esm.js"
      />
    </Fragment>
  )
}

export const IFRAME_CHAT_BOT_ID = "iframe-chat-bot-id"
