import { EditorOptions, useEditor } from "@tiptap/react"
import { ExtensionKit } from "../../extensions/ExtensionKit"
import Placeholder, { PlaceholderOptions } from "@tiptap/extension-placeholder"

type PostEditorOptions = Partial<EditorOptions> & {
  placeholder?: Partial<PlaceholderOptions>
}

export const useDefaultEditor = ({
  placeholder,
  ...options
}: PostEditorOptions) => {
  const extensions = [...ExtensionKit(), Placeholder.configure(placeholder)]
  return useEditor({ extensions, ...options })
}
