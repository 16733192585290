import { DsBox } from "@raisesistemas/ds"
import { useTranslation } from "react-i18next"

import { BulletPing } from "../../common/BulletPing"

import { useStyles } from "./EventBadge.style"

export const EventBadge = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  return (
    <DsBox className={classes.container}>
      {t("root.now")}
      <div className={classes.indicator} data-testid="bullet-ping">
        <BulletPing />
      </div>
    </DsBox>
  )
}
