import { supabase } from "../../lib/supabase"

type CommentDeletePayload = {
  id: string
}

export async function commentDelete({ id }: CommentDeletePayload) {
  const { error } = await supabase
    .from("db")
    .update({
      kind: "trash_post_comment"
    })
    .eq("id", id)

  if (error) throw error
}
