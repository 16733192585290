import { createQueryKeys } from "@lukemorales/query-key-factory"
import { getTenant } from "../utils/getTenant"
import { subscriptionFindUnique } from "../api/subscription/subscriptionFindUnique"

export const subscriptionQueries = createQueryKeys("subscription", {
  findUnique: () => ({
    queryKey: [getTenant()],
    queryFn: () => subscriptionFindUnique()
  })
})
