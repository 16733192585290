import { memo } from "react"
import { DsNavLink, DsNavLinkProps } from "@raisesistemas/ds"

type NavLinkProps = Pick<
  DsNavLinkProps,
  "icon" | "rightSection" | "onClick"
> & {
  title: string
  to?: string
  isActive: boolean
  handleNavigate: (path: string) => void
}

export const NavLinkV2 = memo((props: NavLinkProps) => {
  const {
    title,
    icon,
    to,
    isActive,
    onClick,
    rightSection,
    handleNavigate
  } = props

  const handleClick = () => {
    if (onClick) return onClick()
    if (to) return handleNavigate(to)
  }

  return (
    <DsNavLink
      component="div"
      isActive={isActive}
      label={title}
      icon={icon}
      onClick={handleClick}
      rightSection={rightSection}
    />
  )
})
