import { useTranslation } from "react-i18next"
import { DsButtonIcon, DsText, DsTooltip } from "@raisesistemas/ds"
import { useLike } from "../useLike"
import { IconHeart, IconHeartOutline } from "../../../icons"

export function ButtonLike() {
  const { likeCount, liked, onLike } = useLike()
  const { t } = useTranslation()

  return (
    <DsTooltip label={t("tooltip.like")}>
      <div>
        <DsButtonIcon
          leftIcon={
            liked ? (
              <IconHeart color="#FC2323" size={20} />
            ) : (
              <IconHeartOutline color="gray" size={20} />
            )
          }
          onClick={onLike}
          data-testid="button-like"
        >
          <DsText color="#A0AEC0" weight="regular" variant="body-2">
            {likeCount}
          </DsText>
        </DsButtonIcon>
      </div>
    </DsTooltip>
  )
}
