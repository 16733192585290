import { getTenant } from "../../utils/getTenant"
import { getSession } from "../auth/getSession"
import { MS_PAYMENTS_URL } from "../../env"
import { BalanceData } from "../../types/balance"

export const balanceOwner = async () => {
  const { access_token } = await getSession()

  const response = await fetch(`${MS_PAYMENTS_URL}/balance/${getTenant()}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json"
    }
  })

  if (!response.ok) throw new Error("get owner balance error")

  return (await response.json()) as BalanceData
}
