import { supabase } from "../../lib/supabase"
import { getTenant } from "../../utils/getTenant"

export const audienceDefault = async () => {
  const { data } = await supabase
    .from("audience")
    .select("id::text")
    .eq("tenant", getTenant())
    .eq("label", "_default")
    .single<{ id: string }>()

  if (data) return data.id

  const { data: output, error } = await supabase
    .from("audience")
    .insert({ tenant: getTenant(), label: "_default" })
    .select("id::text")
    .single<{ id: string }>()

  if (error) throw error

  return output.id
}
