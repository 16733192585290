import { MS_MAIL_URL } from "../../../env"
import { MemberType } from "../../../types/member"
import { getSession } from "../../auth/getSession"
import { spaceById } from "../../space/spaceById"
import { spaceConfigGet } from "../../space/spaceConfigGet"
type MemberSendBannedMailPayload = {
  tenant: string
  member: MemberType
}

export const memberSendBannedMail = async (
  payload: MemberSendBannedMailPayload
) => {
  const { access_token } = await getSession()
  const { tenant, member } = payload

  const [space, spaceConfig] = await Promise.all([
    spaceById(),
    spaceConfigGet()
  ])
  const memberNotFound = !member
  const spaceNotFound = !space

  if (memberNotFound || spaceNotFound)
    throw new Error("memberSendBannedEmail: Member or Space not found")

  const { role } = member
  const { name, email } = member.data
  const { logo, title } = space.data
  const {
    theme: {
      colors: { primary }
    }
  } = spaceConfig.data

  const url = new URL(`${MS_MAIL_URL}/member-banned`)

  const config = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      tenant,
      email,
      logo,
      memberName: name,
      color: primary,
      titleCommunity: title,
      roleMember: role
    })
  }

  const { ok } = await fetch(url, config)
  if (ok === false) throw new Error("memberSendBannedEmail: MS error")
}
