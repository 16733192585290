import { useQuery } from "@tanstack/react-query"
import { queries } from "../../queries"
import { useTranslation } from "react-i18next"
import { format, parseISO } from "date-fns"
import { useDateFnsLocale } from "../useDateFnsLocale"
import { MIN_WITHDRAWAL_AMOUNT_IN_CENTS } from "../../lib/constants"

export const useBalanceQueries = () => {
  const { t } = useTranslation()
  const locale = useDateFnsLocale()

  const balanceOwner = () => {
    const { data } = useQuery(queries.balance.balanceOwner())

    const balanceUpdatedAt = () => {
      return t("common.updated_at", {
        date: format(new Date(), "P", {
          locale: locale
        }),
        time: format(new Date(), "HH:mm")
      }).replace(/&#x2F;/g, "/")
    }

    const balanceLastWithdrawUpdatedAt = () => {
      let date = new Date()

      if (data?.last_withdraw) date = parseISO(data.last_withdraw.date)

      return t("common.withdrawal_at", {
        date: format(date, "P", {
          locale: locale
        }),
        time: format(date, "HH:mm")
      }).replace(/&#x2F;/g, "/")
    }

    return {
      balance: data,
      balanceUpdatedAt: balanceUpdatedAt(),

      balanceLastWithdrawUpdatedAt: balanceLastWithdrawUpdatedAt(),
      hasProcessingWithdraw:
        data?.last_withdraw && data.last_withdraw.status == "requested",
      enableToRequestWithdraw: Boolean(
        data && data.balance_cents >= MIN_WITHDRAWAL_AMOUNT_IN_CENTS
      )
    }
  }

  return {
    balanceOwner
  }
}
