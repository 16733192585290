import { useTranslation } from "react-i18next"
import { DsSpace, DsText } from "@raisesistemas/ds"

import { Sheet, SheetDefaultFooter } from "../../Sheet"
import { drawers } from "../../../state"
import { NotificationsManager } from "../NotificationsManager"

export const NotificationsManagerDrawer = () => {
  const { t } = useTranslation()

  const handleClose = () => {
    drawers.notifications.manager.close()
  }

  const footer = <SheetDefaultFooter onClose={handleClose} />

  return (
    <Sheet
      title={t("notification.manage_notifications")}
      opened={drawers.notifications.manager.isOpen}
      onClose={handleClose}
      footer={footer}
    >
      <div>
        <DsText variant="body-1" weight="regular" color="dimmed">
          {t("notification.notifications_paused")}
        </DsText>
        <DsSpace height={24} />
        <NotificationsManager />
      </div>
    </Sheet>
  )
}
