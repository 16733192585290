import { Session } from "@supabase/supabase-js"
import { ROLES } from "../lib/constants"
import { supabaseUserToMemberData } from "./supabaseUserToMemberData"
import { MemberRole } from "../types/member"

type Options = {
  tenant: string
  role: string
}

export const supabaseSessionToMember = (session: Session, options: Options) => {
  const { tenant = "", role = ROLES.MEMBER } = options
  return {
    tenant: tenant,
    role: role as MemberRole,
    user_uuid: session.user.id,
    data: supabaseUserToMemberData(session.user)
  }
}
