import { useCallback } from "react"
import { RN } from "../../../env"
import { useNav } from "../../../hooks/useNav"
import { drawers } from "../../../state"
import { PostModel } from "../../../types/post"

type ShowPost = {
  post: PostModel
}
export const useShowPost = (props: ShowPost) => {
  const { post } = props
  const navigate = useNav()

  const handleShowPost = useCallback(() => {
    if (RN) return navigate(`/posts/${post.id}`)
    drawers.post.view.open({ post })
  }, [post])

  return {
    handleShowPost
  }
}
