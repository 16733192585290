import { TENANT } from "../env"
import { HOSTS_DEV, IS_PRODUCTION } from "../lib/constants"

/**
 * @example const host = getHost()
 * @host app.luminy.app
 * @returns app
 *
 * @host luminy.app
 * @returns luminy.app
 *
 * @host customdomain.com
 * @returns customdomain.com
 */
export const getHost = () => {
  if (TENANT) return TENANT

  const { hostname } = window.location

  const currentHost = IS_PRODUCTION
    ? hostname.replace(/.luminy.app|luminy-stg-pr-.(\w+)./, "")
    : hostname.replace(
        /.localhost|.lvh.me|.stg.luminy.app|.dev.luminy.app|.mycomu.com/,
        ""
      )

  if (HOSTS_DEV.includes(currentHost)) {
    return "dev"
  }

  return currentHost
}
