import { DsButtonIcon, DsText, DsTooltip } from "@raisesistemas/ds"
import { useTranslation } from "react-i18next"
import { IconChatboxOutline } from "../../../icons"

type ButtonCommentProps = {
  onClick?: () => void
  count?: number
}

export function ButtonComment(props: ButtonCommentProps) {
  const { t } = useTranslation()
  return (
    <DsTooltip label={t("tooltip.write_comment")}>
      <div>
        <DsButtonIcon
          leftIcon={<IconChatboxOutline size={20} color="gray" />}
          onClick={props.onClick}
          data-testid="button-comment"
        >
          {props.count ? (
            <DsText color="#A0AEC0" weight="regular" variant="body-2">
              {props.count}
            </DsText>
          ) : null}
        </DsButtonIcon>
      </div>
    </DsTooltip>
  )
}
