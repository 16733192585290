import { Trans, useTranslation } from "react-i18next"
import { ActivityType } from "../../../types/activity"
import { createStyles } from "@raisesistemas/ds"
import { truncateText } from "../../../utils/truncateText"
import { contentToText } from "../../Editor/utils/contentToText"

type MetadataData = {
  emoji?: string
  title?: string
  content?: string
}

export type NotificationDescriptionProps = {
  data: ActivityType<unknown, MetadataData>
}

const useStyles = createStyles(({ primaryColor, colors }) => ({
  text: {
    color: colors[primaryColor][7],
    fontWeight: 600
  }
}))

export const NotificationDescription = (
  props: NotificationDescriptionProps
) => {
  const { type, metadata, target } = props.data
  const { t } = useTranslation()
  const { classes } = useStyles()

  const parentData = metadata?.data
  const content = truncateText(contentToText(parentData?.content || ""), 20)

  const values: Record<string, string | undefined> = {
    post: `${parentData?.emoji} ${parentData?.title}`,
    post_comment: truncateText(metadata?.data?.title ?? "", 20),
    comment_reply: content,
    post_like: truncateText(parentData?.title ?? "", 20),
    comment_like: content,
    reply_like: content,
    post_mention: (target?.data as { title?: string })?.title,
    comment_mention: content,
    reply_mention: content,
    event: truncateText((target?.data as { name: string })?.name ?? "", 20),
    event_mention: truncateText(
      (target?.data as { name: string })?.name ?? "",
      20
    )
  }

  return (
    <Trans
      t={t}
      i18nKey={`notification.title.${type}`}
      values={{ value: values[type] }}
      components={{ 1: <span className={classes.text} /> }}
    />
  )
}
