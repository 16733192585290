import { getTenant } from "../../utils/getTenant"
import { getSession } from "../auth/getSession"
import { MS_DELETE_ACCOUNT_URL } from "../../env"

export const memberDeleteAccount = async () => {
  const { access_token } = await getSession()

  const url = new URL(MS_DELETE_ACCOUNT_URL)
  url.searchParams.set("tenant", getTenant())

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json"
    }
  })

  if (!response.ok) throw new Error("Member delete Account error")
}
