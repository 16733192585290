import { KINDS } from "../../lib/constants"
import { supabase } from "../../lib/supabase"
import { ChannelType } from "../../types/channel"
import { getTenant } from "../../utils/getTenant"

export async function channelList() {
  const { data, error } = await supabase
    .from("db")
    .select("*, id::text")
    .eq("tenant", getTenant())
    .eq("kind", KINDS.CHANNEL)
    .order("id")
    .returns<ChannelType[]>()

  if (error) throw error

  return data
}
