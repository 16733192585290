import {
  DsButtonUnstyled,
  DsFlex,
  DsText,
  createStyles,
  rem
} from "@raisesistemas/ds"
import { drawers } from "../../state"
import { Sheet, SheetButtonBack } from "../Sheet"
import { IconDocumentTextOutline, IconHandClick } from "../../icons"
import { useTranslation } from "react-i18next"

const useStyles = createStyles(({ radius, fn }) => {
  const { border, hover } = fn.variant({ variant: "default", color: "gray" })
  return {
    button: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 8,
      border: `${rem(1)} solid ${border}`,
      borderRadius: radius.md,
      padding: 16,

      "&:hover": {
        backgroundColor: hover
      }
    }
  }
})

export const MemberInviteMethodSelectorDrawer = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const { close, isOpen } = drawers.member.inviteSelector
  return (
    <Sheet
      title={t("drawer.invite_members.title")}
      opened={isOpen}
      onClose={close}
      footer={<SheetButtonBack onClick={close} />}
    >
      <DsFlex gap={8}>
        <DsButtonUnstyled
          className={classes.button}
          onClick={drawers.member.inviteByCSV.open}
        >
          <IconDocumentTextOutline size={24} />
          <DsText variant="body-1" weight="regular">
            Excel(.csv)
          </DsText>
        </DsButtonUnstyled>

        <DsButtonUnstyled
          className={classes.button}
          onClick={drawers.member.inviteByEmail.open}
        >
          <IconHandClick size={24} />
          <DsText variant="body-1" weight="regular">
            {t("root.manually")}
          </DsText>
        </DsButtonUnstyled>
      </DsFlex>
    </Sheet>
  )
}
