import { DsButtonUnstyled, DsText, DsFlex } from "@raisesistemas/ds"
import { capitalize } from "../../utils/capitalize"
import { CommentReplies } from "./CommentReplies"
import { queries } from "../../queries"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "@tanstack/react-query"

export type CommentRepliesSectionProps = {
  commentId: string
  repliesCount: number
  channelId?: string
}

export const CommentRepliesSection = (props: CommentRepliesSectionProps) => {
  const { commentId, repliesCount, channelId } = props
  const [showReplies, setShowReplies] = useState(false)
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const query = queries.replies.list({ commentId })

  const handlePrefetchReplies = async () => {
    await queryClient.prefetchInfiniteQuery(query as any)
  }

  const handleShowReplies = () => {
    handlePrefetchReplies()
    setShowReplies(true)
  }

  const showSeeRepliesButton = repliesCount > 0 && !showReplies

  return (
    <>
      {showSeeRepliesButton && (
        <DsFlex>
          <div onMouseEnter={handlePrefetchReplies}>
            <DsButtonUnstyled onClick={handleShowReplies}>
              <DsText variant="body-1" weight="medium" color="primary">
                {capitalize(t("common.see", { type: t("root.answers") }))}
              </DsText>
            </DsButtonUnstyled>
          </div>
        </DsFlex>
      )}
      {showReplies && (
        <CommentReplies commentId={commentId} channelId={channelId} />
      )}
    </>
  )
}
