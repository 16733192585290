import { MS_EDITOR_URL } from "../../env"
import { GifsType } from "../../types/gif"
import { fetchAPI } from "../../utils/fetchAPI"
import { getSession } from "../auth/getSession"

const endpoint = `${MS_EDITOR_URL}/gif/search`

export type GifSearchPayload = {
  query?: string
  next?: string
}

export const gifSearch = async ({ query, next }: GifSearchPayload) => {
  const session = await getSession()

  const url = new URL(endpoint)

  if (query) url.searchParams.set("search", query)
  if (next) url.searchParams.set("next", next)

  const responseData = await fetchAPI<GifsType>(url, {
    headers: {
      Authorization: `Bearer ${session.access_token}`
    }
  })

  return {
    data: responseData.data,
    nextId: responseData.next,
    previousId: null
  }
}
