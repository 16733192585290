import { MemberType } from "../types/member"
import { getRoles } from "../utils/getRoles"
import { useAuth } from "./useAuth"

type Roles = ReturnType<typeof getRoles>
type User = MemberType & { roles: Roles }

export const useUser = (): User => {
  const { user } = useAuth()

  const roles = getRoles(user?.role)

  return { ...user, roles } as User
}
