import { useInfiniteQuery, useQuery } from "@tanstack/react-query"
import { queries } from "../../queries"
import { useState } from "react"
import { MemberListPayload } from "../../api/member/memberList"

type Enabled = { enabled?: boolean }

export const useMemberQueries = () => {
  const detail = (memberId: string | null) => {
    const { data, isLoading, isFetching } = useQuery({
      ...queries.members.detail(memberId as string),
      staleTime: 60 * 60 * 1000, // 1 hora
      gcTime: 60 * 60 * 1000, // 1 hora
      enabled: !!memberId
    })

    return {
      member: data,
      isLoading,
      isFetching
    }
  }

  const listAll = () => {
    const { data, isLoading } = useQuery(queries.members.listAll())
    return {
      ...data,
      isLoading
    }
  }

  const list = (payload: MemberListPayload & Enabled = {}) => {
    const { enabled = true } = payload

    return useInfiniteQuery({
      ...queries.members.list(payload),
      staleTime: 0,
      gcTime: 0,
      refetchOnWindowFocus: true,
      initialPageParam: 1,
      getNextPageParam: (lastPage: any) => {
        return lastPage.nextId ?? undefined
      },
      getPreviousPageParam: (firstPage: any) => {
        return firstPage.previousId ?? undefined
      },
      enabled
    })
  }

  const byAudience = (audienceId?: string) => {
    if (!audienceId) {
      return { members: undefined }
    }

    const { data } = useQuery(queries.members.byAudience(audienceId))

    return {
      members: data?.map(member => member.member)
    }
  }

  const getChannels = (memberId: string) => {
    const { data } = useQuery(queries.members.getChannels({ memberId }))

    return {
      channels: data
    }
  }

  const search = (payload?: { confirmed: boolean }) => {
    const [searchQuery, setSearchQuery] = useState("")

    const query = useQuery({
      ...queries.members.search({
        query: searchQuery,
        confirmed: payload?.confirmed
      })
    })
    return {
      query,
      input: {
        value: searchQuery,
        set: setSearchQuery
      }
    }
  }

  return {
    list,
    listAll,
    getChannels,
    byAudience,
    detail,
    search
  }
}
