import { JSONContent } from "@tiptap/core"
import { supabase } from "../../lib/supabase"

type CommentCreatePayload = {
  content: JSONContent
  id: string
}

export async function commentReplyEdit(payload: CommentCreatePayload) {
  const { error } = await supabase
    .from("db")
    .update({
      data: {
        content: payload.content
      }
    })
    .eq("id", payload.id)
  if (error) throw error
}
