import { memo } from "react"
import { DsText, DsTextProps } from "@raisesistemas/ds"
import { Box } from "@mantine/core"
import { useShowMemberDetail } from "../../../../hooks/useShowMemberDetail"

type NameProps = Pick<DsTextProps, "color" | "weight" | "variant"> & {
  memberId?: string
  name: string
}

export const Name = memo((props: NameProps) => {
  const { memberId, name, color, variant = "body-3", weight = "bold" } = props
  const { handleMemberDetail } = useShowMemberDetail({
    memberId,
    enabled: !!memberId
  })
  return (
    <Box onClick={handleMemberDetail} style={{ cursor: "pointer", flex: 1 }}>
      <DsText variant={variant} color={color} weight={weight}>
        {name}
      </DsText>
    </Box>
  )
})
