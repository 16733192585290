import { useInfiniteQuery, useQuery } from "@tanstack/react-query"
import { queries } from "../../queries"
import { ChannelByIdPayload } from "../../api/channel/channelById"
import { ChannelListPaginatedPayload } from "../../api/channel/channelListPaginated"

export const useChannelQueries = () => {
  const byId = (payload: ChannelByIdPayload) => {
    const { data } = useQuery(queries.channels.byId(payload))

    return {
      channel: data
    }
  }

  const list = () => {
    const { data } = useQuery(queries.channels.list())

    return {
      channels: data
    }
  }

  const listPaginated = (payload?: ChannelListPaginatedPayload) => {
    return useInfiniteQuery({
      ...queries.channels.listPaginated(payload),
      staleTime: 0,
      gcTime: 0,
      initialPageParam: 1,
      refetchOnWindowFocus: true,
      getNextPageParam: (lastPage: any) => {
        return lastPage.nextId ?? undefined
      },
      getPreviousPageParam: (firstPage: any) => {
        return firstPage.previousId ?? undefined
      }
    })
  }

  return {
    byId,
    list,
    listPaginated
  }
}
