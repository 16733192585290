import { KINDS } from "../../lib/constants"
import { supabase } from "../../lib/supabase"
import { getTenant } from "../../utils/getTenant"
import { getUser } from "../auth/getUser"

type topicCreatePayload = {
  title: string
}

export const topicCreate = async (payload: topicCreatePayload) => {
  const {
    data: { user }
  } = await getUser()

  if (!user) return

  const { error } = await supabase.from("db").insert({
    kind: KINDS.TOPIC,
    tenant: getTenant(),
    user_uuid: user.id,
    data: payload
  })

  if (error) throw error
}
