import { zodResolver } from "@mantine/form"
import { TFunction } from "i18next"
import { z } from "zod"

export const topicEditSchema = (t: TFunction<"translation", undefined>) => {
  const schema = z.object({
    title: z
      .string()
      .max(24, { message: t("validation.title_max_size") })
      .min(3, { message: t("validation.title_min_size") })
  })

  return zodResolver(schema)
}
