import {
  DsBox,
  DsButtonPrimary,
  DsButtonSecondary,
  DsGroup,
  DsStackVertical,
  createStyles
} from "@raisesistemas/ds"
import { JSONContent } from "@tiptap/core"
import { Roles } from "../../api/member/memberSearch"
import { RichText } from "../RichText/RichText"
import { MemberInfo } from "../Member/MemberInfoV2"

import { useForm } from "@mantine/form"
import { useTranslation } from "react-i18next"
import { useAuth } from "../../hooks/useAuth"
import { useCommentEditor } from "../../hooks/editor/useCommentEditor"
import { useCurrentChannel } from "../../hooks/useCurrentChannel"

export type CommentFormProps = {
  channelId?: string
  isVisible?: boolean
  defaultValue?: JSONContent
  showActions?: boolean
  loading?: boolean
  onHidden?: () => void
  onSubmit: (comment: JSONContent) => void
}

const useContentHelpers = (data?: JSONContent) => {
  const hasContent = Boolean(data && data.content && data.content[0].content)

  return {
    hasContent
  }
}

export const CommentForm = (props: CommentFormProps) => {
  const {
    channelId,
    showActions = false,
    defaultValue,
    isVisible = true,
    onHidden,
    onSubmit,
    loading,
    ...restProps
  } = props

  const { t } = useTranslation()
  const { classes } = useStyles()
  const { channel } = useCurrentChannel()
  const { user } = useAuth()

  const isEdit = !!defaultValue
  const mentionRoles: Roles = channel?.data.locked
    ? ["MEMBER_PRO", "MODERATOR", "OWNER"]
    : ["MEMBER", "MEMBER_PRO", "MODERATOR", "OWNER"]

  const form = useForm({
    initialValues: {
      content: defaultValue
    },
    validate: {
      content: content =>
        content?.content?.length === 0 ? "Campo obrigatório" : null
    }
  })

  const { hasContent } = useContentHelpers(form.values.content)

  const editor = useCommentEditor({
    channelId,
    content: form.values.content || "",
    onUpdate({ json }) {
      form.setFieldValue("content", json)
    },
    mentionRoles
  })

  const handleSubmit = () => {
    if (!hasContent) return
    // TODO: verificar sobre o `boolean for` para tipagem dinâmica.
    // @ts-ignore:next-line
    onSubmit(form.values.content)
    form.reset()
    if (!isEdit) editor?.commands.clearContent()
  }

  const handleCancel = () => {
    form.reset()
    onHidden?.()
    editor?.commands.setContent({ type: "doc", content: [] })
  }

  return (
    <DsBox
      sx={{
        display: isVisible ? "block" : "none"
      }}
      {...restProps}
    >
      <MemberInfo>
        {!isEdit && (
          <MemberInfo.Avatar
            memberId={user!.user_uuid}
            name={user!.data.name}
            avatar={user!.data.avatar}
            data-testid={COMMENT_FORM_AVATAR_ID}
          />
        )}

        <RichText editor={editor} classNames={{ root: classes.richTextRoot }} />
      </MemberInfo>
      {(showActions || hasContent) && (
        <DsStackVertical>
          <DsGroup align="start"></DsGroup>
          <DsGroup position="right">
            <DsButtonSecondary
              onClick={handleCancel}
              data-testid={COMMENT_FORM_BUTTON_CANCEL_ID}
            >
              {t("button.cancel")}
            </DsButtonSecondary>
            <DsButtonPrimary
              disabled={!hasContent}
              onClick={handleSubmit}
              data-testid={COMMENT_FORM_BUTTON_CONFIRM_ID}
              loading={loading}
            >
              {isEdit ? t("button.save") : t("button.comment")}
            </DsButtonPrimary>
          </DsGroup>
        </DsStackVertical>
      )}
    </DsBox>
  )
}

export const COMMENT_FORM_AVATAR_ID = "comment-form-avatar-id"
export const COMMENT_FORM_BUTTON_CANCEL_ID = "comment-form-button-cancel"
export const COMMENT_FORM_BUTTON_CONFIRM_ID = "comment-form-button-confirm"

const useStyles = createStyles(({ fn }) => ({
  richTextRoot: {
    [fn.smallerThan("sm")]: {
      flexDirection: "column",
      alignItems: "unset"
    }
  }
}))
