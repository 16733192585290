import { MS_ACTIVITIES_URL } from "../../env"
import { buildURL } from "../../utils/buildUrl"
import { fetchAPI } from "../../utils/fetchAPI"
import { getTenant } from "../../utils/getTenant"

export type ActivityUnreadCountPayload = {
  user_id: string
}

export type Channel = {
  id: string
  count: number
}

export type ResponseData = {
  all: number
  home: number
  channels: Channel[]
}

export const activityUnreadCount = async (recipientId: string) => {
  const tenant = getTenant()

  const queryParams = {
    tenant,
    user_id: recipientId
  }

  const url = buildURL(
    `${MS_ACTIVITIES_URL}/activities/unread/count`,
    queryParams
  )
  const data = await fetchAPI<ResponseData>(url)
  return data
}
