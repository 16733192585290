import { createQueryKeys } from "@lukemorales/query-key-factory"
import { postSavedList } from "../api/postSaved/postSavedList"

import { getTenant } from "../utils/getTenant"

export const postSavedQueries = createQueryKeys("postSaved", {
  list: () => ({
    queryKey: [getTenant()],
    queryFn: ({ pageParam = 1 }: { pageParam: number }) =>
      postSavedList({ page: pageParam, limit: 10 })
  })
})
