import { supabase } from "../../lib/supabase"
import { createInfiniteService } from "../../utils/createInfiniteService"

export type LikeListPayload = {
  kind: string
  parentId: string
}

type LikeType = {}

export const likeList = createInfiniteService<LikeListPayload, LikeType>(
  async ({ rangeEnd, rangeStart, kind, parentId }) => {
    const { data, error, count } = await supabase
      .from("db")
      .select("member(user_uuid, role, data->name,data->avatar)", {
        count: "exact",
        head: false
      })
      .eq("kind", kind)
      .eq("parent_id", parentId)
      .range(rangeStart, rangeEnd)

    return {
      data,
      error,
      count
    }
  }
)
