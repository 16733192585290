import { useMutation, useQueryClient } from "@tanstack/react-query"
import { channelUpdateAudience } from "../../api/channel/channelUpdateAudience"
import { queries } from "../../queries"
import { useStandardToast } from "../useStandardToast"
import { channelCreate } from "../../api/channel/channelCreate"
import { channelUpdateTopic } from "../../api/channel/channelUpdateTopic"
import { channelEdit } from "../../api/channel/channelEdit"
import { channelDelete } from "../../api/channel/channelDelete"
import { toast } from "@raisesistemas/ds"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

export const useChannelMutations = () => {
  const queryClient = useQueryClient()
  const { cantFinalize } = useStandardToast()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const updateAudience = useMutation({
    mutationFn: channelUpdateAudience,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queries.channels.byId._def
      })
      queryClient.invalidateQueries({
        queryKey: queries.channels.list._def
      })
    },
    onError: () => {
      cantFinalize()
    }
  })

  const create = useMutation({
    mutationFn: channelCreate,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queries.channels.byId._def
      })
      queryClient.invalidateQueries({
        queryKey: queries.topic.listWithChannels._def
      })
    }
  })

  const edit = useMutation({
    mutationFn: channelEdit,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queries.channels.byId._def
      })
      queryClient.invalidateQueries({
        queryKey: queries.topic.listWithChannels._def
      })
    }
  })

  const _delete = useMutation({
    mutationFn: channelDelete,
    onSuccess: () => {
      toast.success({
        message: t("validation.deleted_success")
      })
      queryClient.invalidateQueries({
        queryKey: queries.channels.byId._def
      })
      queryClient.invalidateQueries({
        queryKey: queries.topic.listWithChannels._def
      })
      navigate("/channels/posts")
    },
    onError: () => {
      cantFinalize()
    }
  })

  const updateTopic = useMutation({
    mutationFn: channelUpdateTopic
  })

  return {
    updateAudience,
    create,
    edit,
    updateTopic,
    delete: _delete
  }
}
