import { useId } from "react"
import { DsSkeletonLine, DsStackVertical } from "@raisesistemas/ds"

type ContentSkeletonProps = {
  quantity?: number
}

export const ContentSkeleton = (props: ContentSkeletonProps) => {
  const { quantity = 3 } = props
  const lines = [...Array(quantity)]
  const id = useId()

  return (
    <DsStackVertical spacing="lg">
      {lines.map((_, index) => (
        <DsStackVertical spacing="xs" key={id + index}>
          <DsSkeletonLine />
          <DsSkeletonLine isFull />
          <DsSkeletonLine isFull />
        </DsStackVertical>
      ))}
    </DsStackVertical>
  )
}
