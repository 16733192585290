export type InfiniteResponse<T> = {
  data: T[]
  isLastPage: boolean
  page: number
}

export const makeInfiniteResponse = <Data = unknown>({
  data,
  isLastPage,
  page
}: InfiniteResponse<Data>) => ({
  data,
  nextId: isLastPage ? null : page + 1,
  previousId: page > 0 ? page - 1 : null
})
