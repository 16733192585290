import { useMutation, useQueryClient } from "@tanstack/react-query"
import { postFixedUpdate } from "../../api/post/postFixedUpdate"
import { queries } from "../../queries"
import { useStandardToast } from "../useStandardToast"

export const usePostFixedUpdateMutation = () => {
  const queryClient = useQueryClient()
  const { cantFinalize } = useStandardToast()

  return useMutation({
    mutationFn: postFixedUpdate,
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: queries.posts.list._def
      }),
    onError: () => {
      cantFinalize()
    }
  })
}
