import { MantineSizes } from "@mantine/core"
import { useDsMediaQuery, useDsTheme } from "@raisesistemas/ds"

export const useIsScreen = () => {
  const { breakpoints } = useDsTheme()

  const convertoToSize = (width: keyof MantineSizes | number) =>
    typeof width === "string" ? breakpoints[width] : width + "px"

  const isMediaQuery = (minmax: "max" | "min", width: string) =>
    useDsMediaQuery(`(${minmax}-width: ${width})`)

  const isSmallerThan = (width: keyof MantineSizes | number) =>
    isMediaQuery("max", convertoToSize(width))

  const isLargeThan = (width: keyof MantineSizes | number) =>
    isMediaQuery("min", convertoToSize(width))

  const isDesktop = useDsMediaQuery(`(min-width: ${breakpoints.md})`)
  const isMobile = useDsMediaQuery(`(max-width: ${breakpoints.md})`)

  return {
    isLargeThan,
    isSmallerThan,
    isDesktop,
    isMobile,
    breakpoints
  }
}
