import {
  DsGroup,
  DsLinkButton,
  DsStackVertical,
  DsText,
  createStyles
} from "@raisesistemas/ds"
import { createRandomId } from "../../utils/createRandomId"
import { ExtractRow, Historic } from "../../presenter/extractPresenter"
import { ExtractSkeleton } from "../Skeletons/ExtractSkeleton"
import { drawers } from "../../state"
import { useTranslation } from "react-i18next"
import { useThemeColoring } from "../../hooks/useThemeColoring"

type ExtractHistoryProps = {
  historic: Historic[] | null
  loading?: boolean
}

export const ExtractHistory: React.FC<ExtractHistoryProps> = props => {
  const { historic, loading } = props
  const { classes } = useStyles()
  const { t } = useTranslation()
  const { lightDark } = useThemeColoring()

  const openExtractSimple = (extractRow: ExtractRow) => {
    switch (extractRow.extractKey) {
      case "WITHDRAW_SUCCESS":
        drawers.extract.simple.open({
          title: t("drawer.extract_simple.withdraw_success.title"),
          data: extractRow.details
        })
        break
      case "TRANSACTIONS_TAX":
        drawers.extract.simple.open({
          title: t("drawer.extract_simple.transactions_tax.title"),
          subtitle: t("drawer.extract_simple.transactions_tax.subtitle"),
          data: extractRow.details
        })
        break
      case "MEMBER_PAYMENT":
        drawers.extract.simple.open({
          title: t("drawer.extract_simple.member_payment.title"),
          data: extractRow.details
        })
        break
      default:
        break
    }
  }

  if (loading) return <ExtractSkeleton />

  return (
    <DsStackVertical spacing={24} className={classes.main}>
      {historic && !!historic.length ? (
        historic.map(({ date, week, rows }) => (
          <DsStackVertical key={createRandomId()} spacing={16}>
            <DsStackVertical spacing={8}>
              <DsText
                variant="body-2"
                weight="medium"
                color={lightDark("gray.6", "gray.5")}
              >
                {date}
              </DsText>
              <DsText
                variant="body-3"
                weight="regular"
                color={lightDark("gray.6", "gray.5")}
              >
                {week}
              </DsText>
            </DsStackVertical>

            <DsStackVertical spacing={16}>
              {rows.map(extractRow => (
                <DsLinkButton
                  key={createRandomId()}
                  onClick={() => openExtractSimple(extractRow)}
                >
                  <DsGroup
                    spacing={0}
                    position="apart"
                    className={classes.rowInfo}
                  >
                    <DsStackVertical spacing={8}>
                      <DsText
                        variant="body-2"
                        weight="regular"
                        color={lightDark("gray.9", "gray.5")}
                      >
                        {extractRow.name}
                      </DsText>

                      {!!extractRow.payament_type && (
                        <DsText
                          variant="body-3"
                          weight="regular"
                          color={lightDark("gray.6", "gray.6")}
                        >
                          {extractRow.payament_type}
                        </DsText>
                      )}
                    </DsStackVertical>

                    <DsText
                      variant="body-2"
                      weight="regular"
                      color={lightDark("gray.9", "gray.6")}
                    >
                      {extractRow.value}
                    </DsText>
                  </DsGroup>
                </DsLinkButton>
              ))}
            </DsStackVertical>
          </DsStackVertical>
        ))
      ) : (
        <DsText
          variant="body-2"
          weight="regular"
          color={lightDark("gray.7", "gray.7")}
        >
          {t("common.no_registration")}
        </DsText>
      )}
    </DsStackVertical>
  )
}

const useStyles = createStyles({
  main: {
    marginTop: 24
  },
  rowInfo: {
    border: "1px solid #EAECF0",
    borderRadius: 12,
    padding: 16
  }
})
