import { useState } from "react"
import { usePlanForm } from "./usePlanForm"
import { useBankForm } from "./useBankForm"
import { planPresenter } from "./planPresenter"
import { useUser } from "../../../hooks/useUser"
import { isOpenedPlanDrawer } from "../../../state/drawer"
import { usePlanMutations } from "../../../hooks/mutations/usePlanMutations"

export const usePlanStepper = () => {
  const { data } = useUser()
  const planForm = usePlanForm()
  const bankForm = useBankForm()
  const [step, setStep] = useState(0)
  const { createPlanAndBank } = usePlanMutations()

  const handleNavigate = (nextStep: number) => {
    const { hasErrors } = planForm.validate()

    if (nextStep === 1 && hasErrors) return

    setStep(nextStep)
  }

  const handleSubmit = () => {
    const { hasErrors } = bankForm.validate()

    if (hasErrors) return

    const plan = planPresenter(planForm.values)
    const bank = { ...bankForm.values, phone: data.phone ?? "" }

    createPlanAndBank.mutate(
      {
        bankAccount: bank,
        planMember: plan
      },
      {
        onSuccess() {
          isOpenedPlanDrawer.value = false
        }
      }
    )
  }

  return {
    step,
    handleNavigate,
    handleSubmit,
    planForm,
    bankForm,
    isLoading: createPlanAndBank.isPending
  }
}
