import { MS_ACTIVITIES_URL } from "../../env"
import { ActivityType } from "../../types/activity"

import { buildURL } from "../../utils/buildUrl"
import { fetchAPI } from "../../utils/fetchAPI"
import { getTenant } from "../../utils/getTenant"
import { getUserId } from "../../utils/getUserId"

type MetadataData = {
  emoji?: string
  title?: string
  content?: string
}

type ResponseData = ActivityType<unknown, MetadataData>[]

export const activitiesLatest = async () => {
  const tenant = getTenant()
  const userId = await getUserId()

  const queryParams = {
    tenant,
    user_id: userId,
    limit: "4",
    skip: "0",
    type: "post,event",
    time: "24"
  }

  const url = buildURL(`${MS_ACTIVITIES_URL}/activities`, queryParams)
  const data = await fetchAPI<ResponseData>(url)
  return data
}
