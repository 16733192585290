import { currentChannel } from "../state"
import { ChannelType } from "../types/channel"

export const useCurrentChannel = () => {
  const handleChangeActive = (channel: ChannelType) => {
    currentChannel.value = channel
  }

  return {
    channel: currentChannel.value,
    handleChangeActive
  }
}
