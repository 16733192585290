import { useAuth } from "../useAuth"
import { PermissionCanWithoutRole, PermissionRole } from "./permissions.types"
import { usePermissions } from "./usePermissions"

export const useUserPermissions = () => {
  const { user } = useAuth()
  const { can } = usePermissions()

  const handleCan: PermissionCanWithoutRole = (action, resource, data) => {
    if (!user) return false
    return can(user.role as PermissionRole, action, resource, data)
  }

  return {
    can: handleCan
  }
}
