import { MS_PAYMENTS_URL } from "../../env"
import { getTenant } from "../../utils/getTenant"
import { getSession } from "../auth/getSession"

export type PlanAndBankPayload = {
  bankAccount: {
    name: string
    bank_account_type: string
    bank: string
    document: string
    bank_ag: string
    bank_cc: string
    phone: string
  }
  planMember: {
    description: string
    interval_count: number
    interval: string
    price_cents: number
    type: string
    active: boolean
    trial_period_days: number
    fee_paid_by_member: boolean
  }
}

export const planAndBankCreate = async (payload: PlanAndBankPayload) => {
  const { planMember, bankAccount } = payload

  const { access_token } = await getSession()

  const body = { tenant: getTenant(), bankAccount, planMember }

  const response = await fetch(
    `${MS_PAYMENTS_URL}/create-bank-account-and-plan`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    }
  )

  if (!response.ok) throw new Error("Error creating plan and bank account")
}
