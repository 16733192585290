import { DsStackVertical } from "@raisesistemas/ds"
import { Fragment, ReactNode, useEffect, useId, useRef } from "react"
import { InfiniteData, UseInfiniteQueryResult } from "@tanstack/react-query"

import { useIntersection } from "@mantine/hooks"
import { AnimatePresence, motion } from "framer-motion"

import { LoadMore } from "../../LoadMore"
import { PullToRefresh } from "../../PullToRefresh"
import { InfinitePageType } from "../../../types/shared"
import { ContentSkeleton } from "../../Skeletons/ContentSkeleton"

type InfiniteScrollProps = {
  query: UseInfiniteQueryResult<InfiniteData<InfinitePageType<any>>>
  renderItem: (item: any, index: number) => ReactNode
  keyExtractor?: (item: any) => string
  empty?: ReactNode
  spacing?: number
}

export const InfiniteScroll = (props: InfiniteScrollProps) => {
  const { query, renderItem, empty = null, spacing } = props

  const { fetchNextPage, isLoading: isInitialLoading, data, isFetching } = query

  const hasData = data?.pages[0].data && data?.pages[0].data.length > 0
  const uniqueId = useId()

  const keyExtractor = (item: any, index: number): string => {
    if (props.keyExtractor) return props.keyExtractor(item)
    return `${uniqueId}-${index}`
  }

  const animationEnabled = typeof props.keyExtractor === "function"

  const loadRef = useRef<HTMLDivElement>(null)
  const { ref, entry } = useIntersection({
    root: loadRef.current,
    rootMargin: "50%"
  })

  const handleRefresh = async () => await query.refetch()

  useEffect(() => {
    if (entry?.isIntersecting) fetchNextPage()
  }, [entry])

  if (isInitialLoading) {
    return <ContentSkeleton quantity={5} />
  }

  return (
    <PullToRefresh onRefresh={handleRefresh}>
      <AnimatePresence>
        {!hasData && !isFetching && (
          <motion.div
            initial={animationEnabled ? { opacity: 0, y: "-100%" } : {}}
            animate={animationEnabled ? { opacity: 1, y: 0 } : {}}
            exit={animationEnabled ? { opacity: 0, y: "-100%" } : {}}
            transition={{ type: "spring" }}
          >
            {empty}
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {hasData && (
          <motion.div
            exit={animationEnabled ? { opacity: 0, x: "-100%" } : {}}
            transition={{ type: "spring" }}
          >
            <DsStackVertical spacing={spacing}>
              {data?.pages.map(page => (
                <Fragment key={`page-${uniqueId}-${page.nextId}`}>
                  <AnimatePresence>
                    {page.data.map((item, index) => (
                      <motion.div
                        key={keyExtractor(item, index)}
                        exit={
                          animationEnabled ? { opacity: 0, x: "-100%" } : {}
                        }
                        transition={{ type: "spring" }}
                      >
                        {renderItem(item, index)}
                      </motion.div>
                    ))}
                  </AnimatePresence>
                </Fragment>
              ))}
            </DsStackVertical>
            <LoadMore ref={ref} {...query} />
          </motion.div>
        )}
      </AnimatePresence>
    </PullToRefresh>
  )
}
