import { calculateImageDimensions } from "./calculateImageDimensions"

export type ResizeImageOptions = {
  maxWidth?: number
  maxHeight?: number
  quality?: number
}

export const resizeImage = (
  image: HTMLImageElement,
  options?: ResizeImageOptions
) => {
  const { maxWidth = 500, maxHeight = 500, quality = 0.8 } = options || {}
  const canvas = document.createElement("canvas")
  const { width, height } = calculateImageDimensions({
    image,
    maxWidth: maxWidth,
    maxHeight: maxHeight
  })

  canvas.width = width
  canvas.height = height

  const ctx = canvas.getContext("2d")

  if (!ctx) throw new Error("ctx does not exist")

  ctx.drawImage(image, 0, 0, width, height)

  return canvas.toDataURL("image/jpeg", quality)
}
