import { createStyles } from "@raisesistemas/ds"
import { DsBox } from "@raisesistemas/ds"

type NotificationOpenedIndicatorProps = {
  read: boolean
}

const useStyles = createStyles(({ colors }) => ({
  container: {
    width: 8,
    height: 8,
    borderRadius: "100%",
    backgroundColor: colors.red[7]
  }
}))

export const NotificationOpenedIndicator = (
  props: NotificationOpenedIndicatorProps
) => {
  const { classes } = useStyles()
  if (props.read) return null

  return <DsBox className={classes.container} />
}
