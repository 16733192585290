import { supabase } from "../../lib/supabase"
import { DbType } from "../../types/db"
import { getTenant } from "../../utils/getTenant"

export type BankType = {
  name: string
  bank_account_type: string
  bank: string
  document: string
  bank_ag: string
  bank_cc: string
}

export const bankFind = async () => {
  const { data, error } = await supabase
    .from("db")
    .select("*")
    .eq("kind", "bank_account")
    .eq("tenant", getTenant())
    .limit(1)
    .single<DbType<BankType>>()

  if (error) throw error

  return data
}
