import { supabase } from "../../lib/supabase"
import { AudienceType } from "../../types/audience"
import { getTenant } from "../../utils/getTenant"

export const audienceList = async () => {
  const { data, error } = await supabase
    .from("audience")
    .select("id::text, label, tenant")
    .eq("tenant", getTenant())
    .neq("label", "_default")
    .returns<AudienceType[]>()

  if (error) throw error

  return data
}
