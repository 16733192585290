import { NodeViewRendererProps, NodeViewWrapper } from "@tiptap/react"
import { DsButtonUnstyled, createStyles, rem } from "@raisesistemas/ds"
import { drawers } from "../../state"

const useStyles = createStyles(({ colors, primaryColor, fn }) => ({
  button: {
    color: colors[primaryColor][5],
    borderRadius: rem(4),
    padding: rem(2),

    "&:hover": {
      backgroundColor: fn.rgba(colors[primaryColor][5], 0.1)
    }
  }
}))

export const MentionButton = (props: NodeViewRendererProps) => {
  const { node } = props
  const { classes } = useStyles()
  const openMemberDetailDrawer = () =>
    drawers.member.detail.open({
      memberId: props.node.attrs.id
    })

  return (
    <NodeViewWrapper className="mention-button" style={{ display: "inline" }}>
      <DsButtonUnstyled
        onClick={openMemberDetailDrawer}
        className={classes.button}
      >
        @{node.attrs.name}
      </DsButtonUnstyled>
    </NodeViewWrapper>
  )
}
