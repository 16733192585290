import { createStyles, DsBox, DsText, rem } from "@raisesistemas/ds"
import FocusTrap from "focus-trap-react"
import {
  AnimatePresence,
  DragHandlers,
  motion,
  useAnimation
} from "framer-motion"
import { ReactNode, useEffect, useRef } from "react"
import { RN } from "../env"

const HEADER_HEIGHT = 32
const CLOSE_SIZE = 20

const useStyles = createStyles(({ colors, colorScheme, primaryColor, fn }) => ({
  sheet: {
    position: "fixed",
    left: 0,
    right: 0,
    bottom: 0,
    background: colorScheme === "dark" ? colors.dark[7] : "white",
    zIndex: 1002,
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    maxHeight: "95vh",
    maxWidth: "100vw"
  },
  header: {
    margin: `${rem(8)} ${rem(16)}`
  },
  headerInner: {
    display: "flex",
    alignItems: "center",
    margin: `${rem(8)} ${rem(16)} 0 ${rem(16)}`,
    position: "relative",
    height: rem(HEADER_HEIGHT)
  },
  handler: {
    width: 60,
    height: 6,
    borderRadius: 4,
    background: colorScheme === "dark" ? colors.dark[4] : colors.gray[3],

    margin: "0 auto"
  },
  close: {
    position: "absolute",
    top: rem((HEADER_HEIGHT - CLOSE_SIZE) / 2),
    right: 0,
    backgroundColor: fn.rgba(colors[primaryColor][5], 0.1),
    width: rem(CLOSE_SIZE),
    height: rem(CLOSE_SIZE),
    borderRadius: rem(4),
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  backdrop: {
    position: "fixed",
    background: "rgba(0,0,0,0.2)",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1001
  },
  footer: {
    padding: rem(16)
  }
}))

export type BottomSheetProps = {
  title?: ReactNode
  opened?: boolean
  onClose?: () => void
  footer?: ReactNode
  footerHeight?: number
  children: ReactNode
  adjustToContentHeight?: boolean
}

export const BottomSheet = ({
  title,
  opened = false,
  onClose,
  footer,
  footerHeight = 115,
  children,
  adjustToContentHeight = true
}: BottomSheetProps) => {
  const sheetRef = useRef<HTMLDivElement>(null)

  const { classes } = useStyles()

  const controls = useAnimation()
  const transitionProps = { type: "spring", stiffness: 500, damping: 30 }
  useEffect(() => {
    controls.start({
      y: 0,
      transition: transitionProps
    })
  }, [])

  const handleDragEnd: DragHandlers["onDragEnd"] = async (_, info) => {
    const offset = info.offset.y
    const velocity = info.velocity.y
    const height = sheetRef.current?.getBoundingClientRect().height || 0
    if (offset > height / 2 || velocity > 800) {
      await controls.start({ y: "100%", transition: transitionProps })
      onClose?.()
    } else {
      controls.start({ y: 0, transition: transitionProps })
    }
  }

  const minHeight = adjustToContentHeight
    ? "auto"
    : `calc(90vh - ${footerHeight + HEADER_HEIGHT}px)`

  const renderTitle = () => {
    const isString = typeof title === "string"

    if (isString) {
      return (
        <DsText variant="body-1" weight="medium">
          {title}
        </DsText>
      )
    }

    return title
  }

  useEffect(() => {
    if (RN) RN.setPullToRefreshEnabled(!opened)
  }, [opened, RN])

  return (
    <AnimatePresence>
      {opened && (
        <FocusTrap
          focusTrapOptions={{
            clickOutsideDeactivates: true,
            onActivate: () => {
              // prevent scroll outside of modal when modal is open
              document.documentElement.style.overflow = "hidden"
              document.body.style.overflow = "hidden"
            },
            onDeactivate: () => {
              // allow scroll outside of modal when modal is closed
              document.documentElement.style.overflow = "initial"
              document.body.style.overflow = "auto"
            },
            fallbackFocus: () => sheetRef.current?.firstChild as HTMLElement
          }}
        >
          <div>
            <motion.div
              ref={sheetRef}
              className={classes.sheet}
              drag="y"
              exit={{ y: "100%" }}
              animate={controls}
              transition={transitionProps}
              dragDirectionLock
              dragElastic={{ top: 0, bottom: 1 }}
              dragConstraints={{ top: 0, bottom: 0 }}
              onDragEnd={handleDragEnd}
            >
              <DsBox className={classes.header}>
                <DsBox className={classes.headerInner}>
                  <DsBox className={classes.handler} />
                </DsBox>

                {renderTitle()}
              </DsBox>

              <DsBox
                sx={{
                  minHeight,
                  maxHeight: `calc(90vh - ${footerHeight + HEADER_HEIGHT}px)`,
                  overflow: "auto",
                  padding: 16,
                  overscrollBehaviorY: "none"
                }}
              >
                {children}
              </DsBox>

              {footer && (
                <DsBox className={classes.footer} sx={{ height: footerHeight }}>
                  {footer}
                </DsBox>
              )}
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className={classes.backdrop}
              onClick={onClose}
            />
          </div>
        </FocusTrap>
      )}
    </AnimatePresence>
  )
}
