import { createQueryKeys } from "@lukemorales/query-key-factory"
import { ChannelByIdPayload, channelById } from "../api/channel/channelById"
import { channelList } from "../api/channel/channelList"
import {
  channelListPaginated,
  ChannelListPaginatedPayload
} from "../api/channel/channelListPaginated"
import { getTenant } from "../utils/getTenant"

export const channelQueries = createQueryKeys("channels", {
  list: () => ({
    queryKey: [getTenant()],
    queryFn: channelList
  }),
  listPaginated: (payload?: ChannelListPaginatedPayload) => ({
    queryKey: [getTenant(), payload],
    queryFn: ({ pageParam = 1 }: { pageParam: number }) =>
      channelListPaginated({ page: pageParam, ...payload })
  }),
  byId: (payload: ChannelByIdPayload) => ({
    queryKey: [payload],
    queryFn: () => channelById(payload)
  })
})
