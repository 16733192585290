import { useMemo } from "react"
import { useLocalStorage } from "@mantine/hooks"
import { useDsTheme } from "@raisesistemas/ds"
import { RN } from "../../env"
import { STORAGE_KEYS } from "../../lib/constants"

export const usePrimaryColor = () => {
  const { colors } = useDsTheme()

  const defaultValue = useMemo(() => {
    const defaultColor = "violet"
    if (RN) {
      const isValid = Object.keys(colors).includes(RN.primaryColor)
      return isValid ? RN.primaryColor : defaultColor
    }

    return defaultColor
  }, [])

  return useLocalStorage<string>({
    key: STORAGE_KEYS.PRIMARY_COLOR,
    defaultValue,
    getInitialValueInEffect: true
  })
}
