import { Menu } from "@mantine/core"
import { Fragment, PropsWithChildren, ReactNode, useMemo } from "react"
import { Link } from "react-router-dom"

type MenuUserItemProps = {
  name: ReactNode
  icon?: ReactNode

  path?: string
  onClick?: () => void
  color?: string
}

export const MenuUserItem = (props: MenuUserItemProps) => {
  const { path, icon, name, onClick, color } = props

  // TODO: a lógica esta um pouco confusa,
  // abrir uma discussão sobre isso no repo,
  // e pesquisar mais formas de solução.
  const Wrapper = useMemo(() => {
    return (props: PropsWithChildren) => {
      if (path)
        return (
          <Link to={path} style={{ textDecoration: "none" }}>
            {props.children}
          </Link>
        )

      return <Fragment>{props.children}</Fragment>
    }
  }, [])

  return (
    <Wrapper>
      <Menu.Item icon={icon} color={color} onClick={onClick}>
        {name}
      </Menu.Item>
    </Wrapper>
  )
}
