import { createStyles } from "@raisesistemas/ds"

export const useStyles = createStyles(theme => ({
  header: {
    display: "block",
    "@media only screen and (max-width: 600px)": {
      marginTop: "64px"
    }
  },

  logoButton: {
    width: "100%",
    padding: "0.5rem",
    borderRadius: "0.25rem",

    "&:hover": {
      background: theme.fn.variant({
        variant: "default",
        color: theme.primaryColor
      }).background
    }
  }
}))
