import { zodResolver } from "@mantine/form"
import { TFunction } from "i18next"
import { z } from "zod"
import { stringHasCharacters } from "../utils/stringHasCharacters"
import { onlyNumbers } from "../utils/onlyNumbers"
import { getBankByName } from "../utils/getBankByName"
import { getBankAccountMaskMinLength } from "../pages/settings/Plan/getBankAccountMaskMinLength"

export const bankSchema = (t: TFunction<"translation", undefined>) => {
  const schema = z
    .object({
      name: z.custom(stringHasCharacters, {
        message: t("validation.required_field")
      }),
      bank_account_type: z.custom(stringHasCharacters, {
        message: t("validation.required_field")
      }),
      bank: z.custom(stringHasCharacters, {
        message: t("validation.required_field")
      }),
      document: z.custom(
        document => stringHasCharacters(onlyNumbers(document), 11),
        {
          message: t("validation.required_field")
        }
      ),
      bank_ag: z.custom(stringHasCharacters, {
        message: t("validation.required_field")
      }),
      bank_cc: z.custom(stringHasCharacters, {
        message: t("validation.required_field")
      })
    })
    .refine(
      ({ bank_ag, bank }) => {
        const selectedBank = getBankByName(bank)
        return stringHasCharacters(bank_ag, selectedBank?.branch_mask.length)
      },
      { message: t("error.message.invalid_field"), path: ["bank_ag"] }
    )
    .refine(
      ({ bank_cc, bank }) => {
        const selectedBank = getBankByName(bank)
        return stringHasCharacters(
          bank_cc,
          getBankAccountMaskMinLength(selectedBank?.account_mask)
        )
      },
      { message: t("error.message.invalid_field"), path: ["bank_cc"] }
    )

  return zodResolver(schema)
}
