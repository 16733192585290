import {
  DsButtonPrimary,
  DsButtonSecondary,
  DsCheckbox,
  DsMediaQuery,
  DsStackVertical,
  DsText,
  toast,
  useDsTheme
} from "@raisesistemas/ds"

import { Sheet } from "../Sheet"
import { modals } from "@mantine/modals"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { usePaymentMutations } from "../../hooks/mutations/usePaymentMutations"
import { useBankQueries } from "../../hooks/queries/useBankQueries"
import { formatBrazilianDocuments } from "../../utils/formatBrazilianDocuments"
import { drawers } from "../../state"

export const BankAccountInfoDrawer = () => {
  const { isOpen, close } = drawers.bank.accountInfo
  const { colors } = useDsTheme()
  const { t } = useTranslation()
  const [isAcceptedTerms, setIsAcceptedTerms] = useState(false)

  const bankQueries = useBankQueries()
  const { data: bank } = bankQueries.find()

  const { requestWithdraw } = usePaymentMutations()

  const onConfirm = () => {
    requestWithdraw.mutate(undefined, {
      onSuccess: () => {
        close()
        modals.open({
          title: false,
          withCloseButton: false,
          centered: true,
          size: 500,
          children: (
            <DsStackVertical spacing={16}>
              <DsText variant="body-2" weight="medium">
                {t("root.your_money_will_soon_be_credited_to_your_account")}
              </DsText>

              <DsText variant="body-2" weight="regular" color={colors.gray[5]}>
                {t("root.the_deposit_period_for_your_account")}
              </DsText>

              <DsButtonPrimary onClick={() => modals.closeAll()}>
                {t("common.understood")}
              </DsButtonPrimary>
            </DsStackVertical>
          )
        })
      },
      onError: () => {
        toast.error({ message: t("error.message.cant_finalize") })
      }
    })
  }

  const openExtractDrawer = () => {
    close()
    drawers.extract.full.open()
  }
  return (
    <Sheet
      onClose={close}
      opened={isOpen}
      title={t("root.confirm_your_account_details")}
    >
      <DsStackVertical spacing={40}>
        <DsStackVertical spacing={24}>
          <DsText variant="body-2" color={colors.gray[6]} weight="regular">
            {t(
              "root.to_receive_payments_from_members_confirm_your_bank_information"
            )}
          </DsText>

          <DsStackVertical spacing={8}>
            <DsText variant="body-2" weight="regular" color="#171923">
              {t("input.bank.bank.label")}
            </DsText>

            <DsText variant="body-2" weight="regular" color={colors.gray[6]}>
              {bank?.data.bank}
            </DsText>
          </DsStackVertical>

          <DsStackVertical spacing={8}>
            <DsText variant="body-2" weight="regular" color="#171923">
              {t("input.bank.agency.label")}
            </DsText>

            <DsText variant="body-2" weight="regular" color={colors.gray[6]}>
              {bank?.data.bank_ag}
            </DsText>
          </DsStackVertical>

          <DsStackVertical spacing={8}>
            <DsText variant="body-2" weight="regular" color="#171923">
              {t("input.bank.account.label")}
            </DsText>

            <DsText variant="body-2" weight="regular" color={colors.gray[6]}>
              {bank?.data.bank_cc}
            </DsText>
          </DsStackVertical>

          <DsStackVertical spacing={8}>
            <DsText variant="body-2" weight="regular" color="#171923">
              {t("input.bank.account_holder.label")}
            </DsText>

            <DsText variant="body-2" weight="regular" color={colors.gray[6]}>
              {bank?.data.name}
            </DsText>
          </DsStackVertical>

          <DsStackVertical spacing={8}>
            <DsText variant="body-2" weight="regular" color="#171923">
              CPF/CNPJ
            </DsText>

            <DsText variant="body-2" weight="regular" color={colors.gray[6]}>
              {formatBrazilianDocuments(bank?.data.document)}
            </DsText>
          </DsStackVertical>

          <DsStackVertical spacing={8}>
            <DsText variant="body-3" weight="regular" color="#747880">
              {t("root.when_requesting_a_withdrawal", { value: "R$ 1,00" })}
            </DsText>

            <DsCheckbox
              onChange={e => setIsAcceptedTerms(e.target.checked)}
              label={
                <DsText variant="body-3" weight="regular" color="#747880">
                  {t("common.i_agree")}
                </DsText>
              }
            />
          </DsStackVertical>
        </DsStackVertical>

        <DsMediaQuery largerThan="md" styles={{ flexDirection: "row-reverse" }}>
          <DsStackVertical spacing={16}>
            <DsButtonPrimary onClick={onConfirm} disabled={!isAcceptedTerms}>
              {t("common.confirm")}
            </DsButtonPrimary>
            <DsButtonSecondary onClick={openExtractDrawer}>
              {t("root.back")}
            </DsButtonSecondary>
          </DsStackVertical>
        </DsMediaQuery>
      </DsStackVertical>
    </Sheet>
  )
}
