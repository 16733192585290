import { signal } from "@preact/signals-react"

export type ChatMember = {
  id: string
  name: string
  avatar: string
}

const chatMember = signal<ChatMember | null>(null)
const groupId = signal<number | null>(null)

const conversationsLoaded = signal(false)
const hasConversations = signal(false)

const setConversationsLoaded = (loaded: boolean) =>
  (conversationsLoaded.value = loaded)
const setHasConversations = (has: boolean) => (hasConversations.value = has)

const init = (member: ChatMember, chatGroupId: number | null = null) => {
  chatMember.value = member
  groupId.value = chatGroupId
  setHasConversations(true)
}

const clear = () => {
  chatMember.value = null
  groupId.value = null
}

export {
  init,
  clear,
  groupId,
  chatMember,
  setConversationsLoaded,
  setHasConversations,
  conversationsLoaded,
  hasConversations
}
