import { supabase } from "../../lib/supabase"

export type channelUpdateAudiencePayload = {
  channelId: string
  audienceId: string | null
}

export async function channelUpdateAudience(
  payload: channelUpdateAudiencePayload
) {
  const record = {
    audience_id: payload.audienceId
  }

  const { data, error } = await supabase
    .from("db")
    .update(record)
    .eq("id", payload.channelId)

  if (error) throw error

  return data
}
