import { MS_EDITOR_URL } from "../../env"
import { fetchAPI } from "../../utils/fetchAPI"
import { getSession } from "../auth/getSession"

type LinkPreviewResponse = {
  title: string
  description: string
  url: string
  image: string
}

const endpoint = `${MS_EDITOR_URL}/link-preview`

export const linkPreview = async (siteUrl: string) => {
  const session = await getSession()

  const url = new URL(endpoint)

  url.searchParams.set("url", siteUrl)

  const responseData = await fetchAPI<LinkPreviewResponse>(url, {
    headers: {
      Authorization: `Bearer ${session.access_token}`
    }
  })

  return responseData
}
