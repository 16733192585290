import { useInfiniteQuery, useQuery } from "@tanstack/react-query"
import { queries } from "../../queries"

export const useDirectQueries = () => {
  const listGroup = () => {
    return useInfiniteQuery({
      ...queries.direct.listGroup(),
      initialPageParam: 1,
      getNextPageParam: (lastPage: any) => {
        if (lastPage.isLast) return null
        return lastPage.nextPage
      },
      gcTime: 60 * 60 * 1000
    })
  }

  const listMessage = (groupId: number | null) => {
    return useInfiniteQuery({
      ...queries.direct.listMessage(groupId as number),
      initialPageParam: 1,
      getNextPageParam: (lastPage: any) => {
        if (lastPage.isLast) return null
        return lastPage.nextPage
      },
      enabled: !!groupId,
      gcTime: 60 * 60 * 1000
    })
  }

  const count = () => {
    return useQuery({
      ...queries.direct.count(),
      gcTime: 60 * 60 * 1000
    })
  }

  return {
    listGroup,
    listMessage,
    count
  }
}
