import { createStyles } from "@raisesistemas/ds";

export const useStyles = createStyles(() => ({
  linkEditor: {
    display: "flex"
  },
  linkEditorInput: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: 0
  },
  linkEditorSave: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
  }
}))