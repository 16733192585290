import { getTenant } from "../../utils/getTenant"
import { getSession } from "../auth/getSession"
import { MS_METRICS_URL } from "../../env"
import { Metrics } from "../../types/metrics"

export type MetricsGetAllPayload = {
  startDate: string
  channelId: string
}

export const metricsGetAll = async (payload: MetricsGetAllPayload) => {
  const { startDate, channelId } = payload
  const { access_token } = await getSession()

  const url = new URL(MS_METRICS_URL)
  url.searchParams.set("start", startDate)
  url.searchParams.set("channel", channelId)
  url.searchParams.set("tenant", getTenant())

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json"
    }
  })

  if (!response.ok) throw new Error("get metrics error")

  const data: Metrics = await response.json()

  return data
}
