export const convertImageToBlob = async (image: File): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = event => {
      if (event.target?.result instanceof ArrayBuffer) {
        return resolve(new Blob([event.target.result]))
      }

      return reject(new Error("Error reading image"))
    }
    reader.onerror = error => reject(error)
    reader.readAsArrayBuffer(image)
  })
}
