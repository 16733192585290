import { DsSkeletonLine, DsStackVertical } from "@raisesistemas/ds"

export const NotificationManagerSkeleton = () => {
  return (
    <DsStackVertical>
      <DsSkeletonLine isFull />
      <DsSkeletonLine />
      <DsSkeletonLine isFull />
      <DsSkeletonLine />
      <DsSkeletonLine isFull />
    </DsStackVertical>
  )
}
