import {
  DsAvatar,
  DsBox,
  DsLinkButton,
  DsSpace,
  DsStackHorizontal,
  DsText
} from "@raisesistemas/ds"
import { useTranslation } from "react-i18next"

import { useLink } from "../../hooks/useLink"
import { truncateText } from "../../utils/truncateText"
import { InfiniteScroll } from "../common/InfiniteScroll"
import { CommentListByUserIdType } from "../../types/comment"
import { useDateDistanceToNow } from "../../hooks/useDateDistanceToNow"
import { useCommentQueries } from "../../hooks/queries/useCommentQueries"
import { EmptyState } from "../EmptyState"
import { IconCubeOutline } from "../../icons"

type MemberCommentsProps = {
  memberId: string
}

export const MemberComments = (props: MemberCommentsProps) => {
  const { memberId } = props

  const { t } = useTranslation()
  const { linkRegister } = useLink()
  const { dateDistanceToNow } = useDateDistanceToNow()

  const { byUserId } = useCommentQueries()
  const query = byUserId(memberId)

  const renderItem = ({ member, post }: CommentListByUserIdType) => {
    const { channel } = post
    const { emoji, title } = channel.data
    return (
      <DsStackHorizontal noWrap spacing="lg">
        <DsAvatar name={member.data.name} size="md" src={member.data.avatar} />

        <DsBox>
          <DsStackHorizontal spacing="xs">
            <DsText weight="medium" variant="body-1">
              {member.data.name}
            </DsText>
            <DsText color="dimmed" weight="regular" variant="body-3">
              {dateDistanceToNow(post.id)}
            </DsText>
          </DsStackHorizontal>
          <DsText color="dimmed" weight="regular" variant="body-2">
            {t("root.commented_post_in", {
              post: truncateText(post.data.title, 10)
            })}
            <DsLinkButton {...linkRegister(`/channels/${channel.id}/posts`)}>
              {emoji} {title}
            </DsLinkButton>
            "
          </DsText>
        </DsBox>
      </DsStackHorizontal>
    )
  }

  const empty = (
    <EmptyState.Root dashed>
      <EmptyState.Icon icon={IconCubeOutline} />
      <EmptyState.Title>
        {t("empty_state.member.comments.title")}
      </EmptyState.Title>
    </EmptyState.Root>
  )

  return (
    <>
      <DsSpace height={24} />
      <InfiniteScroll query={query} renderItem={renderItem} empty={empty} />
    </>
  )
}
