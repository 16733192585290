import { KINDS } from "../../lib/constants"
import { supabase } from "../../lib/supabase"
import { CommentType } from "../../types/comment"
import { getTenant } from "../../utils/getTenant"
import { getUserId } from "../../utils/getUserId"
import { createInfiniteService } from "../../utils/createInfiniteService"

export type CommentListPayload = {
  postId: string
}

export const commentList = createInfiniteService<
  CommentListPayload,
  CommentType
>(async ({ postId, rangeStart, rangeEnd, limit = 20 }) => {
  const userId = await getUserId()
  return supabase
    .from("db")
    .select(
      `
    id::text,
    postId:parent_id::text,
    data->content,
    user_uuid, 
    member(data->name,data->avatar,data->status),
    likes:db!parent_id(id::text), 
    likesCount:db!parent_id(count),
    repliesCount:db!parent_id(count),
    comment_reply:db!parent_id(id::text, data->content, member(data->name, data->avatar))
    `,
      { count: "exact", head: false }
    )
    .eq("tenant", getTenant())
    .eq("kind", KINDS.POST_COMMENT)
    .eq("parent_id", postId)
    .eq("likes.kind", KINDS.COMMENT_LIKE)
    .eq("likesCount.kind", KINDS.COMMENT_LIKE)
    .eq("likes.user_uuid", userId)
    .eq("comment_reply.kind", KINDS.COMMENT_REPLY)
    .eq("repliesCount.kind", KINDS.COMMENT_REPLY)
    .limit(limit, { foreignTable: KINDS.COMMENT_REPLY })
    .order("id")
    .range(rangeStart, rangeEnd)
})
