import {
  DsCenter,
  DsImageUpload,
  DsStackVertical,
  DsText,
  useDsTheme,
  rem,
  createStyles,
  DsBox,
  DsSpace
} from "@raisesistemas/ds"
import { useTranslation } from "react-i18next"

type ImagePreviewUploadProps = {
  title?: string
  aspectRatio: number
  handleImage: (image: string) => void

  imageDimensionsLabel: string
  imageSizeLabel: string
  image?: string
  fullWidth?: boolean
  fullPreview?: boolean
  error?: string
  withAsterisk?: boolean
}

export const ImagePreviewUpload = (props: ImagePreviewUploadProps) => {
  const {
    title,
    image,
    handleImage,
    fullWidth = false,
    fullPreview = false,
    imageDimensionsLabel,
    imageSizeLabel,
    aspectRatio,
    error,
    withAsterisk
  } = props
  const { colorScheme, colors } = useDsTheme()
  const { t } = useTranslation()
  const isDark = colorScheme === "dark"
  const { classes } = useStyles()
  return (
    <DsBox>
      {title && (
        <DsText
          variant="body-2"
          weight="medium"
          color={isDark ? colors.dark[0] : colors.dark[9]}
        >
          {title}{" "}
          {withAsterisk && <span className={classes.withAsterisk}>*</span>}
        </DsText>
      )}

      <DsImageUpload
        fullWidth={fullWidth}
        fullPreview={fullPreview}
        defaultValue={image}
        iconSize={28}
        cropConfig={{
          aspectRatio,
          cropTitle: t("common.crop_image"),
          cropBtnCancelText: t("common.cancel", { type: "" }),
          cropBtnCropText: t("common.crop", { type: "" })
        }}
        title={
          <DsStackVertical spacing={6}>
            <DsCenter>
              <DsText
                className={classes.textCenter}
                variant="body-2"
                weight="medium"
                color="#6630C8"
              >
                {t("root.drag_the_image_or_click_here_to_upload")}
              </DsText>
            </DsCenter>

            <DsCenter>
              <DsText
                className={classes.textCenter}
                variant="body-3"
                weight="regular"
                color={isDark ? colors.dark[0] : "#718096"}
              >
                JPG, JPEG, PNG
              </DsText>
            </DsCenter>

            <DsCenter>
              <DsText
                className={classes.textCenter}
                variant="body-3"
                weight="regular"
                color={isDark ? colors.dark[0] : "#718096"}
              >
                {t("common.image_min_size", { type: imageDimensionsLabel })}
              </DsText>
            </DsCenter>
            <DsCenter>
              <DsText
                className={classes.textCenter}
                variant="body-3"
                weight="regular"
                color={isDark ? colors.dark[0] : "#718096"}
              >
                {t("root.maximum_file_size", { type: imageSizeLabel })}
              </DsText>
            </DsCenter>
          </DsStackVertical>
        }
        description={null}
        onChange={handleImage}
        styles={{
          dropzone: {
            paddingTop: rem(40),
            paddingBottom: rem(40),
            ".mantine-AspectRatio-root": {
              transition: "all 100ms linear"
            },
            "&:hover .mantine-AspectRatio-root": {
              opacity: isDark ? 0.04 : 0.1
            }
          },
          container: {
            maxWidth: rem(350)
          },
          box: {
            width: 60,
            height: 60
          }
        }}
      />

      {error && (
        <>
          <DsSpace height={8} />
          <DsText color="red" variant="body-3" weight="regular">
            {error}
          </DsText>
        </>
      )}
    </DsBox>
  )
}

const useStyles = createStyles(({ colors }) => ({
  textCenter: {
    textAlign: "center"
  },
  withAsterisk: {
    color: colors.red[5]
  }
}))
