type FetcherOptions = {
  headers?: HeadersInit
  body?: any
}

export const fetchAPI = async <T>(
  url: string | URL,
  options: FetcherOptions = {}
) => {
  const defaultHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json"
  }

  const mergedHeaders = { ...defaultHeaders, ...options.headers }

  const response = await fetch(url, {
    headers: mergedHeaders,
    body:
      typeof options.body !== "string" && typeof options.body !== "undefined"
        ? JSON.stringify(options.body)
        : options.body
  })

  if (!response.ok) {
    throw new Error(response.statusText)
  }

  const json = await response.json()

  return json as T
}
