import { ROLES } from "../lib/constants"
import { MemberRole } from "../types/member"
import { useIsRole } from "./useIsRole"
import { useUser } from "./useUser"

export const useUserPermissions = (contentOwner?: string) => {
  const { roles, user_uuid, role } = useUser()
  const isContentOwner = contentOwner === user_uuid
  const isOwnerOrContentOwner = roles.owner || isContentOwner
  const currentUserRole = useIsRole()

  return {
    /**
     * @description
     * Pode ver o botão de abrir o menu de opções de um resposta
     */
    CAN_SEE_REPLY_ACTION_BUTTON:
      isOwnerOrContentOwner || role === ROLES.MODERATOR,
    /**
     * @description
     * Pode ver o botão de abrir o menu de opções de um comentário
     */
    CAN_SEE_COMMENT_ACTION_BUTTON:
      isOwnerOrContentOwner || role === ROLES.MODERATOR,
    /**
     * @description
     * Pode ver o botão de editar um comentário
     */
    CAN_SEE_EDIT_COMMENT_BUTTON: isContentOwner,
    /**
     * @description
     * Pode ver o botão de editar uma resposta
     */
    CAN_SEE_EDIT_REPLY_BUTTON: isContentOwner,
    /**
     * @description
     * Pode ver o botão de editar um post
     */
    CAN_SEE_EDIT_POST_BUTTON: isContentOwner,
    /**
     * @description
     * Pode ver o botão de excluir post
     */
    CAN_SEE_POST_BUTTON_DELETE: isContentOwner,
    /**
     * @description
     * Pode ver o botão de abrir o menu de opções de um post
     */
    CAN_SEE_POST_ACTION_BUTTON:
      isOwnerOrContentOwner || role === ROLES.MODERATOR,
    /**
     * @description
     * Pode ver o botão de abrir o menu de opções de um canal
     */
    CAN_SEE_CHANNEL_ACTION_BUTTON: role === ROLES.OWNER,
    /**
     * @description
     * Pode ver o botão de adicionar um novo post
     */
    CAN_SEE_NEW_POST_BUTTON: role !== ROLES.MEMBER,
    /**
     * @description
     * Pode ver o botão de adicionar um novo canal
     */
    CAN_SEE_NEW_CHANNEL_BUTTON: role === ROLES.OWNER,
    /**
     * @description
     * Pode ver a tela de configuração da comunidade
     */
    CAN_SEE_SETTINGS_SPACE_TAB: roles.owner,
    /**
     * @description
     * Pode ver a tela de precificação da comunidade
     */
    CAN_SEE_SETTINGS_PRICE_TAB:
      roles.owner ||
      roles.member ||
      roles.memberPro ||
      role?.startsWith("member"),
    /**
     * @description
     * Pode ver a listagem de todos os membros da comunidade
     */
    CAN_SEE_SETTINGS_MEMBERS_TAB: roles.owner,
    /**
     * @description
     * Pode editar a cor principal da comunidade
     */
    CAN_EDIT_PRIMARY_COLOR: roles.owner,
    /**
     * @description
     * Pode ver o botão de detacar post ou remover destaque.
     */
    CAN_SEE_PIN_POST_BUTTON: [ROLES.MODERATOR, ROLES.OWNER].includes(role),

    GROUP: {
      /**
       * @description
       * Pode criar grupos
       */
      CAN_CREATE_GROUP: [ROLES.OWNER].includes(role),

      /**
       * @description
       * Pode editar grupo
       */
      CAN_EDIT_GROUP: [ROLES.OWNER].includes(role),
      /**
       * @description
       * Pode editar grupo
       */
      CAN_SEE_MEMBER_LIST: [ROLES.OWNER].includes(role)
    },

    /**
     * @description
     * Pode ver o botão para abrir o drawer de permissões.
     */
    CAN_SEE_MEMBER_PERMISSIONS: (memberRole: MemberRole) => {
      if (memberRole === "blocked_member" || memberRole === "owner")
        return false
      if (currentUserRole.isMember || currentUserRole.isMemberPro) return false

      if (currentUserRole.isOwner) return true

      if (
        currentUserRole.isModerator &&
        (memberRole === "member" || memberRole === "member_pro")
      )
        return true

      return false
    }
  }
}
