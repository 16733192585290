import { ChangeEvent, useCallback } from "react"
import { useForm } from "@mantine/form"
import debounce from "lodash.debounce"

import { useTranslation } from "react-i18next"
import {
  DsBox,
  DsFlex,
  DsText,
  DsSpace,
  DsSwitch,
  DsStackVertical,
  toast
} from "@raisesistemas/ds"

import { useActivityMutations } from "../../../hooks/mutations/useActivityMutations"

import { useStyles } from "./NotificationsManagerForm.style"

type NotificationManagerFormProps = {
  preferences: {
    paused: boolean
    data: string[]
  }
}

const preferences = [
  "post",
  "post_comment",
  "comment_reply",
  "post_like",
  "comment_like",
  "reply_like",
  "post_mention",
  "comment_mention",
  "reply_mention",
  "event",
  "event_mention"
]

export const NotificationManagerForm = (
  props: NotificationManagerFormProps
) => {
  const {
    preferences: { data, paused }
  } = props

  const { t } = useTranslation()
  const { classes } = useStyles()
  const { mutate } = useActivityMutations().updatePreferences

  const form = useForm({
    initialValues: {
      paused,
      preferences: data
    }
  })

  const handleSubmit = useCallback(
    debounce((variables: { paused: boolean; data: string[] }) => {
      mutate(variables, {
        onError: () => {
          toast.error({
            message: t("error.message.cant_finalize")
          })
        }
      })
    }, 1000),
    []
  )

  const handleTogglePreference = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target

    let newPreferences: string[] = []

    if (checked) {
      newPreferences = [...form.values.preferences, name]
    } else {
      newPreferences = form.values.preferences.filter(pref => pref !== name)
    }

    form.setFieldValue("preferences", newPreferences)

    handleSubmit({ paused: form.values.paused, data: newPreferences })
  }

  const handleTogglePause = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target
    form.setFieldValue("paused", checked)

    handleSubmit({ paused: checked, data: form.values.preferences })
  }

  const handleRegisterPreferenceField = (name: string) => {
    return {
      name,
      value: form.values.preferences?.includes(name),
      onChange: handleTogglePreference,
      disabled: form.values.paused
    }
  }

  return (
    <>
      <DsBox className={classes.muteAllContainer}>
        <DsFlex gap={24} justify="space-between" align="center">
          <DsText variant="body-1" weight="regular">
            {t("notification.mute_all")}
          </DsText>
          <DsSwitch value={form.values.paused} onChange={handleTogglePause} />
        </DsFlex>
      </DsBox>

      <DsSpace height={16} />

      <DsText variant="body-2" weight="medium">
        {t("notification.notify_me_when")}
      </DsText>

      <DsSpace height={16} />

      <DsStackVertical spacing="sm">
        {preferences.map(value => (
          <DsFlex
            key={`notification-option-${value}`}
            justify="space-between"
            align="center"
          >
            <DsText variant="body-1" weight="regular">
              {t(`notification.preferences.label.${value}`)}
            </DsText>
            <DsSwitch {...handleRegisterPreferenceField(value)} />
          </DsFlex>
        ))}
      </DsStackVertical>
    </>
  )
}
