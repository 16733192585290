import { supabase } from "../../lib/supabase"

type Like = { id: string }

type LikeFindUniquePayload = {
  kind: string
  parentId: string
  userId: string
}

export const likeFindUnique = async (payload: LikeFindUniquePayload) => {
  const { kind, parentId, userId } = payload
  const { data } = await supabase
    .from("db")
    .select("id::text")
    .eq("kind", kind)
    .eq("parent_id", parentId)
    .eq("user_uuid", userId)
    .limit(1)
    .returns<Like[]>()
    .single()

  return data
}
