import { DsActionIconBase, DsGroup, useDsTheme } from "@raisesistemas/ds"
import { PostModel } from "../../../types/post"
import { MenuOptions } from "../../MenuOptions"
import { IconPinFill } from "../../../icons"

import { useMenuItens } from "./useMenuItens"
import { useTranslation } from "react-i18next"
import { useDisclosure } from "@mantine/hooks"
import { ConfirmDeleteModal } from "../../ConfirmDeleteModal"
import { usePostDeleteMutation } from "../../../hooks/mutations/usePostDeleteMutation"

type PostMenuOptionsProps = {
  post: PostModel
  hideAction?: boolean
}

export const PostMenuOptions = (props: PostMenuOptionsProps) => {
  const { post, hideAction = false } = props
  const { t } = useTranslation()
  const postDelete = usePostDeleteMutation()
  const [opened, openedHandler] = useDisclosure(false)
  const { colors, primaryColor } = useDsTheme()

  const { canSeePostAction, dropdownItens, handleUnpin } = useMenuItens({
    post,
    handleOpenDelete: openedHandler.open
  })

  const handleDelete = () => postDelete.mutate(post.id)

  return (
    <>
      <DsGroup>
        {post.fixed && (
          <DsActionIconBase onClick={handleUnpin}>
            <IconPinFill size={16} color={colors[primaryColor][5]} />
          </DsActionIconBase>
        )}
        {canSeePostAction && !hideAction && (
          <MenuOptions
            target={{ testId: "menu-options-button" }}
            data-testid="post-options"
            dropdownItens={dropdownItens}
          />
        )}
      </DsGroup>

      <ConfirmDeleteModal
        opened={opened}
        onClose={openedHandler.close}
        title={t("validation.are_you_sure")}
        description={t("common.delete_question", {
          type: t("root.post").toLowerCase()
        })}
        onDelete={handleDelete}
        isLoading={postDelete.isPending}
      />
    </>
  )
}
