import {
  DsBox,
  DsMediaQuery,
  DsMediaQueryProps,
  createStyles
} from "@raisesistemas/ds"

type SpaceVerticalResponsiveProps = {
  base: number
  size: number
  largerThan: DsMediaQueryProps["largerThan"]
}

const useStyles = createStyles((_, { size }: { size: number }) => ({
  base: {
    height: size,
    minHeight: size,
    display: "block"
  }
}))

export const SpaceVerticalResponsive = ({
  base,
  size,
  largerThan
}: SpaceVerticalResponsiveProps) => {
  const { classes } = useStyles({ size: base })
  return (
    <DsMediaQuery
      largerThan={largerThan}
      styles={{ height: size, minHeight: size }}
    >
      <DsBox className={classes.base} />
    </DsMediaQuery>
  )
}
