import { zodResolver } from "@mantine/form"
import { TFunction } from "i18next"
import { z } from "zod"

export const topicCreateSchema = (t: TFunction<"translation", undefined>) => {
  const schema = z.object({
    title: z
      .string()
      .max(24, {
        message: t("input.channel_topic.create.title.validations.max_size")
      })
      .min(3, {
        message: t("input.channel_topic.create.title.validations.min_size")
      })
  })

  return zodResolver(schema)
}
