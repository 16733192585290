import { useSearchParams } from "react-router-dom"

export const useSetParams = () => {
  const [params, setParams] = useSearchParams()

  return {
    params,
    setParams
  }
}
