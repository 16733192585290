import { RichTextEditor, useRichTextEditorContext } from "@mantine/tiptap"
import { ChangeEvent, useCallback, useRef } from "react"
import { uploadImage } from "../../../api/upload/uploadImage"
import { IconImagesOutline } from "../../../icons"

export const EditorPhotoControl = () => {
  const { editor } = useRichTextEditorContext()
  const inputRef = useRef<HTMLInputElement>(null)

  const addImage = useCallback(() => {
    inputRef.current?.click()
  }, [])

  const handleUpload = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const image = event.target.files?.[0]

      if (image) {
        const url = await uploadImage(image)

        if (url) editor.chain().focus().setImage({ src: url }).run()
      }
    },
    [editor]
  )

  return (
    <RichTextEditor.Control
      onClick={addImage}
      aria-label="Add image"
      title="Add image"
    >
      <input
        ref={inputRef}
        type="file"
        onChange={handleUpload}
        style={{ display: "none" }}
        accept="image/*"
      />
      <IconImagesOutline size={16} />
    </RichTextEditor.Control>
  )
}
