import { createStyles, rem } from "@raisesistemas/ds"

export const useStyles = createStyles(() => ({
  image: {
    width: "100%",
    maxWidth: rem(320),
    height: `${rem(150)} !important`,
    objectFit: "cover",
    borderRadius: 8
  },
  item: {
    width: "100%"
  }
}))
