import { useDsColorScheme } from "@raisesistemas/ds"
import { useTranslation } from "react-i18next"

import { IconMoon, IconSun } from "../../icons"

export const useThemeSwitchMenu = () => {
  const { colorScheme, toggleColorScheme } = useDsColorScheme()
  const { t } = useTranslation()
  const Icon = colorScheme === "dark" ? IconSun : IconMoon
  const text =
    colorScheme === "dark" ? t("root.theme_light") : t("root.theme_dark")

  return {
    name: text,
    icon: <Icon size={14} />,
    withDivider: true,
    onClick: () => toggleColorScheme()
  }
}
