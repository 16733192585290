import { MS_ACTIVITIES_URL } from "../../env"
import { ActivityPreferences } from "../../types/activity"
import { getTenant } from "../../utils/getTenant"

export const activityPreferencesByUser = async (userId: string) => {
  const endpoint = `${MS_ACTIVITIES_URL}/preferences?tenant=${getTenant()}&user_id=${userId}`
  const response = await fetch(endpoint)

  if (!response.ok) {
    throw new Error(response.statusText)
  }

  const data = await response.json()

  return data as ActivityPreferences
}
