import { Box } from "@mantine/core"
import { createStyles, useDsTheme } from "@raisesistemas/ds"
import React, { ButtonHTMLAttributes, forwardRef } from "react"

export type FloatingButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  show?: boolean
  children: React.ReactNode
  bg?: string
  right?: number
  bottom?: number
  shadow?: string
}

export const FloatingButton = forwardRef<
  HTMLButtonElement,
  FloatingButtonProps
>((props, ref) => {
  const { colors, primaryColor } = useDsTheme()
  const {
    className,
    show = true,
    bg = colors[primaryColor][7],
    right = 40,
    bottom = 40,
    shadow = "",
    ...restProps
  } = props
  const { classes, cx } = useStyles()

  if (!show) return

  return (
    // TODO: adicionar ref no DsBox
    <Box
      ref={ref}
      component="button"
      className={cx("floating-button", classes.button, className)}
      {...restProps}
      style={{
        bottom,
        right,
        backgroundColor: bg,
        boxShadow: shadow
      }}
    />
  )
})

const useStyles = createStyles(() => ({
  button: {
    width: 56,
    height: 56,
    borderRadius: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    border: 0,
    cursor: "pointer",
    zIndex: 10
  }
}))
