import {
  DsGroup,
  DsSwitch,
  DsText,
  DsTextInputBaseProps
} from "@raisesistemas/ds"
import { useTranslation } from "react-i18next"

type ChannelCanMemberWriteProps = DsTextInputBaseProps

export const ChannelCanMemberWrite = (props: ChannelCanMemberWriteProps) => {
  const { t } = useTranslation()
  return (
    <DsGroup position="apart">
      <DsText variant="body-2" weight="regular">
        {t("root.allow_members_to_create")}
      </DsText>
      <DsSwitch {...props} />
    </DsGroup>
  )
}
