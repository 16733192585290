import { STORAGE_KEYS } from "../../lib/constants"
import { supabase } from "../../lib/supabase"
import { MemberType } from "../../types/member"
import { getTenant } from "../../utils/getTenant"

export const memberActivatedAt = async (payload: MemberType) => {
  const activatedAt = new Date().toISOString().split("T")[0]

  const { error } = await supabase
    .from("member")
    .update({
      data: { ...payload.data, activated_at: activatedAt, confirmed: true }
    })
    .eq("tenant", getTenant())
    .eq("user_uuid", payload.user_uuid)

  if (!error) localStorage.setItem(STORAGE_KEYS.ACTIVATED_AT, activatedAt)
}
