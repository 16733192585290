import { useQuery } from "@tanstack/react-query"
import { queries } from "../../queries"
import { TopicByIdPayload } from "../../api/topic/topicById"

export const useTopicQueries = () => {
  const byId = (payload: TopicByIdPayload) => {
    const { data, isFetching } = useQuery(queries.topic.byId(payload))

    return {
      topic: data,
      isFetching
    }
  }

  const listWithChannels = () => {
    const { data, isFetching, ...rest } = useQuery(
      queries.topic.listWithChannels()
    )
    return {
      ...rest,
      ...data,
      isFetching
    }
  }

  return {
    byId,
    listWithChannels
  }
}
