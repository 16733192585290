import {
  DsButtonPrimary,
  DsButtonSecondary,
  DsGroup,
  DsStackVertical,
  DsTextInputBase,
  createStyles,
  toast
} from "@raisesistemas/ds"
import { useTranslation } from "react-i18next"
import { Sheet } from "../Sheet"
import { useForm } from "@mantine/form"
import { useTopicMutations } from "../../hooks/mutations/useTopicMutations"
import { useCharactersRemaining } from "../../hooks/useCharactersRemaining"
import { drawers } from "../../state"
import { topicEditSchema } from "../../validations/topicEditSchema"
import { useTopicQueries } from "../../hooks/queries/useTopicQueries"
import { useEffect, useState } from "react"

export type ChannelTopicEditDrawerProps = {
  topicId: string
}

const MAX_TITLE_CHARACTERS = 24

export const ChannelTopicEditDrawer = () => {
  const { isOpen, close, props } = drawers.topic.edit

  const { classes } = useStyles()
  const { t } = useTranslation()

  const topicQueries = useTopicQueries()
  const { topic, isFetching } = topicQueries.byId({ id: props.topicId })
  const topicMutations = useTopicMutations()

  const [initialTitle, setInitialTitle] = useState("")
  const form = useForm({
    initialValues: {
      id: props.topicId,
      title: ""
    },

    validate: topicEditSchema(t)
  })

  const { characters } = useCharactersRemaining(
    MAX_TITLE_CHARACTERS,
    form.values.title.length
  )
  const onSuccess = () => {
    toast.success({ message: t("toast.channel_topic.edited") })
    close()
  }

  const handleSubmit = () => {
    form.onSubmit(values => topicMutations.edit.mutate(values, { onSuccess }))()
  }

  const isEditDisabled =
    form.values.title == "" || initialTitle == form.values.title

  useEffect(() => {
    if (!isFetching && topic) {
      setInitialTitle(topic.data.title)
      form.setValues(topic.data)
    }
  }, [isFetching])

  return (
    <Sheet
      onClose={close}
      opened={isOpen}
      title={t("drawer.channel_topic.edit.title")}
      footer={
        <DsGroup position="right" className={classes.footerRoot}>
          <DsButtonSecondary
            onClick={close}
            loading={topicMutations.edit.isPending}
          >
            {t("common.back")}
          </DsButtonSecondary>
          <DsButtonPrimary
            onClick={handleSubmit}
            loading={topicMutations.edit.isPending}
            disabled={isEditDisabled}
          >
            {t("root.save_editions")}
          </DsButtonPrimary>
        </DsGroup>
      }
    >
      <DsStackVertical spacing={16}>
        <DsTextInputBase
          required
          label={t("input.channel_topic.edit.title.label")}
          placeholder={t("input.channel_topic.edit.title.placeholder")}
          maxLength={MAX_TITLE_CHARACTERS}
          description={characters}
          inputWrapperOrder={["label", "input", "description", "error"]}
          {...form.getInputProps("title")}
        />
      </DsStackVertical>
    </Sheet>
  )
}

const useStyles = createStyles(({ fn }) => ({
  footerRoot: {
    [fn.smallerThan("sm")]: {
      flexDirection: "column-reverse",
      alignItems: "stretch"
    }
  }
}))
