import { useEffect } from "react"
import { Navigate, useParams, useSearchParams } from "react-router-dom"

import { signInWithIdToken } from "../../api/auth/signIn"
import { useNav } from "../../hooks/useNav"

export const PageOAuthCallback = () => {
  const [searchParams] = useSearchParams()
  const { provider } = useParams() as { provider: string }

  const navigator = useNav()

  const token = searchParams.get("token")
  const allowedProviders = ["apple", "google"]
  const isValidProvider = allowedProviders.includes(provider)

  if (!token || !isValidProvider) return <Navigate to="/sign-in" replace />

  const handleSignIn = async () => {
    try {
      await signInWithIdToken({
        provider,
        token
      })

      navigator("/oauth", { replace: true })
    } catch (error) {
      alert(error)
      navigator("/sing-in", { replace: true })
    }
  }

  useEffect(() => {
    handleSignIn()
  }, [])

  return null
}
