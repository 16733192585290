import { supabase } from "../../lib/supabase"
import { getTenant } from "../../utils/getTenant"
import { likeFindUnique } from "./likeFindUnique"
import { likeUpdate } from "./likeUpdate"

type LikePayload = {
  kind: string
  parentId: string
  userId: string
}

export const like = async ({ kind, parentId, userId }: LikePayload) => {
  const trashed = await likeFindUnique({
    kind: `trash_${kind}`,
    parentId,
    userId
  })

  if (trashed) {
    return await likeUpdate({ kind, liked: true, parentId, id: trashed.id })
  }

  const { error } = await supabase.from("db").insert({
    kind,
    tenant: getTenant(),
    parent_id: parentId,
    user_uuid: userId
  })

  if (error) throw error
}
