import { KINDS } from "../../lib/constants"
import { supabase } from "../../lib/supabase"
import { SpaceType } from "../../types/space"
import { getTenant } from "../../utils/getTenant"

export async function spaceById() {
  const { data, error } = await supabase
    .from("db")
    .select("id::text, kind, data")
    .eq("tenant", getTenant())
    .in("kind", [KINDS.SPACE, KINDS.SPACE_PRO])
    .single<SpaceType>()

  if (error) throw new Error("SpaceById: Space not found")

  return data
}
