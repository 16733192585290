import { useTranslation } from "react-i18next"
import { DsActionIconBase, DsFlex, DsText } from "@raisesistemas/ds"

import { NotificationList } from "../NotificationList"
import { drawers } from "../../../state"
import { IconSettings } from "../../../icons"
import { Sheet } from "../../Sheet"
import { useActivityMutations } from "../../../hooks/mutations/useActivityMutations"

export const NotificationDrawer = () => {
  const { t } = useTranslation()

  const { mutate } = useActivityMutations().readAll

  const handleClose = () => {
    mutate()
    drawers.notifications.show.close()
  }

  const handleOpenNotificationManager = () => {
    drawers.notifications.manager.open()
  }

  return (
    <Sheet
      title={
        <DsFlex justify="space-between" align="center" gap={24}>
          <DsText variant="body-1">{t("root.notifications")}</DsText>
          <DsActionIconBase size="sm" onClick={handleOpenNotificationManager}>
            <IconSettings size={15} />
          </DsActionIconBase>
        </DsFlex>
      }
      opened={drawers.notifications.show.isOpen}
      onClose={handleClose}
    >
      <NotificationList />
    </Sheet>
  )
}
