import { DsText } from "@raisesistemas/ds"
import { NotificationOpenedIndicator } from "../NotificationOpenedIndicator"
import { useNav } from "../../../hooks/useNav"
import { formatDistanceToNowStrict } from "../../../utils/formatDistanceToNowStrict"
import {
  NotificationDescription,
  NotificationDescriptionProps
} from "./NotificationDescription"
import { useState } from "react"
import { drawers } from "../../../state"
import { useActivityMutations } from "../../../hooks/mutations/useActivityMutations"
import { MemberInfo } from "../../Member/MemberInfo"

export type NotificationItemProps = NotificationDescriptionProps

export const NotificationItem = (props: NotificationItemProps) => {
  const { read, type, actor, target, metadata, time } = props.data

  const [isRead, setIsRead] = useState(read)

  const nav = useNav()
  const { mutate } = useActivityMutations().readAll

  const paths = {
    post: `/posts/${target.id}`,
    post_comment: `/posts/${metadata?.id}#${target.id}`,
    comment_reply: `/posts/${metadata?.parent_id}#${metadata?.id}}`,
    post_like: `/posts/${metadata?.id}`,
    comment_like: `/posts/${metadata?.parent_id}#${metadata?.id}`,
    reply_like: `/redirect?to=post&postCommentId=${metadata?.parent_id}`,
    post_mention: `/posts/${target?.id}`,
    comment_mention: `/posts/${target?.parent_id}`,
    reply_mention: `/posts/${metadata?.parent_id}`,
    event: "/events",
    event_mention: "/events"
  }

  const handleNav = () => {
    mutate()
    setIsRead(true)
    drawers.notifications.show.close()
    nav(paths[type as keyof typeof paths])
  }
  return (
    <MemberInfo.Root
      onClick={handleNav}
      avatarSection={
        <MemberInfo.Avatar
          memberId={actor.id}
          name={actor.name}
          avatar={actor.avatar}
          size="md"
          disableClick
        />
      }
      data-testid="notification-item"
      data-notification-id={props.data.id}
    >
      <MemberInfo.Name memberId={actor.id} name={actor.name} />
      <DsText variant="body-3" weight="regular" color="dimmed">
        <NotificationDescription {...props} />.{" "}
        {formatDistanceToNowStrict(new Date(time))}
        <NotificationOpenedIndicator read={isRead} />
      </DsText>
    </MemberInfo.Root>
  )
}
