import { useQuery } from "@tanstack/react-query"
import { spaceById } from "../../api/space/spaceById"
import { queries } from "../../queries"
import { getHost } from "../../utils/getHost"

export const useSpaceQuery = (enabled = false) => {
  const currentHost = getHost()

  return useQuery({
    queryFn: spaceById,
    queryKey: queries.space.info().queryKey,
    enabled: enabled || currentHost !== "app"
  })
}
