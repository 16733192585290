import { supabase } from "../../lib/supabase"
import { getTenant } from "../../utils/getTenant"

export type MemberGetChannelsPayload = {
  memberId: string
}
export const memberGetChannels = async (payload: MemberGetChannelsPayload) => {
  const { memberId } = payload

  const { data, error } = await supabase.rpc("get_channels_by_member", {
    _tenant: getTenant(),
    _user_uuid: memberId
  })

  if (error) throw new Error(error.message)

  if (!data || data.length <= 0) return null

  return data.map(channel =>
    Object.assign({}, channel, { id: BigInt(channel.id).toString() })
  )
}
