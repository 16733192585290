import { JSONContent, generateJSON, generateText } from "@tiptap/react"
import { ExtensionKit } from "../../../extensions/ExtensionKit"

const extensions = ExtensionKit()

export const contentToText = (content: string | JSONContent) => {
  const isHTML = typeof content === "string"
  const value = isHTML ? generateJSON(content, extensions) : content
  return generateText(value, extensions)
}
