import { postFixedCreate } from "./postFixedCreate"
import { postFixedRemove } from "./postFixedRemove"

type PostFixedUpdatePayload = {
  currentPostId: string
  newPostId: string
  channelId: string
}

export const postFixedUpdate = async (payload: PostFixedUpdatePayload) => {
  const { currentPostId, channelId, newPostId } = payload

  try {
    await postFixedRemove({
      channelId,
      postId: currentPostId
    })

    await postFixedCreate({
      channelId,
      postId: newPostId
    })
  } catch (error) {
    throw error
  }
}
