import { DsBox, DsTextInputBase, DsTextInputBaseProps } from "@raisesistemas/ds"

import { IconSearch } from "../../../icons"
import { useStyles } from "./TextInputSearch.style"

export const TextInputSearch = (props: DsTextInputBaseProps) => {
  const { classNames, ...rest } = props
  const { classes, cx } = useStyles()
  return (
    <DsBox className={classes.container}>
      <DsTextInputBase
        classNames={{
          ...classNames,
          input: cx(classNames?.input, classes.input)
        }}
        {...rest}
      />
      <IconSearch className={classes.icon} />
    </DsBox>
  )
}
