import { Fragment, PropsWithChildren, useRef } from "react"

import { DsBox, DsCenter, DsLoader, createStyles } from "@raisesistemas/ds"
import { usePullToRefresh } from "../../hooks/usePullToRefresh"
import { motion } from "framer-motion"

export type PullToRefreshProps = {
  onRefresh: () => Promise<any>
  distanceToRefresh?: number
}

export const PullToRefresh = (props: PropsWithChildren<PullToRefreshProps>) => {
  const { children, onRefresh, distanceToRefresh = 100 } = props
  const { classes, cx } = useStyles()

  const ref = {
    loadingContainer: useRef<HTMLDivElement>(null)
  }

  const getContainerHeight = () => {
    const { current } = ref.loadingContainer

    if (!current) return 0

    return current.clientHeight
  }

  const {
    handlePullStart,
    handlePullMove,
    handlePullEnd,
    isLoading,
    pullDistance
  } = usePullToRefresh({
    onRefresh,
    distanceToRefresh,
    loadingContainerHeight: getContainerHeight()
  })

  return (
    <DsBox className={classes.root}>
      <DsBox
        ref={ref.loadingContainer}
        className={cx(classes.loadingContainer, {
          [classes.visible]: isLoading
        })}
      >
        <DsCenter>
          <DsLoader />
        </DsCenter>
      </DsBox>

      <motion.div
        onTouchStart={handlePullStart}
        onTouchMove={handlePullMove}
        onTouchEnd={handlePullEnd}
        initial={{ translateY: "0" }}
        animate={{ translateY: `${pullDistance / 3}px` }}
        transition={{ ease: [0.0, 0.0, 0, 0.99], duration: 0.25 }}
        data-testid="pull-to-refresh-content-container"
      >
        <Fragment>{children}</Fragment>
      </motion.div>
    </DsBox>
  )
}

const useStyles = createStyles({
  root: {
    position: "relative"
  },
  loadingContainer: {
    top: 0,
    left: 0,
    right: 0,
    position: "absolute",
    visibility: "hidden"
  },

  visible: {
    visibility: "visible"
  }
})
