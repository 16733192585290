import { useState } from "react"
import { useTranslation } from "react-i18next"
import {
  DsBox,
  DsImage,
  DsSpace,
  DsPopover,
  DsScrollArea,
  DsStackVertical,
  DsActionIconBase,
  DsButtonUnstyled
} from "@raisesistemas/ds"
import { useRichTextEditorContext } from "@mantine/tiptap"

import { IconGif } from "../../../icons"
import { useDebounce } from "../../../hooks/useDebounce"
import { InfiniteScroll } from "../../common/InfiniteScroll"
import { TextInputSearch } from "../../common/TextInputSearch"
import { useGifQueries } from "../../../hooks/queries/useGifQueries"

import { useStyles } from "./EditorGifPicker.style"

export type EditorGifPickerProps = {
  onGifPick?: (src: string, alt: string) => void
  classNames?: {
    target?: string
  }
}

export const EditorGifPicker = (props: EditorGifPickerProps) => {
  const { onGifPick, classNames } = props
  const { t } = useTranslation()
  const { classes } = useStyles()
  const [open, setOpen] = useState(false)
  const { editor } = useRichTextEditorContext()

  const [searchQuery, setSearchQuery] = useState("")
  const debouncedQuery = useDebounce(searchQuery, 500)

  const { search } = useGifQueries()

  const query = search({
    query: debouncedQuery,
    enabled: open
  })

  const target = (
    <DsActionIconBase
      role="button"
      onClick={() => setOpen(open => !open)}
      className={classNames?.target}
    >
      <IconGif size={24} />
    </DsActionIconBase>
  )

  const handleInsertGif = (src: string, alt: string) => {
    if (onGifPick) {
      onGifPick(src, alt)
    } else {
      editor
        .chain()
        .focus()
        .setImage({ src, alt })
        .run()
    }
    setOpen(false)
    setSearchQuery("")
  }

  const renderItem = (item: { gif: string; alt: string }) => (
    <DsButtonUnstyled
      onClick={() => handleInsertGif(item.gif, item.alt)}
      className={classes.item}
    >
      <DsImage
        src={item.gif}
        classNames={{ image: classes.image }}
        width={320}
        height="auto"
        alt={item.alt}
      />
    </DsButtonUnstyled>
  )

  const dropdown = (
    <DsBox>
      <TextInputSearch
        data-testid={EDITOR_GIF_PICKER_INPUT_ID}
        placeholder={t("common.search", { type: "gif" })}
        value={searchQuery}
        onChange={e => setSearchQuery(e.target.value)}
      />
      <DsSpace height={16} />
      <DsScrollArea h={350}>
        <DsStackVertical spacing="xs">
          <InfiniteScroll renderItem={renderItem} query={query} />
        </DsStackVertical>
      </DsScrollArea>
    </DsBox>
  )
  return (
    <DsPopover
      opened={open}
      onClose={() => setOpen(false)}
      targetChildren={target}
      dropdownChildren={dropdown}
      withinPortal
      zIndex={10000}
      data-testid={EDITOR_GIF_PICKER_ID}
    />
  )
}
export const EDITOR_GIF_PICKER_ID = "gif-picker-id"
export const EDITOR_GIF_PICKER_INPUT_ID = "gif-picker-input-id"
