import { supabase } from "../../lib/supabase"

type LikeUpdatePayload = {
  id: string
  kind: string
  parentId: string
  liked: boolean
}

export const likeUpdate = async (payload: LikeUpdatePayload) => {
  const { kind, parentId, liked, id } = payload

  const trashKind = `trash_${kind}`

  const { error } = await supabase
    .from("db")
    .update({
      kind: liked ? kind : trashKind
    })
    .eq("kind", liked ? trashKind : kind)
    .eq("parent_id", parentId)
    .eq("id", id)

  if (error) throw error
}
