import { supabase } from "../../lib/supabase"
import { ROLES } from "../../lib/constants"
import { MemberType } from "../../types/member"
import { getTenant } from "../../utils/getTenant"

export type Roles = Array<keyof typeof ROLES>

export type MemberSearchPayload = {
  query: string
  confirmed?: boolean
  roles?: Roles
}

export const memberSearch = async ({
  query,

  roles = ["MEMBER", "MEMBER_PRO", "MODERATOR", "OWNER", "BLOCKED_MEMBER"],
  confirmed = true
}: MemberSearchPayload) => {
  const rolesFormatted = roles.map(role => role.toLowerCase())

  const { data, error } = await supabase
    .from("member")
    .select("*")
    .eq("tenant", getTenant())
    .in("role", rolesFormatted)
    .ilike("data->>name", `%${query}%`)
    .eq("data->>confirmed", confirmed)
    .limit(10)
    .order("data->>created_at", { ascending: false })

  if (error) throw error

  return data as MemberType[]
}
