import {
  DsBox,
  DsGroup,
  DsSpace,
  DsStackVertical,
  DsWrapperSpace,
  createStyles
} from "@raisesistemas/ds"

import { CommentType } from "../../types/comment"
import { JSONContent } from "@tiptap/core"
import { CommentHeader } from "./CommentHeader"
import { CommentBody } from "./CommentBody"
import { CommentRepliesSection } from "./CommentRepliesSection"
import { ButtonCommentGroup } from "../ButtonCommentGroup"
import { CommentForm } from "./CommentForm"
import { KINDS } from "../../lib/constants"
import { ConfirmDeleteModal } from "../ConfirmDeleteModal"

import { useState } from "react"
import { useDisclosure } from "@mantine/hooks"
import { useTranslation } from "react-i18next"
import { useCommentMutations } from "../../hooks/mutations/useCommentMutations"
import { useReplyMutations } from "../../hooks/mutations/useReplyMutations"

export type CommentProps = {
  comment: CommentType
  channelId?: string
}

export const Comment = (props: CommentProps) => {
  const { comment, channelId } = props
  const { t } = useTranslation()
  const { classes } = useStyles()
  const [newContent, setNewContent] = useState<JSONContent>(comment.content)
  const [isEdit, isEditHandler] = useDisclosure(false)
  const [formOpened, formHandler] = useDisclosure(false)
  const [confirmDeleteOpened, confirmDeleteHandler] = useDisclosure(false)
  const commentMutations = useCommentMutations()
  const replyMutations = useReplyMutations()

  const handleReplyCreate = (content: JSONContent) => {
    replyMutations.create.mutate(
      { parent_id: comment.id, content },
      { onSuccess: formHandler.close }
    )
  }

  const handleCommentLike = (liked: boolean) =>
    commentMutations.like.mutate({ commentId: comment.id, liked })

  const handleCommentDelete = () =>
    commentMutations.destroy.mutate({ id: comment.id })

  const handleCommentEdit = (content: JSONContent) =>
    commentMutations.edit.mutate(
      { id: comment.id, content },
      {
        onSuccess: () => {
          setNewContent(content)
          isEditHandler.close()
        }
      }
    )

  return (
    <DsBox>
      <DsGroup align="flex-start" style={{ width: "100%" }}>
        <DsBox className={classes.comment}>
          <DsSpace height="sm" />

          <CommentHeader
            id={comment.id}
            memberId={comment.user_uuid}
            memberAvatar={comment.member.avatar}
            memberName={comment.member.name}
            onEdit={isEditHandler.open}
            onDelete={confirmDeleteHandler.open}
          />

          <DsWrapperSpace paddingLeft={55}>
            <DsStackVertical>
              <CommentBody
                channelId={channelId}
                isEdit={isEdit}
                content={newContent!}
                defaultValue={comment.content}
                onSubmit={handleCommentEdit}
                onCloseEdit={isEditHandler.close}
                loading={commentMutations.edit.isPending}
              />
              <ButtonCommentGroup
                likeParentId={comment.id}
                countLike={comment.likesCount?.[0].count}
                countComment={comment.repliesCount?.[0].count}
                liked={comment.likes.length > 0}
                likeKind={KINDS.COMMENT_LIKE}
                onComment={formHandler.open}
                onLike={handleCommentLike}
              />
              <CommentForm
                channelId={channelId}
                showActions
                isVisible={formOpened}
                onHidden={formHandler.close}
                onSubmit={handleReplyCreate}
              />

              <CommentRepliesSection
                commentId={comment.id}
                channelId={channelId}
                repliesCount={comment.repliesCount?.[0].count}
              />
            </DsStackVertical>
          </DsWrapperSpace>
        </DsBox>
      </DsGroup>

      <ConfirmDeleteModal
        opened={confirmDeleteOpened}
        onClose={confirmDeleteHandler.close}
        title={t("validation.are_you_sure")}
        description={t("common.delete_question", {
          type: t("root.comment").toLocaleLowerCase()
        })}
        onDelete={handleCommentDelete}
        isLoading={commentMutations.destroy.isPending}
      />
    </DsBox>
  )
}

const useStyles = createStyles(theme => ({
  comment: {
    background: theme.fn.variant({ variant: "light", color: "gray" })
      .background,
    flex: 1,
    padding: 10,
    borderRadius: 8
  }
}))
