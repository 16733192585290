import { useStyles } from "./NavHeader.style"
import { NavLogo } from "./NavLogo"
import { DsButtonUnstyled } from "@raisesistemas/ds"
import { memo } from "react"

type NavHeaderProps = {
  handleNavigate: (path: string) => void
}

export const NavHeader = memo((props: NavHeaderProps) => {
  const { handleNavigate } = props
  const { classes } = useStyles()

  const handleGoToHome = () => handleNavigate("/channels/posts")

  return (
    <DsButtonUnstyled onClick={handleGoToHome} className={classes.header}>
      <NavLogo />
    </DsButtonUnstyled>
  )
})
