import { useMutation } from "@tanstack/react-query"
import { paymentRequestWithdraw } from "../../api/payment/paymentRequestWithdraw"

export const usePaymentMutations = () => {
  const requestWithdraw = useMutation({
    mutationFn: paymentRequestWithdraw
  })
  return {
    requestWithdraw
  }
}
