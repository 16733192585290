import { signal } from "@preact/signals-react"
import { ReactNode, useEffect } from "react"
import { ButtonAi } from "../../components/ButtonAi/ButtonAi"

type ButtonFloat = {
  id: string
  allPages?: boolean
  element: ReactNode
  permitInsert: boolean
}
const state = signal<ButtonFloat[]>([
  { id: "button-ai", element: <ButtonAi />, allPages: true, permitInsert: true }
])

export const useButtonFloat = (newButton?: ButtonFloat) => {
  const buttons = state.value
  const inList = (id: string) => state.value.some(button => button.id === id)
  const clearButtonNotFixed = () => {
    state.value = state.value.filter(button => button?.allPages)
  }
  if (newButton && !inList(newButton.id) && newButton.permitInsert)
    state.value = [...buttons, newButton]

  useEffect(() => {
    return () => clearButtonNotFixed()
  }, [])
  return {
    buttons
  }
}
