import { DsText, DsTextProps, createStyles } from "@raisesistemas/ds"

type StyleProps = { maxWidth: number | string }
type TextEllipsisProps = DsTextProps & StyleProps

export const TextEllipsis = (props: TextEllipsisProps) => {
  const { maxWidth, ...restProps } = props
  const { classes } = useStyles({ maxWidth })
  return <DsText {...restProps} className={classes.root} />
}

const useStyles = createStyles((_, { maxWidth }: StyleProps) => ({
  root: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth
  }
}))
