import { modals } from "@mantine/modals"
import { DsText } from "@raisesistemas/ds"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"

import { postFixedByChannel } from "../../../api/post/postFixedByChannel"
import { PostModel } from "../../../types/post"
import { useUserPermissions } from "../../useUserPermissions"

import { usePostFixedCreateMutation } from "../../mutations/usePostFixedCreateMutation"
import { usePostFixedRemoveMutation } from "../../mutations/usePostFixedRemoveMutation"
import { usePostFixedUpdateMutation } from "../../mutations/usePostFixedUpdateMutation"

type UseFixedPostProps = Pick<PostModel, "user_uuid" | "id" | "fixed">

export const useFixedPost = ({ id, user_uuid, fixed }: UseFixedPostProps) => {
  const params = useParams()
  const channelId = params?.id || ""
  const { t } = useTranslation()
  const { mutate: createMutate } = usePostFixedCreateMutation()
  const { mutate: removeMutate } = usePostFixedRemoveMutation()
  const { mutate: updateMutate } = usePostFixedUpdateMutation()
  const { CAN_SEE_PIN_POST_BUTTON } = useUserPermissions(user_uuid)

  const handlePinnedPostOverrideConfirmation = (currentPostId: string) => {
    /**
     * Mostra o modal de confirmação de substituição do post fixado
     */

    modals.openConfirmModal({
      title: t("root.replace_pinned_post_question"),
      children: (
        <DsText variant="body-2" weight="regular">
          {t("root.replace_pinned_post_description")}
        </DsText>
      ),
      labels: { confirm: t("button.replace"), cancel: t("button.cancel") },
      onConfirm: () => updateMutate({ channelId, currentPostId, newPostId: id })
    })
  }

  const handlePin = async () => {
    /**
     * Para o fluxo se o usuário não tiver permissão de fixar um post
     */
    if (!CAN_SEE_PIN_POST_BUTTON) return

    /**
     * Verifique se existe um post fixado neste canal
     */
    const currentFixedPost = await postFixedByChannel(channelId)

    /**
     * Se tiver um post fixado neste canal e o usuário estiver tentando
     * fixar um novo post, mostra um modal com um aviso.
     */
    if (currentFixedPost) {
      return handlePinnedPostOverrideConfirmation(currentFixedPost.id)
    }

    createMutate({ channelId, postId: id })
  }

  const handleUnpin = async () => {
    removeMutate({ channelId, postId: id })
  }

  const handleToggle = () => (fixed ? handleUnpin() : handlePin())

  return {
    handlePin,
    handleUnpin,
    handleToggle
  }
}
