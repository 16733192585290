import { supabase } from "../../lib/supabase"
import { MemberDataType, MemberType } from "../../types/member"
import { getTenant } from "../../utils/getTenant"

export type FinishRegisterPayload = {
  user_uuid: string
  data: MemberDataType
}

export const memberFinishRegister = async (payload: FinishRegisterPayload) => {
  const { user_uuid, data } = payload

  await supabase.auth.updateUser({
    data: {
      phone: data.phone?.replace(/\D/g, "")
    }
  })

  const response = await supabase
    .from("member")
    .update({ data })
    .eq("tenant", getTenant())
    .eq("user_uuid", user_uuid)
    .select()
    .single<MemberType>()

  return {
    user: response.data
  }
}
