import { BubbleMenu } from "@tiptap/react"
import { useTranslation } from "react-i18next"
import { RichTextEditor, RichTextEditorProps } from "@mantine/tiptap"
import { DsBox, DsFlex, DsText, createStyles } from "@raisesistemas/ds"

import { IconCodeSlash, IconEdit, IconType } from "../../icons"
import { EditorVideoControl } from "../Editor/EditorVideoControl"
import { EditorPhotoControl } from "../Editor/EditorPhotoControl"
import { EditorLinkPreviewControl } from "../Editor/EditorLinkPreviewControl"
import { EditorGifPicker } from "../Editor/EditorGifPicker"

type PostEditorProps = Pick<RichTextEditorProps, "editor"> & {
  error?: string | null
  toolbarStickyOffset?: number
}

const useStyles = createStyles(theme => {
  const isDark = theme.colorScheme === "dark"
  const borderColor = isDark ? theme.colors.dark[5] : theme.colors.gray[3]
  const controlColors = theme.fn.variant({ variant: "default" })
  return {
    root: {
      border: 0,
      padding: 0
    },
    rootError: {
      padding: 16,
      border: `1px solid ${theme.colors.red[5]}`
    },
    toolbar: {
      gap: 8,
      borderBottom: 0,
      borderRadius: 0,
      border: `1px solid ${borderColor}`,
      borderWidth: "1px 0px 1px 0px",
      padding: "8px 0",
      flexWrap: "nowrap",
      overflowX: "scroll",
      WebkitOverflowScrolling: "touch",

      "&::-webkit-scrollbar": {
        display: "none"
      }
    },
    content: {
      padding: "16px 0",

      "&>.ProseMirror": {
        padding: "1rem 0"
      }
    },
    controlsGroup: {
      flexWrap: "nowrap"
    },
    control: {
      border: 0,
      width: 35,
      height: 35,
      backgroundColor: isDark ? theme.colors.dark[7] : "white",

      "&:hover": {
        backgroundColor: `${controlColors.hover} !important`
      },

      "&>svg": {
        width: 18,
        height: 18,
        color: controlColors.color
      }
    },
    divider: {
      width: 1,
      height: 24,
      display: "block",
      background: borderColor
    }
  }
})

export const PostEditor = ({
  editor,
  error,
  toolbarStickyOffset = 0
}: PostEditorProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  return (
    <DsBox>
      <RichTextEditor
        editor={editor}
        classNames={{
          root: !!error ? classes.rootError : classes.root,
          toolbar: classes.toolbar,
          content: classes.content,
          controlsGroup: classes.controlsGroup,
          control: classes.control
        }}
        labels={{
          linkEditorSave: t("button.save"),
          linkEditorExternalLink: t("tooltip.target_blank"),
          linkEditorInternalLink: t("tooltip.target_self")
        }}
      >
        <RichTextEditor.Toolbar sticky stickyOffset={toolbarStickyOffset}>
          <DsFlex gap={2}>
            <RichTextEditor.H2 icon={() => <IconType />} />
            <RichTextEditor.H3 icon={() => <IconType size={12} />} />
            <RichTextEditor.Bold />
            <RichTextEditor.Italic />
            <RichTextEditor.Underline />
            <RichTextEditor.Strikethrough />
            <RichTextEditor.Blockquote />
          </DsFlex>

          <DsBox className={classes.divider} />

          <DsFlex gap={2}>
            <RichTextEditor.Highlight
              icon={() => <IconEdit strokeWidth={1.2} />}
            />
            <RichTextEditor.AlignLeft />
            <RichTextEditor.AlignCenter />
            <RichTextEditor.AlignRight />
            <RichTextEditor.BulletList />
            <RichTextEditor.OrderedList />
          </DsFlex>

          <DsBox className={classes.divider} />

          <DsFlex gap={2}>
            <EditorGifPicker classNames={{ target: classes.control }} />
            <EditorPhotoControl />
            <EditorVideoControl />
            <EditorLinkPreviewControl />
          </DsFlex>

          <DsBox className={classes.divider} />

          <DsFlex gap={2}>
            <RichTextEditor.Hr />
            <RichTextEditor.Code icon={() => <IconCodeSlash />} />
          </DsFlex>
        </RichTextEditor.Toolbar>

        {editor && (
          <BubbleMenu editor={editor}>
            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Bold />
              <RichTextEditor.Italic />
              <RichTextEditor.Link />

              {editor.getAttributes("link")?.href && <RichTextEditor.Unlink />}
            </RichTextEditor.ControlsGroup>
          </BubbleMenu>
        )}

        <RichTextEditor.Content />
      </RichTextEditor>
      {!!error && (
        <DsText color="red" variant="body-3">
          {error}
        </DsText>
      )}
    </DsBox>
  )
}
