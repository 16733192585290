import { createStyles, rem } from "@raisesistemas/ds"
import { JSONContent } from "@tiptap/core"
import { RichText } from "../RichText"

import { useEffect } from "react"
import { useCommentEditor } from "../../hooks/editor/useCommentEditor"
import { useDisclosure } from "@mantine/hooks"
import { RichTextSkeleton } from "../RichText/RichTextSkeleton"

export type CommentContentProps = {
  content: JSONContent
  showActions: boolean
}

export const CommentContent = (props: CommentContentProps) => {
  const { content, ...restProps } = props
  const { classes, cx } = useStyles()
  const [isLoading, isLoadingHandler] = useDisclosure(true)

  const editor = useCommentEditor({
    editable: false,
    content,
    onCreate: isLoadingHandler.close
  })

  useEffect(() => {
    editor?.commands.setContent(content)
  }, [content])

  return (
    <>
      {isLoading && <RichTextSkeleton content={content} />}

      <RichText
        editor={editor}
        classNames={{
          root: cx(classes.root, { [classes.rootVisible]: !isLoading })
        }}
        data-testid={COMMENT_CONTENT_ID}
        {...restProps}
      />
    </>
  )
}

export const COMMENT_CONTENT_ID = "comment-content-id"

const useStyles = createStyles({
  root: {
    border: 0,
    height: 0,
    opacity: 0,
    transition: "opacity 0.5s ease, height 0.5s ease"
  },
  rootVisible: {
    height: "auto",
    opacity: 1
  },
  content: {
    backgroundColor: "transparent",
    "&>div.tiptap": { padding: `${rem(16)} 0` }
  }
})
