import { createQueryKeys } from "@lukemorales/query-key-factory"
import { bankBRListAll } from "../api/bank/bankBRListAll"
import { bankFind } from "../api/bank/bankFind"
import { getTenant } from "../utils/getTenant"

export const bankQueries = createQueryKeys("banks", {
  list: () => ({
    queryKey: ["all"],
    queryFn: bankBRListAll
  }),

  find: () => ({
    queryKey: [getTenant()],
    queryFn: () => bankFind()
  })
})
