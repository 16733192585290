import { DsActionIconBase, DsMenu, DsMenuProps } from "@raisesistemas/ds"
import { IconEllipsisVertical } from "../../icons"

type MenuOptionsProps = Pick<DsMenuProps, "dropdownItens"> & {
  target?: {
    testId?: string
    className?: string
  }
}

export const MenuOptions = (props: MenuOptionsProps) => {
  const { target, ...rest } = props
  return (
    <DsMenu
      target={
        <div
          data-testid="menu-options-target-container"
          className={target?.className}
        >
          <DsActionIconBase data-testid={target?.testId}>
            {<IconEllipsisVertical size={16} />}
          </DsActionIconBase>
        </div>
      }
      {...rest}
    />
  )
}
