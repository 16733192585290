import {
  DsButtonPrimary,
  DsButtonSecondary,
  DsGroup,
  DsStackVertical,
  DsTextInputBase,
  createStyles,
  toast
} from "@raisesistemas/ds"
import { useTranslation } from "react-i18next"
import { Sheet } from "../Sheet"
import { useForm } from "@mantine/form"
import { topicCreateSchema } from "../../validations/topicCreateSchema"
import { useTopicMutations } from "../../hooks/mutations/useTopicMutations"
import { useCharactersRemaining } from "../../hooks/useCharactersRemaining"
import { drawers } from "../../state"

const MAX_TITLE_CHARACTERS = 24
const DEFAULT_TITLE = ""

export const ChannelTopicCreateDrawer = () => {
  const { isOpen, close } = drawers.topic.create

  const { classes } = useStyles()
  const { t } = useTranslation()
  const topicMutations = useTopicMutations()
  const form = useForm({
    initialValues: {
      title: DEFAULT_TITLE
    },

    validate: topicCreateSchema(t)
  })

  const { characters } = useCharactersRemaining(
    MAX_TITLE_CHARACTERS,
    form.values.title.length
  )

  const isEditDisabled = form.values.title == DEFAULT_TITLE

  const onSuccess = () => {
    toast.success({ message: t("toast.channel_topic.created") })
    close()
  }

  const handleSubmit = () => {
    form.onSubmit(values =>
      topicMutations.create.mutate(values, { onSuccess })
    )()
  }

  return (
    <Sheet
      onClose={close}
      opened={isOpen}
      title={t("button.create_topic")}
      footer={
        <DsGroup position="right" className={classes.footerRoot}>
          <DsButtonSecondary onClick={close}>
            {t("common.back")}
          </DsButtonSecondary>
          <DsButtonPrimary
            onClick={handleSubmit}
            loading={topicMutations.create.isPending}
            disabled={isEditDisabled}
          >
            {t("button.create")}
          </DsButtonPrimary>
        </DsGroup>
      }
    >
      <DsStackVertical spacing={16}>
        <DsTextInputBase
          required
          label={t("input.channel_topic.create.title.label")}
          placeholder={t("input.channel_topic.create.title.placeholder")}
          maxLength={MAX_TITLE_CHARACTERS}
          description={characters}
          inputWrapperOrder={["label", "input", "description", "error"]}
          {...form.getInputProps("title")}
        />
      </DsStackVertical>
    </Sheet>
  )
}

const useStyles = createStyles(({ fn }) => ({
  footerRoot: {
    [fn.smallerThan("sm")]: {
      flexDirection: "column-reverse",
      alignItems: "stretch"
    }
  }
}))
