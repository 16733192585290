import { useMutation, useQueryClient } from "@tanstack/react-query"
import { activityRead } from "../../api/activity/activityRead"
import { queries } from "../../queries"
import { activityReadAll } from "../../api/activity/activityReadAll"
import { activityPreferenceUpdate } from "../../api/activity/activityPreferenceUpdate"
import { activityReadByType } from "../../api/activity/activityReadByType"

export const useActivityMutations = () => {
  const queryClient = useQueryClient()

  const read = useMutation({
    mutationFn: activityRead,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queries.activities.list._def
      })
      queryClient.invalidateQueries({
        queryKey: queries.activities.unreadCount._def
      })
    }
  })

  const readAll = useMutation({
    mutationFn: () => activityReadAll(),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queries.activities.list._def
      })
      queryClient.invalidateQueries({
        queryKey: queries.activities.unreadCount._def
      })
    }
  })

  const readByType = useMutation({
    mutationFn: activityReadByType,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queries.activities.unreadCountByType._def
      })
    }
  })

  const updatePreferences = useMutation({
    mutationFn: activityPreferenceUpdate
  })

  return {
    read,
    readAll,
    readByType,
    updatePreferences
  }
}
