import { createQueryKeys } from "@lukemorales/query-key-factory"
import { ActivityListPayload, activityList } from "../api/activity/activityList"
import { activityPreferencesByUser } from "../api/activity/activityPreferencesByUser"
import { activityUnreadCount } from "../api/activity/activityUnreadCount"
import { activitiesLatest } from "../api/activity/activitiesLatest"
import { getTenant } from "../utils/getTenant"
import { activityUnreadCountByType } from "../api/activity/activityUnreadCountByType"
import {
  ActivitiesLatestListPayload,
  activitiesLatestList
} from "../api/activity/activitiesLatestList"

export const activitiesQueries = createQueryKeys("activities", {
  list: (payload: ActivityListPayload) => ({
    queryKey: [payload],
    queryFn: ({ pageParam = 1 }: { pageParam: number }) =>
      activityList({ ...payload, page: pageParam })
  }),
  latestList: (payload?: ActivitiesLatestListPayload) => ({
    queryKey: [payload],
    queryFn: ({ pageParam = 1 }: { pageParam: number }) =>
      activitiesLatestList({ ...payload, page: pageParam })
  }),
  latest: () => ({
    queryKey: [getTenant()],
    queryFn: activitiesLatest
  }),
  preferencesByUser: (userId: string) => ({
    queryKey: [userId],
    queryFn: () => activityPreferencesByUser(userId)
  }),
  unreadCount: (recipientId: string) => ({
    queryKey: [recipientId],
    queryFn: () => activityUnreadCount(recipientId)
  }),
  unreadCountByType: (type: string) => ({
    queryKey: [type],
    queryFn: () => activityUnreadCountByType(type)
  })
})
