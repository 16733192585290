import { supabase } from "../../lib/supabase"
import { getTenant } from "../../utils/getTenant"
import { getUserId } from "../../utils/getUserId"

type Record = { id: string; kind: string }

type RecordPayload = {
  kind: string
  parentId: string
  userId: string
}

type UpdateRecordPayload = Omit<RecordPayload, "userId"> & {
  id: string
  enabled: boolean
}

type TogglePayload = {
  kind: string
  parentId: string
}

const getRecord = async (payload: RecordPayload) => {
  const { kind, parentId: parent_id, userId: user_uuid } = payload
  const { data } = await supabase
    .from("db")
    .select("id::text, kind")
    .in("kind", [kind, `trash_${kind}`])
    .match({ parent_id, user_uuid })
    .limit(1)
    .returns<Record[]>()
    .single()

  return data
}

const updateRecord = async (payload: UpdateRecordPayload) => {
  const { kind, parentId: parent_id, enabled, id } = payload

  const trashKind = `trash_${kind}`
  const nextKind = enabled ? kind : trashKind
  const previousKind = enabled ? trashKind : kind

  const { error } = await supabase
    .from("db")
    .update({ kind: nextKind })
    .match({ kind: previousKind, parent_id, id })

  if (error) throw error

  return { enabled: nextKind === kind }
}

const createRecord = async ({ kind, parentId, userId }: RecordPayload) => {
  const { error } = await supabase.from("db").insert({
    kind,
    tenant: getTenant(),
    parent_id: parentId,
    user_uuid: userId
  })

  if (error) throw error

  return { enabled: true }
}

export const toggleRecord = async (payload: TogglePayload) => {
  const { kind, parentId } = payload
  const userId = await getUserId()

  const record = await getRecord({ kind, parentId, userId })

  if (!record) return await createRecord({ kind, parentId, userId })

  const { id } = record
  const enabled = record.kind !== kind

  return await updateRecord({ enabled, id, kind, parentId })
}
