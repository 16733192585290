import { memo } from "react"
import { DsBox, DsDividerBase, DsFlex, DsSpace } from "@raisesistemas/ds"
import { BASE_URL, RN } from "../../../env"
import { ButtonCommentGroup } from "../../ButtonCommentGroup"
import { ButtonShare } from "../../ButtonShare"
import { PostSaveButton } from "../PostSaveButton"
import { KINDS } from "../../../lib/constants"

import { usePostMutations } from "../../../hooks/mutations/usePostMutations"

type PostFooterProps = {
  postId: string
  postTitle: string
  postLikesCount: number
  postLiked: boolean
  postCommentsCount: number
  postSaved: boolean
  handleShowPost: () => void
}

export const PostFooter = memo((props: PostFooterProps) => {
  const {
    postId,
    postTitle,
    handleShowPost,
    postLikesCount,
    postLiked,
    postCommentsCount,
    postSaved
  } = props

  const postMutations = usePostMutations()
  const shareMessage = `${postTitle}: \n${BASE_URL}/posts/${postId}`
  const handleLikePost = (liked: boolean) =>
    postMutations.like.mutate({ postId, liked })
  return (
    <DsBox>
      <DsDividerBase />
      <DsSpace height="xl" />
      <DsFlex justify="space-between" align="start" gap={24}>
        <DsFlex gap={16}>
          <ButtonCommentGroup
            position="left"
            countLike={postLikesCount}
            liked={postLiked}
            onLike={handleLikePost}
            countComment={postCommentsCount}
            onComment={handleShowPost}
            hideSeeLike={false}
            likeKind={KINDS.POST_LIKE}
            likeParentId={postId}
          />
          {RN && <ButtonShare message={shareMessage} />}
        </DsFlex>
        <PostSaveButton saved={postSaved} postId={postId} />
      </DsFlex>

      <DsSpace height="xl" />
    </DsBox>
  )
})
