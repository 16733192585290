import {
  DsBox,
  DsButtonPrimary,
  DsMediaQuery,
  DsStackVertical,
  DsTabs,
  createStyles
} from "@raisesistemas/ds"
import {
  MemberDetailHeader,
  MemberDetailHeaderProps
} from "./MemberDetailHeader"
import { useTranslation } from "react-i18next"
import { MemberAbout } from "../MemberAbout"
import { MemberPosts } from "../MemberPosts"
import { MemberComments } from "../MemberComments"
import { rem } from "@mantine/core"
import { useUser } from "../../../hooks/useUser"

type MemberDetailViewProps = MemberDetailHeaderProps & {
  sendMessage: () => void
}

export const MemberDetailView = (props: MemberDetailViewProps) => {
  const { user_uuid } = useUser()
  const { member, isOnline, sendMessage } = props
  const { t } = useTranslation()
  const { classes } = useStyles()

  const isCurrentUserDetail = user_uuid === member.user_uuid

  const tabData = [
    {
      name: "about",
      title: t("root.about"),
      content: (
        <DsBox className={classes.tabContent}>
          <MemberAbout member={member} />
        </DsBox>
      )
    },
    {
      name: "posts",
      title: "Posts",
      content: (
        <DsBox className={classes.tabContent}>
          <MemberPosts memberId={member.user_uuid} />
        </DsBox>
      )
    },
    {
      name: "comments",
      title: t("root.comments"),
      content: (
        <DsBox className={classes.tabContent}>
          <MemberComments memberId={member.user_uuid} />
        </DsBox>
      )
    }
  ]

  return (
    <DsStackVertical
      spacing={0}
      className={classes.root}
      data-testid={MEMBER_DETAIL_VIEW_ID}
    >
      <DsBox className={classes.content}>
        <DsStackVertical spacing={24} align="flex-start">
          <MemberDetailHeader member={member} isOnline={isOnline} />
          <DsBox style={{ width: "100%" }}>
            <DsTabs
              grow
              defaultTab="about"
              variant="contained"
              data={tabData}
            />
          </DsBox>
        </DsStackVertical>
      </DsBox>
      {!isCurrentUserDetail && (
        <DsBox className={classes.footer}>
          <DsMediaQuery
            largerThan="sm"
            styles={{ flexDirection: "row", justifyContent: "right" }}
          >
            <DsStackVertical spacing={0}>
              <DsButtonPrimary
                onClick={sendMessage}
                data-testid={BUTTON_SEND_MESSAGE_ID}
              >
                {t("root.send_message")}
              </DsButtonPrimary>
            </DsStackVertical>
          </DsMediaQuery>
        </DsBox>
      )}
    </DsStackVertical>
  )
}

export const MEMBER_DETAIL_VIEW_ID = "member-detail-view-id"
export const BUTTON_SEND_MESSAGE_ID = "button-send-message-id"

const useStyles = createStyles(({ fn }) => {
  const footerHeight = 36
  return {
    root: {
      overflow: "hidden",
      height: "100%"
    },
    content: {
      height: `calc(100% - ${rem(footerHeight)})`,
      overflowY: "scroll",
      "::-webkit-scrollbar": { display: "none" },
      msOverflowStyle: "none",
      scrollbarWidth: "none",
      [fn.largerThan("sm")]: {
        maxHeight: `calc(100% - ${rem(footerHeight)})`
      }
    },
    tabContent: {
      padding: "10px 0"
    },
    footer: {
      height: rem(footerHeight)
    }
  }
})
