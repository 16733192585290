import { getUserId } from "../../utils/getUserId"
import { getSession } from "../auth/getSession"
import { Direct } from "./direct"

type TDirectResponse = {
  count_unread_messages: number
}

export const directCount = async () => {
  const { access_token } = await getSession()
  const userId = await getUserId()

  const url = new URL(Direct.endpoint.count(userId))
  const config = Direct.fetchConfig("GET", access_token)

  const res = await fetch(url, config)
  const data = (await res.json()) as TDirectResponse

  return data.count_unread_messages
}
