import { supabase } from "../../lib/supabase"
import { getTenant } from "../../utils/getTenant"
import { makeUrl } from "../../utils/makeUrl"
import { uploadImageBase64 } from "../upload/uploadImageBase64"

export type EventUpdatePayload = {
  id: string
  name: string
  type: string
  local?: string
  banner: string
  end_date: string
  start_date: string
  end_hour: string
  start_hour: string
  available_to: string
  link: string
}

export const eventUpdate = async (payload: EventUpdatePayload) => {
  const isBase64 = payload.banner.startsWith("data:image")
  const banner = isBase64
    ? await uploadImageBase64(payload.banner, {
        maxWidth: 450,
        maxHeight: 150,
        quality: 0.8
      })
    : payload.banner

  if (!banner) throw new Error("Banner upload failed")

  const { error } = await supabase
    .from("db")
    .update({
      data: {
        ...payload,
        banner,
        link: makeUrl(payload.link)
      }
    })
    .match({ kind: "event", tenant: getTenant(), id: payload.id })

  if (error) throw error
}
