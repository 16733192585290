import { DsStackVertical, createStyles } from "@raisesistemas/ds"
import { useButtonFloat } from "../../hooks/useButtonFloat/useButtonFloat"
import { Fragment } from "react"

export const FloatButtons = () => {
  const { buttons } = useButtonFloat()
  const { classes } = useStyles()

  return (
    <DsStackVertical className={classes.float}>
      {buttons.map(({ id, element }) => (
        <Fragment key={id}>{element}</Fragment>
      ))}
    </DsStackVertical>
  )
}

const useStyles = createStyles(({ fn }) => ({
  float: {
    right: 24,
    bottom: 70,
    position: "fixed",
    zIndex: 500,
    [fn.largerThan("sm")]: {
      bottom: 48,
      right: 48
    }
  }
}))
