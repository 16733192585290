import differenceInDays from "date-fns/differenceInDays"

import { parseISO } from "date-fns"
import { useTenantQuery } from "./queries/useTenantQuery"
import { useUser } from "./useUser"
import { ROLES } from "../lib/constants"

export const useTrial = () => {
  const { role } = useUser()
  const { data } = useTenantQuery()

  if (!data || !data.data) return { isTrial: false }

  const { trial_end, subscribed_at, show_trial_banner } = data.data

  const isFree = subscribed_at === null

  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const trialEnd = parseISO(trial_end)

  const timeLeft = Math.max(0, differenceInDays(trialEnd, today))

  const isTrial = isFree && timeLeft > 0

  const showBanner = isTrial && show_trial_banner && role === ROLES.OWNER

  return { isTrial, timeLeft, showBanner }
}
