import { supabase } from "../../lib/supabase"
import { MemberRole, MemberType } from "../../types/member"
import { createInfiniteService } from "../../utils/createInfiniteService"
import { getTenant } from "../../utils/getTenant"
import { getUserId } from "../../utils/getUserId"

export type MemberListPayload = {
  confirmed?: boolean
  nameLike?: string

  role?: {
    not?: MemberRole[]
  }
}

export const memberList = createInfiniteService<MemberListPayload, MemberType>(
  async ({
    rangeEnd,
    rangeStart,
    confirmed = true,
    nameLike = "",
    role = { not: [] }
  }) => {
    const userId = await getUserId()
    return supabase
      .from("member")
      .select("*", {
        count: "exact",
        head: false
      })
      .neq("user_uuid", userId)
      .neq("role", role.not)
      .ilike("data->>name", `%${nameLike}%`)
      .eq("tenant", getTenant())
      .eq("data->>confirmed", confirmed)
      .order("data->>created_at", { ascending: false })
      .range(rangeStart, rangeEnd)
  }
)
