import { supabase } from "../../lib/supabase"
import { EventType } from "../../types/event"
import { createInfiniteService } from "../../utils/createInfiniteService"
import { getTenant } from "../../utils/getTenant"

export type EventListPayload = {
  type: "next" | "previous"
}

export const eventList = createInfiniteService<EventListPayload, EventType>(
  async ({ type, rangeStart, rangeEnd }) => {
    const date = new Date()
    date.setHours(0, 0, 0, 0)

    const dateString = date.toISOString()

    let query = supabase
      .from("db")
      .select("id::text, data, kind, user_uuid", {
        count: "exact",
        head: false
      })
      .eq("kind", "event")
      .eq("tenant", getTenant())
      .order("data->>start_date")

    if (type === "next") {
      query = query.gte("data->>end_date", dateString)
    } else {
      query = query.lt("data->>end_date", dateString)
    }

    return query.range(rangeStart, rangeEnd)
  }
)
