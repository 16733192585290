import { useInfiniteQuery } from "@tanstack/react-query"
import { queries } from "../../queries"
import { useUser } from "../useUser"
import { getHost } from "../../utils/getHost"

export const useActivitiesQuery = () => {
  const { user_uuid } = useUser()
  const currentHost = getHost()
  return useInfiniteQuery({
    ...queries.activities.list({
      tenant: currentHost,
      user_id: user_uuid
    }),
    initialPageParam: 1,
    gcTime: Infinity,
    staleTime: 1000 * 60 * 3,
    getNextPageParam: (lastPage: any) => {
      return lastPage.nextId ?? undefined
    },
    getPreviousPageParam: (firstPage: any) => {
      return firstPage.previousId ?? undefined
    }
  })
}
