import { useInfiniteQuery } from "@tanstack/react-query"
import { queries } from "../../queries"
import { LikeListPayload } from "../../api/like/likeList"

export const useLikeQueries = () => {
  const list = (payload: LikeListPayload) => {
    return useInfiniteQuery({
      ...queries.likes.list(payload),
      initialPageParam: 1,
      getNextPageParam: (lastPage: any) => {
        return lastPage.nextId ?? undefined
      },
      getPreviousPageParam: (firstPage: any) => {
        return firstPage.previousId ?? undefined
      }
    })
  }

  return { list }
}
