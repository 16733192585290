import {
  DsButtonDestructive,
  DsButtonSecondary,
  DsGroup,
  DsMediaQuery,
  DsModal,
  DsModalProps,
  DsText,
  createStyles,
  rem,
  toast
} from "@raisesistemas/ds"
import { useThemeColoring } from "../../../hooks/useThemeColoring"
import { MemberType } from "../../../types/member"
import { useTranslation } from "react-i18next"
import { useMemberMutations } from "../../../hooks/mutations/useMemberMutations"

export type MemberRemoveModalProps = Pick<
  DsModalProps,
  "opened" | "onClose"
> & {
  member: MemberType
  onRemove?: () => void
}

export const MemberRemoveModal = (props: MemberRemoveModalProps) => {
  const { opened, onClose, member, onRemove = () => {} } = props

  const memberMutations = useMemberMutations()
  const { t } = useTranslation()
  const { lightDark, colors } = useThemeColoring()
  const { classes } = useStyles()

  const handleConfirm = () => {
    toast.success({ message: t("toast.member.removed") })
    memberMutations.remove.mutate({ id: member.user_uuid })
    onClose()
    onRemove()
  }

  return (
    <DsModal
      className={classes.root}
      title={
        <DsText
          variant="body-1"
          color={lightDark("dark.9", "dark.0")}
          weight="medium"
        >
          {t("modal.remove_member.title")}
        </DsText>
      }
      opened={opened}
      onClose={onClose}
      main={
        <DsText variant="body-2" color={colors.gray[6]} weight="regular">
          {t("modal.remove_member.text", { memberName: member.data.name })}
        </DsText>
      }
      footer={
        <DsMediaQuery
          smallerThan="sm"
          styles={{ flexDirection: "column-reverse", alignItems: "stretch" }}
        >
          <DsGroup spacing={10} position="right">
            <DsButtonSecondary
              onClick={onClose}
              data-testid={MEMBER_REMOVE_MODAL_BUTTON_CANCEL_ID}
            >
              {t("common.cancel", { type: "" })}
            </DsButtonSecondary>
            <DsButtonDestructive
              onClick={handleConfirm}
              data-testid={MEMBER_REMOVE_MODAL_BUTTON_REMOVE_ID}
            >
              {t("common.remove")}
            </DsButtonDestructive>
          </DsGroup>
        </DsMediaQuery>
      }
      data-testid={MEMBER_REMOVE_MODAL_ID}
    />
  )
}

const useStyles = createStyles({
  root: {
    ".mantine-Paper-root": {
      borderRadius: rem(12)
    },
    ".mantine-Modal-header": {
      paddingBottom: 0
    }
  }
})

export const MEMBER_REMOVE_MODAL_ID = "member-remove-modal-id"
export const MEMBER_REMOVE_MODAL_BUTTON_CANCEL_ID =
  "member-remove-button-cancel-id"
export const MEMBER_REMOVE_MODAL_BUTTON_REMOVE_ID =
  "member-remove-modal-button-remove-id"
