import { ROLES } from "../lib/constants"
import { drawers } from "../state"
import { useMemberQueries } from "./queries/useMemberQueries"

type ShowMemberDetailProps = {
  enabled?: boolean
  memberId?: string
}

export const useShowMemberDetail = (props: ShowMemberDetailProps) => {
  const { memberId = null, enabled } = props
  const { detail } = useMemberQueries()
  const { member } = detail(memberId)

  const isBlockedMember = member?.role === ROLES.BLOCKED_MEMBER

  const handleMemberDetail = (e: any) => {
    if (!memberId || !enabled) return

    e.stopPropagation()
    if (isBlockedMember) return
    drawers.member.detail.open({ memberId })
  }

  return {
    handleMemberDetail
  }
}
