import { NotificationManagerForm } from "../NotificationsManagerForm"
import { NotificationManagerSkeleton } from "./NotificationManagerSkeleton"
import { useActivityPreferencesQuery } from "../../../hooks/queries/useActivityPreferencesQuery"

export const NotificationsManager = () => {
  const { data } = useActivityPreferencesQuery()

  if (!data) return <NotificationManagerSkeleton />

  return <NotificationManagerForm preferences={data} />
}
