import { useQuery } from "@tanstack/react-query"
import { queries } from "../../queries"

export const useExtractQueries = () => {
  const ownerEntriesList = () => {
    const { data, isFetching } = useQuery(queries.extract.ownerEntriesList())

    return {
      historic: data,
      isFetching
    }
  }

  const ownerExistsList = () => {
    const { data, isFetching } = useQuery(queries.extract.ownerExistsList())

    return {
      historic: data,
      isFetching
    }
  }

  return {
    ownerEntriesList,
    ownerExistsList
  }
}
