import { createFormContext } from "@mantine/form"

export type Member = {
  name: string
  email: string
}
interface MemberInviteValues {
  members: Member[]
}

export const [
  MemberInviteFormProvider,
  useMemberInviteFormContext,
  useMemberInviteForm
] = createFormContext<MemberInviteValues>()
