import { useContext } from "react"
import { LikeContext } from "./LikeContext"

export const useLike = () => {
  const context = useContext(LikeContext)

  if (!context) throw new Error("useLike needs to be wrapped by LikeProvider")

  return context
}
