import { KINDS } from "../../lib/constants"
import { supabase } from "../../lib/supabase"
import { ChannelType } from "../../types/channel"
import { getTenant } from "../../utils/getTenant"
import { createInfiniteService } from "../../utils/createInfiniteService"

export type ChannelListPaginatedPayload = {
  onlyLocked?: boolean
  onlyUnlocked?: boolean
}

export const channelListPaginated = createInfiniteService<
  ChannelListPaginatedPayload,
  ChannelType[]
>(
  async ({
    rangeStart,
    rangeEnd,
    limit = 20,
    onlyLocked = false,
    onlyUnlocked = false
  }) => {
    const lockedIn = [true, false]

    if (onlyLocked) lockedIn.pop()
    if (onlyUnlocked) lockedIn.shift()

    return supabase
      .from("db")
      .select(
        `
          id::text,
          *
        `
      )
      .eq("tenant", getTenant())
      .eq("kind", KINDS.CHANNEL)
      .in("data->>locked", lockedIn)
      .order("id")
      .range(rangeStart, rangeEnd)
      .limit(limit)
  }
)
