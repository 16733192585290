import React, { useCallback } from "react"
import Picker from "@emoji-mart/react"
import { useTranslation } from "react-i18next"
import { useDisclosure } from "@mantine/hooks"
import { DsFlex, DsButtonUnstyled, DsPopover } from "@raisesistemas/ds"

import { IconMoodHappy } from "../../icons"
import { ErrorMessage } from "../common/ErrorMessage"

import { useStyles } from "./EmojiPicker.style"
import { useIsScreen } from "../../hooks/useIsScreen"
import { FloatingPosition } from "@mantine/core/lib/Floating"

const getEmojis = async () => {
  const response = await fetch("https://cdn.jsdelivr.net/npm/@emoji-mart/data")
  return response.json()
}

type EmojiPickerProps = {
  value?: string
  onSelect?: (value: string) => void
  error?: string
  required?: boolean
  target?: React.ReactNode
  open?: boolean
  onOpenChange?: (open: boolean) => void
}

export function EmojiPicker(props: EmojiPickerProps) {
  const { value, onSelect, error, required, target, open, onOpenChange } = props
  const { t, i18n } = useTranslation()
  const { classes, theme } = useStyles()
  const locale = i18n.language.substring(0, 2)
  const [opened, { close, toggle }] = useDisclosure(false)
  const emoji = value ? value : <IconMoodHappy size={24} />
  const { isMobile } = useIsScreen()

  const handleClose = onOpenChange ? () => onOpenChange(false) : close

  const handleSelectEmoji = useCallback(
    ({ native }: { native: string }) => {
      onSelect?.(native)
      handleClose()
    },
    [onSelect, close]
  )

  const position: FloatingPosition = isMobile ? "top-end" : "top"

  const defaultTarget = (
    <DsButtonUnstyled
      onClick={toggle}
      className={classes.button}
      data-testid="emoji-picker-target"
    >
      {emoji}
    </DsButtonUnstyled>
  )

  const renderTarget = (
    <div className={classes.target}>
      <DsFlex gap={4}>
        {target || defaultTarget}
        {required && <span className={classes.asterisk}>*</span>}
      </DsFlex>

      {!!error && <ErrorMessage>{t("root.required_emoji")}</ErrorMessage>}
    </div>
  )

  const dropdown = (
    <Picker
      autoFocus
      locale={locale}
      data={getEmojis}
      theme={theme.colorScheme}
      onEmojiSelect={handleSelectEmoji}
      previewPosition="none"
      skinTonePosition="none"
    />
  )

  return (
    <DsPopover
      opened={open ?? opened}
      onClose={handleClose}
      targetChildren={renderTarget}
      dropdownChildren={dropdown}
      dropdownStyle={{ padding: 0 }}
      zIndex={10_000}
      position={position}
      withinPortal
    />
  )
}
