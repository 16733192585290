import { MemberDetailSkeleton } from "./MemberDetailSkeleton"
import { MemberDetailParams, useMemberDetail } from "./useMemberDetail"
import { MemberDetailView } from "./MemberDetailView"

type MemberDetailProps = MemberDetailParams

export const MemberDetail = (props: MemberDetailProps) => {
  const { isLoading, member, isOnline, handleSendMessage } = useMemberDetail(
    props
  )

  if (!member || isLoading) return <MemberDetailSkeleton />

  return (
    <MemberDetailView
      member={member}
      isOnline={isOnline}
      sendMessage={handleSendMessage}
    />
  )
}
