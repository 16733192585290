import { useInfiniteQuery, useQuery } from "@tanstack/react-query"
import { queries } from "../../queries"

export const useActivitiesQueries = () => {
  const latest = () => {
    return useQuery(queries.activities.latest())
  }

  const unreadCountByType = (type: string) => {
    return useQuery({
      ...queries.activities.unreadCountByType(type),
      refetchOnWindowFocus: true
    })
  }

  const latestList = () => {
    return useInfiniteQuery({
      ...queries.activities.latestList(),
      initialPageParam: 1,
      gcTime: Infinity,
      staleTime: 1000 * 60 * 3,
      getNextPageParam: (lastPage: any) => {
        return lastPage.nextId ?? undefined
      },
      getPreviousPageParam: (firstPage: any) => {
        return firstPage.previousId ?? undefined
      }
    })
  }

  return { latest, unreadCountByType, latestList }
}
