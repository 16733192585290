export const buildURL = (
  baseUrl: string,
  queryParams: Record<string, string>
) => {
  const url = new URL(baseUrl)

  Object.entries(queryParams).forEach(([key, value]) => {
    url.searchParams.set(key, value)
  })

  return url
}
