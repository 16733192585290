import { format, parseISO } from "date-fns"
import { ExtractDataEntry, ExtractDataExist } from "../../types/extract"
import { capitalize } from "../../utils/capitalize"
import { useDateFnsLocale } from "../../hooks/useDateFnsLocale"
import { ExtractSimpleData } from "../../components/ExtractDrawer/ExtractSimpleDrawer"
import { BankType } from "../../api/bank/bankFind"
import { formatBrazilianDocuments } from "../../utils/formatBrazilianDocuments"

import { TFunction } from "i18next"
import { formatMoneyInCents } from "../../utils/formatMoneyInCents"
import { useTranslation } from "react-i18next"

export type ExtractKey =
  | "WITHDRAW_SUCCESS"
  | "TRANSACTIONS_TAX"
  | "MEMBER_PAYMENT"

export type ExtractRow = {
  extractKey: ExtractKey
  name: string
  payament_type?: string
  value: string

  details: ExtractSimpleData[]
}

export type Historic = {
  date: string
  week: string
  rows: ExtractRow[]
}

const entriesByDate = (extractData?: ExtractDataEntry[]): Historic[] | null => {
  const { t } = useTranslation()
  const locale = useDateFnsLocale()

  if (!extractData) return null

  const groupedDates = extractData.reduce((groups, data) => {
    const key = format(parseISO(data.date), "yyyy-MM-dd", { locale: locale })

    if (!groups[key]) groups[key] = []

    groups[key].push(data)

    return groups
  }, {} as { [key: string]: ExtractDataEntry[] })

  return Object.keys(groupedDates)
    .map(date => {
      return {
        date: format(parseISO(date), "PPP", { locale: locale }),
        week: capitalize(format(parseISO(date), "EEEE", { locale: locale })),
        rows: groupedDates[date].map(data =>
          convertToExtractSimple.MEMBER_PAYMENT(data, locale, t)
        )
      }
    })
    .reverse()
}

const existsByDate = (
  extractData?: ExtractDataExist[],
  bank?: BankType
): Historic[] | null => {
  const { t } = useTranslation()
  const locale = useDateFnsLocale()

  if (!extractData || !bank) return null

  const groupedDates = extractData.reduce((groups, data) => {
    const key = format(parseISO(data.data.date), "yyyy-MM-dd", {
      locale: locale
    })

    if (!groups[key]) groups[key] = []

    groups[key].push(data)

    return groups
  }, {} as { [key: string]: ExtractDataExist[] })

  return Object.keys(groupedDates)
    .map(date => {
      return {
        date: format(parseISO(date), "PPP", { locale: locale }),
        week: capitalize(format(parseISO(date), "EEEE", { locale: locale })),
        rows: groupedDates[date].map(data => {
          if (data.data.value_cents < 200)
            return convertToExtractSimple.TRANSACTIONS_TAX(data, locale, t)

          return convertToExtractSimple.WITHDRAW_SUCCESS(data, bank, locale, t)
        })
      }
    })
    .reverse()
}

const convertToExtractSimple = {
  MEMBER_PAYMENT: (
    entry: ExtractDataEntry,
    locale: Locale,
    t: TFunction<"translation", undefined>
  ): ExtractRow => {
    const date = format(parseISO(entry.date), "P", { locale: locale })
    const hour = format(parseISO(entry.date), "p", { locale: locale })

    return {
      extractKey: "MEMBER_PAYMENT",
      name: capitalize(entry.customer_name),
      payament_type: t("root.credit_card"),
      value: formatMoneyInCents(entry.received_value_cents),
      details: [
        {
          label: t("root.value"),
          value: formatMoneyInCents(entry.received_value_cents)
        },
        {
          label: t("root.date_and_hour"),
          value: `${date} - ${hour}`
        },
        {
          label: t("root.member"),
          value: capitalize(entry.customer_name)
        },
        {
          label: t("root.payment_by"),
          value: t("root.credit_card")
        }
      ]
    }
  },
  TRANSACTIONS_TAX: (
    entry: ExtractDataExist,
    locale: Locale,
    t: TFunction<"translation", undefined>
  ): ExtractRow => {
    const date = format(new Date(entry.data.date), "P", { locale: locale })
    const hour = format(new Date(entry.data.date), "p", { locale: locale })

    return {
      extractKey: "TRANSACTIONS_TAX",
      name: capitalize("taxa de transação"),
      value: formatMoneyInCents(entry.data.value_cents),
      details: [
        {
          label: t("root.value"),
          value: formatMoneyInCents(entry.data.value_cents)
        },
        {
          label: t("root.date_and_hour"),
          value: `${date} - ${hour}`
        },
        {
          label: t("root.transfer_to"),
          value: "Raise Sistemas"
        },
        {
          label: "CPF/CNPJ",
          value: "000.000.000-00"
        }
      ]
    }
  },

  WITHDRAW_SUCCESS: (
    entry: ExtractDataExist,
    bank: BankType,
    locale: Locale,
    t: TFunction<"translation", undefined>
  ): ExtractRow => {
    const date = format(new Date(entry.data.date), "P", { locale: locale })
    const hour = format(new Date(entry.data.date), "p", { locale: locale })

    return {
      extractKey: "WITHDRAW_SUCCESS",
      name: capitalize("saque realizado"),
      value: formatMoneyInCents(entry.data.value_cents),
      payament_type: "Conta bancária",
      details: [
        {
          label: t("root.value"),
          value: formatMoneyInCents(entry.data.value_cents)
        },
        {
          label: t("root.date_and_hour"),
          value: `${date} - ${hour}`
        },
        {
          label: t("root.transfer_to"),
          value: bank.name
        },
        {
          label: "CPF/CNPJ",
          value: formatBrazilianDocuments(bank.document)
        },
        {
          label: t("input.bank.bank.label"),
          value: bank.bank
        },
        {
          label: t("input.bank.account_type.label"),
          value:
            bank.bank_account_type === "cc"
              ? "Conta corrente"
              : "Conta poupança"
        },
        {
          label: t("input.bank.agency.label"),
          value: bank.bank_ag
        },
        {
          label: t("input.bank.account.label"),
          value: bank.bank_cc
        },
        {
          label: "ID",
          value: entry.id.toString()
        }
      ]
    }
  }
}

export const extractPresenter = {
  entriesByDate,
  existsByDate
}
