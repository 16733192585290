import { ROLES } from "../lib/constants"
import { useUser } from "./useUser"

export const useIsRole = () => {
  const { role } = useUser()
  const isOwner = role == ROLES.OWNER
  const isModerator = role == ROLES.MODERATOR
  const isMember = role === ROLES.MEMBER
  const isMemberPro = role === ROLES.MEMBER_PRO
  const isBlockedMember = role === ROLES.BLOCKED_MEMBER

  return {
    isOwner,
    isModerator,
    isMember,
    isMemberPro,
    isBlockedMember
  }
}
