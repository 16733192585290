import { DsCenter, DsStackVertical, DsText } from "@raisesistemas/ds"
import { useTranslation } from "react-i18next"
import { NotificationEmpty } from "../NotificationEmpty"
import { NotificationItem } from "../NotificationItem"
import { useActivitiesQuery } from "../../../hooks/queries/useActivitiesQuery"
import { useInView } from "react-intersection-observer"
import { LoadMore } from "../../LoadMore"
import { Fragment, useEffect } from "react"
import { NotificationsSkeleton } from "../../Skeletons/NotificationsSkeleton"

export const NotificationList = () => {
  const { t } = useTranslation()
  const { ref, inView } = useInView()
  const query = useActivitiesQuery()
  const { data, isLoading, fetchNextPage } = query

  useEffect(() => {
    if (inView) fetchNextPage()
  }, [inView])

  if (isLoading) return <NotificationsSkeleton />

  if (!data || data.pages[0].data.length === 0) {
    return <NotificationEmpty />
  }

  return (
    <DsStackVertical>
      {data.pages.map(page => (
        <Fragment key={page.nextId}>
          {page.data.map((notification: any) => (
            <NotificationItem
              key={`notification-item-${notification.id}`}
              data={notification}
            />
          ))}
        </Fragment>
      ))}

      <DsCenter>
        <DsText variant="body-3" weight="regular" color="dimmed">
          {t("notification.end_notifications")}
        </DsText>
      </DsCenter>

      <LoadMore ref={ref} {...query} />
    </DsStackVertical>
  )
}
