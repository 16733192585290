import {
  DsBox,
  DsSkeletonCircle,
  DsSkeletonLine,
  DsStackHorizontal,
  DsStackVertical
} from "@raisesistemas/ds"

export const UserSkeleton = () => {
  return (
    <DsStackHorizontal spacing="lg" align="flex-start">
      <DsSkeletonCircle />

      <DsBox sx={{ flex: 1 }}>
        <DsStackVertical spacing="xs">
          <DsSkeletonLine />
          <DsSkeletonLine isFull />
        </DsStackVertical>
      </DsBox>
    </DsStackHorizontal>
  )
}
