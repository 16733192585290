import { useCallback, useEffect, useState } from "react"

import debounce from "lodash.debounce"
import { useTranslation } from "react-i18next"
import {
  DsActionIconBase,
  DsTooltip,
  toast,
  useDsTheme
} from "@raisesistemas/ds"

import { capitalize } from "../../utils/capitalize"
import { IconBookmarkSimpleFill, IconBookmarkSimple } from "../../icons"
import { usePostSavedMutations } from "../../hooks/mutations/usePostSavedMutations"

type PostSaveButton = {
  postId: string
  saved: boolean
}

const INTERVAL = 1000

export const PostSaveButton = ({ saved, postId }: PostSaveButton) => {
  const { fn } = useDsTheme()
  const { t } = useTranslation()

  const [isSaved, setIsSaved] = useState(saved)

  const { toggle } = usePostSavedMutations()

  const handleToggle = useCallback(
    debounce(() => {
      setIsSaved(saved => !saved)

      toggle.mutate(postId, {
        onSuccess: ({ enabled }) => {
          setIsSaved(enabled)

          if (enabled) {
            toast.success({
              id: "post_saved",
              message: capitalize(t("common.saved", { type: t("root.post") }))
            })
          }
        },
        onError: () => {
          setIsSaved(enabled => !enabled)
        }
      })
    }, INTERVAL),
    []
  )

  useEffect(() => {
    setIsSaved(saved)
  }, [saved])

  return (
    <DsTooltip label={isSaved ? t("button.remove") : t("button.save")}>
      <DsActionIconBase
        color={isSaved ? fn.primaryColor() : "gray"}
        data-testid="button-save"
        onClick={handleToggle}
      >
        {isSaved ? (
          <IconBookmarkSimpleFill size={20} />
        ) : (
          <IconBookmarkSimple size={20} />
        )}
      </DsActionIconBase>
    </DsTooltip>
  )
}
