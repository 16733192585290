import { useQuery } from "@tanstack/react-query"
import { queries } from "../../queries"
import { useUser } from "../useUser"

export const useActivityUnreadCountQuery = () => {
  const { user_uuid } = useUser()
  return useQuery({
    ...queries.activities.unreadCount(user_uuid),
    refetchOnWindowFocus: true
  })
}
