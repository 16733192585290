import { KINDS } from "../../lib/constants"
import { likeToggle } from "../like/likeToggle"

type PostLikePayload = {
  liked: boolean
  postId: string
}

export const postLike = async ({ postId, liked }: PostLikePayload) => {
  await likeToggle({
    kind: KINDS.POST_LIKE,
    parentId: postId,
    liked
  })
}
