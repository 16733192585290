import { formatDistanceToNow } from "date-fns"
import { ruid2uid } from "../lib/ruid2uid"
import { uid2date } from "../lib/uid2date"
import { useDateFnsLocale } from "./useDateFnsLocale"

export const useDateDistanceToNow = () => {
  const locale = useDateFnsLocale()

  const dateDistanceToNow = (ruid: string) => {
    const uid = ruid2uid(ruid)
    const dateParse = uid2date(uid)

    return formatDistanceToNow(dateParse, {
      locale,
      addSuffix: true
    })
  }

  return {
    dateDistanceToNow
  }
}
