import { useMutation, useQueryClient } from "@tanstack/react-query"
import { postFixedCreate } from "../../api/post/postFixedCreate"
import { queries } from "../../queries"
import { useStandardToast } from "../useStandardToast"

export const usePostFixedCreateMutation = () => {
  const queryClient = useQueryClient()
  const { cantFinalize } = useStandardToast()

  return useMutation({
    mutationFn: postFixedCreate,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: queries.posts.list._def }),
    onError: () => {
      cantFinalize()
    }
  })
}
