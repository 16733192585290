import { InfinitListResponse } from "../../components/InfinitList/useInfinitList"
import { getUserId } from "../../utils/getUserId"
import { getSession } from "../auth/getSession"
import { Direct } from "./direct"
import { DirectMessageType } from "../../types/direct"

type DirectListMessage = {
  page: number
  groupId: number
}

export const directListMessage = async (
  payload: DirectListMessage
): Promise<InfinitListResponse<DirectMessageType>> => {
  const { access_token } = await getSession()
  const { page, groupId } = payload
  const userId = await getUserId()

  const url = new URL(Direct.endpoint.listMessage(userId, groupId, page))
  const config = Direct.fetchConfig("GET", access_token)
  const res = await fetch(url, config)

  const data = await res.json()
  const hasNextPage = data && data.length === Direct.pagination
  const nextPage = page + 1
  const isLast = !res.ok || !hasNextPage

  return {
    data,
    nextPage,
    page,
    isLast
  }
}
