import { useInfiniteQuery, useQuery } from "@tanstack/react-query"
import { queries } from "../../queries"
import { EventListPayload } from "../../api/events/eventList"

export const useEventQueries = () => {
  const list = (payload: EventListPayload) => {
    return useInfiniteQuery({
      ...queries.events.list(payload),
      initialPageParam: 1,
      getNextPageParam: (lastPage: any) => {
        return lastPage.nextId ?? undefined
      },
      getPreviousPageParam: (firstPage: any) => {
        return firstPage.previousId ?? undefined
      }
    })
  }

  const hasEventInProgress = () => {
    return useQuery(queries.events.inProgress())
  }

  return {
    list,
    hasEventInProgress
  }
}
