import { createStyles, rem } from "@raisesistemas/ds"

export const useStyles = createStyles(({ fn }) => {
  const { background, color } = fn.variant({ variant: "light" })
  return {
    container: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      backgroundColor: background,
      color,
      fontSize: rem(12),
      borderRadius: 10,
      padding: `0 ${rem(30)} 0 ${rem(6)}`,
      height: 22
    },
    indicator: {
      position: "absolute",
      top: 11,
      transform: "translateY(-50%)",
      right: 8
    }
  }
})
