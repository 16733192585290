import { enUS, ptBR } from "date-fns/locale"
import { useTranslation } from "react-i18next"

export const locales = {
  enUS,
  ptBR
} as const

export type LocaleType = keyof typeof locales

export const useDateFnsLocale = () => {
  const { i18n } = useTranslation()

  const isValid = Object.keys(locales).includes(i18n.language)
  const currentLanguage = isValid ? (i18n.language as LocaleType) : "ptBR"

  return locales[currentLanguage]
}
