import { createQueryKeys } from "@lukemorales/query-key-factory"
import { balanceOwner } from "../api/balance/balanceOwner"
import { getTenant } from "../utils/getTenant"

export const balanceQueries = createQueryKeys("balance", {
  balanceOwner: () => ({
    queryKey: [getTenant()],
    queryFn: balanceOwner
  })
})
