import { memo } from "react"
import { Box, MantineNumberSize } from "@mantine/core"
import {
  DsGroup,
  DsGroupProps,
  DsStackVertical,
  createStyles
} from "@raisesistemas/ds"

type RootProps = Pick<DsGroupProps, "align" | "spacing"> & {
  avatarSection: React.ReactNode
  rightSection?: React.ReactNode
  children: React.ReactNode
  onClick?: () => void
  className?: string

  infoSpacing?: MantineNumberSize
  infoAlign?: React.CSSProperties["alignItems"]
}

export const Root = memo((props: RootProps) => {
  const {
    align,
    spacing,

    infoAlign,
    infoSpacing = 2,

    children,
    avatarSection,
    rightSection,
    onClick,
    className,
    ...restProps
  } = props
  const { classes } = useStyles()
  return (
    <Box
      onClick={onClick}
      style={{ position: "relative", cursor: "pointer" }}
      {...restProps}
    >
      <DsGroup align={align} spacing={spacing} noWrap className={className}>
        {avatarSection}
        <DsStackVertical
          className={classes.infoWrapper}
          spacing={infoSpacing as any}
          align={infoAlign}
        >
          {children}
        </DsStackVertical>
        {rightSection}
      </DsGroup>
    </Box>
  )
})

const useStyles = createStyles({
  infoWrapper: {
    width: "100%"
  }
})
