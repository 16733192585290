import { MS_AUTH_URL } from "../../env"
import { getTenant } from "../../utils/getTenant"

type MsMemberIsBlockedPayload = {
  email: string
  tenant?: string
}

type MsMemberIsBlockedResponse = {
  isBlocked: boolean
}

export const msMemberIsBlocked = async (payload: MsMemberIsBlockedPayload) => {
  const { email, tenant = getTenant() } = payload
  const url = new URL(`${MS_AUTH_URL}/verify-blocked-member`)
  url.searchParams.set("tenant", tenant)
  url.searchParams.set("email", email)

  const response = await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" }
  })

  const data: MsMemberIsBlockedResponse = await response.json()

  return Object.assign(response, { data })
}
