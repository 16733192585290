import { DsText, DsThemeIcon } from "@raisesistemas/ds"

export const NotificationBadge = ({ count }: { count: number }) => {
  return (
    <DsThemeIcon size={24} radius={12}>
      <DsText variant="body-3" weight="medium">
        {count}
      </DsText>
    </DsThemeIcon>
  )
}
