import { Fragment } from "react"
import { MemberDataSocial, MemberType } from "../../types/member"
import {
  DsButtonUnstyled,
  DsGroup,
  DsSpace,
  DsStackVertical,
  DsText,
  createStyles,
  useDsTheme
} from "@raisesistemas/ds"
import { useTranslation } from "react-i18next"
import {
  IconFaLinkedinIn,
  IconIoCalendarOutline,
  IconIoLocationOutline,
  IconIoLogoInstagram,
  IconTfiWorld
} from "../../icons"
import { memberPresenter } from "../../presenter"
import { useDateFnsLocale } from "../../hooks/useDateFnsLocale"
import { makeUrl } from "../../utils/makeUrl"

type MemberAboutProps = {
  member?: MemberType
}

const useStyles = createStyles(() => ({
  about: {
    whiteSpace: "pre-line"
  }
}))

export const MemberAbout = (props: MemberAboutProps) => {
  const { member } = props
  const { fn, colors, colorScheme } = useDsTheme()
  const { t } = useTranslation()
  const locale = useDateFnsLocale()
  const isDark = colorScheme === "dark"

  const { classes } = useStyles()

  const socials: Array<keyof MemberDataSocial> = [
    "instagram",
    "linkedin",
    "site"
  ]
  const hasSocial = socials.some(social => !!member?.data[social])

  const openURL = (url: string) => () => window.open(makeUrl(url), "_blank")

  if (!member) return <Fragment />

  return (
    <DsStackVertical spacing={0}>
      <DsSpace height={24} />

      <DsStackVertical spacing={24}>
        <DsStackVertical spacing={8}>
          {member.data.address && (
            <DsGroup spacing={8}>
              <IconIoLocationOutline size={16} />
              <DsText
                variant="body-2"
                weight="regular"
                color={isDark ? colors.gray[4] : colors.gray[9]}
              >
                {member.data.address}
              </DsText>
            </DsGroup>
          )}

          <DsGroup spacing={8}>
            <IconIoCalendarOutline size={16} />
            <DsText
              variant="body-2"
              weight="regular"
              color={isDark ? colors.gray[4] : colors.gray[9]}
            >
              {memberPresenter.since(member, locale)}
            </DsText>
          </DsGroup>
        </DsStackVertical>

        {member.data.about && (
          <DsStackVertical spacing={16}>
            <DsText
              variant="body-2"
              weight="medium"
              color={isDark ? colors.gray[0] : colors.gray[9]}
            >
              {t("root.about")}
            </DsText>

            <DsText
              variant="body-1"
              weight="regular"
              color={isDark ? colors.gray[4] : colors.gray[6]}
              className={classes.about}
            >
              {member.data.about}
            </DsText>
          </DsStackVertical>
        )}

        {hasSocial && (
          <DsStackVertical spacing={16}>
            <DsText
              variant="body-2"
              weight="medium"
              color={isDark ? colors.gray[0] : colors.gray[9]}
            >
              Social
            </DsText>

            <DsStackVertical spacing={8}>
              {member.data.instagram && (
                <DsButtonUnstyled onClick={openURL(member.data.instagram)}>
                  <DsGroup align="center" position="apart">
                    <DsText
                      variant="body-2"
                      weight="regular"
                      color={isDark ? colors.gray[4] : colors.dark[9]}
                    >
                      Instagram
                    </DsText>

                    <IconIoLogoInstagram size={16} color={fn.primaryColor()} />
                  </DsGroup>
                </DsButtonUnstyled>
              )}

              {member.data.linkedin && (
                <DsButtonUnstyled onClick={openURL(member.data.linkedin)}>
                  <DsGroup align="center" position="apart">
                    <DsText
                      variant="body-2"
                      weight="regular"
                      color={isDark ? colors.gray[4] : colors.dark[9]}
                    >
                      Linkedin
                    </DsText>

                    <IconFaLinkedinIn size={16} color={fn.primaryColor()} />
                  </DsGroup>
                </DsButtonUnstyled>
              )}

              {member.data.site && (
                <DsButtonUnstyled onClick={openURL(member.data.site)}>
                  <DsGroup align="center" position="apart">
                    <DsText
                      variant="body-2"
                      weight="regular"
                      color={isDark ? colors.gray[4] : colors.dark[9]}
                    >
                      Site
                    </DsText>

                    <IconTfiWorld size={16} color={fn.primaryColor()} />
                  </DsGroup>
                </DsButtonUnstyled>
              )}
            </DsStackVertical>
          </DsStackVertical>
        )}
      </DsStackVertical>

      <DsSpace height={24} />
    </DsStackVertical>
  )
}
