import { useHotkeys } from "@mantine/hooks"
import { DsColorScheme } from "@raisesistemas/ds"

import { useEffect } from "react"
import { RN } from "../../env"
import { customTheme } from "./customTheme"
import { useColorScheme } from "./useColorScheme"
import { usePrimaryColor } from "./usePrimaryColor"

export const useTheme = () => {
  useHotkeys([["mod+J", () => toggleColorScheme()]])

  const [primaryColor] = usePrimaryColor()
  const [colorScheme, setColorScheme] = useColorScheme()

  const toggleColorScheme = (value?: DsColorScheme) => {
    const newColorScheme = value || (colorScheme === "dark" ? "light" : "dark")
    setColorScheme(newColorScheme)
    if (RN) RN.setDarkMode(newColorScheme === "dark")
  }

  const theme = {
    primaryColor,
    colorScheme,
    ...customTheme
  }

  useEffect(() => {
    if (RN) RN.setPrimaryColor(primaryColor)
  }, [primaryColor, RN])

  return {
    theme,
    colorScheme,
    primaryColor,
    toggleColorScheme
  }
}
