import { createQueryKeys } from "@lukemorales/query-key-factory"
import { getTenant } from "../utils/getTenant"
import { topicListWithChannels } from "../api/topic/topicListWithChannels"
import { TopicByIdPayload, topicById } from "../api/topic/topicById"

export const topicQueries = createQueryKeys("topic", {
  byId: (payload: TopicByIdPayload) => ({
    queryKey: [payload],
    queryFn: () => topicById(payload)
  }),
  listWithChannels: () => ({
    queryKey: [{ tenant: getTenant() }],
    queryFn: () => topicListWithChannels()
  })
})
