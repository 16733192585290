import { RN } from "../../env"
import { STORAGE_KEYS } from "../../lib/constants"
import { queryClient } from "../../lib/react-query"
import { supabase } from "../../lib/supabase"

export async function signOut() {
  localStorage.removeItem(STORAGE_KEYS.USER_ID)
  localStorage.removeItem(STORAGE_KEYS.TENANT_ID)
  localStorage.removeItem(STORAGE_KEYS.ACTIVATED_AT)
  queryClient.removeQueries()

  await supabase.auth.signOut()

  if (RN) RN.signOut()
}
