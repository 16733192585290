import { Fragment, useRef } from "react"
import { useThemeColoring } from "../../hooks/useThemeColoring"
import { IconAI } from "../../icons"
import { FloatingButton } from "../FloatingButton"
import { createStyles } from "@mantine/core"
import { DsBox, DsGroup, DsLoader, DsText, rem } from "@raisesistemas/ds"
import { RequestAccess } from "./RequestAccess"
import { useButtonAi } from "../../hooks/useButtonAi"
import { IframeChatBot } from "./IframeChatBot"
import { useIsRole } from "../../hooks/useIsRole"
import { useClickOutside, useWindowEvent } from "@mantine/hooks"
import { useTranslation } from "react-i18next"

export const ButtonAi = () => {
  const { t } = useTranslation()
  const { lightDark } = useThemeColoring()
  const { isMember } = useIsRole()
  const { cx, classes } = useStyles()
  const { hasAi, isLoading, boxShow, setShowBox } = useButtonAi()

  const buttonRef = useRef<HTMLButtonElement>(null)
  const boxRef = useClickOutside(() => setShowBox(false), null, [
    buttonRef.current
  ])

  useWindowEvent("keydown", e => e.key === "Escape" && setShowBox(false))

  const ButtonIcon = () =>
    isLoading ? (
      <DsLoader />
    ) : (
      <DsGroup noWrap spacing={0}>
        <IconAI />
        <DsText weight="medium" color={lightDark("dark.9", "dark.9")}>
          {t("root.ai")}
        </DsText>
      </DsGroup>
    )
  if (!hasAi) return <Fragment />

  return (
    <Fragment>
      <FloatingButton
        ref={buttonRef}
        onClick={() => setShowBox(current => !current)}
        bg={lightDark("gray.0", "gray.0")}
        shadow="2px 2px 10px 0px #00000033"
        data-testid={BUTTON_AI_ID}
      >
        <ButtonIcon />
      </FloatingButton>

      <DsBox
        ref={boxRef}
        className={cx(classes.box, { [classes.boxShow]: boxShow })}
        data-testid={BUTTON_AI_POPOVER_ID}
      >
        {isMember ? (
          <RequestAccess />
        ) : (
          <IframeChatBot
            width={358}
            height={541}
            chatId="cltc5b3tl000655u4grsnsnqj"
          />
        )}
      </DsBox>
    </Fragment>
  )
}

export const BUTTON_AI_ID = "button-ai-id"
export const BUTTON_AI_POPOVER_ID = "button-ai-popover-id"

const useStyles = createStyles(({ colors }) => {
  return {
    box: {
      position: "absolute",
      opacity: 0,
      visibility: "hidden",
      transition: "all 100ms linear",
      backgroundColor: colors["dark"][7],
      width: rem(358),
      height: rem(541),
      maxHeight: `calc(100vh - ${rem(200)})`,
      borderWidth: rem(1),
      borderStyle: "solid",
      borderColor: colors.gray[0],
      borderRadius: rem(32),
      overflow: "hidden",
      transform: "translate(-100%, -102%)",
      marginLeft: rem(56)
    },
    boxShow: {
      opacity: 1,
      visibility: "visible"
    }
  }
})
