import { MS_AUTH_URL } from "../../env"
import { getTenant } from "../../utils/getTenant"

type msOTPPayload = {
  email: string
  tenant?: string
}

export const msOTP = async (payload: msOTPPayload) => {
  const { email, tenant = getTenant() } = payload

  const url = new URL(`${MS_AUTH_URL}/otp`)
  url.searchParams.set("type", "signin")
  url.searchParams.set("tenant", tenant)
  url.searchParams.set("email", email)

  return await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" }
  })
}
