import { useTranslation } from "react-i18next"
import { drawers } from "../../../state"
import { capitalize } from "../../../utils/capitalize"
import { Sheet } from "../../Sheet"
import { InfiniteScroll } from "../../common/InfiniteScroll"
import { useActivitiesQueries } from "../../../hooks/queries/useActivitiesQueries"
import { NotificationItem } from "../NotificationItem"

export const NotificationsLatestDrawer = () => {
  const { t } = useTranslation()

  const { latestList } = useActivitiesQueries()

  const query = latestList()

  const renderItem = (item: any) => {
    return <NotificationItem data={item} />
  }
  return (
    <Sheet
      title={capitalize(t("common.recent", { type: t("root.activities") }))}
      opened={drawers.notifications.latest.isOpen}
      onClose={drawers.notifications.latest.close}
    >
      <InfiniteScroll query={query} renderItem={renderItem} />
    </Sheet>
  )
}
