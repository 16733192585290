import { JSONContent } from "@tiptap/core"
import { KINDS } from "../../lib/constants"
import { supabase } from "../../lib/supabase"
import { getTenant } from "../../utils/getTenant"
import { getUserId } from "../../utils/getUserId"

type CommentCreatePayload = {
  content: JSONContent
  parent_id: string
}

export async function commentCreate(payload: CommentCreatePayload) {
  const userId = await getUserId()

  const { error } = await supabase.from("db").insert({
    user_uuid: userId,
    tenant: getTenant(),
    kind: KINDS.POST_COMMENT,
    data: {
      content: payload.content
    },
    parent_id: payload.parent_id
  })
  if (error) throw new Error(error.message)
}
