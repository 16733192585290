import { useEffect } from "react"
import { createStyles, rem } from "@raisesistemas/ds"
import { JSONContent } from "@tiptap/core"

import { usePostEditor } from "../../../hooks/editor/usePostEditor"
import { RichText } from "../../RichText"
import { useDisclosure } from "@mantine/hooks"
import { RichTextSkeleton } from "../../RichText/RichTextSkeleton"

type PostContentProps = {
  content: JSONContent
}

export const PostContent = (props: PostContentProps) => {
  const { content } = props
  const { classes, cx } = useStyles()
  const [isLoading, isLoadingHandler] = useDisclosure(true)
  const editor = usePostEditor({
    editable: false,
    content,
    onCreate: isLoadingHandler.close
  })

  useEffect(() => {
    editor?.commands.setContent(content)
  }, [content])

  return (
    <>
      {isLoading && <RichTextSkeleton content={content} />}

      <RichText
        editor={editor}
        classNames={{
          root: cx(classes.root, { [classes.rootVisible]: !isLoading })
        }}
        showActions={false}
      />
    </>
  )
}

const useStyles = createStyles({
  root: {
    border: 0,
    opacity: 0,
    height: 0,
    transition: "opacity 0.5s ease, height 0.5s ease"
  },
  rootVisible: {
    height: "auto",
    opacity: 1
  },
  content: {
    backgroundColor: "transparent",
    "&>div.tiptap": { padding: `${rem(16)} 0` }
  }
})
