import { mergeAttributes, Node } from "@tiptap/core"
import { ReactNodeViewRenderer } from "@tiptap/react"

import { LinkPreviewNode } from "./LinkPreviewNode"

export interface LinkPreviewOptions {
  HTMLAttributes: {
    [key: string]: any
  }
}

type LinkPreviewData = {
  href: string
  title: string
  description: string
  cover?: string
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    linkPreview: {
      /**
       * Add an linkPreview
       */
      setLinkPreview: (data: LinkPreviewData) => ReturnType
    }
  }
}

export const LinkPreview = Node.create<LinkPreviewOptions>({
  name: "linkPreview",

  group: "block",

  atom: true,

  content: "block",

  selectable: false,

  addOptions() {
    return {
      HTMLAttributes: {
        class: "link-preview-wrapper"
      }
    }
  },

  addAttributes() {
    return {
      href: {
        default: null,
        parseHTML(element) {
          return element.getAttribute("href")
        }
      },
      title: {
        default: null,
        parseHTML(element) {
          return element.firstChild?.childNodes[0].childNodes[0].nodeValue
        },
        renderHTML() {
          return
        }
      },
      description: {
        default: null,
        parseHTML(element) {
          return element.firstChild?.childNodes[1].childNodes[0].nodeValue
        },
        renderHTML() {
          return
        }
      },
      cover: {
        default: null,
        parseHTML(element) {
          // @ts-ignore
          return element.childNodes[1].src
        },
        renderHTML() {
          return
        }
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: `a[data-type="${this.name}"]`,
        priority: 1000
      }
    ]
  },

  renderHTML({ HTMLAttributes, node }) {
    const { title, description, href, cover } = node.attrs

    return [
      "a",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        "data-type": this.name
      }),
      ["div", ["h2", title], ["p", description], ["span", href]],
      ["img", { src: cover }]
    ]
  },

  addCommands() {
    return {
      setLinkPreview:
        (data: LinkPreviewData) =>
        ({ tr, dispatch }) => {
          const { selection } = tr
          const node = this.type.create(data)

          if (dispatch) {
            tr.replaceRangeWith(selection.from, selection.to, node)
          }

          return true
        }
    }
  },

  addNodeView() {
    return ReactNodeViewRenderer(LinkPreviewNode)
  }
})
