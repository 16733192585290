import { JSONContent } from "@tiptap/core"
import { KINDS } from "../../lib/constants"
import { supabase } from "../../lib/supabase"
import { getTenant } from "../../utils/getTenant"
import { getUserId } from "../../utils/getUserId"

type CommentCreatePayload = {
  content: JSONContent
  parent_id: string
}

export async function commentReply(payload: CommentCreatePayload) {
  const userId = await getUserId()
  if (!userId) throw new Error("User not found")
  const { error } = await supabase.from("db").insert({
    user_uuid: userId,
    tenant: getTenant(),
    kind: KINDS.COMMENT_REPLY,
    data: {
      content: payload.content
    },
    parent_id: payload.parent_id
  })
  if (error) throw error
}
