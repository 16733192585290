import { DsContainer, DsSpace } from "@raisesistemas/ds"
import { UserSkeleton } from "../../Skeletons/UserSkeleton"
import { ContentSkeleton } from "../../Skeletons/ContentSkeleton"

export const MemberDetailSkeleton = () => {
  return (
    <DsContainer sx={{ width: "100%" }} data-testid={MEMBER_DETAIL_SKELETON_ID}>
      <UserSkeleton />
      <DsSpace height={32} />
      <ContentSkeleton quantity={5} />
    </DsContainer>
  )
}

export const MEMBER_DETAIL_SKELETON_ID = "member-detail-skeleton-id"
