export type Speaker = {
  id: string
  name: string
}

export const convertSpeakersToObj = (data?: string[]): Speaker[] => {
  if (!data || data.length === 0) return []

  return data.map(value => {
    const [id, name] = value.split(":")
    return { id, name }
  })
}

export const convertSpeakersToString = (data?: Speaker[] | string): string => {
  if (!data || data.length === 0) return ""
  if (typeof data === "string") return data

  return data.map(v => v.name).join(", ")
}
