import { MS_AUTH_URL } from "../../env"
import { getTenant } from "../../utils/getTenant"
import { getSession } from "./getSession"

type Member = {
  name: string
  email: string
}

type SendInvitationsPayload = {
  members: Member[]
}

export const sendInvitations = async ({ members }: SendInvitationsPayload) => {
  const { access_token } = await getSession()

  const url = new URL(`${MS_AUTH_URL}/invite-upload`)
  url.searchParams.set("tenant", getTenant())

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(members)
  })

  if (!response.ok) throw new Error("Failed to send invitation")
}
