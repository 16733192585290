import { getSession } from "../api/auth/getSession"

export const getSpaceUrl = async (tenant: string, path?: string) => {
  const { host, protocol } = window.location

  const session = await getSession()

  const baseHost = host.replace(/app./, "")

  if (host.includes(tenant)) return `${protocol}//${baseHost}${path}`

  return `${protocol}//${tenant}.${baseHost}${path}#access_token=${session.access_token}&expires_in=${session.expires_in}&provider_token=${session.provider_token}&refresh_token=${session.refresh_token}&token_type=bearer`
}
