import {
  SetStateAction,
  Dispatch,
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState
} from "react"
import debounce from "lodash.debounce"
import { useActivityMutations } from "../hooks/mutations/useActivityMutations"

type ActivitiesReadContextProps = {
  setActivitiesReadIds: Dispatch<SetStateAction<string[]>>
}

export const ActivitiesReadContext = createContext<ActivitiesReadContextProps>({
  setActivitiesReadIds: () => {}
})

type ActivitiesReadProviderProps = {
  children: ReactNode
}
export const ActivitiesReadProvider = (props: ActivitiesReadProviderProps) => {
  const { children } = props
  const [activitiesReadIds, setActivitiesReadIds] = useState<string[]>([])

  const { read } = useActivityMutations()

  const readInit = useCallback(
    debounce((ids: string[]) => {
      if (ids.length > 0) {
        read.mutate({ target_ids: ids })
      }
    }, 1000 * 5),
    []
  )

  useEffect(() => {
    readInit(activitiesReadIds)
  }, [activitiesReadIds])

  return (
    <ActivitiesReadContext.Provider value={{ setActivitiesReadIds }}>
      {children}
    </ActivitiesReadContext.Provider>
  )
}
