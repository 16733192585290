import {
  DsBox,
  DsGroup,
  DsSkeletonLine,
  DsStackVertical,
  rem
} from "@raisesistemas/ds"
import { createRandomId } from "../../utils/createRandomId"

export const ExtractSkeleton = () => {
  return (
    <DsBox style={{ width: "100%", marginTop: rem(10) }}>
      <DsStackVertical spacing="lg">
        {Array.from({ length: 10 }).map(() => (
          <DsGroup key={createRandomId()}>
            <DsSkeletonLine />
            <DsSkeletonLine isFull />
            <DsSkeletonLine isFull />
          </DsGroup>
        ))}
      </DsStackVertical>
    </DsBox>
  )
}
