import {
  DsBox,
  DsCenter,
  DsGroup,
  DsText,
  createStyles,
  useDsTheme
} from "@raisesistemas/ds"
import { MemberType } from "../../types/member"
import { memberPresenter } from "../../presenter"
import { createRandomId } from "../../utils/createRandomId"
import { rem } from "@raisesistemas/ds"

type MemberTagsProps = {
  member: MemberType
}

export const MemberTags: React.FC<MemberTagsProps> = props => {
  const { member } = props
  const { fn } = useDsTheme()
  const { classes } = useStyles()
  return (
    <DsGroup spacing={2} noWrap>
      {memberPresenter.tags(member).map(tag => (
        <DsBox className={classes.badge} key={createRandomId()}>
          <DsCenter>
            <DsText variant="body-3" weight="regular" color={fn.primaryColor()}>
              {tag.name}
            </DsText>
          </DsCenter>
        </DsBox>
      ))}
    </DsGroup>
  )
}

const useStyles = createStyles(({ fn, colorScheme }) => ({
  badge: {
    flex: 1,
    backgroundColor:
      colorScheme === "dark"
        ? fn.rgba(fn.primaryColor(), 0.1)
        : fn.lighten(fn.primaryColor(), 0.9),
    maxWidth: rem(220),
    borderRadius: rem(16),
    padding: rem(3.5)
  }
}))
