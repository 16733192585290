import { useState } from "react"

type TouchEventHandler<T = void> = (e: React.TouchEvent<HTMLDivElement>) => T

export type UsePullToFreshProps = {
  onRefresh: () => Promise<any>
  loadingContainerHeight?: number
  distanceToRefresh?: number
}

export const usePullToRefresh = (props: UsePullToFreshProps) => {
  const {
    loadingContainerHeight = 0,
    onRefresh,
    distanceToRefresh = 100
  } = props
  const [startPoint, setStartPoint] = useState(0)
  const [pullDistance, setPullDistance] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const startLoading = () => setIsLoading(true)
  const stopLoading = () => setIsLoading(false)

  const resetStartPoint = () => setStartPoint(0)
  const resetPullDistance = () => setPullDistance(0)

  const getScreenY: TouchEventHandler<number> = event => {
    const { screenY } = event.targetTouches[0]
    return screenY
  }

  const handlePullStart: TouchEventHandler = event => {
    setStartPoint(getScreenY(event))
  }

  const handlePullMove: TouchEventHandler = event => {
    const screenY = getScreenY(event)
    const pullDistanceMoved =
      startPoint < screenY ? Math.abs(screenY - startPoint) : 0

    if (pullDistanceMoved > 100) startLoading()
    setPullDistance(pullDistanceMoved)
  }

  const handlePullEnd: TouchEventHandler = () => {
    if (pullDistance > distanceToRefresh) {
      setPullDistance(loadingContainerHeight * 3)
      resetStartPoint()
      handleRefresh()
    } else {
      stopLoading()
      resetStartPoint()
      resetPullDistance()
    }
  }

  const handleRefresh = async () => {
    await onRefresh()
    stopLoading()
    resetPullDistance()
  }

  return {
    startPoint,
    isLoading,
    pullDistance,

    handlePullStart,
    handlePullMove,
    handlePullEnd
  }
}
