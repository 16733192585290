import { CommentForm } from "./CommentForm"
import { CommentContent } from "./CommentContent"
import { JSONContent } from "@tiptap/core"
import { DsBox } from "@raisesistemas/ds"

export type CommentBodyProps = {
  channelId?: string
  loading?: boolean
  isEdit: boolean
  content: JSONContent
  defaultValue: JSONContent
  onSubmit: (content: JSONContent) => void
  onCloseEdit: () => void
}

export const CommentBody = (props: CommentBodyProps) => {
  const {
    channelId,
    isEdit,
    content,
    defaultValue,
    onCloseEdit,
    onSubmit,
    loading,
    ...restProps
  } = props

  return (
    <DsBox {...restProps}>
      <CommentForm
        isVisible={isEdit}
        onHidden={onCloseEdit}
        onSubmit={onSubmit}
        defaultValue={defaultValue}
        channelId={channelId}
        data-testid={COMMENT_FORM_ID}
        loading={loading}
      />
      {!isEdit && (
        <CommentContent
          content={content}
          showActions={isEdit}
          data-testid={COMMENT_CONTENT_ID}
        />
      )}
    </DsBox>
  )
}

export const COMMENT_FORM_ID = "comment-body-comment-form-id"
export const COMMENT_CONTENT_ID = "comment-body-comment-content-id"
