import { Root } from "./Root"
import { Avatar } from "./Avatar"
import { Name } from "./Name"
import { Description } from "./Description"
import { Tags } from "./Tags"

export const MemberInfo = {
  Root,
  Avatar,
  Name,
  Description,
  Tags
}
