import { createBrowserRouter } from "react-router-dom"
import { TenantWrapper } from "../components/TenantWrapper"

import { publicRoutes } from "./publicRoutes"
import { privateRoutes } from "./privateRoutes"

export const router = createBrowserRouter([
  {
    path: "/",
    element: <TenantWrapper />,
    children: [publicRoutes, privateRoutes]
  },
  {
    path: "*",
    lazy: async () => {
      const { PageNotFound } = await import("../pages/PageNotFound")
      return { Component: PageNotFound }
    }
  }
])
