import {
  DsButtonDestructive,
  DsButtonSecondary,
  DsGroup,
  DsMediaQuery,
  DsSpace,
  DsText,
  createStyles,
  rem,
  useDsTheme
} from "@raisesistemas/ds"
import { Fragment } from "react"
import { useTranslation } from "react-i18next"
import { signOut } from "../api/auth/signOut"
import { modals } from "@mantine/modals"

type ModalSignOutProps = {
  handleCancel: () => void
}

export const ModalSignOut = (props: ModalSignOutProps) => {
  const { handleCancel } = props
  const { t } = useTranslation()
  const { colors } = useDsTheme()
  const { classes } = useStyles()

  return (
    <Fragment>
      <DsGroup>
        <DsText variant="body-2" color={colors.gray[6]} weight="regular">
          {t("auth.sign_out_message")}
        </DsText>
      </DsGroup>
      <DsSpace height={16} />

      <DsMediaQuery
        smallerThan="sm"
        styles={{ flexDirection: "column-reverse", alignItems: "stretch" }}
      >
        <DsGroup spacing={16} position="right">
          <DsButtonSecondary className={classes.button} onClick={handleCancel}>
            {t("button.cancel")}
          </DsButtonSecondary>
          <DsButtonDestructive
            className={classes.button}
            onClick={() => {
              signOut()
              modals.closeAll()
            }}
          >
            {t("auth.sign_out")}
          </DsButtonDestructive>
        </DsGroup>
      </DsMediaQuery>
    </Fragment>
  )
}

const useStyles = createStyles({
  button: {
    minWidth: rem(100)
  }
})
