import { z } from "zod"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useForm, zodResolver } from "@mantine/form"

import {
  DsText,
  DsSwitch,
  DsMediaQuery,
  DsButtonPrimary,
  DsStackVertical,
  DsTextInputBase,
  DsStackHorizontal,
  DsButtonSecondary,
  DsTimeInput,
  DsDateInput,
  DsSelect
} from "@raisesistemas/ds"

import { PostEditor } from "../post/PostEditor"
import { Sheet } from "../Sheet"
import { eventSchema } from "../../validations/eventSchema"
import { EventCreatePayload } from "../../api/events/eventCreate"
import { useEventMutations } from "../../hooks/mutations/useEventMutations"
import { EventType } from "../../types/event"
import { useDefaultEditor } from "../../hooks/editor/useDefaultEditor"
import { ImagePreviewUpload } from "../ImagePreviewUpload"
import { SpaceVerticalResponsive } from "../common/SpaceVerticalResponsive"
import { drawers } from "../../state"
import { STORAGE_KEYS } from "../../lib/constants"
import { contentToText } from "../Editor/utils/contentToText"
import { modals } from "@mantine/modals"
import { InputSelectSpeaker } from "./InputSelectSpeaker"
import { convertSpeakersToObj } from "./helpers"

type EventForm = z.infer<typeof eventSchema>

export type EventHandlerDrawerProps = {
  event?: EventType
}

const MODAL_EVENT_CREATE_ID = "MODAL_EVENT_CREATE"

export const EventHandlerDrawer = () => {
  const { isOpen, close, props } = drawers.event.handler
  const { event } = props
  const { t } = useTranslation()
  const { create, update } = useEventMutations()
  const { i18n } = useTranslation()

  const languageMapper: Record<string, string> = {
    ptBR: "pt",
    enUS: "en"
  }

  const initialValues: Partial<EventForm> = event
    ? {
        ...event.data,
        for_member_pro: event.data.available_to === "member_pro",
        end_date: new Date(event.data.end_date),
        start_date: new Date(event.data.start_date),
        speaker: Array.isArray(event.data.speaker)
          ? event.data.speaker.map(v => `${v.id}:${v.name}`)
          : []
      }
    : { for_member_pro: false }

  const form = useForm<Partial<EventForm>>({
    initialValues,
    validate: zodResolver(eventSchema)
  })

  const editor = useDefaultEditor({
    placeholder: {
      placeholder: t("input.event.content.placeholder")
    },
    content: event?.data.content,
    onUpdate: ({ editor }) => {
      if (!editor) return

      const json = editor.getJSON()
      form.setFieldValue("content", json)
    }
  })

  const handleSubmit = () => {
    const data = JSON.stringify({ ...form.values, banner: "" })
    localStorage.setItem(STORAGE_KEYS.EVENT_CREATE_DATA, data)

    const isInvalid = form.validate().hasErrors

    const text = contentToText(form.values.content || "")

    const invalidLocal = form.values.type === "local" && !form.values.local
    const invalidContent = text.length === 0

    if (invalidLocal) {
      form.setFieldError("local", t("validation.required_field"))
    }

    if (invalidContent) {
      form.setFieldError("content", t("validation.required_field"))
    }

    if (isInvalid || invalidContent || invalidLocal) return

    const { for_member_pro, start_date, end_date, speaker, ...rest } =
      form.values as EventForm

    const payload: EventCreatePayload = {
      ...rest,
      start_date: start_date.toISOString(),
      end_date: end_date.toISOString(),
      available_to: for_member_pro ? "member_pro" : "all",
      speaker: convertSpeakersToObj(speaker)
    }

    if (event) {
      return update.mutate(
        { id: event.id, ...payload },
        {
          onSuccess: close
        }
      )
    }

    create.mutate(payload, {
      onSuccess: () => {
        close()
        form.reset()
      }
    })
  }

  const action = event ? "edit" : "create"

  const title = t(`common.${action}`, { type: t("root.event").toLowerCase() })

  useEffect(() => {
    if (form.values.type === "online") {
      form.clearFieldError("local")
    }
  }, [form.values.type])

  useEffect(() => {
    const eventStorage = localStorage.getItem(STORAGE_KEYS.EVENT_CREATE_DATA)

    if (action === "create" && eventStorage) {
      const _event = JSON.parse(eventStorage)

      form.setValues({
        ..._event,
        start_date: _event.start_date ? new Date(_event.start_date) : undefined,
        end_date: _event.end_date ? new Date(_event.end_date) : undefined
      })

      editor?.commands.setContent(_event.content)
    }
  }, [editor, action])

  const handleClose = () => {
    if (action === "create") {
      const data = JSON.stringify({ ...form.values, banner: "" })
      localStorage.setItem(STORAGE_KEYS.EVENT_CREATE_DATA, data)
    }
    close()
  }

  const handleConfirmClose = () => {
    modals.close(MODAL_EVENT_CREATE_ID)
    localStorage.removeItem(STORAGE_KEYS.EVENT_CREATE_DATA)
    close()
  }

  const handleOpenConfirmCloseModal = () =>
    modals.openConfirmModal({
      id: MODAL_EVENT_CREATE_ID,
      title: (
        <DsText variant="body-1" weight="medium">
          {t("modal.confirm_closing.title")}
        </DsText>
      ),
      children: (
        <DsText variant="body-2" color="dimmed">
          {t("modal.confirm_closing.text")}
        </DsText>
      ),
      labels: {
        confirm: t("root.close"),
        cancel: t("button.cancel")
      },
      confirmProps: {
        color: "red"
      },
      onConfirm: handleConfirmClose
    })

  const footer = (
    <DsMediaQuery
      largerThan="md"
      styles={{
        flexDirection: "row-reverse",
        justifyContent: "end"
      }}
    >
      <DsStackVertical spacing="sm">
        <DsButtonPrimary
          onClick={handleSubmit}
          loading={event ? update.isPending : create.isPending}
        >
          {title}
        </DsButtonPrimary>
        <DsButtonSecondary onClick={handleOpenConfirmCloseModal}>
          {t("root.back")}
        </DsButtonSecondary>
      </DsStackVertical>
    </DsMediaQuery>
  )

  return (
    <Sheet
      title={title}
      opened={isOpen}
      onClose={handleClose}
      footer={footer}
      size="xl"
    >
      <DsStackVertical>
        <ImagePreviewUpload
          fullWidth
          fullPreview
          title={t("common.thumbnail_label", {
            type: t("root.event").toLowerCase()
          })}
          aspectRatio={840 / 300}
          image={form.values.banner}
          handleImage={image => form.setFieldValue("banner", image)}
          imageDimensionsLabel="840 x 300px"
          imageSizeLabel="10mb"
          error={form.errors?.banner?.toString()}
          withAsterisk
        />

        <DsSelect
          label={t("common.kind_of", {
            type: t("root.event").toLowerCase()
          })}
          placeholder={t("common.select_type", {
            type: t("root.event").toLowerCase()
          })}
          // @ts-ignore
          withAsterisk
          data={[
            {
              value: "online",
              label: "Online"
            },
            {
              value: "local",
              label: t("root.in_person")
            }
          ]}
          {...form.getInputProps("type")}
        />
        <DsTextInputBase
          withAsterisk
          label={t("input.event.link.label")}
          placeholder={t("input.event.link.placeholder")}
          {...form.getInputProps("link")}
        />
        <DsTextInputBase
          withAsterisk
          label={t("input.event.name.label")}
          placeholder={t("input.event.name.placeholder")}
          {...form.getInputProps("name")}
        />

        <InputSelectSpeaker {...form.getInputProps("speaker")} />
        <DsStackHorizontal spacing="md" noWrap>
          <DsDateInput
            locale={languageMapper[i18n.language]}
            label={t("input.event.start_date.label")}
            placeholder={t("input.event.start_date.placeholder")}
            minDate={new Date()}
            withAsterisk
            maxDate={
              form.values.end_date ? new Date(form.values.end_date) : undefined
            }
            {...form.getInputProps("start_date")}
          />
          <DsDateInput
            locale={languageMapper[i18n.language]}
            label={t("input.event.end_date.label")}
            placeholder={t("input.event.end_date.placeholder")}
            withAsterisk
            minDate={
              form.values.start_date
                ? new Date(form.values.start_date)
                : new Date()
            }
            {...form.getInputProps("end_date")}
          />
        </DsStackHorizontal>
        <DsStackHorizontal spacing="md" noWrap>
          <DsTimeInput
            withAsterisk
            label={t("input.event.start_time.label")}
            {...form.getInputProps("start_hour")}
          />
          <DsTimeInput
            withAsterisk
            label={t("input.event.end_time.label")}
            {...form.getInputProps("end_hour")}
          />
        </DsStackHorizontal>

        {form.values.type === "local" && (
          <DsTextInputBase
            withAsterisk
            label="Local"
            placeholder="São Paulo, BR"
            {...form.getInputProps("local")}
          />
        )}

        <PostEditor editor={editor} error={form.errors?.content as string} />
        <DsStackHorizontal spacing="xs">
          <DsSwitch {...form.getInputProps("for_member_pro")} />
          <DsText variant="body-1" weight="regular">
            {t("input.event.member_pro_only.label")}
          </DsText>
        </DsStackHorizontal>
      </DsStackVertical>

      <SpaceVerticalResponsive largerThan="md" base={0} size={100} />
    </Sheet>
  )
}
