import { ChannelType } from "../../types/channel"
import { MemberType, MemberType as User } from "../../types/member"
import { PostModel } from "../../types/post"

type ActionFunction<T> = (user: User, data: T) => boolean

const grantAllToResource = {
  read: true,
  create: true,
  update: true,
  delete: true
}

const denyAllToResource = {
  read: false,
  create: false,
  update: false,
  delete: false
}

const only = (resources: string[]) => {
  const resourceEnbled = Object.assign({}, denyAllToResource)
  resources.map(key => {
    Object.defineProperty(resourceEnbled, key, {
      value: true
    })
  })

  return resourceEnbled
}

const grantTo = <T>(callback: ActionFunction<T>) => callback

const memberPermissions = {
  post: {
    read: grantTo<{ channel?: ChannelType; member?: MemberType }>((_, data) => {
      const { channel, member } = data
      if (!channel || !member) return false

      const isChennelLocked = channel.data.locked
      if (!isChennelLocked) return true
      if (isChennelLocked && member.role !== "member") return true

      return false
    }),
    create: grantTo<{ channel?: ChannelType }>((_, data) => {
      return Boolean(data?.channel?.can_member_write)
    }),
    update: grantTo<{ post?: PostModel }>(
      (user, data) => user.user_uuid === data.post?.member.user_uuid
    ),
    delete: grantTo<{ post?: PostModel }>(
      (user, data) => user.user_uuid === data.post?.member.user_uuid
    )
  },
  pin_post: only(["read"]),
  group: only(["read"]),
  space: only(["read"]),
  plan: {
    ...denyAllToResource,
    read: grantTo<{ hasPlan: boolean }>((_, data) => data.hasPlan)
  },
  event: {
    ...denyAllToResource,
    read: grantTo<{ available_to: string }>(
      (_, data) => data.available_to === "all"
    )
  },
  invite: denyAllToResource
}

export const permissions = {
  member: memberPermissions,
  member_pro: {
    ...memberPermissions,
    post: {
      ...memberPermissions.post,
      read: true
    },
    event: {
      ...memberPermissions.event,
      read: true
    }
  },
  owner: {
    post: {
      ...grantAllToResource,
      update: grantTo<{ post?: PostModel }>((user, data) => {
        return user.user_uuid === data.post?.member.user_uuid
      })
    },
    pin_post: grantAllToResource,
    group: grantAllToResource,
    space: grantAllToResource,
    plan: grantAllToResource,
    event: grantAllToResource,
    invite: grantAllToResource
  },
  moderator: {
    post: {
      ...grantAllToResource,
      update: grantTo<{ post?: PostModel }>((user, data) => {
        const { post } = data
        if (!post || !user) return false

        if (post.member.user_uuid === user.user_uuid) return true

        if (post.member.role === "owner") return false

        return true
      }),
      delete: grantTo<{ post?: PostModel }>((user, data) => {
        const { post } = data
        if (!post || !user) return false

        if (post.member.user_uuid === user.user_uuid) return true

        if (post.member.role === "owner") return false

        return true
      })
    },
    pin_post: grantAllToResource,
    group: only(["read"]),
    space: only(["read"]),
    plan: denyAllToResource,
    event: grantAllToResource,
    invite: grantAllToResource
  }
}
