import { KINDS } from "../../lib/constants"
import { supabase } from "../../lib/supabase"
import { getTenant } from "../../utils/getTenant"
import { audienceDefault } from "../audience/audienceDefault"
import { authorizeMemberToCreatePost } from "./authorizeMemberToCreatePost"

export type ChannelEditPayload = {
  id: string
  title: string
  emoji: string
  audience_id?: string | null
  locked: boolean
  can_member_write: boolean
}

export async function channelEdit(payload: ChannelEditPayload) {
  let audienceId = payload.audience_id

  if (payload.audience_id === "_default") {
    audienceId = await audienceDefault()
  }

  const record = {
    tenant: getTenant(),
    kind: KINDS.CHANNEL,
    data: {
      emoji: payload.emoji,
      title: payload.title,
      locked: payload.locked
    },
    audience_id: audienceId
  }

  const { error } = await supabase
    .from("db")
    .update(record)
    .eq("id", payload.id)

  await authorizeMemberToCreatePost({
    parent_id: payload.id,
    can_write: payload.can_member_write
  })

  if (error) throw new Error(error.message)
}
