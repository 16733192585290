import { Button, ButtonProps } from "@mantine/core"
import { ButtonHTMLAttributes } from "react"

type ButtonSubtleProps = Pick<
  ButtonProps,
  "fullWidth" | "leftIcon" | "rightIcon" | "children"
> &
  ButtonHTMLAttributes<HTMLButtonElement>

export const ButtonSubtle = (props: ButtonSubtleProps) => {
  return <Button variant="subtle" {...props} />
}
