import { useInfiniteQuery } from "@tanstack/react-query"

import { queries } from "../../queries"

export const useReplyQueries = () => {
  const list = (commentId: string) => {
    return useInfiniteQuery({
      ...queries.replies.list({ commentId }),
      initialPageParam: 1,
      getNextPageParam: (lastPage: any) => {
        return lastPage.nextId ?? undefined
      },
      getPreviousPageParam: (firstPage: any) => {
        return firstPage.previousId ?? undefined
      }
    })
  }
  return {
    list
  }
}
