import { MS_AUTH_URL } from "../../env"
import { getTenant } from "../../utils/getTenant"

type AuthRequestPinPayload = {
  email: string
}

export const authRequestPin = async (payload: AuthRequestPinPayload) => {
  const { email } = payload
  const url = new URL(`${MS_AUTH_URL}/otp`)
  url.searchParams.set("tenant", getTenant())
  url.searchParams.set("type", "signin")
  url.searchParams.set("email", email)

  const response = await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" }
  })

  if (!response.ok) throw new Error("Request pin error")
}
