import {
  CSSProperties,
  Fragment,
  NamedExoticComponent,
  ReactNode,
  memo
} from "react"
import { Box } from "@mantine/core"
import { DsBadge, DsGroup, DsGroupProps } from "@raisesistemas/ds"

/* -------------------------------------------------------------------------------------------------
 * MemberInfo #Root
 * -----------------------------------------------------------------------------------------------*/

type MemberInfoProps = Pick<DsGroupProps, "align" | "spacing"> & {
  children: ReactNode
  onClick?: () => void
  className?: string
}

const MemberInfoComponent = memo((props: MemberInfoProps) => {
  const { align, spacing, className, children, onClick, ...restProps } = props
  return (
    <Box
      onClick={onClick}
      style={{ position: "relative", cursor: "pointer" }}
      {...restProps}
    >
      <DsGroup align={align} spacing={spacing} noWrap className={className}>
        {children}
      </DsGroup>
    </Box>
  )
})

/* -------------------------------------------------------------------------------------------------
 * MemberInfoAvatar
 * -----------------------------------------------------------------------------------------------*/

import { Indicator } from "@mantine/core"
import { isOnline } from "../../../utils/isOnline"
import { DsAvatar, useDsImageProxy } from "@raisesistemas/ds"
import { useShowMemberDetail } from "../../../hooks/useShowMemberDetail"

type AvatarProps = {
  memberId: string
  name: string
  disableClick?: boolean
  size?: number | string
  avatar: string
}

const Avatar = memo((props: AvatarProps) => {
  const {
    memberId,
    name,
    avatar,
    size = 38,
    disableClick = false,
    ...restProps
  } = props
  const { handleMemberDetail } = useShowMemberDetail({
    memberId,
    enabled: !disableClick
  })
  const { url } = useDsImageProxy(avatar, { width: 80, height: 80 })

  return (
    <Box
      style={{ cursor: "pointer" }}
      onClick={handleMemberDetail}
      {...restProps}
    >
      <Indicator
        size={14}
        offset={5}
        position="bottom-end"
        color="green"
        withBorder
        disabled={!isOnline(memberId)}
      >
        <DsAvatar size={size} name={name} src={avatar ? url : undefined} />
      </Indicator>
    </Box>
  )
})

/* -------------------------------------------------------------------------------------------------
 * MemberInfoName
 * -----------------------------------------------------------------------------------------------*/

import { DsText, DsTextProps } from "@raisesistemas/ds"

type NameProps = Pick<DsTextProps, "color" | "weight" | "variant"> & {
  memberId?: string
  name: string
  disableClick?: boolean
}

export const Name = memo((props: NameProps) => {
  const {
    memberId,
    name,
    color,
    variant = "body-3",
    weight = "bold",
    disableClick = false
  } = props

  const { handleMemberDetail } = useShowMemberDetail({
    memberId,
    enabled: !disableClick
  })
  return (
    <Box style={{ cursor: "pointer", flex: 1 }} onClick={handleMemberDetail}>
      <DsText variant={variant} color={color} weight={weight}>
        {name}
      </DsText>
    </Box>
  )
})

/* -------------------------------------------------------------------------------------------------
 * MemberInfoDescription
 * -----------------------------------------------------------------------------------------------*/
import { TextEllipsis } from "../../Typography/TextEllipsis"
import { createRandomId } from "../../../utils/createRandomId"

type DescriptionProps = Pick<DsTextProps, "color" | "weight" | "variant"> & {
  text: string
  rightSection?: ReactNode
  maxWidth?: string
}

export const Description = memo((props: DescriptionProps) => {
  const {
    text,
    color,
    variant = "body-3",
    weight = "regular",
    rightSection,
    maxWidth = "100%"
  } = props
  return (
    <DsGroup
      align="center"
      spacing={2}
      noWrap
      style={{ flex: 1, width: "100%" }}
    >
      <TextEllipsis
        variant={variant}
        weight={weight}
        color={color}
        maxWidth={maxWidth}
      >
        {text}
      </TextEllipsis>
      {rightSection}
    </DsGroup>
  )
})

/* -------------------------------------------------------------------------------------------------
 * MemberInfoContent
 * -----------------------------------------------------------------------------------------------*/

type ContentProps = Pick<DsGroupProps, "position" | "align" | "spacing"> & {
  children: ReactNode
  direction?: CSSProperties["flexDirection"]
}

export const Content = (props: ContentProps) => {
  const {
    position,
    align = "flex-start",
    spacing = 2,
    children,
    direction = "column"
  } = props

  return (
    <DsGroup
      style={{ flex: 1, flexDirection: direction }}
      align={align}
      position={position}
      spacing={spacing}
    >
      {children}
    </DsGroup>
  )
}

/* -------------------------------------------------------------------------------------------------
 * MemberInfoTags
 * -----------------------------------------------------------------------------------------------*/

type Tag = {
  name: string
  color: string
  size: string
  isVisible: boolean
}

type TagsProps = {
  tags?: Tag[]
}

export const Tags = memo((props: TagsProps) => {
  const { tags } = props

  const visibleTags = tags?.filter(({ isVisible }) => isVisible)
  if (!tags) return <Fragment />

  return (
    <DsGroup spacing={2}>
      {visibleTags?.map(({ color, name, size }) => (
        <DsBadge key={createRandomId()} color={color} size={size}>
          {name}
        </DsBadge>
      ))}
    </DsGroup>
  )
})

interface MemberInfoType extends NamedExoticComponent<MemberInfoProps> {
  Avatar: typeof Avatar
  Name: typeof Name
  Description: typeof Description
  Content: typeof Content
  Tags: typeof Tags
}

//@ts-ignore
const MemberInfo = MemberInfoComponent as MemberInfoType

MemberInfo.Avatar = Avatar
MemberInfo.Name = Name
MemberInfo.Description = Description
MemberInfo.Content = Content
MemberInfo.Tags = Tags

export { MemberInfo }
