import { z } from "zod"

const hourRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/

const linkRegex =
  /^(https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?:\/[^\s]*)?$/

export const eventSchema = z.object({
  name: z.string().min(3),
  speaker: z.array(z.string()).optional(),
  banner: z.string().url(),
  link: z.string().regex(linkRegex),
  end_date: z.date(),
  start_date: z.date(),
  end_hour: z.string().regex(hourRegex),
  start_hour: z.string().regex(hourRegex),
  content: z.object({
    content: z.array(z.any()).optional()
  }),
  for_member_pro: z.boolean(),
  type: z.enum(["online", "local"]),
  local: z.string().optional()
})
