import { likeFindUnique } from "./likeFindUnique"
import { likeUpdate } from "./likeUpdate"

type DisLikePayload = {
  kind: string
  parentId: string
  userId: string
}

export const dislike = async ({ kind, parentId, userId }: DisLikePayload) => {
  const like = await likeFindUnique({ kind, parentId, userId })
  if (!like) return

  return await likeUpdate({ kind, liked: false, parentId, id: like.id })
}
