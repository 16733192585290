import { MS_PAYMENTS_URL } from "../../env"
import { getSession } from "../auth/getSession"
import { getTenant } from "../../utils/getTenant"

export const paymentRequestWithdraw = async () => {
  const { access_token } = await getSession()

  const tenant = getTenant()

  const response = await fetch(
    `${MS_PAYMENTS_URL}/request-withdraw/${tenant}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json"
      }
    }
  )

  if (!response.ok) throw new Error("Error requesting withdraw")
}
