import { supabase } from "../../lib/supabase"
import { getTenant } from "../../utils/getTenant"

export const eventInProgress = async () => {
  const date = new Date()
  date.setHours(0, 0, 0, 0)

  const dateString = date.toISOString()

  const { count, error } = await supabase
    .from("db")
    .select("*", { count: "exact", head: true })
    .lte("data->>start_date", dateString)
    .gte("data->>end_date", dateString)
    .eq("kind", "event")
    .eq("tenant", getTenant())

  if (error) throw error

  return Boolean(count && count > 0)
}
