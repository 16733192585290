import { supabase } from "../../lib/supabase"

type CommentCreatePayload = {
  id: string
}

export async function commentReplyDelete(payload: CommentCreatePayload) {
  const { error } = await supabase
    .from("db")
    .update({
      kind: "trash_comment_reply"
    })
    .eq("id", payload.id)
  if (error) throw error
}
