import { createStyles } from "@raisesistemas/ds"
import { RichTextEditor } from "@mantine/tiptap"

import { IconLink } from "../../../icons"

const useStyles = createStyles(() => ({
  linkEditorSave: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
  }
}))

type EditorLinkPreviewTargetProps = {
  onOpen: () => void
}

export const EditorLinkPreviewTarget = ({
  onOpen
}: EditorLinkPreviewTargetProps) => {
  const { classes } = useStyles()
  return (
    <div>
      <RichTextEditor.Control
        aria-label="Add link"
        title="Add link"
        onClick={onOpen}
        className={classes.linkEditorSave}
        role="button"
      >
        <IconLink size={16} />
      </RichTextEditor.Control>
    </div>
  )
}
