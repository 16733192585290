import { FileWithPath, MIME_TYPES } from "@mantine/dropzone"
import {
  DsBox,
  DsButtonUnstyled,
  DsFlex,
  DsSpace,
  DsText,
  DsTooltip,
  createStyles,
  rem,
  toast
} from "@raisesistemas/ds"
import { useState } from "react"
import { useTranslation } from "react-i18next"

import { RN } from "../../../env"
import { useAuthMutations } from "../../../hooks/mutations/useAuthMutations"
import { useSetParams } from "../../../hooks/use-set-params"
import { IconDownload, IconOutlineInfo } from "../../../icons"
import { drawers } from "../../../state"
import { capitalize } from "../../../utils/capitalize"
import { csvJSON } from "../../../utils/csvJSON"
import { memberInviteSchema } from "../../../validations/memberInviteSchema"
import { FileDropzone } from "../../FileDropzone"
import { Sheet, SheetDefaultFooter } from "../../Sheet"
import { Member, useMemberInviteForm } from "./member-invite-form-context"

const useStyles = createStyles(({ fn }) => ({
  download: {
    display: "flex",
    alignItems: "center",
    gap: rem(8),
    marginLeft: "auto",
    marginTop: rem(16),
    color: fn.primaryColor()
  }
}))

export const MemberInviteByCSVDrawer = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const [fileName, setFileName] = useState<string | undefined>()
  const { sendInvitations } = useAuthMutations()
  const { setParams } = useSetParams()

  const { isOpen, close } = drawers.member.inviteByCSV

  const form = useMemberInviteForm({
    initialValues: {
      members: []
    },
    validate: memberInviteSchema(t)
  })

  const handleDownloadCSVBase = () => {
    if (!!RN)
      RN.downloadFile(
        "https://hhvimmltfglwkqbhiezq.supabase.co/storage/v1/object/public/static/users.csv",
        "users",
        "csv",
        "application/csv"
      )

    if (!RN)
      window.open(
        "https://hhvimmltfglwkqbhiezq.supabase.co/storage/v1/object/public/static/users.csv"
      )

    toast.success({
      message: t("toast.csv_template_downloaded")
    })
  }

  const onDrop = (files: FileWithPath[]) => {
    if (files.length !== 1) return

    const file = files[0]

    setFileName(file.name)

    const reader = new FileReader()

    reader.onload = function(event) {
      if (!event.target || !event.target.result) return
      const csvData = event.target.result
      const users = csvJSON(csvData as string) as Member[]

      if (users.length === 0) {
        toast.error({ message: t("toast.member.invalid_csv") })
      }

      form.setFieldValue("members", users)
    }

    reader.readAsText(file)
  }

  const handleAddMembers = form.onSubmit(formValues => {
    sendInvitations.mutate(formValues, {
      onSuccess: () => {
        close()
        drawers.member.inviteSelector.close()
        setParams({ filter: "invitations" })
      }
    })
  })

  const handleSubmit = () => {
    if (form.values.members.length === 0) {
      return toast.error({ message: t("common.required", { type: "CSV" }) })
    }

    const isInvalid = form.validate().hasErrors

    if (isInvalid) {
      return toast.error({ message: t("toast.member.invalid_csv") })
    }

    return handleAddMembers()
  }

  const title = (
    <DsFlex align="center" gap={8}>
      <DsText weight="medium">{t("drawer.invite_members.title")}</DsText>
      <DsTooltip
        multiline
        label={t("tooltip.invite_members_by_csv")}
        maxWidth={310}
      >
        <IconOutlineInfo />
      </DsTooltip>
    </DsFlex>
  )

  const footer = (
    <SheetDefaultFooter
      isLoading={sendInvitations.isPending}
      onClose={close}
      onSubmit={handleSubmit}
      submitText={capitalize(t("common.send", { type: t("root.invitations") }))}
    />
  )

  return (
    <Sheet title={title} opened={isOpen} onClose={close} footer={footer}>
      <DsBox>
        <DsText variant="body-2" weight="medium">
          {t("input.invite_members.label")}
        </DsText>

        <DsSpace height={8} />

        <FileDropzone
          onDrop={onDrop}
          maxSize={5 * 1024 ** 2}
          accept={[MIME_TYPES.csv]}
          maxFiles={1}
          description={fileName}
        />

        <DsButtonUnstyled
          className={classes.download}
          onClick={handleDownloadCSVBase}
        >
          <DsText variant="body-1" weight="regular">
            {t("button.download_csv_template")}
          </DsText>

          <IconDownload size={24} />
        </DsButtonUnstyled>
      </DsBox>
    </Sheet>
  )
}
