import {
  DsBox,
  DsGroup,
  DsSkeletonCircle,
  DsSkeletonLine,
  DsStackVertical
} from "@raisesistemas/ds"
import { createRandomId } from "../../utils/createRandomId"

export const NotificationsSkeleton = () => {
  return (
    <DsStackVertical spacing="lg">
      {Array.from({ length: 10 }).map(() => (
        <DsGroup noWrap align="center" key={createRandomId()}>
          <DsBox>
            <DsSkeletonCircle />
          </DsBox>
          <DsGroup style={{ flex: 1 }} align="center">
            <DsSkeletonLine isFull />
            <DsSkeletonLine isFull />
          </DsGroup>
        </DsGroup>
      ))}
    </DsStackVertical>
  )
}
