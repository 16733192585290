import {
  DsButtonPrimary,
  DsButtonSecondary,
  DsMediaQuery,
  DsStackVertical
} from "@raisesistemas/ds"
import { useTranslation } from "react-i18next"

type StepperControlsProps = {
  active: number
  onNavigateTo: (index: number) => void
  stepsCount: number
  onFinalize?: () => void
  isLoading?: boolean
}

export const StepperControls = (props: StepperControlsProps) => {
  const { active, onNavigateTo, stepsCount, onFinalize, isLoading } = props
  const { t } = useTranslation()
  return (
    <DsMediaQuery
      largerThan="md"
      styles={{ flexDirection: "row", justifyContent: "end" }}
    >
      <DsStackVertical spacing="sm">
        {active > 0 && (
          <DsButtonSecondary onClick={() => onNavigateTo(active - 1)}>
            {t("root.back")}
          </DsButtonSecondary>
        )}

        {active < stepsCount - 1 && (
          <DsButtonPrimary onClick={() => onNavigateTo(active + 1)}>
            {t("root.next")}
          </DsButtonPrimary>
        )}

        {active === stepsCount - 1 && (
          <DsButtonPrimary onClick={onFinalize} loading={isLoading}>
            {t("root.finish")}
          </DsButtonPrimary>
        )}
      </DsStackVertical>
    </DsMediaQuery>
  )
}
