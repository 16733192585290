import { DsCenter, DsLoader } from "@raisesistemas/ds"
import { CSSProperties } from "react"

type LoaderProps = {
  mih?: CSSProperties["minHeight"]
}

export const Loader = (props: LoaderProps) => {
  const { mih = "auto", ...restProps } = props
  return (
    <DsCenter mih={mih} {...restProps}>
      <DsLoader variant="oval" />
    </DsCenter>
  )
}
