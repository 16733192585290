import { useEffect } from "react"

import { supabase } from "../../lib/supabase"
import { useAuth } from "../../hooks/useAuth"
import { onlineUsers } from "../../state/online"
import { getTenant } from "../../utils/getTenant"

export const OnlineUsersContainer = ({
  children
}: {
  children: React.ReactNode
}) => {
  const { user } = useAuth()

  useEffect(() => {
    if (!user) return
    const tenant = getTenant()
    const channel = supabase.channel(`online_users:${tenant}`)

    channel
      .on("presence", { event: "sync" }, () => {
        const userIds = []
        for (const id in channel.presenceState()) {
          userIds.push(
            channel.presenceState<{ user_uuid: string }>()[id][0].user_uuid
          )
        }
        onlineUsers.value = [...new Set(userIds)]
      })
      .subscribe(async status => {
        if (status === "SUBSCRIBED") {
          await channel.track({
            online_at: new Date().toISOString(),
            user_uuid: user.user_uuid
          })
        }
      })
  }, [user])

  return children
}
