export const SUPABASE_KEY = import.meta.env.VITE_SUPABASE_KEY
export const SUPABASE_URL = import.meta.env.VITE_SUPABASE_URL
export const SUPABASE_ANON_PUBLIC_KEY = import.meta.env
  .VITE_SUPABASE_ANON_PUBLIC_KEY

export const BASE_URL = document.location.origin

export const MS_SUBDOMAIN_URL = `https://${SUPABASE_KEY}.functions.supabase.co/subdomain`
export const MS_NOTIFICATION_URL = `https://${SUPABASE_KEY}.functions.supabase.co/notification`
export const MS_METRICS_URL = `https://${SUPABASE_KEY}.supabase.co/functions/v1/metrics-service/metrics`

export const RN = window.RN

export const TENANT = import.meta.env.VITE_TENANT

export const MS_ACTIVITIES_URL = import.meta.env.VITE_MS_ACTIVITIES_URL
export const MS_PAYMENTS_URL = import.meta.env.VITE_MS_PAYMENTS_URL
export const MS_DELETE_ACCOUNT_URL = import.meta.env.VITE_MS_DELETE_ACCOUNT_URL
export const MS_AUTH_URL = import.meta.env.VITE_MS_AUTH_URL
export const MS_EDITOR_URL = import.meta.env.VITE_MS_EDITOR_URL
export const MS_MAIL_URL = import.meta.env.VITE_MS_MAIL_URL
export const MS_DIRECT_URL = import.meta.env.VITE_MS_DIRECT_URL
export const WS_URL = import.meta.env.VITE_WS_URL
export const MS_PUSH_NOTIFICATION_URL = import.meta.env
  .VITE_MS_PUSH_NOTIFICATION_URL

export const GOOGLE_SIGNING_CLIENT_ID = import.meta.env
  .VITE_GOOGLE_SIGNING_CLIENT_ID

export const GOOGLE_SIGNING_CLIENT_SECRET = import.meta.env
  .VITE_GOOGLE_SIGNING_CLIENT_SECRET
