import { createQueryKeys } from "@lukemorales/query-key-factory"

import { getTenant } from "../utils/getTenant"
import { eventInProgress } from "../api/events/eventInProgress"
import { EventListPayload, eventList } from "../api/events/eventList"

export const eventQueries = createQueryKeys("events", {
  list: ({ type }: EventListPayload) => ({
    queryKey: [getTenant(), type],
    queryFn: ({ pageParam = 1 }: { pageParam: number }) =>
      eventList({ type, page: pageParam })
  }),
  inProgress: () => ({
    queryKey: [getTenant()],
    queryFn: eventInProgress
  })
})
