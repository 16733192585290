import { useEffect, useState } from "react"
import { getTenant } from "../../utils/getTenant"

export const useButtonAi = () => {
  const [boxShow, setShowBox] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const tenant = getTenant()

  const config = TENANT_CONFIGS.find(config => config.tenant === tenant)
  const hasAi = Boolean(config) === true

  const stopLoading = () => setLoading(false)
  useEffect(() => {
    const timerLoading = setTimeout(stopLoading, 10000)
    return () => clearTimeout(timerLoading)
  }, [])

  return {
    hasAi,
    isLoading,
    boxShow,
    setShowBox
  }
}

const TENANT_CONFIGS = [
  {
    tenant: "dev",
    chatId: "cltc5b3tl000655u4grsnsnqj"
  },
  {
    tenant: "teste-qa-prd",
    chatId: "cltc5b3tl000655u4grsnsnqj"
  },
  {
    tenant: "luminy",
    chatId: "cltc5b3tl000655u4grsnsnqj"
  },
  {
    tenant: "titanium",
    chatId: "cltc5b3tl000655u4grsnsnqj"
  }
]
