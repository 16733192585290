import { MemberDetail } from "."
import { Sheet } from "../../Sheet"
import { drawers } from "../../../state"

export type MemberDetailDrawerProps = {
  memberId: string
}

export const MemberDetailDrawer = () => {
  const { isOpen, close, props } = drawers.member.detail
  const { memberId } = props
  return (
    <Sheet
      scroll
      noPadding
      title=""
      showOverlay={true}
      onClose={close}
      opened={isOpen}
      adjustToContentHeight={false}
      testId={MEMBER_DETAIL_DRAWER_ID}
    >
      <MemberDetail memberId={memberId} />
    </Sheet>
  )
}

export const MEMBER_DETAIL_DRAWER_ID = "member-detail-drawer-id"
