import { MS_ACTIVITIES_URL } from "../../env"
import { buildURL } from "../../utils/buildUrl"
import { getTenant } from "../../utils/getTenant"
import { getUserId } from "../../utils/getUserId"

export type ActivityReadAllPayload = {
  target_ids: string[]
}

export const activityRead = async (props: ActivityReadAllPayload) => {
  const { target_ids } = props

  const user_id = await getUserId()

  const tenant = getTenant()

  const queryParams = { tenant, user_id }

  const url = buildURL(`${MS_ACTIVITIES_URL}/activities/read/post`, queryParams)

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ target_ids })
  })

  if (!response.ok) {
    throw new Error(response.statusText)
  }
}
