import { createStyles, rem } from "@raisesistemas/ds"

export const useStyles = createStyles(theme => ({
  button: {
    width: rem(50),
    height: rem(50),
    border: `1px dashed ${
      theme.colorScheme === "dark" ? theme.colors.dark[2] : theme.colors.gray[5]
    }`,
    fontSize: rem(32),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4
  },
  target: {
    width: "fit-content",
    display: "flex",
    flexDirection: "column",
    gap: rem(8)
  },
  asterisk: {
    color: theme.colors.red[8]
  }
}))
