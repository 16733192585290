import { base64ToBlob } from "./base64ToBlob"
import { blobToImage } from "./blobToImage"
import { convertImageToBlob } from "./convertImageToBlob"
import { ResizeImageOptions, resizeImage } from "./resizeImage"

export const optimizeImage = async (
  inputImage: string | Blob | File,
  options?: ResizeImageOptions
) => {
  let imageBlob: Blob | undefined

  if (inputImage instanceof File) {
    imageBlob = await convertImageToBlob(inputImage)
  }

  if (typeof inputImage === "string") {
    imageBlob = await base64ToBlob(inputImage)
  }

  if (inputImage instanceof Blob) {
    imageBlob = inputImage
  }

  if (!imageBlob) throw new Error("Unable to create image")

  const base64 = await optimizeBlobImage(imageBlob, options)

  return base64
}

export const optimizeBlobImage = (
  imageBlob: Blob,
  options?: ResizeImageOptions
): Promise<Blob> => {
  return new Promise(async (resolve, reject) => {
    const image = blobToImage(imageBlob)

    image.onload = async () => {
      const resizedImage = resizeImage(image, options)

      const blob = await base64ToBlob(resizedImage)
      resolve(blob)
    }

    image.onerror = event => reject(event)
  })
}
