import { MS_ACTIVITIES_URL } from "../../env"
import { ActivityType } from "../../types/activity"

export type ActivityListPayload = {
  tenant: string
  user_id: string
  limit?: number
  page?: number
}

type MetadataData = {
  emoji?: string
  title?: string
  content?: string
}

export const activityList = async (props: ActivityListPayload) => {
  const { tenant, user_id, limit = 20, page = 1 } = props

  const currentPage = Math.max(0, page - 1)
  const skip = currentPage * limit

  const endpoint = `${MS_ACTIVITIES_URL}/activities?tenant=${tenant}&user_id=${user_id}&limit=${limit}&skip=${skip}`
  const response = await fetch(endpoint)

  if (!response.ok) {
    throw new Error(response.statusText)
  }

  const data = await response.json()

  return {
    data: data as ActivityType<unknown, MetadataData>[],
    nextId: data.length < limit ? null : page + 1,
    previousId: page > 0 ? page - 1 : null
  }
}
