import { useInfiniteQuery } from "@tanstack/react-query"
import { queries } from "../../queries"
import { GifSearchPayload } from "../../api/gif/gifSearch"

export const useGifQueries = () => {
  const search = (payload: GifSearchPayload & { enabled: boolean }) => {
    return useInfiniteQuery({
      ...queries.gifs.search(payload),
      initialPageParam: "",
      getNextPageParam: (lastPage: any) => {
        return lastPage.nextId ?? undefined
      },
      getPreviousPageParam: (firstPage: any) => {
        return firstPage.previousId ?? undefined
      },
      enabled: payload.enabled
    })
  }

  return { search }
}
