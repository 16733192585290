import { useTranslation } from "react-i18next"

export const useCharactersRemaining = (
  max: number,
  currentCharactersLength: number
) => {
  const { t } = useTranslation()
  const remaining = max - currentCharactersLength
  const characters = remaining + " " + t("input.channel.characters_length")
  return {
    characters
  }
}
