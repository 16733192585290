import { getBankByName } from "../../../utils/getBankByName"
import { getTenant } from "../../../utils/getTenant"
import { onlyNumbers } from "../../../utils/onlyNumbers"

type FormattBankPayloadParams = {
  name: string
  document: string
  bank_account_type: string
  bank: string
  bank_ag: string
  bank_cc: string
  phone?: string
}

const formattBankPayload = (params: FormattBankPayloadParams) => {
  const { bank, bank_cc, bank_ag, bank_account_type } = params
  switch (bank) {
    case "Banco do Brasil":
      const digit = bank_ag.slice(-1)
      const hasDigitZero = Number(digit) === 0
      return {
        ...params,
        bank_ag: hasDigitZero ? "x" : digit
      }
    // @ts-ignore
    case "Caixa Econômica":
      const oldOPCodes = {
        cp: "013",
        cc: "001"
      }
      const newOPCodes = {
        cp: "1288",
        cc: "0001"
      }

      const cc = onlyNumbers(bank_cc)

      const isOldAgFormat = cc.length === 13
      const isNewAgFormat = cc.length === 14
      const isPoupanca = bank_account_type === "cp"
      const isCorrente = bank_account_type === "cc"

      if (isOldAgFormat) {
        if (isPoupanca) {
          return {
            ...params,
            bank_cc: bank_cc.replace("000", oldOPCodes.cp)
          }
        }

        if (isCorrente) {
          return {
            ...params,
            bank_cc: bank_cc.replace("000", oldOPCodes.cc)
          }
        }
      }

      if (isNewAgFormat) {
        if (isPoupanca) {
          return {
            ...params,
            bank_cc: bank_cc.replace("0000", newOPCodes.cp)
          }
        }

        if (isCorrente) {
          return {
            ...params,
            bank_cc: bank_cc.replace("0000", newOPCodes.cc)
          }
        }
      }

    default:
      return params
  }
}

export const formatBankBody = (params: FormattBankPayloadParams) => {
  const payload = formattBankPayload(params)

  const bank = getBankByName(payload.bank)

  if (!bank) throw new Error("Bank not found")

  return Object.assign(
    {
      tenant: getTenant(),
      code: bank.code.toString().padStart(3, "0")
    },
    formattBankPayload(payload)
  )
}
