import { ReactNode } from "react"
import { DsProvider } from "@raisesistemas/ds"
import { ModalsProvider } from "@mantine/modals"
import { GoogleOAuthProvider } from "@react-oauth/google"
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client"
import { persister, queryClient } from "./lib/react-query"

import { ActivitiesReadProvider } from "./contexts/ActivitiesRead"
import { OnlineUsersContainer } from "./components/OnlineUsersContainer"

import { AuthProvider, AuthProviderProps } from "./contexts/Auth"
import { useLanguage } from "./hooks/useLanguage"
import { useTheme } from "./hooks/theme/useTheme"
import { GOOGLE_SIGNING_CLIENT_ID } from "./env"
import { WebsocketProvider } from "./contexts/Websocket"

export type ProvidersProps = AuthProviderProps & {
  children: ReactNode
}

export const Providers = (props: ProvidersProps) => {
  useLanguage()

  const { children, mockedUser } = props
  const { colorScheme, toggleColorScheme, theme } = useTheme()

  return (
    <DsProvider
      theme={theme}
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
      imageProxy="https://img.luminy.app/cdn-cgi/image"
    >
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{ persister }}
      >
        <ModalsProvider modalProps={{ zIndex: 10_000 }}>
          <GoogleOAuthProvider clientId={GOOGLE_SIGNING_CLIENT_ID}>
            <AuthProvider mockedUser={mockedUser}>
              <ActivitiesReadProvider>
                <OnlineUsersContainer>
                  <WebsocketProvider>{children}</WebsocketProvider>
                </OnlineUsersContainer>
              </ActivitiesReadProvider>
            </AuthProvider>
          </GoogleOAuthProvider>
        </ModalsProvider>
      </PersistQueryClientProvider>
    </DsProvider>
  )
}
