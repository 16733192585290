import { DsPopover } from "@raisesistemas/ds"
import { useEditorLinkPreview } from "./useEditorLinkPreview"
import { EditorLinkPreviewTarget } from "./EditorLinkPreviewTarget"
import { EditorLinkPreviewContent } from "./EditorLinkPreviewContent"

export const EditorLinkPreviewControl = () => {
  const { open, close, opened, submit, form } = useEditorLinkPreview()

  return (
    <DsPopover
      width={300}
      shadow="md"
      withinPortal
      zIndex={10000}
      offset={-44}
      opened={opened}
      onOpen={open}
      onClose={close}
      targetChildren={<EditorLinkPreviewTarget onOpen={open} />}
      dropdownChildren={
        <EditorLinkPreviewContent form={form} onSubmit={submit} />
      }
    />
  )
}
