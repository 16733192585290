import { supabase } from "../../lib/supabase"

export type channelUpdateAudiencePayload = {
  channelId: string
  topicId: string | null
}

export async function channelUpdateTopic(
  payload: channelUpdateAudiencePayload
) {
  const record = {
    parent_id: payload.topicId
  }

  const { data, error } = await supabase
    .from("db")
    .update(record)
    .eq("id", payload.channelId)

  if (error) throw error

  return data
}
