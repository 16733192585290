import { KINDS } from "../../lib/constants"
import { likeToggle } from "../like/likeToggle"

type ReplyLikePayload = {
  liked: boolean
  replyId: string
}

export const replyLike = async ({ replyId, liked }: ReplyLikePayload) => {
  await likeToggle({
    kind: KINDS.REPLY_LIKE,
    parentId: replyId,
    liked
  })
}
