import { supabase } from "../../lib/supabase"

export async function postDelete(postId: string) {
  const { error } = await supabase
    .from("db")
    .update({ kind: "trash_post" })
    .eq("id", postId)

  if (error) throw new Error(error.message)
}
