import { DsButtonPrimary, DsButtonSecondary, DsGroup } from "@raisesistemas/ds"
import { useTranslation } from "react-i18next"

type ChannelFooterProps = {
  onClose: () => void
  onSubmit: () => void
  isEdit: boolean
  isLoading: boolean
}

export const ChannelFooter = ({
  onClose,
  onSubmit,
  isEdit,
  isLoading
}: ChannelFooterProps) => {
  const { t } = useTranslation()
  return (
    <DsGroup position="right">
      <DsButtonSecondary onClick={onClose}>
        {t("button.cancel")}
      </DsButtonSecondary>

      <DsButtonPrimary onClick={onSubmit} loading={isLoading}>
        {isEdit
          ? t("common.edit", { type: t("root.channel").toLowerCase() })
          : t("root.create_channel")}
      </DsButtonPrimary>
    </DsGroup>
  )
}
