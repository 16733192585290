import { useQuery } from "@tanstack/react-query"
import { tenantByHost } from "../../api/tenant/tenantByHost"
import { queries } from "../../queries"
import { getHost } from "../../utils/getHost"

export const useTenantQuery = () => {
  const currentHost = getHost()
  return useQuery({
    queryKey: queries.tenant.info.queryKey,
    queryFn: tenantByHost,
    staleTime: 1000 * 60 * 30,
    gcTime: 1000 * 60 * 60,

    enabled: currentHost !== "app"
  })
}
