import { EntityActions } from "../EntityActions"
import { MemberInfo } from "../Member/MemberInfoV2"

import { useUserPermissions } from "../../hooks/useUserPermissions"
import { useDateDistanceToNow } from "../../hooks/useDateDistanceToNow"
import { useRef } from "react"

export type CommentHeaderProps = {
  id: string
  memberId: string
  memberAvatar: string
  memberName: string
  onEdit: () => void
  onDelete: () => void
}

export const CommentHeader = (props: CommentHeaderProps) => {
  const {
    id,
    memberId,
    memberAvatar,
    memberName,
    onEdit,
    onDelete,
    ...restProps
  } = props
  const permissions = useUserPermissions(memberId)
  const { dateDistanceToNow } = useDateDistanceToNow()
  const formRef = useRef<HTMLInputElement>(null)

  const CAN_SEE_ANY_ACTION =
    permissions.CAN_SEE_EDIT_COMMENT_BUTTON ||
    permissions.CAN_SEE_POST_BUTTON_DELETE

  return (
    <MemberInfo {...restProps}>
      <MemberInfo.Avatar
        memberId={memberId}
        name={memberName}
        avatar={memberAvatar}
        data-testid={COMMENT_HEADER_AVATAR_ID}
      />
      <MemberInfo.Content>
        <MemberInfo.Name memberId={memberId} name={memberName} />
        <MemberInfo.Description text={dateDistanceToNow(id)} />
      </MemberInfo.Content>

      {CAN_SEE_ANY_ACTION && (
        <EntityActions
          onDelete={onDelete}
          onEdit={() => {
            formRef.current?.focus()
            onEdit()
          }}
          data-testid={COMMENT_HEADER_ACTIONS_ID}
        />
      )}
    </MemberInfo>
  )
}

export const COMMENT_HEADER_AVATAR_ID = "comment-header-avatar-id"
export const COMMENT_HEADER_ACTIONS_ID = "comment-header-actions-id"
