import { supabase } from "../../lib/supabase"
import { AudienceWithMembers } from "./audienceListWithMembers"

export const audienceById = async (id: string) => {
  const { data, error } = await supabase
    .from("audience")
    .select("id::text, label, tenant, member( * )")
    .eq("id", id)
    .single<AudienceWithMembers>()

  if (error) throw error

  return data
}
