import { FEE_IN_DECIMAL } from "../../../lib/constants"
import { onlyNumbers } from "../../../utils/onlyNumbers"

type GetPlanPriceCentsProps = {
  price_cents: string
  fee_paid_by_member: boolean
}

export const getPlanPriceCents = (props: GetPlanPriceCentsProps) => {
  const { fee_paid_by_member, price_cents } = props

  const priceWithoutFeeCents = Number(onlyNumbers(price_cents))
  const priceWithFeeCents = Math.ceil(
    priceWithoutFeeCents / (1 - FEE_IN_DECIMAL)
  )

  return fee_paid_by_member && !price_cents
    ? priceWithFeeCents
    : priceWithoutFeeCents
}
