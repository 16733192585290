import { memo } from "react"
import {
  DsButtonUnstyled,
  DsGroup,
  DsImageLogo,
  DsText
} from "@raisesistemas/ds"
import { useSpaceQuery } from "../../hooks/queries/useSpaceQuery"
import { useStyles } from "./NavHeader.style"

export const NavLogo = memo(() => {
  const { classes } = useStyles()
  const { data: space } = useSpaceQuery(true)

  return (
    <DsButtonUnstyled className={classes.logoButton}>
      <DsGroup>
        <DsImageLogo src={space?.data?.logo || ""} size="sm" radius="sm" />
        <DsText variant="body-1" weight="semi-bold">
          {space?.data?.title}
        </DsText>
      </DsGroup>
    </DsButtonUnstyled>
  )
})
