import { useEffect } from "react"
import { useTranslation } from "react-i18next"

import {
  DsBox,
  DsButtonPrimary,
  DsButtonSecondary,
  DsMediaQuery,
  DsStackVertical
} from "@raisesistemas/ds"

import { BankForm } from "./BankForm"
import { useBankForm } from "./useBankForm"
import { useUser } from "../../../hooks/useUser"
import { Sheet } from "../../../components/Sheet"
import { useBankQueries } from "../../../hooks/queries/useBankQueries"
import { useBankMutations } from "../../../hooks/mutations/useBankMutations"
import { drawers } from "../../../state"

export const BankEditDrawer = () => {
  const { isOpen, close } = drawers.bank.edit
  const { data: user } = useUser()
  const form = useBankForm()
  const { t } = useTranslation()
  const { find } = useBankQueries()
  const { update } = useBankMutations()

  const { data } = find()

  const handleSubmit = form.onSubmit(values => {
    update.mutate(
      {
        phone: user.phone,
        account_type: form.values.bank_account_type,
        ...values
      },
      {
        onSuccess: () => {
          close()
          form.reset()
        }
      }
    )
  })

  useEffect(() => {
    if (data) form.setValues(data.data)
  }, [data])

  return (
    <Sheet title="" opened={isOpen} onClose={close} showOverlay={true}>
      <form onSubmit={handleSubmit}>
        <DsBox>
          <BankForm form={form} />

          <DsMediaQuery
            largerThan="md"
            styles={{ flexDirection: "row", justifyContent: "end" }}
          >
            <DsStackVertical>
              <DsButtonSecondary onClick={close}>
                {t("root.close")}
              </DsButtonSecondary>
              <DsButtonPrimary type="submit" loading={update.isPending}>
                {t("button.save")}
              </DsButtonPrimary>
            </DsStackVertical>
          </DsMediaQuery>
        </DsBox>
      </form>
    </Sheet>
  )
}
