import {
  rem,
  DsBox,
  DsFlex,
  DsText,
  createStyles,
  DsButtonUnstyled
} from "@raisesistemas/ds"
import { useTranslation } from "react-i18next"

import { useTrial } from "../hooks/useTrial"
import { WHATSAPP_LINK } from "../lib/constants"

const useStyles = createStyles(({ colors, primaryColor, colorScheme }) => ({
  root: {
    position: "sticky",
    top: 0,
    height: "var(--trial-height)",
    backgroundColor: colors[primaryColor][colorScheme === "dark" ? 9 : 0],
    zIndex: 1_000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: rem(16)
  },
  text: {
    display: "inline",
    textAlign: "center"
  },
  link: {
    textDecoration: "underline"
  }
}))

export const FreeTrialBanner = () => {
  const { t } = useTranslation()
  const { classes, theme } = useStyles()
  const { colors, primaryColor, colorScheme } = theme

  const isDark = colorScheme === "dark"

  const { showBanner, timeLeft } = useTrial()

  const handleSubscribe = () => {
    const message = `Olá! Gostaria de saber mais sobre os planos da Luminy.`
    window.open(`${WHATSAPP_LINK}?text=${encodeURIComponent(message)}}`)
  }

  if (!showBanner) return null

  return (
    <DsBox className={classes.root}>
      <DsFlex gap={8}>
        <DsText
          variant="body-2"
          weight="regular"
          color={isDark ? colors.gray[4] : colors.dark[5]}
          className={classes.text}
        >
          {t("common.trial_time_left", { timeLeft })}{" "}
          <DsButtonUnstyled onClick={handleSubscribe}>
            <DsText
              variant="body-2"
              weight="medium"
              className={classes.link}
              color={isDark ? colors.gray[0] : colors[primaryColor][5]}
            >
              {t("root.subscribe_to_the_pro_plan")}
            </DsText>
          </DsButtonUnstyled>
        </DsText>
      </DsFlex>
    </DsBox>
  )
}
