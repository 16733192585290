import { supabase } from "../../lib/supabase"
import { AudienceType } from "../../types/audience"
import { MemberType } from "../../types/member"
import { getTenant } from "../../utils/getTenant"

export type AudienceWithMembers = AudienceType & {
  member: MemberType[]
}

export const audienceListWithMembers = async () => {
  const { data, error } = await supabase
    .from("audience")
    .select("id::text, label, tenant, member( * )")
    .eq("tenant", getTenant())
    .neq("label", "_default")
    .returns<AudienceWithMembers[]>()

  if (error) throw error

  return data
}
