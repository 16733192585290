import { supabase } from "../../lib/supabase"
import { MemberType } from "../../types/member"
import { getTenant } from "../../utils/getTenant"

export const memberById = async (user_uuid: string) => {
  if (!user_uuid) throw new Error("user_uuid is required")
  return await supabase
    .from("member")
    .select("*")
    .match({
      user_uuid,
      tenant: getTenant()
    })
    .single<MemberType>()
}
