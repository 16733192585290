import { DsButtonUnstyled, DsText, useDsTheme } from "@raisesistemas/ds"
import { capitalize } from "../../../utils/capitalize"
import { useTranslation } from "react-i18next"
import { drawers } from "../../../state"

type SeeLikesButtonProps = {
  kind: string
  parentId: string
}

export const SeeLikesButton = (props: SeeLikesButtonProps) => {
  const { t } = useTranslation()
  const { primaryColor } = useDsTheme()

  const handleOpenLikeList = () => drawers.like.list.open(props)

  return (
    <DsButtonUnstyled onClick={handleOpenLikeList}>
      <DsText variant="body-2" color={primaryColor} weight="medium">
        {capitalize(t("common.see", { type: t("common.likes") }))}
      </DsText>
    </DsButtonUnstyled>
  )
}
