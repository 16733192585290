import { DsGroup, DsGroupProps, DsStackVertical } from "@raisesistemas/ds"

import { ButtonLike } from "./ButtonLike"
import { LikeContext } from "./LikeContext"
import { LikeProvider } from "./LikeProvider"
import { ButtonComment } from "./ButtonComment"
import { SeeLikesButton } from "../Like/SeeLikesButton"

export type ButtonCommentGroupProps = Pick<DsGroupProps, "position"> & {
  visibleContent?: "like" | "full"
  countLike: number
  liked: boolean
  onLike: (liked: boolean) => void
  countComment?: number
  onComment?: () => void
  likeKind: string
  likeParentId: string
  hideSeeLike?: boolean
}

export function ButtonCommentGroup(props: ButtonCommentGroupProps) {
  const {
    position,
    countLike,
    liked,
    onLike,
    likeKind,
    likeParentId,
    hideSeeLike = true,
    visibleContent = "full",
    countComment,
    onComment,
    ...restProps
  } = props
  return (
    <LikeProvider liked={liked} likeCount={countLike} onLike={onLike}>
      <LikeContext.Consumer>
        {({ likeCount }) => (
          <DsStackVertical {...restProps}>
            <DsGroup position={position} spacing={20}>
              <ButtonLike />
              {visibleContent === "full" && (
                <ButtonComment count={countComment} onClick={onComment} />
              )}
            </DsGroup>

            {likeCount > 0 && !hideSeeLike && (
              <SeeLikesButton kind={likeKind} parentId={likeParentId} />
            )}
          </DsStackVertical>
        )}
      </LikeContext.Consumer>
    </LikeProvider>
  )
}
