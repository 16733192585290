import { createQueryKeys } from "@lukemorales/query-key-factory"

import {
  metricsGetAll,
  MetricsGetAllPayload
} from "../api/metrics/metricsGetAll"

export const metricsQueries = createQueryKeys("metrics", {
  getAll: (payload: MetricsGetAllPayload) => ({
    queryKey: [payload],
    queryFn: () => metricsGetAll(payload)
  })
})
