import { Navigate, Outlet } from "react-router-dom"
import { useAuth } from "../../hooks/useAuth"

export const PublicRoutesRules = () => {
  const { signed } = useAuth()

  if (signed) return <Navigate to="/channels/posts" replace />

  return <Outlet />
}
