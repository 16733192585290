import { createQueryKeys } from "@lukemorales/query-key-factory"
import { getTenant } from "../utils/getTenant"
import { directListGroup } from "../api/direct/directListGroup"
import { directListMessage } from "../api/direct/directListMessage"
import { directCount } from "../api/direct/directCount"

export const directQueries = createQueryKeys("direct", {
  listGroup: () => ({
    queryKey: [getTenant()],
    queryFn: ({ pageParam }: { pageParam: number }) =>
      directListGroup({ page: pageParam })
  }),

  listMessage: (groupId: number) => ({
    queryKey: [getTenant(), groupId],
    queryFn: ({ pageParam }: { pageParam: number }) =>
      directListMessage({ page: pageParam as number, groupId })
  }),

  count: () => ({
    queryKey: [getTenant()],
    queryFn: () => directCount()
  })
})
