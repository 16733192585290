import { useCallback } from "react"
import { useForm } from "@mantine/form"
import { useDisclosure } from "@mantine/hooks"
import { useTranslation } from "react-i18next"
import { useRichTextEditorContext } from "@mantine/tiptap"

import { isValidUrl } from "../../../utils/isValidUrl"
import { linkPreview } from "../../../api/linkPreview/linkPreview"

export const useEditorLinkPreview = () => {
  const { t } = useTranslation()
  const { editor } = useRichTextEditorContext()
  const [opened, handlers] = useDisclosure(false)

  const form = useForm({
    initialValues: {
      url: "",
      withPreview: false
    },
    validate: {
      url: value =>
        isValidUrl(value) || value === "" ? null : t("validation.invalid_url")
    }
  })

  const open = () => {
    handlers.open()
    const linkData = editor.getAttributes("link")
    form.setFieldValue("url", linkData?.href || "")
  }

  const close = () => {
    handlers.close()
    form.reset()
  }

  const addLinkPreview = useCallback(async () => {
    close()

    if (form.values.url === "") {
      return editor.chain().focus().unsetLink().run()
    }

    if (form.values.withPreview) {
      const { title, description, url, image } = await linkPreview(
        form.values.url
      )

      return editor
        .chain()
        .focus()
        .setLinkPreview({ title, description, cover: image, href: url })
        .run()
    }

    editor
      .chain()
      .focus()
      .setLink({ href: form.values.url, target: "_blank" })
      .run()
  }, [editor, close, form.values.url])

  const submit = () => {
    form.onSubmit(addLinkPreview)()
  }

  return {
    opened,
    open,
    close,
    submit,
    form
  }
}
