import StarterKit from "@tiptap/starter-kit"
import { useTranslation } from "react-i18next"
import Placeholder from "@tiptap/extension-placeholder"
import { EditorOptions, JSONContent, useEditor } from "@tiptap/react"
import Link from "@tiptap/extension-link"
import { Roles } from "../../api/member/memberSearch"
import { Mention, suggestions } from "../../extensions/Mention"
import Image from "@tiptap/extension-image"

type UseCommentEditorOptions = Partial<
  Pick<EditorOptions, "editable" | "content" | "onCreate">
> & {
  onUpdate?: ({ html, json }: { html: string; json: JSONContent }) => void
  channelId?: string | null
  mentionRoles?: Roles
}

export const useCommentEditor = ({
  channelId,
  mentionRoles,
  ...options
}: UseCommentEditorOptions) => {
  const { t } = useTranslation()

  return useEditor({
    ...options,
    editorProps: {
      attributes: {
        "data-testid": COMMENT_EDITOR_ID
      }
    },
    extensions: [
      StarterKit,
      Mention.configure({
        suggestion: suggestions(mentionRoles)
      }),
      Placeholder.configure({
        placeholder: t("input.comment.create.content.placeholder")
      }),
      Link.configure({
        HTMLAttributes: {
          target: "_blank"
        }
      }),
      Image
    ],
    onUpdate({ editor }) {
      if (!editor) return

      const html = editor.getHTML()
      const json = editor.getJSON()

      options?.onUpdate?.({ html, json })
    }
  })
}

export const COMMENT_EDITOR_ID = "comment-editor-id"
