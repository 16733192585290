import { ruid } from "../../lib/ruid"
import { supabase } from "../../lib/supabase"
import { getTenant } from "../../utils/getTenant"
import { optimizeImage } from "../../utils/optimizeImage"
import { ResizeImageOptions } from "../../utils/resizeImage"

export type UploadImageOptions = ResizeImageOptions & {
  folder?: string
  bucket?: string
  fileName?: string
  upsert?: boolean
}

export const uploadImage = async (
  image: string | Blob | File,
  options?: UploadImageOptions
) => {
  const {
    folder = getTenant(),
    bucket = "files",
    fileName = ruid() + ".png",
    upsert = false
  } = options || {}

  const optimizedImageBase64 = await optimizeImage(image, options)

  const { data } = await supabase.storage
    .from(bucket)
    .upload(`${folder}/${fileName}`, optimizedImageBase64, { upsert })

  if (data) {
    const { data: urlData } = supabase.storage
      .from(bucket)
      .getPublicUrl(data.path)

    return urlData.publicUrl + `?updated=${Date.now()}`
  }

  return null
}
