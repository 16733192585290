import { memo } from "react"
import { Box, Indicator } from "@mantine/core"
import { DsAvatar, DsAvatarProps, useDsImageProxy } from "@raisesistemas/ds"

import { isOnline } from "../../../../utils/isOnline"
import { useShowMemberDetail } from "../../../../hooks/useShowMemberDetail"

type AvatarProps = Omit<DsAvatarProps, "src"> & {
  avatar: string
  memberId?: string
  disableClick?: boolean
}

export const Avatar = memo((props: AvatarProps) => {
  const { memberId, disableClick = false, avatar, ...restProps } = props
  const { handleMemberDetail } = useShowMemberDetail({
    memberId,
    enabled: !disableClick
  })
  const { url } = useDsImageProxy(avatar as string, { width: 80, height: 80 })

  return (
    // TODO: adicionar o onClick no DsAvatar
    <Box style={{ cursor: "pointer" }} onClick={handleMemberDetail}>
      <Indicator
        size={14}
        offset={5}
        position="bottom-end"
        color="green"
        withBorder
        disabled={!isOnline(memberId)}
      >
        <DsAvatar {...restProps} src={avatar ? url : undefined} />
      </Indicator>
    </Box>
  )
})
