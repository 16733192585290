import { ScrollArea } from "@mantine/core"
import { createStyles, rem } from "@raisesistemas/ds"
import { DsAvatar, DsBox, DsButtonUnstyled } from "@raisesistemas/ds"
import { SuggestionKeyDownProps, SuggestionProps } from "@tiptap/suggestion"
import { forwardRef, useEffect, useImperativeHandle, useState } from "react"

import { SuggestionItem } from "./suggestions"

const useStyles = createStyles(
  ({ colorScheme, colors, shadows, primaryColor }) => ({
    container: {
      backgroundColor: colorScheme === "dark" ? colors.dark[9] : "#fff",
      borderRadius: rem(4),
      boxShadow: shadows.sm,
      overflow: "hidden"
    },
    viewport: {
      maxHeight: rem(300)
    },
    item: {
      display: "flex",
      alignItems: "center",
      gap: rem(12),
      padding: `${rem(12)} ${rem(16)}`,
      width: "100%",
      fontSize: rem(15),

      "&:hover": {
        backgroundColor: colors[primaryColor][5],
        color: "#fff"
      }
    },
    empty: {
      display: "block",
      padding: `${rem(6)} ${rem(16)}`,
      width: "100%",
      fontSize: rem(14)
    }
  })
)

export default forwardRef<unknown, SuggestionProps<SuggestionItem>>(
  (props, ref) => {
    const [selectedIndex, setSelectedIndex] = useState(0)

    const { classes } = useStyles()

    const selectItem = (index: number) => {
      const item = props.items[index]

      if (item) {
        props.command(item)
      }
    }

    const upHandler = () => {
      setSelectedIndex(
        (selectedIndex + props.items.length - 1) % props.items.length
      )
    }

    const downHandler = () => {
      setSelectedIndex((selectedIndex + 1) % props.items.length)
    }

    const enterHandler = () => {
      selectItem(selectedIndex)
    }

    useEffect(() => setSelectedIndex(0), [props.items])

    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }: SuggestionKeyDownProps) => {
        if (event.key === "ArrowUp") {
          upHandler()
          return true
        }

        if (event.key === "ArrowDown") {
          downHandler()
          return true
        }

        if (event.key === "Enter") {
          enterHandler()
          return true
        }

        return false
      }
    }))

    return (
      <DsBox className={classes.container}>
        {/* TODO: Ajustar o DsScrollArea */}
        <ScrollArea
          type="scroll"
          offsetScrollbars
          scrollbarSize={4}
          classNames={{ viewport: classes.viewport }}
        >
          {props.items.length ? (
            props.items.map((item: SuggestionItem, index: number) => (
              <DsButtonUnstyled
                className={classes.item}
                key={index}
                onClick={() => selectItem(index)}
              >
                <DsAvatar name={item.name} size="sm" src={item.avatar} />
                {item.name || item.id}
              </DsButtonUnstyled>
            ))
          ) : (
            <DsBox className={classes.empty}>Nenhum resultado</DsBox>
          )}
        </ScrollArea>
      </DsBox>
    )
  }
)
