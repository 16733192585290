import {
  DsBox,
  DsButtonPrimary,
  DsStackVertical,
  DsText,
  DsThemeIcon,
  createStyles
} from "@raisesistemas/ds"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { IconSearch } from "../../../icons"

export const NotificationEmpty = () => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  return (
    <DsStackVertical align="center" spacing="xl">
      <DsBox />
      <DsThemeIcon radius={60} size={60}>
        <IconSearch size={24} />
      </DsThemeIcon>

      <DsStackVertical spacing="xs">
        <DsText variant="body-1" weight="semi-bold" className={classes.text}>
          {t("notification.no_notification")}
        </DsText>
        <DsText
          variant="body-2"
          weight="regular"
          color="dimmed"
          className={classes.text}
        >
          {t("notification.explore_community")}
        </DsText>
      </DsStackVertical>
      <Link to="/channels/posts">
        <DsButtonPrimary>{t("root.see_posts")}</DsButtonPrimary>
      </Link>
    </DsStackVertical>
  )
}

const useStyles = createStyles({
  text: {
    textAlign: "center"
  }
})
