import { KINDS } from "../../lib/constants"
import { toggleRecord } from "../utils/toggle"

export const postSavedToggle = async (postId: string) => {
  const result = await toggleRecord({
    kind: KINDS.POST_SAVED,
    parentId: postId
  })

  return result
}
