export const getPageParams = ({
  totalItems,
  limit,
  page
}: {
  totalItems: number
  limit: number
  page: number
}) => {
  const totalPages = Math.ceil(totalItems / limit)
  const isLastPage = page >= totalPages

  return {
    isLastPage,
    totalPages
  }
}
