import { ROLES } from "../lib/constants"

export const getRoles = (role?: string) => {
  return {
    owner: role === ROLES.OWNER,
    member: role === ROLES.MEMBER,
    moderator: role === ROLES.MODERATOR,
    memberPro: role === ROLES.MEMBER_PRO
  }
}
