import { memo, ReactNode } from "react"
import { DsGroup, DsTextProps } from "@raisesistemas/ds"
import { TextEllipsis } from "../../../Typography/TextEllipsis"

type DescriptionProps = Pick<DsTextProps, "color" | "weight" | "variant"> & {
  text: string
  rightSection?: ReactNode
  maxWidth?: string
}

export const Description = memo((props: DescriptionProps) => {
  const {
    text,
    color,
    variant = "body-3",
    weight = "regular",
    rightSection,
    maxWidth = "100%"
  } = props
  return (
    <DsGroup align="center" spacing={2} noWrap>
      <TextEllipsis
        variant={variant}
        weight={weight}
        color={color}
        maxWidth={maxWidth}
      >
        {text}
      </TextEllipsis>
      {rightSection}
    </DsGroup>
  )
})
