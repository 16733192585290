import { useTranslation } from "react-i18next"
import { rem, DsBox, DsStackVertical, DsSpace } from "@raisesistemas/ds"

import { Sheet, SheetDefaultFooter } from "../../Sheet"
import { drawers } from "../../../state"
import { IconPlusCircle } from "../../../icons"
import { ButtonSubtle } from "../../ButtonSubtle"
import { capitalize } from "../../../utils/capitalize"
import { MemberInviteByEmailUserCard } from "./MemberInviteByEmailUserCard"
import { useAuthMutations } from "../../../hooks/mutations/useAuthMutations"
import {
  useMemberInviteForm,
  MemberInviteFormProvider,
  Member
} from "./member-invite-form-context"
import { memberInviteSchema } from "../../../validations/memberInviteSchema"
import { useSetParams } from "../../../hooks/use-set-params"

export const MemberInviteByEmailDrawer = () => {
  const { t } = useTranslation()
  const { sendInvitations } = useAuthMutations()
  const { isOpen, close } = drawers.member.inviteByEmail
  const { setParams } = useSetParams()

  const createBlankMemberField = (): Member => ({ name: "", email: "" })

  const form = useMemberInviteForm({
    initialValues: {
      members: [createBlankMemberField()]
    },
    validate: memberInviteSchema(t)
  })

  const handleAddMembers = form.onSubmit(formValues => {
    sendInvitations.mutate(formValues, {
      onSuccess: () => {
        close()
        drawers.member.inviteSelector.close()
        setParams({ filter: "invitations" })
      }
    })
  })

  const addBlankMemberFields = () =>
    form.insertListItem("members", createBlankMemberField())

  const hasMultipleMembers = form.values.members.length > 1

  const footer = (
    <SheetDefaultFooter
      isLoading={sendInvitations.isPending}
      onClose={close}
      onSubmit={handleAddMembers}
      submitText={capitalize(t("common.send", { type: t("root.invitations") }))}
    />
  )

  return (
    <Sheet
      title={t("drawer.invite_members.title")}
      opened={isOpen}
      onClose={close}
      footer={footer}
    >
      <MemberInviteFormProvider form={form}>
        <DsBox style={{ paddingBottom: rem(80) }}>
          <form>
            <DsStackVertical>
              {form.values.members.map((_, index) => (
                <MemberInviteByEmailUserCard
                  key={index}
                  index={index}
                  showRemoveBtn={hasMultipleMembers}
                />
              ))}
            </DsStackVertical>
            <DsSpace height={24} />
            <ButtonSubtle
              fullWidth
              rightIcon={<IconPlusCircle />}
              onClick={addBlankMemberFields}
            >
              {t("root.add")}
            </ButtonSubtle>
          </form>
        </DsBox>
      </MemberInviteFormProvider>
    </Sheet>
  )
}
