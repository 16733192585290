import { ReactNode, createContext } from "react"
import { useUser } from "../hooks/useUser"
import { useQueryClient } from "@tanstack/react-query"
import { useWebsocket } from "../hooks/useWebsocket"
import { queries } from "../queries"
import { Direct } from "../api/direct/direct"

type WebsocketContextProps = {}

export const WebsocketContext = createContext<WebsocketContextProps>(
  {} as WebsocketContextProps
)

type WebsocketProviderProps = {
  children: ReactNode
}
export const WebsocketProvider = (props: WebsocketProviderProps) => {
  const { children } = props

  const { user_uuid } = useUser()
  const queryClient = useQueryClient()

  const isCurrentChat = (compareId: string) => {
    const query = new URLSearchParams(window.location.search)
    const memberId = query.get("memberId")
    const hasChatOpen = !!memberId

    return hasChatOpen && compareId == memberId
  }

  const updateUnreadCount = (fromUserId: string, count: number) => {
    if (!fromUserId) return

    queryClient.invalidateQueries({
      queryKey: queries.direct.listGroup().queryKey
    })

    if (isCurrentChat(fromUserId)) return count
    return count + 1
  }

  useWebsocket({
    url: Direct.endpoint.websocket.unreadMessage(user_uuid),
    onRecieveData(payload) {
      queryClient.setQueryData(
        queries.direct.count().queryKey,
        (count: number) => updateUnreadCount(payload.fromUserId, count)
      )
    }
  })

  return (
    <WebsocketContext.Provider value={{}}>{children}</WebsocketContext.Provider>
  )
}
