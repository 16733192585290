import { useForm } from "@mantine/form"

import { bankSchema } from "../../../validations/bankSchema"
import { useTranslation } from "react-i18next"

export const useBankForm = () => {
  const { t } = useTranslation()

  return useForm({
    initialValues: {
      name: "",
      bank_account_type: "",
      bank: "",
      document: "",
      bank_ag: "",
      bank_cc: ""
    },
    validate: bankSchema(t)
  })
}
