import { useTranslation } from "react-i18next"

import {
  DsBox,
  DsSpace,
  DsStackVertical,
  DsTextInputBase,
  toast
} from "@raisesistemas/ds"
import { EmojiPicker } from "../EmojiPicker"
import { ChannelFooter } from "./ChannelFooter"
import { Sheet } from "../Sheet"
import { ChannelCanMemberWrite } from "./ChannelCanMemberWrite"
import { ChannelAccessSelect } from "./ChannelAccessSelect"
import { drawers } from "../../state"
import { useForm } from "@mantine/form"
import { useChannelMutations } from "../../hooks/mutations/useChannelMutations"
import { useCharactersRemaining } from "../../hooks/useCharactersRemaining"
import { channelCreateSchema } from "../../validations/channelCreateSchema"
import { useChannelQueries } from "../../hooks/queries/useChannelQueries"
import { ChannelEditPayload } from "../../api/channel/channelEdit"
import { useEffect } from "react"

export type ChannelEditDrawerProps = {
  channelId?: string
}

const MAX_TITLE_CHARACTERS = 24

export const ChannelEditDrawer = () => {
  const { isOpen, close, props } = drawers.channel.edit
  const { channelId } = props
  const { t } = useTranslation()

  const channelQueries = useChannelQueries()
  const { channel } = channelQueries.byId({ id: channelId })
  const ChannelMutations = useChannelMutations()

  const channelDataToForm = (): ChannelEditPayload => ({
    title: channel?.data.title || "",
    emoji: channel?.data.emoji || "",
    id: channel?.id || "",
    locked: channel?.data.locked || false,
    can_member_write: channel?.can_member_write || false
  })

  const form = useForm<ChannelEditPayload>({
    initialValues: channelDataToForm(),
    validate: channelCreateSchema(t)
  })

  const { characters } = useCharactersRemaining(
    MAX_TITLE_CHARACTERS,
    form.values.title.length
  )

  const onSuccess = () => {
    toast.success({ message: t("validation.edited_success") })
    close()
  }

  const handleSubmit = () => {
    form.onSubmit(values => {
      ChannelMutations.edit.mutate(values, { onSuccess })
    })()
  }

  useEffect(() => {
    if (channel) form.setValues(channelDataToForm())
  }, [channel])

  return (
    <Sheet title={t("root.edit")} opened={isOpen} onClose={close}>
      <DsBox>
        <DsStackVertical>
          <DsTextInputBase
            withAsterisk
            label={t("input.channel.create.label")}
            placeholder={t("input.channel.create.placeholder")}
            maxLength={MAX_TITLE_CHARACTERS}
            description={characters}
            inputWrapperOrder={["label", "input", "description", "error"]}
            {...form.getInputProps("title")}
          />

          <EmojiPicker
            value={form.values.emoji}
            onSelect={value => form.setFieldValue("emoji", value)}
            error={form.errors?.emoji ? String(form.errors?.emoji) : ""}
            required
          />

          <ChannelAccessSelect
            locked={Boolean(form.values.locked)}
            onSelectPrivate={() => form.setFieldValue("locked", true)}
            onSelectPublic={() => form.setFieldValue("locked", false)}
          />

          <DsSpace height={8} />

          <ChannelCanMemberWrite {...form.getInputProps("can_member_write")} />

          <DsSpace height={8} />

          <ChannelFooter
            isEdit
            onClose={close}
            onSubmit={handleSubmit}
            isLoading={ChannelMutations.edit.isPending}
          />
        </DsStackVertical>
      </DsBox>
    </Sheet>
  )
}
