import TiptapMention from "@tiptap/extension-mention"
import { ReactNodeViewRenderer, mergeAttributes } from "@tiptap/react"
import { MentionButton } from "./MentionButton"

export const Mention = TiptapMention.extend({
  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: element => element.getAttribute("data-id"),
        renderHTML: attributes => {
          if (!attributes.id) {
            return {}
          }

          return {
            "data-id": attributes.id
          }
        }
      },

      name: {
        default: null,
        parseHTML: element => element.getAttribute("data-name"),
        renderHTML: attributes => {
          if (!attributes.name) {
            return {}
          }

          return {
            "data-name": attributes.name
          }
        }
      },

      avatar: {
        default: null,
        parseHTML: element => element.getAttribute("data-avatar"),
        renderHTML: attributes => {
          if (!attributes.avatar) {
            return {}
          }

          return {
            "data-avatar": attributes.avatar
          }
        }
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: `span[data-type="${this.name}"]`
      }
    ]
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      "span",
      mergeAttributes(
        { "data-type": this.name },
        this.options.HTMLAttributes,
        HTMLAttributes
      ),
      this.options.renderLabel({
        options: this.options,
        node
      })
    ]
  },

  renderText({ node }) {
    return `@${node.attrs.name ?? node.attrs.id}`
  },

  addNodeView() {
    return ReactNodeViewRenderer(MentionButton)
  }
})
