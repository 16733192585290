import { MS_ACTIVITIES_URL } from "../../env"
import { buildURL } from "../../utils/buildUrl"
import { fetchAPI } from "../../utils/fetchAPI"
import { getTenant } from "../../utils/getTenant"
import { getUserId } from "../../utils/getUserId"

export type ResponseData = {
  count: number
}

export const activityUnreadCountByType = async (type: string) => {
  const tenant = getTenant()
  const user_id = await getUserId()

  const queryParams = {
    tenant,
    user_id,
    type
  }

  const url = buildURL(
    `${MS_ACTIVITIES_URL}/activities/count/search`,
    queryParams
  )
  const data = await fetchAPI<ResponseData>(url)
  return data
}
