import {
  DsBadge,
  DsBox,
  DsButtonPrimary,
  DsButtonSecondary,
  DsFlex,
  DsImage,
  DsMediaQuery,
  DsSpace,
  DsStackVertical,
  DsText,
  useDsTheme
} from "@raisesistemas/ds"
import format from "date-fns/format"
import ptBR from "date-fns/locale/pt-BR"
import enUS from "date-fns/locale/en-US"
import { AspectRatio } from "@mantine/core"
import { useTranslation } from "react-i18next"

import { EventType } from "../../types/event"
import { PostContent } from "../post/PostItem/PostContent"
import { Sheet } from "../Sheet"
import {
  IconTimeOutline,
  IconCalendarOutline,
  IconLocationOutline
} from "../../icons"
import { isEqual } from "date-fns"
import { useRequestAccessModal } from "../Channel/useRequestAccessModal"
import { useUserPermissions } from "../../hooks/permissions/useUserPermissions"
import { SpaceVerticalResponsive } from "../common/SpaceVerticalResponsive"
import { drawers } from "../../state"
import { convertSpeakersToString } from "./helpers"

export type EventViewDrawerProps = {
  event: EventType
}

export const EventViewDrawer = () => {
  const { isOpen, close, props } = drawers.event.detail
  const { event } = props
  const { t, i18n } = useTranslation()
  const { can } = useUserPermissions()
  const { colors, colorScheme } = useDsTheme()
  const { requestAccessModal } = useRequestAccessModal()

  const languageMapper: Record<string, Locale> = { ptBR, enUS }

  const formatDate = (date: string) => {
    return format(new Date(date), "dd MMM yyyy", {
      locale: languageMapper[i18n.language]
    })
  }

  const canSeeEvent = can("read", "event", {
    available_to: event.data.available_to
  })

  const color = colors.dark[colorScheme === "dark" ? 0 : 9]

  const oneDayEvent = isEqual(
    new Date(event?.data.start_date),
    new Date(event?.data.end_date)
  )

  const handleAccessEvent = () => {
    if (!canSeeEvent) return requestAccessModal("event")
    window.open(event?.data.link, "_blank")
  }

  const footer = (
    <DsMediaQuery
      largerThan="md"
      styles={{
        flexDirection: "row-reverse",
        justifyContent: "end"
      }}
    >
      <DsStackVertical spacing="sm">
        <DsButtonPrimary onClick={handleAccessEvent}>
          {t("common.access", { type: t("root.event").toLowerCase() })}
        </DsButtonPrimary>
        <DsButtonSecondary onClick={close}>{t("root.back")}</DsButtonSecondary>
      </DsStackVertical>
    </DsMediaQuery>
  )

  if (!event) return null

  return (
    <Sheet
      title={event.data.name}
      opened={isOpen}
      onClose={close}
      footer={footer}
    >
      <AspectRatio ratio={840 / 300}>
        <DsImage
          src={event.data.banner}
          styles={{
            figure: { width: "100%" }
          }}
          width="auto"
          height="auto"
        />
      </AspectRatio>

      <DsSpace height={24} />

      {event.data.speaker && event.data.speaker.length > 0 && (
        <>
          <DsText variant="body-1" weight="medium">
            {t("root.speaker")}: {convertSpeakersToString(event.data.speaker)}
          </DsText>

          <DsSpace height={8} />
        </>
      )}

      <DsBox>
        <DsFlex gap={8}>
          <IconCalendarOutline />
          <DsText variant="body-2" weight="regular" color={color}>
            {formatDate(event.data.start_date)}
            {!oneDayEvent && ` • ${formatDate(event.data.end_date)}`}
          </DsText>
        </DsFlex>

        <DsSpace height={4} />

        <DsFlex gap={8}>
          <IconTimeOutline />

          <DsText variant="body-2" weight="regular" color={color}>
            {event.data.start_hour} - {event.data.end_hour}
          </DsText>
        </DsFlex>

        <DsSpace height={4} />

        {event.data.local && (
          <DsFlex gap={8}>
            <IconLocationOutline />
            <DsText variant="body-2" weight="regular" color={color}>
              {event.data.local}
            </DsText>
          </DsFlex>
        )}

        <DsSpace height={8} />

        <DsFlex gap={8}>
          <DsBadge>
            {event.data.available_to === "all"
              ? t("root.for_all")
              : t("root.members") + " " + "PRO"}
          </DsBadge>
          <DsBadge>
            {event.data.type === "online" ? "Online" : t("root.in_person")}
          </DsBadge>
        </DsFlex>
      </DsBox>

      <DsSpace height={24} />

      <PostContent content={event.data.content} />

      <SpaceVerticalResponsive largerThan="md" base={0} size={100} />
    </Sheet>
  )
}
