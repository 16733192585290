import { DropdownItensProps, DsBox } from "@raisesistemas/ds"
import { Fragment, memo } from "react"
import { IconLinkOutline, IconLogout, IconSettings } from "../../icons"
import { getTenant } from "../../utils/getTenant"
import { navigateToSpace } from "../../utils/navigateToSpace"
import { modals } from "@mantine/modals"
import { ModalSignOut } from "../ModalSignOut"
import { IconSelector } from "@tabler/icons-react"
import { MemberInfo } from "../Member/MemberInfoV2"
import { Menu } from "@mantine/core"

import { useStyles } from "./MenuUser.style"
import { useAuth } from "../../hooks/useAuth"
import { useTranslation } from "react-i18next"
import { useThemeSwitchMenu } from "../../hooks/theme/useThemeSwitchMenu"
import { useLanguageSwitchMenu } from "../../hooks/useLanguageSwitchMenu"
import { useUser } from "../../hooks/useUser"
import { MenuUserItem } from "./MenuUserItem"

type MenuItem = DropdownItensProps & { hide?: boolean; path?: string }

export const MenuUser = memo(() => {
  const { user } = useAuth()
  const { t } = useTranslation()
  const { roles } = useUser()
  const { classes } = useStyles()

  const themeMenu = useThemeSwitchMenu()
  const languagesMenu = useLanguageSwitchMenu()

  const handleOpenSignOutModal = () => {
    modals.open({
      title: t("root.confirm_sign_out"),
      centered: true,
      size: 500,
      xOffset: 16,
      children: <ModalSignOut handleCancel={modals.closeAll} />
    })
  }

  const menuItens: MenuItem[] = [
    ...languagesMenu,
    {
      name: t("root.my_public_page"),
      icon: <IconLinkOutline size={14} />,
      label: t("root.community"),
      withDivider: true,
      hide: !roles.owner,
      onClick: () => {
        navigateToSpace({
          path: "/?public=true",
          replace: false,
          space: getTenant()
        })
      }
    },
    themeMenu,
    {
      name: t("root.settings"),
      icon: <IconSettings size={14} />,
      label: t("root.settings"),
      withDivider: true,
      path: "/settings/profile"
    },
    {
      name: t("auth.sign_out"),
      icon: <IconLogout size={14} />,
      withDivider: false,
      color: "red",
      onClick: handleOpenSignOutModal
    }
  ].filter((menuItem: MenuItem) => !menuItem?.hide)

  return (
    <Menu width={280} shadow="md">
      <Menu.Target>
        <DsBox className={classes.user}>
          <MemberInfo>
            <MemberInfo.Avatar
              disableClick
              avatar={user!.data.avatar}
              memberId={user!.user_uuid}
              name={user!.data.name}
            />
            <MemberInfo.Name
              disableClick
              memberId={user!.user_uuid}
              name={user!.data.name}
              weight="regular"
              variant="body-2"
            />
            <IconSelector size={14} />
          </MemberInfo>
        </DsBox>
      </Menu.Target>

      <Menu.Dropdown>
        {menuItens.map(({ label, withDivider, name, ...restProps }) => (
          <Fragment key={`menu-user-item-${name}`}>
            {label && <Menu.Label>{label}</Menu.Label>}
            <MenuUserItem name={name} {...restProps} />
            {withDivider && <Menu.Divider style={{ margin: 0 }} />}
          </Fragment>
        ))}
      </Menu.Dropdown>
    </Menu>
  )
})
