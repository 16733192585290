import { forwardRef, useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  MultiSelect,
  MultiSelectProps,
  SelectItem,
  SelectItemProps
} from "@mantine/core"
import { DsAvatar, DsFlex, DsText, useDsImageProxy } from "@raisesistemas/ds"
import { useDebounce } from "../../hooks/useDebounce"
import { memberSearch } from "../../api/member/memberSearch"

type ItemProps = SelectItemProps & { avatar?: string }

const Item = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, value, avatar, ...others }, ref) => {
    const { url } = useDsImageProxy(avatar ?? "", { width: 24, height: 24 })

    return (
      <div ref={ref} {...others}>
        <DsFlex align="center" gap={16}>
          <DsAvatar
            src={avatar ? url : undefined}
            size={24}
            name={value ?? ""}
          />

          <DsText variant="body-3" weight="medium">
            {label}
          </DsText>
        </DsFlex>
      </div>
    )
  }
)

export const InputSelectSpeaker = (props: Omit<MultiSelectProps, "data">) => {
  const { value } = props
  const { t } = useTranslation()
  const [speakers, setSpeakers] = useState<SelectItemProps[]>([])
  const [searchValue, onSearchChange] = useState("")
  const debouncedSearch = useDebounce(searchValue, 200)

  const getMembers = useCallback(async () => {
    try {
      const members = await memberSearch({ query: debouncedSearch })

      const suggestions = members.map(({ data, user_uuid }) => {
        const name = data.name || "Desconhecido"

        return {
          value: `${user_uuid}:${name}`,
          label: name,
          avatar: data.avatar
        }
      })

      setSpeakers(suggestions)
    } catch (error) {
      console.error(error)
    }
  }, [debouncedSearch])

  const speakersCb = (v: string) => ({ value: v, label: v.split(":").pop() })
  const _speakers = value ? value.map(speakersCb) : []
  const suggestions = speakers.concat(_speakers)

  useEffect(() => {
    getMembers()
  }, [getMembers])

  return (
    <MultiSelect
      {...props}
      data={suggestions as SelectItem[]}
      label={t("input.event.speaker.label")}
      placeholder={t("input.event.speaker.placeholder")}
      itemComponent={Item}
      searchable
      searchValue={searchValue}
      onSearchChange={onSearchChange}
      creatable
      getCreateLabel={query => `+ ${query}`}
      onCreate={query => {
        const item = { value: `${crypto.randomUUID()}:${query}`, label: query }
        return item
      }}
    />
  )
}
