import { createQueryKeys } from "@lukemorales/query-key-factory"
import { spaceConfigGet } from "../api/space/spaceConfigGet"
import { getTenant } from "../utils/getTenant"

export const spaceQueries = createQueryKeys("space", {
  info: () => ({
    queryKey: [getTenant()]
  }),
  config: () => ({
    queryKey: [getTenant()],
    queryFn: spaceConfigGet
  })
})
