import { getUserId } from "../../utils/getUserId"
import { dislike } from "./dislike"
import { like } from "./like"

type LikeTogglePayload = {
  kind: string
  parentId: string
  liked: boolean
}

export const likeToggle = async (payload: LikeTogglePayload) => {
  const { kind, parentId, liked } = payload

  const userId = await getUserId()

  const props = { kind, parentId, userId }

  if (liked) return await dislike({ kind, parentId, userId })
  return await like(props)
}
