import { Session } from "@supabase/supabase-js"
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useState
} from "react"

import { memberById } from "../api/member/memberById"
import { memberCreate } from "../api/member/memberCreate"
import { tenantCreatorByUserId } from "../api/tenant/tenantCreatorByUserId"
import { RN } from "../env"
import { STORAGE_KEYS } from "../lib/constants"
import { supabase } from "../lib/supabase"
import { MemberType } from "../types/member"
import { getTenant } from "../utils/getTenant"
import { setUserId } from "../utils/setUserId"
import { supabaseSessionToMember } from "../utils/supabaseSessionToMember"
import { memberActivatedAt } from "../api/member/memberActivatedAt"
import { isHostApp } from "../utils/isHostApp"
import { Loader } from "../components/Loader"

type User = MemberType | null | undefined

type AuthContextData = {
  signed: boolean
  isLoading: boolean
  user?: User
  setUser: Dispatch<SetStateAction<User>>
}

export type AuthProviderProps = {
  mockedUser?: User
}

export const AuthContext = createContext({} as AuthContextData)

export const AuthProvider = ({
  children,
  mockedUser = null
}: PropsWithChildren<AuthProviderProps>) => {
  const [user, setUser] = useState<User>(mockedUser)
  const [session, setSession] = useState<Session | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [loadedSession, setLoadedSession] = useState(false)

  /**
   * Função responsável por manipular os dados da sessão
   * e criar um novo membro no banco de dados. Uma exceção
   * é lançada se ocorrer um erro.
   */
  const handleCreateMember = async (session: Session) => {
    const tenant = getTenant()
    const options = { tenant, role: "member" }
    const memberPayload = supabaseSessionToMember(session, options)
    const { error } = await memberCreate(memberPayload)
    if (error) throw error
    return memberPayload
  }

  /**
   * Função responsável por verificar a existência de um usuário
   * no banco de dados, caso não exista, é feita a inserção.
   */
  const handleFindMemberOrCreate = async (session: Session) => {
    const { data } = await memberById(session.user.id)
    if (data) return data

    return await handleCreateMember(session)
  }

  /**
   * Função responsável por logar na aplicação, definir
   * o estado e persistir os dados necessários no navegador
   */
  const handleSignInApp = async (user: User) => {
    if (!user) return
    setUser(user)
    setUserId(user.user_uuid)
    localStorage.setItem(STORAGE_KEYS.TENANT_ID, user.tenant)
    await memberActivatedAt(user)
  }

  /**
   * Função responsável por efetuar o logar na aplicação como creator.
   * Se o creator não tiver uma comunidade, o tenant será definido
   * como uma string vazia.
   */
  const handleCreatorSignIn = async (session: Session) => {
    const userId = session.user.id

    const { member } = await tenantCreatorByUserId({ userId })

    if (!member) {
      const fakerMember = supabaseSessionToMember(session, {
        tenant: "",
        role: "owner"
      })
      return await handleSignInApp(fakerMember)
    }

    return await handleSignInApp(member)
  }

  /**
   * Função responsável por gerenciar todas as
   * regras de negócio vinculadas ao login
   */
  const handleSignIn = useCallback(async (session: Session | null) => {
    if (!session) {
      if (RN) RN.signOut()
      return
    }

    if (isHostApp()) return handleCreatorSignIn(session)

    try {
      const member = await handleFindMemberOrCreate(session)
      await handleSignInApp(member)

      if (RN) {
        RN.setAuthData(
          { token: session.access_token },
          { id: member.user_uuid, ...member.data }
        )
      }
    } catch (error) {}
  }, [])

  useEffect(() => {
    if (mockedUser) {
      setLoadedSession(true)
      setIsLoading(false)
    }

    const { data } = supabase.auth.onAuthStateChange(async (event, session) => {
      const authEvents = ["SIGNED_IN", "TOKEN_REFRESHED", "INITIAL_SESSION"]

      if (authEvents.includes(event)) {
        setSession(session)
        setLoadedSession(true)
      }

      if (event === "SIGNED_OUT") {
        setUser(null)
        if (RN) RN.signOut()
      }
    })

    return () => data.subscription.unsubscribe()
  }, [])

  useEffect(() => {
    ;(async () => {
      if (loadedSession) {
        await handleSignIn(session)
        setIsLoading(false)
      }
    })()
  }, [session, loadedSession])

  const values: AuthContextData = {
    user,
    setUser,
    isLoading,
    signed: !!user
  }

  if (!mockedUser && (isLoading || !loadedSession))
    return <Loader mih="100vh" />

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}
