import { useCallback, useEffect } from "react"
import { getHost } from "../../utils/getHost"
import {
  GOOGLE_SIGNING_CLIENT_ID,
  GOOGLE_SIGNING_CLIENT_SECRET
} from "../../env"

export const PageOAuthRedirect = () => {
  let baseUrl = window.location.host
  baseUrl = baseUrl.replace(`${getHost()}.`, "")

  let redirectUri = baseUrl
  if (!baseUrl.includes("localhost")) redirectUri = `app.${baseUrl}`

  const protocol = window.location.protocol

  const googleCredentials = {
    client_id: GOOGLE_SIGNING_CLIENT_ID,
    client_secret: GOOGLE_SIGNING_CLIENT_SECRET,
    redirect_uri: `${protocol}//${redirectUri}/oauth/callback`,
    grant_type: "authorization_code"
  }

  const getOAuthTokens = async (credential: string) => {
    const response = await fetch("https://www.googleapis.com/oauth2/v4/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: new URLSearchParams({
        code: credential,
        ...googleCredentials
      })
    })

    return await response.json()
  }

  const handleGoogleSigning = () => {
    const state = JSON.stringify({ tenant: getUrlParams().get("tenant") })

    const baseUrl =
      "https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount"

    const params = new URLSearchParams({
      state: state,
      gsiwebsdk: "3",
      client_id: googleCredentials.client_id,
      scope: "openid profile email",
      redirect_uri: googleCredentials.redirect_uri,
      prompt: "consent",
      access_type: "offline",
      response_type: "code",
      enable_granular_consent: "true",
      service: "lso",
      o2v: "2",
      theme: "glif",
      flowName: "GeneralOAuthFlow"
    })

    window.location.href = `${baseUrl}?${params.toString()}`
  }

  const getUrlParams = () => {
    return new URLSearchParams(window.location.search)
  }

  const handleCallback = useCallback(async () => {
    let baseRedirectUrl = window.location.host
    baseRedirectUrl = baseRedirectUrl.replace(`${getHost()}.`, "")
    const protocol = window.location.protocol

    const tokens = await getOAuthTokens(getUrlParams().get("code") ?? "")
    const { tenant } = JSON.parse(getUrlParams().get("state") ?? "")

    if (tokens.id_token)
      window.location.href = `${protocol}//${tenant}.${baseUrl}/oauth/google/callback?token=${tokens.id_token}`
    else window.location.href = `${protocol}//${tenant}.${baseUrl}/sign-in`
  }, [getOAuthTokens, baseUrl])

  useEffect(() => {
    if (!getUrlParams().get("code")) return handleGoogleSigning()

    handleCallback()
  }, [handleGoogleSigning, handleCallback])

  return null
}
