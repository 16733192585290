import { DsBox, DsGrid, DsGridCol, createStyles } from "@raisesistemas/ds"
import { useId } from "react"

const useStyles = createStyles(({ primaryColor, colors }) => ({
  indicator: {
    width: "100%",
    height: 3,
    backgroundColor: colors.gray[2]
  },
  indicatorActive: {
    backgroundColor: colors[primaryColor][4]
  }
}))

type StepperIndicatorProps = {
  active: number
  stepsCount: number
  onNavigateTo: (index: number) => void
  shouldNavigate?: boolean
}

export const StepperIndicator = (props: StepperIndicatorProps) => {
  const { active, stepsCount, onNavigateTo, shouldNavigate } = props

  const { classes, cx } = useStyles()
  const uniqueId = useId()

  const handleNavigation = (index: number) => {
    if (!shouldNavigate) return
    onNavigateTo(index)
  }

  return (
    <DsGrid columns={stepsCount}>
      {Array.from({ length: stepsCount }).map((_, index) => (
        <DsGridCol
          key={`${uniqueId}-${index}`}
          span={1}
          onClick={() => handleNavigation(index)}
        >
          <DsBox
            className={cx(classes.indicator, {
              [classes.indicatorActive]: active === index
            })}
          />
        </DsGridCol>
      ))}
    </DsGrid>
  )
}
