import { toast } from "@raisesistemas/ds"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { postDelete } from "../../api/post/postDelete"
import { queries } from "../../queries"
import { useStandardToast } from "../useStandardToast"

export const usePostDeleteMutation = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { cantFinalize } = useStandardToast()

  return useMutation({
    mutationFn: postDelete,
    onSuccess: () => {
      toast.success({
        message: t("validation.deleted_success")
      })

      queryClient.invalidateQueries({ queryKey: queries.posts._def })
    },
    onError: () => {
      cantFinalize()
    }
  })
}
