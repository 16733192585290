export const getBankAccountMaskMinLength = (
  bank_account_mask?: string | Array<{ mask: string }>
) => {
  if (!bank_account_mask) return 1

  if (Array.isArray(bank_account_mask)) {
    const masks = bank_account_mask.map(({ mask }) => mask.length)

    return Math.min(...masks)
  }

  return bank_account_mask.length
}
