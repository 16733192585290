import { RouteObject } from "react-router-dom"
import { PublicRoutesRules } from "./PublicRoutesRules"
import { PageOAuthRedirect } from "../../pages/oauth/PageOAuthRedirect"
import { PageOAuthCallback } from "../../pages/oauth/PageOAuthCallback"
import { PageOAuth } from "../../pages/oauth/PageOAuth"

export const publicRoutes: RouteObject = {
  children: [
    {
      path: "/oauth/callback",
      element: <PageOAuthRedirect />
    },
    {
      path: "/oauth/:provider/callback",
      element: <PageOAuthCallback />
    },
    {
      path: "/oauth",
      element: <PageOAuth />
    },
    {
      path: "/tenant/:tenant",
      lazy: async () => {
        const { PageTenant } = await import("../../pages/PageTenant")
        return { Component: PageTenant }
      }
    },
    {
      path: "/creator",
      lazy: async () => {
        const { PageHomeCreator } = await import(
          "../../pages/home/PageHomeCreator"
        )
        return { Component: PageHomeCreator }
      }
    },
    {
      element: <PublicRoutesRules />,
      children: [
        {
          path: "/",
          lazy: async () => {
            const { PageHome } = await import("../../pages/home/PageHome")
            return { Component: PageHome }
          }
        },
        {
          path: "/sign-in",
          lazy: async () => {
            const { PageSignIn } = await import("../../pages/sign/PageSignIn")
            return { Component: PageSignIn }
          }
        },
        {
          path: "/sign-up",
          lazy: async () => {
            const { PageSignUp } = await import("../../pages/sign/PageSignUp")
            return { Component: PageSignUp }
          }
        },
        {
          path: "/sign-up/creator",
          lazy: async () => {
            const { PageSignUpCreator } = await import(
              "../../pages/sign/PageSignUpCreator"
            )
            return { Component: PageSignUpCreator }
          }
        },
        {
          path: "/sign-with-pin",
          lazy: async () => {
            const { PageSignWithPin } = await import(
              "../../pages/sign/PageSignWithPin"
            )
            return { Component: PageSignWithPin }
          }
        }
      ]
    }
  ]
}
