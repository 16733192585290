import { useTranslation } from "react-i18next"
import { UseFormReturnType } from "@mantine/form"

import {
  rem,
  DsBox,
  DsSpace,
  DsText,
  DsSelectSearch,
  DsTextInputBase,
  DsStackVertical,
  DsTextInputDocument,
  DsTextInputMask
} from "@raisesistemas/ds"

import { getBankByName } from "../../../utils/getBankByName"
import { SelectBank } from "../../../components/common/SelectBank"
import { useMemo } from "react"

type BankType = {
  name: string
  bank_account_type: string
  bank: string
  document: string
  bank_ag: string
  bank_cc: string
}

type BankFormProps = {
  form: UseFormReturnType<BankType>
}

export const BankForm = (props: BankFormProps) => {
  const { form } = props
  const { t } = useTranslation()

  const bank = useMemo(
    () => getBankByName(form.values.bank),
    [form.values.bank]
  )

  return (
    <DsBox sx={{ paddingBottom: rem(24), paddingTop: rem(24) }}>
      <DsText weight="medium" variant="body-1">
        {t("root.fill_in_your_account_details")}
      </DsText>

      <DsSpace height="sm" />

      <DsText weight="regular" color="dimmed" variant="body-3">
        {t("root.to_receive_payments_from_pro_member_plan")}
      </DsText>
      <DsSpace height="xl" />

      <DsStackVertical spacing="sm">
        <DsTextInputBase
          withAsterisk
          label={t("input.bank.account_holder.label")}
          placeholder="John Doe"
          {...form.getInputProps("name")}
        />
        <DsTextInputDocument
          withAsterisk
          label="CPF/CNPJ"
          onlyCNPJ
          onlyCPF
          {...form.getInputProps("document")}
        />
        <SelectBank
          withAsterisk
          placeholder={t("input.bank.bank.placeholder")}
          label={t("input.bank.bank.label")}
          {...form.getInputProps("bank")}
        />
        <DsSelectSearch
          withAsterisk
          label={t("input.bank.account_type.label")}
          placeholder={t("input.bank.account_type.placeholder")}
          data={[
            {
              value: "cc",
              label: t("drawer.bank.checking_account")
            },
            {
              value: "cp",
              label: t("drawer.bank.savings_account")
            }
          ]}
          {...form.getInputProps("bank_account_type")}
        />

        <DsTextInputMask
          withAsterisk
          completeMaskOnBlur
          label={t("input.bank.agency.label")}
          mask={bank?.branch_mask || "0000"}
          {...form.getInputProps("bank_ag")}
        />
        <DsTextInputMask
          withAsterisk
          completeMaskOnBlur
          label={t("input.bank.account.label")}
          mask={bank?.account_mask || "000000"}
          {...form.getInputProps("bank_cc")}
        />
      </DsStackVertical>
      <DsSpace height="xl" />
    </DsBox>
  )
}
