import { DsBox, DsContainer, createStyles, rem } from "@raisesistemas/ds"
import { PostHeader } from "./PostItem/PostHeader"
import { PostFooter } from "./PostItem/PostFooter"
import { PostComments } from "./PostItem/PostComments"
import { PostContent } from "./PostItem/PostContent"
import { PostThumbnail } from "./PostItem/PostThumbnail"
import { PostModel } from "../../types/post"
import { PostMenuOptions } from "./PostItem/PostMenuOptions"

import { useShowPost } from "./PostItem/useShowPost"

type PostViewProps = {
  post: PostModel
}

export const PostView = (props: PostViewProps) => {
  const { post } = props
  const { classes } = useStyles()
  const { handleShowPost } = useShowPost({ post })

  return (
    <DsBox className={classes.container} data-post-id={post.id}>
      <PostThumbnail thumbnail={post.data.thumbnail} />
      <DsContainer fluid className={classes.body}>
        <PostHeader
          postId={post.id}
          memberId={post.member.user_uuid}
          memberName={post.member.data.name}
          memberAvatar={post.member.data.avatar}
          rightSection={<PostMenuOptions post={post} hideAction={true} />}
        />
        <PostContent content={post.data.content} />
        <PostFooter
          postId={post.id}
          postCommentsCount={post.commentsCount}
          postLiked={post.liked}
          postLikesCount={post.likesCount}
          postTitle={post.data.title}
          postSaved={post.saved}
          handleShowPost={handleShowPost}
        />
        <PostComments postId={post.id} channelId={post.channel?.id} />
      </DsContainer>
    </DsBox>
  )
}

const useStyles = createStyles(({ colorScheme, colors }) => {
  const isDark = colorScheme === "dark"
  return {
    container: {
      overflow: "hidden",
      border: `1px solid ${isDark ? colors.dark[4] : colors.gray[2]}`,
      borderRadius: rem(8)
    },
    body: {
      paddingTop: rem(20),
      paddingBottom: rem(20),
      paddingLeft: rem(16),
      paddingRight: rem(16)
    }
  }
})
