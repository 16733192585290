import { createStyles } from "@raisesistemas/ds"

export const useStyles = createStyles(
  ({ spacing, colors, colorScheme, black }) => ({
    user: {
      display: "block",
      width: "100%",
      padding: spacing.md,
      color: colorScheme === "dark" ? colors.dark[0] : black,
      borderTop: `1px solid ${
        colorScheme === "dark" ? colors.dark[4] : colors.gray[3]
      }`,

      "&:hover": {
        backgroundColor:
          colorScheme === "dark" ? colors.dark[8] : colors.gray[0]
      }
    }
  })
)
