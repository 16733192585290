import { toast } from "@raisesistemas/ds"
import { useTranslation } from "react-i18next"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { queries } from "../../queries"
import { commentDelete } from "../../api/comment/commentDelete"
import { commentLike } from "../../api/comment/commentLike"
import { commentEdit } from "../../api/comment/commentEdit"

export const useCommentMutations = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const destroy = useMutation({
    mutationFn: commentDelete,
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: queries.posts._def
      })
      // TODO: verificar se o postId ou parentId vem nas variaveis
      // queryClient.invalidateQueries(queries.comments.list({ postId }))
    },
    onError() {
      toast.error({
        message: t("validation.comment_delete_fail")
      })
    }
  })

  const edit = useMutation({
    mutationFn: commentEdit,
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: queries.posts.list._def })
      // queryClient.invalidateQueries(
      //   queries.comments.list({ postId: postId })
      // )
    },
    onError() {
      toast.error({
        message: t("validation.comment_edit_fail")
      })
    }
  })

  const like = useMutation({
    mutationFn: commentLike
  })

  return {
    edit,
    destroy,
    like
  }
}
