import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { STORAGE_KEYS } from "../lib/constants"

export const useLanguage = () => {
  const { i18n } = useTranslation()

  useEffect(() => {
    const currentLanguage = localStorage.getItem(STORAGE_KEYS.LANGUAGE)

    if (!currentLanguage) return

    i18n.changeLanguage(JSON.parse(currentLanguage))
  }, [])

  const changeLanguage = (language: string) => {
    localStorage.setItem(STORAGE_KEYS.LANGUAGE, JSON.stringify(language))
    i18n.changeLanguage(language)
  }

  return { changeLanguage, language: i18n.language as "ptBR" | "enUS" }
}
