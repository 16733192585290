import { DsImage } from "@raisesistemas/ds"
import { useLanguage } from "./useLanguage"

export const languageOptions = [
  {
    name: "Português",
    value: "ptBR",
    flag: "https://cdn-icons-png.flaticon.com/128/206/206597.png"
  },
  {
    name: "English",
    value: "enUS",
    flag: "https://cdn-icons-png.flaticon.com/128/330/330459.png"
  }
]

export const useLanguageSwitchMenu = () => {
  const { changeLanguage } = useLanguage()

  return languageOptions.map(languageOption => ({
    name: languageOption.name,
    icon: (
      <DsImage
        width={20}
        height="auto"
        src={languageOption.flag}
        alt={languageOption.name}
      />
    ),
    withDivider: true,
    onClick: () => changeLanguage(languageOption.value)
  }))
}
