import { useNav } from "./useNav"

export const useLink = () => {
  const navigate = useNav()

  const linkTo = (path: string) => () => navigate(path)

  const linkRegister = (path: string) => ({
    onClick: () => navigate(path)
  })

  return {
    linkTo,
    linkRegister
  }
}
