import {
  DsButtonPrimary,
  DsButtonSecondary,
  DsCenter,
  DsFlex,
  DsModal,
  DsStackVertical,
  DsText,
  createStyles
} from "@raisesistemas/ds"
import { useTranslation } from "react-i18next"

import { WHATSAPP_LINK } from "../../lib/constants"

import {
  IconBolt,
  IconGift,
  IconPremiumRights,
  IconWhatsapp
} from "../../icons"
import { Personalization } from "../Illustrations/Personalization"
import { PlanChooseItem } from "../Plan/PlanChooseItem"

type ModalPriceProps = {
  opened: boolean
  onClose: () => void
}

const useStyles = createStyles(() => ({
  button: {
    width: "100%"
  }
}))

// TODO: verificar onde esse modal é chamado e verificar esses valores.
export function ModalPrice(props: ModalPriceProps) {
  const { t } = useTranslation()
  const { opened, onClose } = props
  const { classes } = useStyles()

  const plans = [
    {
      title: "Grátis",
      price: 0,
      frequency: "month",
      description: t("root.free_plan_description"),
      icon: <IconGift />,
      id: "1"
    },
    {
      title: "Pro Mensal",
      price: 149,
      frequency: "month",
      description: t("root.payed_plan_description"),
      icon: <IconPremiumRights />,
      id: "2"
    },
    {
      title: "Pro Anual",
      price: 1520,
      frequency: "year",
      description: t("root.payed_plan_description"),
      icon: <IconBolt />,
      id: "3"
    }
  ]

  const handleSubmit = () => {
    const message = `Olá! Gostaria de saber mais sobre os planos da Luminy.`
    window.open(`${WHATSAPP_LINK}?text=${encodeURIComponent(message)}}`)
    onClose()
  }

  return (
    <DsModal
      opened={opened}
      onClose={onClose}
      title={
        <DsText variant="body-1" weight="semi-bold">
          {t("root.choose_plan")}
        </DsText>
      }
      main={
        <>
          <DsText variant="body-2" weight="regular">
            {t("root.members_pro_access")}
          </DsText>

          <DsCenter>
            <Personalization />
          </DsCenter>

          <DsStackVertical>
            {plans.map(plan => (
              <PlanChooseItem key={`plan-${plan.id}`} data={plan} />
            ))}
          </DsStackVertical>
        </>
      }
      footer={
        <DsFlex justify="end" gap="md">
          <DsButtonSecondary onClick={onClose} className={classes.button}>
            {t("button.cancel")}
          </DsButtonSecondary>

          <DsButtonPrimary
            leftIcon={<IconWhatsapp size={16} />}
            onClick={handleSubmit}
            className={classes.button}
          >
            {t("root.contact_us")}
          </DsButtonPrimary>
        </DsFlex>
      }
    ></DsModal>
  )
}
