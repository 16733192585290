import { differenceInDays, format, Locale, parseISO } from "date-fns"
import { MemberType } from "../types/member"
import { t } from "i18next"
import { ROLES } from "../lib/constants"

export const memberPresenter = {
  forCardAtavar: (members?: MemberType[]) =>
    members?.map(member => ({
      name: member.data.name,
      avatar: member.data.avatar
    })) || [],

  forSelect: (members?: MemberType[]) =>
    members?.map(member => ({
      label: member.data.name,
      value: member.user_uuid
    })) || [],
  forSelectWithImage: (members?: MemberType[]) =>
    members?.map(member => ({
      label: member.data.name,
      value: member.user_uuid,
      description: member.data.email,
      image: member.data.avatar
    })) || [],
  tags: function (member: MemberType) {
    const date = parseISO(member.data.created_at)
    const today = new Date()
    const isNewUser = differenceInDays(today, date) < 7
    const isBlockedMember = member.role === "blocked_member"

    const colorByRole = {
      [ROLES.BLOCKED_MEMBER]: "red",
      [ROLES.MEMBER]: "violet",
      [ROLES.MEMBER_PRO]: "violet",
      [ROLES.MODERATOR]: "cyan",
      [ROLES.OWNER]: "blue"
    }

    const tags = [
      {
        name: t(`root.${member.role}`),
        color: colorByRole[member.role],
        size: "xs",
        isVisible: true
      },
      {
        name: t("root.new_user"),
        color: "teal",
        size: "xs",
        isVisible: isNewUser && !isBlockedMember
      }
    ]

    return tags.filter(
      tag => typeof tag.isVisible === "undefined" || tag.isVisible
    )
  },

  since: function (member: MemberType, locale: Locale) {
    const date = parseISO(member.data.created_at)

    return t("root.member_since", {
      day: format(date, "dd"),
      month: format(date, "MMMM", { locale }),
      year: format(date, "yyyy")
    })
  }
}
