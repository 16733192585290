import { Children } from "react"
import { DsBox, DsBoxProps } from "@raisesistemas/ds"
import { StepperControls } from "./StepperControls"
import { StepperIndicator } from "./StepperIndicator"

type StepperRootProps = Pick<DsBoxProps, "sx" | "className" | "style"> & {
  active: number
  onNavigateTo: (index: number) => void
  children: React.ReactNode
  shouldNavigate?: boolean
  onFinalize?: () => void
  isLoading?: boolean
}

export const StepperRoot = (props: StepperRootProps) => {
  const {
    active,
    onNavigateTo,
    children,
    shouldNavigate = false,
    onFinalize,
    isLoading,
    ...rest
  } = props

  const stepsCount = Children.count(children)

  return (
    <DsBox {...rest}>
      <StepperIndicator
        active={active}
        onNavigateTo={onNavigateTo}
        stepsCount={stepsCount}
        shouldNavigate={shouldNavigate}
      />

      {Children.map(children, (child, index) => {
        if (index === active) return child
      })}

      <StepperControls
        active={active}
        stepsCount={stepsCount}
        onNavigateTo={onNavigateTo}
        onFinalize={onFinalize}
        isLoading={isLoading}
      />
    </DsBox>
  )
}
