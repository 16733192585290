import { KINDS } from "../../lib/constants"
import { supabase } from "../../lib/supabase"
import { getTenant } from "../../utils/getTenant"
import { getUser } from "../auth/getUser"

type TopicEditPayload = {
  id: string
  title: string
}

export const TopicEdit = async (payload: TopicEditPayload) => {
  const {
    data: { user }
  } = await getUser()

  if (!user) return

  const { error } = await supabase
    .from("db")
    .update({
      data: payload
    })
    .eq("tenant", getTenant())
    .eq("kind", KINDS.TOPIC)
    .eq("id", payload.id)

  if (error) throw error
}
