import {
  DsGroup,
  DsStackVertical,
  DsText,
  createStyles
} from "@raisesistemas/ds"
import { MemberType } from "../../../types/member"
import { MemberTags } from "../MemberTags"
import { AvatarOnline } from "../../AvatarOnline"
import { useThemeColoring } from "../../../hooks/useThemeColoring"
import { useUser } from "../../../hooks/useUser"

export type MemberDetailHeaderProps = {
  member: MemberType
  isOnline: boolean
}

export const MemberDetailHeader = (props: MemberDetailHeaderProps) => {
  const { member, isOnline } = props
  const { classes } = useStyles()
  const { role } = useUser()
  const { colors, lightDark } = useThemeColoring()
  const { name, profession, email, phone, hide_email, avatar } = member.data

  return (
    <DsGroup style={{ width: "100%" }}>
      <AvatarOnline
        name={name}
        src={avatar}
        size={110}
        online={isOnline}
        onlineOptions={{
          size: 24,
          offset: 14
        }}
      />

      <DsStackVertical spacing={8} className={classes.info}>
        <DsText
          variant="body-1"
          weight="medium"
          color={lightDark("gray.9", "gray.0")}
        >
          {name}
        </DsText>
        <DsText variant="body-2" weight="regular" color={colors.gray[6]}>
          {profession}
        </DsText>
        {!hide_email && (
          <DsText variant="body-2" weight="regular" color={colors.gray[6]}>
            {email}
          </DsText>
        )}

        {role === "owner" && (
          <DsText variant="body-2" weight="regular" color={colors.gray[6]}>
            {phone}
          </DsText>
        )}

        <MemberTags member={member} />
      </DsStackVertical>
    </DsGroup>
  )
}

const useStyles = createStyles(() => ({
  info: {
    flex: 1
  }
}))
