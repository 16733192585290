import { KINDS } from "../../lib/constants"
import { supabase } from "../../lib/supabase"
import { ReplyType } from "../../types/comment"
import { getTenant } from "../../utils/getTenant"
import { getUserId } from "../../utils/getUserId"
import { createInfiniteService } from "../../utils/createInfiniteService"

export type ReplyListPayload = {
  commentId: string
}

export const replyList = createInfiniteService<ReplyListPayload, ReplyType>(
  async ({ commentId, rangeEnd, rangeStart, limit = 10 }) => {
    const userId = await getUserId()

    return supabase
      .from("db")
      .select(
        `
      id::text,
      data->content,
      user_uuid,
      member(data->name,data->avatar),
      likes:db!parent_id(id::text),
      likesCount:db!parent_id(count)
    `,
        { count: "exact", head: false }
      )
      .eq("tenant", getTenant())
      .eq("kind", KINDS.COMMENT_REPLY)
      .eq("likes.kind", KINDS.REPLY_LIKE)
      .eq("likesCount.kind", KINDS.REPLY_LIKE)
      .eq("likes.user_uuid", userId)
      .eq("parent_id", commentId)
      .limit(limit)
      .order("id")
      .range(rangeStart, rangeEnd)
  }
)
