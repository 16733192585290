import { createQueryKeys } from "@lukemorales/query-key-factory"
import { memberAudienceFindUnique } from "../api/member/memberAudienceFindUnique"
import { memberByAudience } from "../api/member/memberByAudience"
import { memberById } from "../api/member/memberById"
import { MemberListPayload, memberList } from "../api/member/memberList"
import { MemberType } from "../types/member"
import { getTenant } from "../utils/getTenant"
import {
  MemberGetChannelsPayload,
  memberGetChannels
} from "../api/member/memberGetChannels"
import { MemberSearchPayload, memberSearch } from "../api/member/memberSearch"
import { memberListAll } from "../api/member/memberListAll"

export const memberQueries = createQueryKeys("members", {
  list: (payload?: MemberListPayload) => ({
    queryKey: [getTenant(), payload],
    queryFn: ({ pageParam = 1 }: { pageParam: number }) =>
      memberList({ page: pageParam, ...payload })
  }),
  listAll: () => ({
    queryKey: [getTenant()],
    queryFn: () => memberListAll()
  }),
  search: (payload: MemberSearchPayload) => ({
    queryKey: [payload],
    queryFn: () => memberSearch(payload)
  }),
  detail: (memberId: string) => ({
    queryKey: [memberId],
    queryFn: async () => {
      const { data, error } = await memberById(memberId)
      if (error) throw error
      return data as MemberType
    }
  }),
  byAudience: (audienceId: string) => ({
    queryKey: [audienceId],
    queryFn: () => memberByAudience(audienceId)
  }),
  findUnique: (payload: { audienceId: string; memberId: string }) => ({
    queryKey: [payload],
    queryFn: () => memberAudienceFindUnique(payload)
  }),
  getChannels: (payload: MemberGetChannelsPayload) => ({
    queryKey: [payload],
    queryFn: () => memberGetChannels(payload)
  })
})
