import { KINDS } from "../../lib/constants"
import { likeToggle } from "../like/likeToggle"

type CommentLikePayload = {
  liked: boolean
  commentId: string
}

export const commentLike = async ({ commentId, liked }: CommentLikePayload) => {
  await likeToggle({
    kind: KINDS.COMMENT_LIKE,
    parentId: commentId,
    liked
  })
}
