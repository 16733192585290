import { base64ToBlob } from "../../utils/base64ToBlob"
import { ResizeImageOptions } from "../../utils/resizeImage"
import { uploadImage } from "./uploadImage"

export const uploadImageBase64 = async (
  base64?: string,
  options?: ResizeImageOptions
) => {
  if (!base64) return null
  const blob = await base64ToBlob(base64)
  return await uploadImage(blob, options)
}
