const date = (start: Date, end: Date) =>
  new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()))

const boolean = () => Math.random() < 0.5

const number = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1)) + min

const color = () => {
  const r = Math.floor(Math.random() * 256)
  const g = Math.floor(Math.random() * 256)
  const b = Math.floor(Math.random() * 256)
  return "rgba(" + r + ", " + g + ", " + b + ", 0.5)"
}
export const random = {
  date,
  number,
  boolean,
  color
}
