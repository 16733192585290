import { Fragment } from "react"
import { Navigate, Outlet } from "react-router-dom"
import { useAuth } from "../../hooks/useAuth"
import { signOut } from "../../api/auth/signOut"
import { toast } from "@raisesistemas/ds"
import { useTranslation } from "react-i18next"
import { useIsRole } from "../../hooks/useIsRole"
import { useIsRoute } from "../../hooks/useIsRoute"
import { RN } from "../../env"

export const PrivateRoutesRules = () => {
  const { signed, user } = useAuth()
  const { t } = useTranslation()
  const { isRoute } = useIsRoute()
  const { isBlockedMember } = useIsRole()

  if (!signed) return <Navigate to="/" replace />

  const pageSpaceCreatePath = "/spaces/create"
  if (!user?.tenant && isRoute(pageSpaceCreatePath) === false) {
    return <Navigate to={pageSpaceCreatePath} />
  }

  if (isBlockedMember) {
    const timerMessage = setTimeout(() => {
      clearTimeout(timerMessage)
      toast.error({ message: t("error.message.blocked_member") })
    }, 500)
    signOut()
    return <Fragment />
  }

  const hasPhone = Boolean(user?.data?.phone)
  const pageRegisterPath = "/finish-register"

  if (!hasPhone && !isRoute(pageRegisterPath) && !RN) {
    return <Navigate to={pageRegisterPath} />
  }

  return <Outlet />
}
