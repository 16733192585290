import { KINDS, ROLES } from "../../lib/constants"
import { supabase } from "../../lib/supabase"
import { MemberType } from "../../types/member"

type TenantCreatorByUserIdPayload = {
  userId: string
}

export const tenantCreatorByUserId = async (
  payload: TenantCreatorByUserIdPayload
) => {
  const { userId } = payload

  const { data } = await supabase
    .from("db")
    .select("member!inner(user_uuid, role, data, tenant)")
    .in("kind", [KINDS.SPACE, KINDS.SPACE_PRO])
    .eq("member.user_uuid", userId)
    .eq("member.role", ROLES.OWNER)
    .limit(1)
    .single<{ member: MemberType }>()

  return {
    member: data?.member
  }
}
