import { supabase } from "../../lib/supabase"

export async function eventDelete(eventId: string) {
  const { error } = await supabase
    .from("db")
    .update({ kind: "trash_event" })
    .eq("id", eventId)

  if (error) throw new Error(error.message)
}
