import { createStyles, rem } from "@raisesistemas/ds"

const useStyles = createStyles(({ colors, fn, primaryColor }) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: rem(12),
    width: rem(12),
    position: "relative"
  },
  bullet: {
    height: rem(8),
    width: rem(8),
    backgroundColor: colors[primaryColor][5],
    borderRadius: "100%"
  },
  ping: {
    position: "absolute",
    width: "100%",
    height: "100%",
    borderRadius: "100%",
    backgroundColor: fn.rgba(colors[primaryColor][5], 0.3)
  }
}))

export const BulletPing = () => {
  const { classes, cx } = useStyles()
  return (
    <span className={classes.root}>
      <span
        className={cx(classes.ping, {
          "animate-ping": true
        })}
      />
      <span className={classes.bullet} />
    </span>
  )
}
