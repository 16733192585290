import { useCallback, useState } from "react"
import { DsActionIconBase } from "@raisesistemas/ds"
import { useRichTextEditorContext } from "@mantine/tiptap"

import { EmojiPicker } from "../../EmojiPicker"
import { IconHappyOutline } from "../../../icons"

export const EditorEmojiPicker = () => {
  const [open, setOpen] = useState(false)
  const { editor } = useRichTextEditorContext()

  const handleSelectEmoji = useCallback(
    (emoji: string) =>
      editor
        .chain()
        .focus()
        .insertContent(emoji)
        .run(),
    [editor]
  )

  const target = (
    <DsActionIconBase
      onClick={() => setOpen(open => !open)}
      data-testid={EDITOR_EMOJI_PICKER_ID}
    >
      <IconHappyOutline size={24} />
    </DsActionIconBase>
  )

  return (
    <EmojiPicker
      open={open}
      target={target}
      onOpenChange={setOpen}
      onSelect={handleSelectEmoji}
    />
  )
}

export const EDITOR_EMOJI_PICKER_ID = "editor-emoji-picker-id"
