import { supabase } from "../../lib/supabase"
import { MemberAudience } from "../../types/member"
import { getTenant } from "../../utils/getTenant"

type MemberAudienceFindUniquePayload = {
  audienceId: string
  memberId: string
}

export const memberAudienceFindUnique = async (
  payload: MemberAudienceFindUniquePayload
) => {
  const { data, error } = await supabase
    .from("member_audience")
    .select("audience_id::text, member(*)")
    .eq("tenant", getTenant())
    .eq("audience_id", payload.audienceId)
    .eq("user_uuid", payload.memberId)
    .limit(1)
    .returns<MemberAudience[]>()
    .single()

  if (error) throw error

  return data
}
