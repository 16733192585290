import {
  DsSkeletonLine,
  DsStackVertical,
  createStyles,
  rem
} from "@raisesistemas/ds"
import { JSONContent } from "@tiptap/react"
import { useId } from "react"

type RichTextContentSkeletonProps = {
  type?: string
}

export const RichTextContentSkeleton = (
  props: RichTextContentSkeletonProps
) => {
  const { type } = props

  switch (type) {
    case "heading":
      return <DsSkeletonLine isFull style={{ height: 35, borderRadius: 8 }} />
    case "linkPreview":
      return <DsSkeletonLine isFull style={{ height: 70, borderRadius: 8 }} />
    case "iframe":
    case "image":
      return <DsSkeletonLine isFull style={{ height: 350, borderRadius: 8 }} />
    case "paragraph":
      return <DsSkeletonLine isFull style={{ borderRadius: 8 }} />
    default:
      return <DsSkeletonLine isFull style={{ borderRadius: 8 }} />
  }
}

type RichTextSkeletonProps = {
  content?: JSONContent
}
export const RichTextSkeleton = (props: RichTextSkeletonProps) => {
  const { content } = props
  const { classes } = useStyles()
  const id = useId()

  const hasContent = Boolean(content?.content)

  return (
    <DsStackVertical className={classes.root}>
      {hasContent ? (
        content?.content?.map(({ type }, index) => (
          <RichTextContentSkeleton
            key={`richtext-skeleton-${id}-${index}`}
            type={type}
          />
        ))
      ) : (
        <RichTextContentSkeleton type="paragraph" />
      )}
    </DsStackVertical>
  )
}

const useStyles = createStyles({
  root: {
    padding: `${rem(16)} 0`
  }
})
