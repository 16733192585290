import { getTenant } from "../../utils/getTenant"
import { memberAddRole } from "./memberAddRole"
import { memberById } from "./memberById"
import { memberEdit } from "./memberEdit"
import { memberSendBannedMail } from "./memberSendBannedMail"

export type MemberRemovePayload = {
  id: string
  tenant?: string
}

export async function memberRemove(payload: MemberRemovePayload) {
  const { id, tenant = getTenant() } = payload
  const { data: member } = await memberById(id)

  if (!member) throw new Error("memberRemove: member not found")

  await memberAddRole({
    id,
    role: "blocked_member",
    tenant
  })

  memberSendBannedMail({ member, tenant })

  await memberEdit({
    ...member.data,
    user_uuid: id,
    avatar: "",
    email: ""
  })
}
