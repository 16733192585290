import { useTranslation } from "react-i18next"
import { drawers } from "../../../state"
import { Sheet } from "../../Sheet"
import {
  DsActionIconBase,
  DsBox,
  DsButtonSecondary,
  DsGroup,
  DsSpace,
  DsStackVertical,
  DsSwitch,
  DsText,
  createStyles,
  rem,
  toast
} from "@raisesistemas/ds"
import { MemberRole, MemberType } from "../../../types/member"
import { useEffect, useState } from "react"
import { IconRemoveCircleOutline } from "../../../icons"
import { useIsRole } from "../../../hooks/useIsRole"
import { MemberRemoveModal } from "../MemberRemoveModal"
import { useDisclosure } from "@mantine/hooks"
import { useMemberMutations } from "../../../hooks/mutations/useMemberMutations"
import { firstWord } from "../../../utils/firstWord"
import { capitalize } from "../../../utils/capitalize"
import { useIsScreen } from "../../../hooks/useIsScreen"
import { MemberInfo } from "../MemberInfo"

export type MemberPermissionDrawerProps = {
  member: MemberType
}

export const MemberPermissionDrawer = () => {
  const { isOpen, close, props } = drawers.member.permissions
  const { member } = props
  const [memberRole, setMemberRole] = useState<MemberRole | null>(null)
  const currentUserRole = useIsRole()
  const { isMobile } = useIsScreen()
  const [removeMemberModalOpened, removeMemberModal] = useDisclosure(false)

  const memberMutations = useMemberMutations()

  const { classes } = useStyles()
  const { t } = useTranslation()

  const isRole = (role: MemberRole | null) => memberRole === role

  const getMessageByNewRole = (role: MemberRole) =>
    t(`toast.member.permissions.${role}`, {
      memberName: capitalize(firstWord(member.data.name))
    })

  const handleMemberRole = (newRole: MemberRole) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = e.target.checked
    const currentRole = member.role

    if (checked) {
      toast.success({ message: getMessageByNewRole(newRole) })
      memberMutations.updateRole.mutate({
        id: member.user_uuid,
        role: newRole
      })
      return setMemberRole(newRole)
    }

    if (!checked && currentRole !== "member") {
      memberMutations.updateRole.mutate({
        id: member.user_uuid,
        role: "member"
      })
    }

    setMemberRole(null)
  }

  useEffect(() => {
    if (member) setMemberRole(member.role)
  }, [member])

  const footer = (
    <DsGroup position="right">
      <DsButtonSecondary
        onClick={close}
        data-testid={MEMBER_PERMISSION_DRAWER_BACK_BUTTON_ID}
      >
        {t("common.back")}
      </DsButtonSecondary>
    </DsGroup>
  )

  return (
    <Sheet
      title={t("drawer.member_permissions.title")}
      opened={isOpen}
      onClose={close}
      footer={footer}
      testId={MEMBER_PERMISSION_DRAWER_ID}
      hideFooter={isMobile}
    >
      <MemberRemoveModal
        onClose={removeMemberModal.close}
        opened={removeMemberModalOpened}
        member={member}
        onRemove={close}
      />
      <DsStackVertical spacing={24}>
        <DsBox>
          <DsSpace height={9} />
          <MemberInfo.Root
            avatarSection={
              <MemberInfo.Avatar
                size={32}
                memberId={member.user_uuid}
                name={member.data.name}
                avatar={member.data.avatar}
              />
            }
            spacing={8}
          >
            <MemberInfo.Name
              name={member.data.name}
              weight="medium"
              variant="body-2"
            />
          </MemberInfo.Root>
        </DsBox>

        <DsStackVertical spacing={8}>
          <DsGroup position="apart" className={classes.row}>
            <DsText variant="body-2" weight="regular">
              {t("root.promote_to_pro_member")}
            </DsText>
            <DsSwitch
              value={isRole("member_pro")}
              onChange={handleMemberRole("member_pro")}
              data-testid={MEMBER_PERMISSION_DRAWER_CHECKBOX_MEMBER_PRO_ID}
            />
          </DsGroup>

          {currentUserRole.isOwner && (
            <DsGroup position="apart" className={classes.row}>
              <DsText variant="body-2" weight="regular">
                {t("root.promote_to_community_moderator")}
              </DsText>
              <DsSwitch
                value={isRole("moderator")}
                onChange={handleMemberRole("moderator")}
                data-testid={MEMBER_PERMISSION_DRAWER_CHECKBOX_MODERATOR_ID}
              />
            </DsGroup>
          )}
        </DsStackVertical>

        <DsGroup position="apart" className={classes.row}>
          <DsText
            variant="body-2"
            weight="regular"
            className={classes.labelRed}
          >
            {t("drawer.member_permissions.ban_from_community")}
          </DsText>

          <DsActionIconBase
            variant="subtle"
            onClick={removeMemberModal.open}
            data-testid={
              MEMBER_PERMISSION_DRAWER_BUTTON_REMOVE_FROM_COMMUNITY_ID
            }
          >
            <IconRemoveCircleOutline size={20} className={classes.labelRed} />
          </DsActionIconBase>
        </DsGroup>
      </DsStackVertical>
    </Sheet>
  )
}

const useStyles = createStyles(({ colors, colorScheme }) => {
  const isDark = colorScheme === "dark"
  return {
    row: {
      padding: `${rem(8)} ${rem(10)}`,
      borderRadius: rem(12),
      background: isDark ? colors.gray[9] : colors.gray[0],
      gap: 4
    },
    labelRed: {
      color: colors.red[6]
    }
  }
})

export const MEMBER_PERMISSION_DRAWER_ID = "member-permissions-drawer"
export const MEMBER_PERMISSION_DRAWER_BACK_BUTTON_ID =
  "member-permissions-drawer-back-button"

export const MEMBER_PERMISSION_DRAWER_CHECKBOX_MEMBER_PRO_ID =
  "member-permissions-drawer-checkbox-member-pro"

export const MEMBER_PERMISSION_DRAWER_CHECKBOX_MODERATOR_ID =
  "member-permissions-drawer-checkbox-moderator"

export const MEMBER_PERMISSION_DRAWER_BUTTON_REMOVE_FROM_COMMUNITY_ID =
  "member-permissions-drawer-button-remove-from-communit-id"
