import { useTranslation } from "react-i18next"
import { toast } from "@raisesistemas/ds"

export const useStandardToast = () => {
  const { t } = useTranslation()

  const cantFinalize = () => {
    toast.error({
      title: t("root.oops"),
      message: t("error.message.cant_finalize")
    })
  }

  const authError = () => {
    toast.error({
      title: t("auth.invalid_email_or_password"),
      message: t("validation.verify_your_info")
    })
  }

  const unexpectedError = (message?: string) => {
    toast.error({
      title: t("error.message.unexpected_error"),
      message
    })
  }

  return {
    authError,
    cantFinalize,
    unexpectedError
  }
}
