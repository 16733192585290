import { supabase } from "../../lib/supabase"
import { TenantDataType, TenantType } from "../../types/tenant"
import { getHost } from "../../utils/getHost"

const getTenantOrThrow = async () => {
  const host = getHost()

  const { data, error } = await supabase
    .from("tenant")
    .select("id, data")
    .eq("id", host)
    .single<TenantType>()

  if (error) throw error

  return {
    tenant: data.id,
    data: data.data
  }
}

const getTenantByHostOrThrow = async () => {
  const host = getHost()

  const { data, error } = await supabase
    .from("host")
    .select("tenant, data:tenant(data)")
    .eq("id", host)
    .single<{ tenant: string; data: TenantDataType }>()

  if (error) throw error

  return {
    tenant: data.tenant,
    data: data.data
  }
}

export const tenantByHost = async () => {
  const host = getHost()
  return host.includes(".") ? getTenantByHostOrThrow() : getTenantOrThrow()
}
