import { DsStackVertical } from "@raisesistemas/ds"

import { NavLinkV2 } from "./NavLinkV2"
import { useNavLinks } from "./useNavLinks"

type NavLinksProps = {
  handleNavigate: (path: string) => void
}

export const NavLinks = (props: NavLinksProps) => {
  const { handleNavigate } = props
  const navLinks = useNavLinks()

  return (
    <DsStackVertical spacing={0}>
      {navLinks.map(link => (
        <NavLinkV2 key={link.id} handleNavigate={handleNavigate} {...link} />
      ))}
    </DsStackVertical>
  )
}
