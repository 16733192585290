import { MS_ACTIVITIES_URL } from "../../env"
import { buildURL } from "../../utils/buildUrl"
import { getTenant } from "../../utils/getTenant"
import { getUserId } from "../../utils/getUserId"

export const activityReadAll = async () => {
  const user_id = await getUserId()

  const tenant = getTenant()

  const queryParams = { tenant, user_id }

  const url = buildURL(`${MS_ACTIVITIES_URL}/activities/read`, queryParams)

  const response = await fetch(url, {
    method: "POST"
  })

  if (!response.ok) {
    throw new Error(response.statusText)
  }
}
