import { createQueryKeys } from "@lukemorales/query-key-factory"
import { extractOwnerEntriesList } from "../api/extract/extractOwnerEntriesList"
import { getTenant } from "../utils/getTenant"
import { extractOwnerExistsList } from "../api/extract/extractOwnerExistsList"

export const extractQueries = createQueryKeys("extract", {
  ownerEntriesList: () => ({
    queryKey: [getTenant()],
    queryFn: extractOwnerEntriesList
  }),
  ownerExistsList: () => ({
    queryKey: [getTenant()],
    queryFn: extractOwnerExistsList
  })
})
