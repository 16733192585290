import { TokenResponse } from "@react-oauth/google"
import {
  SignInWithIdTokenCredentials,
  VerifyEmailOtpParams
} from "@supabase/supabase-js"
import {
  GOOGLE_SIGNING_CLIENT_ID,
  GOOGLE_SIGNING_CLIENT_SECRET,
  RN
} from "../../env"

import { supabase } from "../../lib/supabase"
import { getTenant } from "../../utils/getTenant"
import { msMemberIsBlocked } from "./msMemberIsBlocked"
import { msOTP } from "./msOTP"

export type SigninPayload = {
  email: string
  tenant?: string
}

export async function signin(payload: SigninPayload) {
  const { email, tenant = getTenant() } = payload

  const {
    data: { isBlocked }
  } = await msMemberIsBlocked({ email, tenant })

  if (isBlocked) throw new BlockedMemberError()

  const responseOTP = await msOTP({ email, tenant })

  if (
    (email.endsWith("@luminy.app") && tenant === "qa") ||
    email === "demo@luminy.app"
  ) {
    const json = await responseOTP.json()
    window.location = json.action_link
    return { isDemo: true }
  }

  return {
    hasUser: responseOTP.ok
  }
}

export const signInWithIdToken = async (
  credentials: SignInWithIdTokenCredentials
) => {
  const { error } = await supabase.auth.signInWithIdToken(credentials)

  if (error) throw error
}

export const signVerifyOTP = async (payload: VerifyEmailOtpParams) => {
  const { data, error } = await supabase.auth.verifyOtp(payload)

  if (error) throw error

  return data
}

export const getOAuthTokens = async (
  credentials: Omit<TokenResponse, "error" | "error_description" | "error_uri">
) => {
  const url = "https://www.googleapis.com/oauth2/v4/token"
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded"
  }

  const body = new URLSearchParams({
    client_id: GOOGLE_SIGNING_CLIENT_ID,
    client_secret: GOOGLE_SIGNING_CLIENT_SECRET,
    grant_type: "refresh_token",
    refresh_token: credentials.access_token
  })

  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: body
  })

  const tokens = await response.json()
  return tokens
}

export const signInWithApple = () => {
  if (RN) return RN.signInOAuth("apple")
}

export class BlockedMemberError extends Error {
  constructor(message = "Membro bloqueado") {
    super(message)
  }
}
