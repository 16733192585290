import { MS_DIRECT_URL, WS_URL } from "../../env"
import { getTenant } from "../../utils/getTenant"
import { MemberType } from "../../types/member"

const IS_HTTPS = window.location.protocol === "https:"

export const Direct = {
  provider: "lu",
  pagination: 20,
  endpoint: {
    base(userId: string) {
      return `${MS_DIRECT_URL}/orgs/${
        Direct.provider
      }/members/${Direct.encodeId(userId)}`
    },
    createGroup(userId: string) {
      return Direct.endpoint.base(userId) + `/groups`
    },
    listGroup(userId: string, page: number) {
      return Direct.endpoint.base(userId) + `/groups?page=${page}`
    },
    listMessage(userId: string, groupId: number, page: number) {
      return (
        Direct.endpoint.base(userId) +
        `/groups/${groupId}/messages?page=${page}`
      )
    },
    count(userId: string) {
      return Direct.endpoint.base(userId) + `/messages/count/unread`
    },
    persistMessage(userId: string, groupId: number) {
      return Direct.endpoint.base(userId) + `/groups/${groupId}/messages`
    },
    websocket: {
      sendMessage(groupId: number) {
        return `https://${WS_URL}/send/${Direct.provider}/${groupId}`
      },

      listenMessages(groupId: number) {
        const protocol = IS_HTTPS ? "wss" : "ws"
        return `${protocol}://${WS_URL}/connect/${Direct.provider}/${groupId}`
      },
      unreadMessage(memberId: string) {
        const protocol = IS_HTTPS ? "wss" : "ws"

        return `${protocol}://${WS_URL}/connect/${Direct.provider}/unread-messages-${memberId}`
      },
      incrementUnreadMessage(memberId: string) {
        return `https://${WS_URL}/send/${Direct.provider}/unread-messages-${memberId}`
      }
    }
  },

  createMemberFrom(member: MemberType) {
    return {
      id: member.user_uuid,
      avatar: member.data.avatar,
      name: member.data.name
    }
  },

  encodeId(userId: string) {
    return `${Direct.provider}.${getTenant()}.${userId}`
  },
  decodeId(userId: string) {
    return userId.replace(`${Direct.provider}.${getTenant()}.`, "")
  },
  fetchConfig(method: string, accessToken: string, body?: object): RequestInit {
    return {
      method,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        org_id: Direct.provider
      },
      body: JSON.stringify(body)
    }
  }
}
