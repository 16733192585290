import { getPageParams } from "./getPageParams"
import { getSupabaseRange } from "./getSupabaseRange"
import { makeInfiniteResponse } from "./makeInfiniteResponse"

export type PayloadTypeBuilder<P> = P & {
  page?: number
  limit?: number
}

type CallbackParamsBuilder<P> = PayloadTypeBuilder<P> & {
  rangeStart: number
  rangeEnd: number
}

export const createInfiniteService = <
  PayloadType = unknown,
  ResponseType = unknown
>(
  callback: (payload: CallbackParamsBuilder<PayloadType>) => any
) => {
  return async (payload: PayloadTypeBuilder<PayloadType>) => {
    const { page = 1, limit = 10 } = payload

    const { rangeStart, rangeEnd } = getSupabaseRange(page, limit)

    const query = callback({
      ...payload,
      rangeStart,
      rangeEnd
    })

    const { data, error, count } = await query
    if (error) throw error

    const { isLastPage } = getPageParams({
      limit,
      page,
      totalItems: count ?? 0
    })

    return makeInfiniteResponse<ResponseType>({ data, isLastPage, page })
  }
}
