import { JSONContent } from "@tiptap/core"
import { ReplyType } from "../../types/comment"
import { ButtonCommentGroup } from "../ButtonCommentGroup"
import { DsStackVertical, DsWrapperSpace } from "@raisesistemas/ds"
import { KINDS } from "../../lib/constants"
import { ConfirmDeleteModal } from "../ConfirmDeleteModal"
import { CommentHeader } from "./CommentHeader"
import { CommentBody } from "./CommentBody"

import { useDisclosure } from "@mantine/hooks"
import { useTranslation } from "react-i18next"
import { useReplyMutations } from "../../hooks/mutations/useReplyMutations"

export type CommentReplyProps = {
  channelId?: string
  reply: ReplyType
}

export const CommentReply = (props: CommentReplyProps) => {
  const { reply, channelId } = props
  const { t } = useTranslation()
  const [isEdit, isEditHandler] = useDisclosure(false)
  const [opened, handler] = useDisclosure(false)
  const replyMutations = useReplyMutations()

  const handleReplyEdit = async (content: JSONContent) => {
    replyMutations.edit.mutate(
      {
        id: reply.id,
        content
      },
      { onSuccess: isEditHandler.close }
    )
  }

  const handleReplyLike = (liked: boolean) => {
    replyMutations.like.mutate({ replyId: reply.id, liked })
  }

  const handleReplyDelete = () =>
    replyMutations.destroy.mutate({ id: reply.id })

  return (
    <>
      <DsStackVertical spacing="md">
        <CommentHeader
          id={reply.id}
          memberId={reply.user_uuid}
          memberAvatar={reply.member.avatar}
          memberName={reply.member.name}
          onEdit={isEditHandler.open}
          onDelete={handler.open}
          data-testid={COMMENT_REPLY_HEADER_ID}
        />

        <DsWrapperSpace paddingLeft={55}>
          <DsStackVertical>
            <CommentBody
              channelId={channelId}
              isEdit={isEdit}
              content={reply.content!}
              defaultValue={reply.content}
              onSubmit={handleReplyEdit}
              onCloseEdit={isEditHandler.close}
              data-testid={COMMENT_REPLY_BODY_ID}
              loading={replyMutations.edit.isPending}
            />

            <ButtonCommentGroup
              visibleContent="like"
              countLike={reply.likesCount?.[0].count}
              liked={reply.likes.length > 0}
              onLike={handleReplyLike}
              likeKind={KINDS.REPLY_LIKE}
              likeParentId={reply.id}
              data-testid={COMMENT_REPLY_COMMENT_GROUP_ID}
            />
          </DsStackVertical>
        </DsWrapperSpace>
      </DsStackVertical>

      <ConfirmDeleteModal
        opened={opened}
        onClose={handler.close}
        title={t("validation.are_you_sure")}
        description={t("common.delete_question", {
          type: t("root.comment").toLocaleLowerCase()
        })}
        onDelete={handleReplyDelete}
        isLoading={replyMutations.destroy.isPending}
      />
    </>
  )
}

export const COMMENT_REPLY_HEADER_ID = "comment-reply-header-id"
export const COMMENT_REPLY_BODY_ID = "comment-reply-body-id"
export const COMMENT_REPLY_COMMENT_GROUP_ID = "comment-reply-comment-group-id"
