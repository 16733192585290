import {
  DsButtonSecondary,
  DsButtonSecondaryProps,
  createStyles
} from "@raisesistemas/ds"
import { useTranslation } from "react-i18next"

const useStyles = createStyles(({ breakpoints }) => ({
  button: {
    display: "block",
    width: "100%",

    [`@media (min-width: ${breakpoints.md})`]: {
      width: "auto",
      marginLeft: "auto"
    }
  }
}))

type SheetButtonBackProps = Pick<DsButtonSecondaryProps, "onClick">

export const SheetButtonBack = (props: SheetButtonBackProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  return (
    <DsButtonSecondary className={classes.button} {...props}>
      {t("root.back")}
    </DsButtonSecondary>
  )
}
