import { DsActionIconBase } from "@raisesistemas/ds"
import { RN } from "../env"
import { IconSend } from "../icons"

type ButtonShareProps = {
  message: string
}

export const ButtonShare = ({ message }: ButtonShareProps) => {
  return (
    <DsActionIconBase onClick={() => RN?.share(message)}>
      <IconSend color="gray" size={20} />
    </DsActionIconBase>
  )
}
