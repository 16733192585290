import { useQuery } from "@tanstack/react-query"
import { queries } from "../../queries"

export const useBankQueries = () => {
  const list = () => {
    return useQuery({
      ...queries.banks.list(),
      gcTime: Infinity,
      staleTime: 1000 * 60 * 60 * 24 * 7 // 1 week
    })
  }

  const find = () => {
    return useQuery(queries.banks.find())
  }

  return {
    list,
    find
  }
}
