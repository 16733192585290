import { useMutation, useQueryClient } from "@tanstack/react-query"
import { postSavedToggle } from "../../api/postSaved/postSavedToggle"
import { queries } from "../../queries"

export const usePostSavedMutations = () => {
  const queryClient = useQueryClient()

  const toggle = useMutation({
    mutationFn: postSavedToggle,
    onSettled() {
      queryClient.invalidateQueries({ queryKey: queries.postSaved._def })
      queryClient.invalidateQueries({ queryKey: queries.posts._def })
    }
  })

  return {
    toggle
  }
}
