import { createQueryKeys } from "@lukemorales/query-key-factory"
import { replyList } from "../api/reply/replyList"

export const replyQueries = createQueryKeys("replies", {
  list: ({ commentId }: { commentId: string }) => ({
    queryKey: [{ commentId }],
    queryFn: ({ pageParam = 1 }: { pageParam: number }) =>
      replyList({ commentId, limit: 5, page: pageParam })
  })
})
