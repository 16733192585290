import { DropdownItensProps, DsActionIconBase, DsMenu } from "@raisesistemas/ds"
import { ReactNode, useId } from "react"
import { useTranslation } from "react-i18next"
import { IconEllipsisVertical } from "../../icons"

type Props = {
  onDelete: () => void
  onEdit: () => void
  customTargetIcon?: ReactNode
  hideEdit?: boolean
  hideDelete?: boolean

  customDropdownIntes?: DropdownItensProps[]

  id?: string
}

export const EntityActions = (props: Props) => {
  const defaultId = useId()
  const {
    onDelete,
    onEdit,
    hideDelete = false,
    hideEdit = false,
    customDropdownIntes,
    id = defaultId,
    ...restProps
  } = props
  const { t } = useTranslation()

  const getDropdrownItens = () => {
    const dropdownItens: DropdownItensProps[] = []

    const defaults: DropdownItensProps[] = [
      {
        icon: " ",
        name: t("button.edit"),
        onClick: onEdit,
        withDivider: true,
        visible: !hideEdit
      },
      {
        icon: " ",
        color: "red",
        name: t("button.delete"),
        onClick: onDelete,
        withDivider: false,
        visible: !hideDelete
      }
    ]

    if (customDropdownIntes) {
      return dropdownItens.concat(customDropdownIntes).concat(defaults)
    }

    return defaults
  }

  return (
    <DsMenu
      target={
        <div>
          <DsActionIconBase data-testid={id}>
            {<IconEllipsisVertical size={16} />}
          </DsActionIconBase>
        </div>
      }
      dropdownItens={getDropdrownItens()}
      {...restProps}
    />
  )
}
