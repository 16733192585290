import {
  InfiniteData,
  useMutation,
  useQueryClient
} from "@tanstack/react-query"
import { signVerifyOTP } from "../../api/auth/signIn"
import { authRequestPin } from "../../api/auth/request-pin"
import { toast } from "@raisesistemas/ds"
import { useTranslation } from "react-i18next"
import { useStandardToast } from "../useStandardToast"
import { newPassword } from "../../api/auth/newPassword"
import { sendInvitations } from "../../api/auth/sendInvitations"
import { capitalize } from "../../utils/capitalize"
import { queries } from "../../queries"
import { MemberType } from "../../types/member"
import { createRandomId } from "../../utils/createRandomId"
import { getTenant } from "../../utils/getTenant"
import { InfinitePageType } from "../../types/shared"

export const useAuthMutations = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { cantFinalize } = useStandardToast()

  const changePassword = useMutation({
    mutationFn: newPassword,
    onError() {
      cantFinalize()
    },
    onSuccess() {
      toast.success({
        title: t("password.change_success"),
        message: ""
      })
    }
  })

  const verifyOTP = useMutation({
    mutationFn: signVerifyOTP,
    onError() {
      toast.error({ message: t("validation.invalid_pin") })
    }
  })

  const _sendInvitations = useMutation({
    mutationFn: sendInvitations,

    async onSuccess(_, variables) {
      const { queryKey } = queries.members.list({
        confirmed: false,
        nameLike: ""
      })

      const { queryKey: membersKey } = queries.members.list({
        confirmed: true,
        nameLike: ""
      })

      const newMembers: MemberType[] = variables.members.map(member => ({
        user_uuid: createRandomId(),
        data: {
          name: member.name,
          email: member.email,
          avatar: "",
          created_at: ""
        },
        role: "member",
        tenant: getTenant()
      }))

      queryClient.setQueryData<InfiniteData<InfinitePageType<MemberType>>>(
        queryKey,
        queryData => {
          if (!queryData) return

          const members =
            queryClient.getQueryData<
              InfiniteData<InfinitePageType<MemberType>>
            >(membersKey)

          const membersIds =
            members?.pages.reduce((acc, page) => {
              return acc.concat(page.data.map(member => member.data.email))
            }, [] as string[]) || []

          const guestIds = queryData.pages.reduce((acc, page) => {
            return acc.concat(page.data.map(member => member.data.email))
          }, [] as string[])

          const existingMembersIds = membersIds.concat(guestIds)

          const newMembersFiltered = newMembers.filter(member => {
            return !existingMembersIds.includes(member.data.email)
          })

          queryData.pages[0].data = [
            ...newMembersFiltered,
            ...queryData.pages[0].data
          ]

          return queryData
        }
      )

      toast.success({
        message: capitalize(
          t("common.sent_plural", { type: t("root.invitations") })
        )
      })
    },
    onError() {
      queryClient.invalidateQueries({ queryKey: queries.members.list._def })
    }
  })

  const requestPin = useMutation({
    mutationFn: authRequestPin
  })
  return {
    verifyOTP,
    requestPin,
    changePassword,
    sendInvitations: _sendInvitations
  }
}
