import { useDsTheme } from "@raisesistemas/ds"

export const Personalization = () => {
  const { primaryColor, colors } = useDsTheme()

  const color = colors[primaryColor][6]
  return (
    <svg
      width="254"
      height="225"
      viewBox="0 0 254 225"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1153_12274)">
        <path
          d="M186.332 53.4197V19.5835C186.332 14.3896 184.269 9.40848 180.596 5.73587C176.924 2.06325 171.943 0 166.749 0H95.062C89.8681 9.8133e-07 84.887 2.06326 81.2144 5.73587C77.5418 9.40849 75.4785 14.3896 75.4785 19.5835V205.212C75.4785 210.405 77.5418 215.387 81.2144 219.059C84.887 222.732 89.8681 224.795 95.062 224.795H166.749C171.943 224.795 176.924 222.732 180.596 219.059C184.269 215.387 186.332 210.406 186.332 205.212V77.5049C186.494 77.5049 186.655 77.473 186.805 77.4109C186.955 77.3488 187.091 77.2578 187.205 77.1431C187.32 77.0285 187.411 76.8923 187.473 76.7424C187.535 76.5926 187.567 76.432 187.567 76.2698V54.6548C187.567 54.3273 187.437 54.0131 187.205 53.7815C186.974 53.5498 186.66 53.4197 186.332 53.4197Z"
          fill="#3F3D56"
        />
        <path
          d="M182.165 19.7179V205.078C182.165 208.928 180.646 212.623 177.938 215.36C175.23 218.097 171.552 219.654 167.702 219.694C167.648 219.7 167.595 219.702 167.542 219.701H95.506C91.6278 219.701 87.9085 218.16 85.1662 215.418C82.4239 212.675 80.8832 208.956 80.8828 205.078V19.7179C80.8832 15.8397 82.4239 12.1204 85.1662 9.37815C87.9085 6.63585 91.6278 5.09509 95.506 5.09473H104.248C103.818 6.15009 103.654 7.29489 103.771 8.42841C103.888 9.56192 104.281 10.6494 104.916 11.5952C105.552 12.541 106.41 13.3161 107.416 13.8523C108.421 14.3884 109.543 14.6693 110.683 14.6701H151.751C152.89 14.667 154.01 14.3845 155.014 13.8476C156.018 13.3106 156.875 12.5355 157.509 11.5902C158.144 10.645 158.537 9.55844 158.654 8.42596C158.771 7.29347 158.608 6.14962 158.18 5.09473H167.542C171.42 5.09508 175.139 6.63584 177.881 9.37814C180.624 12.1204 182.164 15.8397 182.165 19.7179Z"
          fill="white"
        />
        <path
          d="M75.876 72.349C75.434 72.3485 75.0103 72.1727 74.6978 71.8602C74.3853 71.5477 74.2095 71.1239 74.209 70.682V59.3462C74.2096 58.9045 74.3856 58.4811 74.6981 58.169C75.0107 57.8569 75.4343 57.6816 75.876 57.6816C76.3177 57.6816 76.7414 57.8569 77.0539 58.169C77.3665 58.4811 77.5424 58.9045 77.543 59.3462V70.682C77.5425 71.124 77.3667 71.5477 77.0542 71.8602C76.7417 72.1727 76.318 72.3485 75.876 72.349Z"
          fill="#3F3D56"
        />
        <path
          d="M75.542 50.3441C75.1001 50.3436 74.6764 50.1678 74.3638 49.8553C74.0513 49.5428 73.8755 49.1191 73.875 48.6771V37.3413C73.8757 36.8996 74.0516 36.4762 74.3641 36.1641C74.6767 35.8521 75.1003 35.6768 75.542 35.6768C75.9837 35.6768 76.4074 35.8521 76.7199 36.1641C77.0325 36.4762 77.2084 36.8996 77.2091 37.3413V48.6771C77.2085 49.1191 77.0327 49.5428 76.7202 49.8553C76.4077 50.1678 75.984 50.3436 75.542 50.3441Z"
          fill="#3F3D56"
        />
        <path
          d="M119.885 7.00199C119.885 6.56003 120.061 6.13631 120.374 5.8238C120.686 5.51128 121.11 5.33548 121.552 5.33496H132.888C133.329 5.33562 133.753 5.51154 134.065 5.8241C134.377 6.13666 134.552 6.5603 134.552 7.00199C134.552 7.44369 134.377 7.86732 134.065 8.17988C133.753 8.49244 133.329 8.66836 132.888 8.66902H121.552C121.11 8.6685 120.686 8.4927 120.374 8.18018C120.061 7.86767 119.885 7.44396 119.885 7.00199Z"
          fill="white"
        />
        <path
          d="M75.876 91.6869C75.434 91.6864 75.0103 91.5106 74.6978 91.1981C74.3853 90.8855 74.2095 90.4618 74.209 90.0199V78.6841C74.2096 78.2424 74.3856 77.819 74.6981 77.5069C75.0107 77.1948 75.4343 77.0195 75.876 77.0195C76.3177 77.0195 76.7414 77.1948 77.0539 77.5069C77.3665 77.819 77.5424 78.2424 77.543 78.6841V90.0199C77.5425 90.4618 77.3667 90.8855 77.0542 91.1981C76.7417 91.5106 76.318 91.6864 75.876 91.6869Z"
          fill="#3F3D56"
        />
        <path
          d="M139.222 8.66901C140.142 8.66901 140.889 7.92266 140.889 7.00199C140.889 6.08131 140.142 5.33496 139.222 5.33496C138.301 5.33496 137.555 6.08131 137.555 7.00199C137.555 7.92266 138.301 8.66901 139.222 8.66901Z"
          fill="white"
        />
        <path
          d="M123.698 212.512L128.185 212.511L130.319 195.206H123.697L123.698 212.512Z"
          fill="#9E616A"
        />
        <path
          d="M122.553 211.047L123.816 211.047L128.746 209.042L131.389 211.047C132.882 211.047 134.314 211.64 135.37 212.696C136.426 213.752 137.02 215.184 137.02 216.678V216.861L122.553 216.861L122.553 211.047Z"
          fill="#2F2E41"
        />
        <path
          d="M144.542 212.512L149.029 212.511L151.163 195.206H144.541L144.542 212.512Z"
          fill="#9E616A"
        />
        <path
          d="M143.396 211.047L144.659 211.047L149.59 209.042L152.232 211.047H152.233C153.726 211.047 155.158 211.64 156.214 212.696C157.27 213.752 157.863 215.184 157.864 216.678V216.861L143.397 216.861L143.396 211.047Z"
          fill="#2F2E41"
        />
        <path
          d="M148.128 117.807L112.877 118.726L113.49 135.892C113.49 135.892 112.877 140.49 114.103 141.716C115.329 142.942 115.942 142.942 115.329 145.088C114.716 147.234 117.955 150.738 117.562 151.132C117.168 151.525 116.249 153.671 116.249 153.671L119.314 171.143C119.314 171.143 121.46 201.49 122.073 202.103C122.686 202.716 123.299 202.103 122.686 203.636C122.073 205.168 121.46 204.555 122.073 205.168C122.616 205.754 123.128 206.368 123.606 207.007H131.017C131.017 207.007 131.575 203.942 131.575 203.636C131.575 203.329 132.188 201.49 132.188 201.183C132.188 200.877 131.647 200.335 131.647 200.335C131.459 199.712 131.333 199.072 131.269 198.425C131.269 197.812 129.43 174.209 129.43 174.209L133.108 143.555L141.998 173.595C141.998 173.595 141.997 199.957 142.611 200.57C143.224 201.183 143.224 200.877 142.917 202.103C142.611 203.329 141.384 203.023 142.304 203.942C143.224 204.862 143.53 203.329 143.224 204.862L142.917 206.394L152.113 206.526C152.113 206.526 153.339 203.942 152.726 203.023C152.113 202.103 152.15 202.354 152.898 200.849C153.646 199.344 153.952 199.038 153.646 198.731C153.339 198.425 153.339 196.794 153.339 196.794L151.807 167.465C151.807 167.465 151.5 136.505 151.5 135.585C151.477 135.112 151.564 134.64 151.755 134.206V132.958L150.58 128.535L148.128 117.807Z"
          fill="#2F2E41"
        />
        <path
          d="M120.549 56.2423C125.797 56.2423 130.052 51.9879 130.052 46.7398C130.052 41.4917 125.797 37.2373 120.549 37.2373C115.301 37.2373 111.047 41.4917 111.047 46.7398C111.047 51.9879 115.301 56.2423 120.549 56.2423Z"
          fill="#9E616A"
        />
        <path
          d="M116.23 53.2033L115.846 52.895C115.839 52.8893 115.109 52.31 114.412 52.4114C114.253 52.4378 114.102 52.4976 113.968 52.5869C113.834 52.6762 113.72 52.7929 113.634 52.9294C113.585 53.0044 113.515 53.0633 113.433 53.0984C113.35 53.1335 113.259 53.1432 113.171 53.1265C112.267 52.9841 110.635 50.309 109.812 47.6218C109.305 45.9647 108.707 42.962 110.354 41.3618C110.482 40.6373 111.795 34.107 117.571 33.9256C123.512 33.7367 127.468 34.9365 128.422 37.2109C128.585 37.5979 128.748 38.0534 128.909 38.5647C128.991 38.8141 129.001 39.0816 128.937 39.3363C128.874 39.591 128.739 39.8225 128.549 40.0039C128.377 40.1713 128.166 40.2935 127.935 40.3593C127.704 40.4252 127.461 40.4327 127.226 40.3813C127.197 40.6733 127.12 40.9585 126.999 41.2258C126.48 42.351 125.174 43.0852 123.671 43.0966C122.78 43.0835 121.899 42.9135 121.068 42.5943L123.472 44.4439L117.727 43.7291L119.609 44.7353L119.232 44.9143C118.062 45.4329 117.096 46.3245 116.487 47.4498C115.877 48.5751 115.658 49.8707 115.863 51.1341L116.23 53.2033Z"
          fill="#2F2E41"
        />
        <path
          d="M146.397 113.264C147.734 110.956 146.461 107.62 146.461 107.62C146.461 107.62 149.92 102.505 149.071 100.324L144.998 68.7595C144.998 68.7595 143.725 65.3642 140.754 63.6665C139.939 63.2007 135.66 63.3838 134.722 63.3916L131.674 62.0887C131.497 62.7351 128.173 55.997 128.156 56.667C126.034 56.667 124.667 56.9599 121.365 57.5159C115.632 58.482 118.521 63.662 118.884 64.2735C118.738 64.283 118.593 64.3032 118.45 64.3338C117.703 64.4739 115.156 66.6766 115.156 66.6766L110.602 70.7213C103.739 76.1071 105.276 84.5105 105.276 84.5105L115.284 94.5818L110.899 114.788C110.899 114.788 112.763 117.789 111.799 119.134C110.836 120.48 120.287 120.093 120.287 120.093C126.811 123.001 149.793 120.543 149.793 120.543C149.474 119.711 146.397 113.264 146.397 113.264Z"
          fill="#E6E6E6"
        />
        <path
          d="M113.606 135.749C113.273 135.376 113.026 134.934 112.883 134.454C112.74 133.975 112.703 133.47 112.776 132.975C112.849 132.48 113.029 132.006 113.305 131.589C113.58 131.171 113.944 130.819 114.37 130.557L110.053 113.585L115.955 115.847L119.236 131.873C119.628 132.619 119.734 133.482 119.533 134.3C119.331 135.118 118.837 135.834 118.143 136.312C117.449 136.79 116.604 136.996 115.767 136.893C114.931 136.789 114.162 136.382 113.606 135.749Z"
          fill="#9E616A"
        />
        <path
          d="M105.661 81.2832L105.128 88.0604C105.128 88.0604 104.465 95.5061 104.638 96.8829C104.812 98.2597 105.135 106.668 105.135 106.668L110.754 117.783L117.12 116.085L116.739 110.871L117.12 115.661L117.969 111.417L115.847 104.202L117.12 101.655L116.478 93.1069L105.661 81.2832Z"
          fill="#E6E6E6"
        />
        <path
          d="M252.056 127.012C251.306 126.123 250.243 125.557 249.086 125.431C248.922 125.413 248.757 125.404 248.591 125.404L163.264 125.324C162.647 125.322 162.035 125.447 161.467 125.69C160.9 125.934 160.388 126.291 159.964 126.74C159.147 127.628 158.701 128.795 158.717 130.001L158.704 143.862C158.697 146.374 159.241 148.856 160.298 151.134C159.708 153.37 158.723 155.482 157.388 157.37C156.973 157.968 156.735 158.671 156.701 159.398C156.668 160.126 156.84 160.848 157.199 161.482C157.557 162.115 158.087 162.635 158.728 162.981C159.369 163.327 160.094 163.485 160.821 163.438C161.72 163.382 162.616 163.28 163.505 163.132C166.092 162.727 168.56 161.766 170.739 160.314C172.416 160.842 174.163 161.111 175.921 161.112L235.92 161.168C238.183 161.176 240.425 160.734 242.515 159.868C244.606 159.002 246.503 157.729 248.097 156.123C251.345 152.808 253.15 148.342 253.117 143.701L253.13 130.095C253.149 128.973 252.768 127.88 252.056 127.012Z"
          fill={color}
        />
        <path
          d="M229.76 137.903L182.07 137.858C181.934 137.858 181.799 137.831 181.673 137.779C181.547 137.727 181.433 137.65 181.336 137.554C181.24 137.457 181.164 137.343 181.112 137.217C181.06 137.091 181.033 136.956 181.033 136.82C181.033 136.684 181.06 136.549 181.113 136.423C181.165 136.297 181.241 136.183 181.338 136.086C181.434 135.99 181.549 135.914 181.675 135.862C181.801 135.81 181.936 135.783 182.072 135.783L229.762 135.828C230.037 135.828 230.301 135.938 230.496 136.132C230.69 136.327 230.799 136.591 230.799 136.866C230.799 137.142 230.689 137.405 230.494 137.6C230.299 137.794 230.035 137.903 229.76 137.903Z"
          fill="white"
        />
        <path
          d="M229.754 144.272L182.064 144.228C181.928 144.227 181.793 144.201 181.667 144.148C181.541 144.096 181.427 144.019 181.331 143.923C181.234 143.827 181.158 143.712 181.106 143.586C181.054 143.46 181.027 143.325 181.027 143.189C181.027 143.053 181.054 142.918 181.107 142.792C181.159 142.666 181.235 142.552 181.332 142.456C181.428 142.359 181.543 142.283 181.669 142.231C181.795 142.179 181.93 142.152 182.066 142.152L229.756 142.197C230.031 142.197 230.295 142.307 230.49 142.501C230.684 142.696 230.793 142.96 230.793 143.235C230.793 143.511 230.683 143.774 230.488 143.969C230.294 144.163 230.03 144.272 229.754 144.272Z"
          fill="white"
        />
        <path
          d="M187.616 150.601L182.058 150.596C181.922 150.596 181.787 150.569 181.661 150.516C181.535 150.464 181.421 150.388 181.325 150.291C181.228 150.195 181.152 150.08 181.1 149.954C181.048 149.828 181.021 149.693 181.021 149.557C181.022 149.421 181.049 149.286 181.101 149.16C181.153 149.034 181.23 148.92 181.326 148.824C181.423 148.727 181.537 148.651 181.663 148.599C181.789 148.547 181.924 148.52 182.06 148.521L187.618 148.526C187.893 148.526 188.156 148.636 188.35 148.831C188.544 149.026 188.653 149.289 188.653 149.564C188.652 149.839 188.543 150.103 188.349 150.297C188.154 150.491 187.891 150.601 187.616 150.601Z"
          fill="white"
        />
        <path
          d="M1.68455 73.3606C2.43426 72.4714 3.49762 71.9052 4.65383 71.7795C4.81823 71.7617 4.98347 71.7527 5.14882 71.7526L90.4758 71.6729C91.0935 71.6707 91.705 71.7952 92.2726 72.0388C92.8402 72.2824 93.3519 72.6398 93.7758 73.089C94.5928 73.9769 95.0392 75.1437 95.0234 76.3501L95.0364 90.211C95.043 92.7224 94.4988 95.2047 93.4421 97.483C94.0318 99.7185 95.0173 101.83 96.3519 103.718C96.7672 104.316 97.0052 105.02 97.0387 105.747C97.0723 106.474 96.8999 107.196 96.5414 107.83C96.1829 108.464 95.6528 108.984 95.0122 109.33C94.3715 109.676 93.6462 109.834 92.9196 109.786C92.0202 109.731 91.1243 109.629 90.2355 109.481C87.6484 109.076 85.1801 108.114 83.0006 106.663C81.3241 107.191 79.5768 107.46 77.8192 107.46L17.8201 107.516C15.5573 107.525 13.3154 107.083 11.2248 106.217C9.13427 105.351 7.23678 104.078 5.64272 102.472C2.39474 99.1564 0.590128 94.6907 0.622772 90.0496L0.610046 76.444C0.591149 75.3213 0.971996 74.2284 1.68455 73.3606Z"
          fill={color}
        />
        <path
          d="M22.9395 83.214C22.9395 82.9389 23.0487 82.6751 23.243 82.4804C23.4374 82.2857 23.701 82.176 23.9761 82.1754L71.6665 82.1309C71.9417 82.1306 72.2057 82.2397 72.4005 82.4341C72.5953 82.6285 72.7048 82.8923 72.7051 83.1675C72.7053 83.4427 72.5963 83.7068 72.4019 83.9015C72.2074 84.0963 71.9436 84.2059 71.6684 84.2061L23.9781 84.2507C23.703 84.2506 23.4391 84.1414 23.2444 83.9471C23.0497 83.7527 22.94 83.4891 22.9395 83.214Z"
          fill="white"
        />
        <path
          d="M22.9453 89.5831C22.9454 89.308 23.0546 89.0442 23.2489 88.8495C23.4432 88.6548 23.7069 88.5451 23.982 88.5445L71.6723 88.5C71.9475 88.4997 72.2116 88.6088 72.4063 88.8032C72.6011 88.9976 72.7107 89.2615 72.7109 89.5367C72.7112 89.8119 72.6021 90.0759 72.4077 90.2707C72.2133 90.4654 71.9495 90.575 71.6743 90.5753L23.9839 90.6198C23.7088 90.6197 23.445 90.5105 23.2503 90.3162C23.0556 90.1219 22.9459 89.8582 22.9453 89.5831Z"
          fill="white"
        />
        <path
          d="M65.084 95.9129C65.0841 95.6378 65.1932 95.374 65.3876 95.1793C65.5819 94.9846 65.8456 94.8749 66.1207 94.8743L71.6783 94.8691C71.9535 94.8689 72.2175 94.978 72.4123 95.1724C72.607 95.3668 72.7166 95.6306 72.7169 95.9058C72.7171 96.181 72.6081 96.445 72.4136 96.6398C72.2192 96.8346 71.9554 96.9442 71.6802 96.9444L66.1226 96.9496C65.8475 96.9495 65.5837 96.8403 65.389 96.646C65.1943 96.4517 65.0846 96.188 65.084 95.9129Z"
          fill="white"
        />
        <path
          d="M173.544 41.2777C173.068 41.5159 172.651 41.8554 172.321 42.2723C171.991 42.6892 171.756 43.1734 171.633 43.6908C171.511 44.2082 171.503 44.7462 171.611 45.267C171.718 45.7877 171.939 46.2785 172.257 46.7047L165.002 57.4025L171.718 57.4894L177.578 47.3173C178.259 46.7358 178.703 45.9247 178.825 45.0379C178.948 44.151 178.741 43.25 178.244 42.5056C177.746 41.7611 176.993 41.2249 176.127 40.9986C175.261 40.7723 174.342 40.8716 173.544 41.2777Z"
          fill="#9E616A"
        />
        <path
          d="M175.848 51.3661L164.671 71.9178L164.571 71.9244C164.489 71.9299 160.933 72.1652 156.59 72.4009C155.857 72.4407 155.426 73.7401 154.662 73.7788C154.135 73.8054 152.192 76.2479 151.66 76.2731C146.926 76.4981 144.211 78.6616 142.41 78.4932C139.969 78.2898 136.716 71.5737 134.942 69.8838L134.901 69.8395L134.895 69.7792C134.77 68.5467 135.07 67.3087 135.744 66.2693C136.418 65.2299 137.426 64.4512 138.601 64.0615C144.288 62.2104 150.328 61.7064 156.243 62.5893L159.607 63.0954L169.819 49.3799L175.848 51.3661Z"
          fill="#E6E6E6"
        />
        <path
          d="M236.145 11.5686C235.396 10.6794 234.332 10.1132 233.176 9.98748C233.012 9.96969 232.847 9.9607 232.681 9.96056L147.354 9.88089C146.737 9.8787 146.125 10.0032 145.557 10.2468C144.99 10.4904 144.478 10.8478 144.054 11.297C143.237 12.1849 142.791 13.3517 142.807 14.5581L142.794 28.419C142.787 30.9304 143.331 33.4127 144.388 35.691C143.798 37.9265 142.813 40.0383 141.478 41.9263C141.063 42.5244 140.825 43.2276 140.791 43.9549C140.758 44.6823 140.93 45.4044 141.289 46.0382C141.647 46.672 142.177 47.1918 142.818 47.5378C143.458 47.8838 144.184 48.0421 144.91 47.9944C145.81 47.9388 146.706 47.8369 147.594 47.689C150.182 47.2839 152.65 46.3225 154.829 44.871C156.506 45.3987 158.253 45.6676 160.011 45.6685L220.01 45.7245C222.273 45.7328 224.515 45.291 226.605 44.425C228.696 43.5589 230.593 42.2859 232.187 40.6798C235.435 37.3644 237.24 32.8987 237.207 28.2576L237.22 14.652C237.239 13.5293 236.858 12.4364 236.145 11.5686Z"
          fill={color}
        />
        <path
          d="M213.85 22.4587L166.16 22.4141C166.023 22.414 165.889 22.387 165.763 22.3348C165.637 22.2825 165.523 22.206 165.426 22.1095C165.33 22.0131 165.254 21.8986 165.202 21.7727C165.15 21.6468 165.123 21.5118 165.123 21.3755C165.123 21.2393 165.15 21.1044 165.202 20.9785C165.255 20.8527 165.331 20.7384 165.428 20.6421C165.524 20.5458 165.639 20.4695 165.764 20.4175C165.89 20.3655 166.025 20.3387 166.162 20.3389L213.852 20.3834C214.127 20.3836 214.391 20.4932 214.585 20.688C214.78 20.8828 214.889 21.1468 214.889 21.422C214.888 21.6972 214.779 21.961 214.584 22.1554C214.389 22.3498 214.125 22.4589 213.85 22.4587Z"
          fill="white"
        />
        <path
          d="M213.844 28.8278L166.154 28.7833C165.879 28.783 165.615 28.6735 165.42 28.4787C165.226 28.2839 165.117 28.0199 165.117 27.7447C165.117 27.4695 165.227 27.2057 165.422 27.0112C165.617 26.8168 165.881 26.7078 166.156 26.708L213.846 26.7525C214.121 26.7528 214.385 26.8624 214.58 27.0571C214.774 27.2519 214.883 27.5159 214.883 27.7911C214.883 28.0663 214.773 28.3302 214.578 28.5246C214.383 28.719 214.119 28.8281 213.844 28.8278Z"
          fill="white"
        />
        <path
          d="M171.706 35.1576L166.148 35.1524C166.012 35.1523 165.877 35.1253 165.751 35.0731C165.625 35.0208 165.511 34.9443 165.415 34.8478C165.318 34.7514 165.242 34.6369 165.19 34.511C165.138 34.385 165.111 34.2501 165.111 34.1138C165.111 33.9776 165.138 33.8427 165.191 33.7168C165.243 33.591 165.319 33.4766 165.416 33.3804C165.512 33.2841 165.627 33.2078 165.753 33.1558C165.879 33.1037 166.014 33.077 166.15 33.0771L171.708 33.0823C171.982 33.083 172.246 33.1928 172.44 33.3875C172.634 33.5823 172.743 33.846 172.743 34.1209C172.742 34.3958 172.633 34.6594 172.439 34.8538C172.244 35.0481 171.981 35.1574 171.706 35.1576Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1153_12274">
          <rect
            width="253"
            height="224.83"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
